<template>
  <div class="modal floating">
    <div class="relative w-full h-full max-w-2xl md:h-auto">
      <!-- Modal content -->
      <div class="modal-inner-wrap no-select">
        <!-- Modal header -->
        <slot name="header">
          <div
            :class="[
              'flex items-start justify-between px-5 pt-3 pb-2',
              titleClass,
            ]"
          >
            <h3 class="text-lg font-semibold text-gray-600 dark:text-gray-200">
              <slot name="title" />
            </h3>
            <button
              v-if="!hideClose"
              type="button"
              class="btn-close"
              aria-label="Close (ESC)"
              :title="$t('buttons.close') + ' (ESC)'"
              @click="handleClose"
            >
              <PhX size="20" class="mt-0.5" />
            </button>
          </div>
        </slot>
        <!-- Modal body -->
        <div class="px-6 mt-4 mb-6 pt-3">
          <slot name="body" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { PhX } from '@phosphor-icons/vue';

const emit = defineEmits(['close']);
const props = defineProps({
  hideClose: Boolean,
  titleClass: {
    type: String,
    default: 'border-b rounded-t dark:border-black/30',
  },
});

const handleClose = (event: Event) => {
  // event.preventDefault();
  emit('close', true);
};
</script>
<style lang="scss" scoped>
//border-light-divider dark:border-dark-divider
.modal-inner-wrap {
  @apply relative bg-white rounded-lg shadow-lg dark:bg-dark-surface min-w-52
  border dark:border-black/40;
}
</style>
