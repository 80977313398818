<template>
  <div>
    <!--    <header-bar v-if="showHeader" showMenu showLogo />-->

    <section>
      <div class="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
        <div class="mx-auto max-w-screen-sm text-center">
          <h1
            class="mb-6 text-4xl tracking-tight font-bold text-gray-900 md:text-5xl dark:text-white"
          >
            <slot name="title">
              <div class='flex items-center justify-center space-x-3'>
                <PhNetworkSlash :size="50" weight="light" />
                <span>{{
                  title || "Resource Error"
                  }}</span>
              </div>
            </slot>
          </h1>
          <div class="mb-3 text-lg font-light text-gray-500 dark:text-gray-400">
            <div class="flex items-center justify-center space-x-1">
              <slot name="desciption">
                <i class="material-icons text-gray-400/70">{{ info.icon }}</i>
                <span class="pl-1">{{ t(info.message) }}</span>
              </slot>
            </div>
          </div>
          <div>
            <a
              :href="Directories.base"
              class="inline-flex text-white bg-blue-400 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:focus:ring-primary-900 my-4"
              ><ph-arrow-circle-right :size="20" weight="light" class="mr-2" />
              {{ t("sidebar.teamFolder") }}</a
            >
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script setup lang="ts">
import { Directories } from "@/utils/constants";
import { PhArrowCircleRight, PhGlobeX, PhNetworkSlash } from '@phosphor-icons/vue';
import { computed } from "vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n({});

const errors: {
  [key: number]: {
    icon: string;
    message: string;
  };
} = {
  0: {
    icon: "cloud_off",
    message: "errors.connection",
  },
  403: {
    icon: "error",
    message: "errors.forbidden",
  },
  404: {
    icon: "gps_off",
    message: "errors.notFound",
  },
  500: {
    icon: "error_outline",
    message: "errors.internal",
  },
};

const props = withDefaults(
  defineProps<{
    errorCode?: number;
    title?: string;
    showHeader?: boolean;
  }>(),
  {
    errorCode: 500,
    title: "",
    showHeader: false,
  }
);

const info = computed(() => {
  return errors[props.errorCode] ? errors[props.errorCode] : errors[500];
});
</script>
