{
  "title": "setFn",
  "subTitle": "チームスペース",
  "connection": {
    "failedMsg": "サーバーへの接続に失敗しました。"
  },
  "auth": {
    "login": {
      "createAnAccount": "アカウント作成",
      "loginInstead": "すでにアカウントがあります",
      "password": "パスワード",
      "passwordsDontMatch": "パスワードが一致しません",
      "signup": "登録する",
      "submit": "ログイン",
      "username": "ユーザー名",
      "usernameTaken": "そのユーザー名は既に使われています",
      "wrongCredentials": "ユーザー名またはパスワードが正しくありません"
    },
    "signIn": {
      "title": "ログイン",
      "explain": "",
      "agree": "利用規約およびプライバシーポリシーに同意しますか？",
      "forgetPwd": "パスワードをお忘れですか？",
      "submit": "ログイン",
      "signUpBtn": "新規登録",
      "errorOccurred": "ログインに失敗しました。",
      "forbiddenAccount": "アカウント情報を確認してください。",
      "unActivated": "ログインしましたが、まだアクティベートされていません。"
    },
    "signUp": {
      "title": "新規登録",
      "nickname": "ニックネーム",
      "password": "パスワード",
      "passwordConfirm": "パスワード確認",
      "submit": "登録",
      "completed": "登録が成功しました。",
      "response": {
        "notAllowed": "現在、会員登録は制限されています。",
        "errorOccurred": "登録に失敗しました。",
        "forbidden": "登録する権限がありません。",
        "conflictNick": "他の人が使用中のニックネームです。",
        "conflictUserName": "他の人が使用中のユーザー名です。"
      }
    },
    "invalid-msg": {
      "username": "正しいユーザー名を入力してください。",
      "nickname": "ニックネームを入力してください。",
      "password": "パスワードを入力してください。",
      "unMatchPassword": "パスワードが一致しません。",
      "email": "正しいメールアドレスを入力してください。",
      "max": "最大{max}文字まで入力できます。",
      "min": "最低{min}文字以上入力してください。"
    }
  },
  "invalid": {
    "required": "この項目は必須です。",
    "only-type": "{type}タイプのみ入力可能です。",
    "min": "最低{min}文字以上入力してください。",
    "max": "最大{max}文字まで入力可能です。",
    "duplicatedName": "重複した名前です。",
    "underscoresAlpha": "アルファベットとアンダースコア(_)のみ使用可能です。"
  },
  "fileMessages": {
    "conflict": "フォルダまたはファイルが既に存在します。",
    "invalidName": "無効な文字が含まれています。",
    "clipboardCopySuccess": "{cnt}個の項目がクリップボードにコピーされました。",
    "clipboardCutSuccess": "{cnt}個の項目がクリップボードに切り取りされました。",
    "moveSuccess": "{cnt}個の項目が移動されました。",
    "copySuccess": "{cnt}個の項目がコピーされました。",
    "deleteSuccess": "{cnt}個の項目が削除されました。",
    "noSelectedItem": "選択された項目がありません。",
    "history": {
      "copy": "コピーされました。",
      "copy+override": "コピーして上書きされました。",
      "copy+rename": "コピーして名前が変更されました。",
      "move":  "移動されました。",
      "move+override":  "移動して上書きされました。",
      "move+rename": "移動して名前が変更されました。",
      "edit": "編集されました。",
      "rename": "名前が変更されました。",
      "undefined": "-"
    }
  },
  "message": {
    "inValidFileName": "無効な文字が含まれています。",
    "multiTask": "{cnt}個の項目を{action}しました。",
    "folderTask": "フォルダを{action}しました。",
    "fileTask": "ファイルを{action}しました。",
    "linkCopied": "リンクがコピーされました！",
    "createFail": "作成に失敗しました。",
    "favoriteActionSuccess": "お気に入りが{action}されました。",
    "favoriteActionFail": "お気に入り{action}に失敗しました。",
    "conflictNameMsg": "同じ名前が既に存在します。",
    "forbiddenMsg": "{action}権限がありません。",
    "updateOnlyCreatorMsg": "作成者本人のみ設定できます。",
    "updateWarnAdminMsg": "作成者本人のみ設定可能ですが、管理者権限は設定可能です。",
    "passwordForbiddenMsg": "現在のパスワードが間違っているか、権限がありません。",
    "actionNewFolder": "フォルダ作成",
    "actionNewFile": "ファイル作成",
    "actionAdd": "追加",
    "actionDelete": "削除",
    "actionModify": "変更",
    "actionRemove": "削除",
    "loading": "読み込み中",
    "initializing": "初期化中"
  },
  "logsActions": {
    "register": "新規登録",
    "login": "ログイン",
    "logout": "ログアウト",
    "update": "情報更新",
    "delete": "アカウント削除"
  },
  "logsStatus": {
    "unactivated": "アカウント非アクティブ",
    "success": "成功",
    "fail": "失敗"
  },
  "accessLevel": {
    "setting": "レベル権限",
    "title": "メンバーレベルアクセス権限",
    "settingHelp": "自分のレベルまで設定可能",
    "forbiddenByLevelExplain": "{level}レベル以上のアクセス権限が必要です。管理者に連絡してください。",
    "forbiddenExplain": "アクセス権限がありません。管理者にお問い合わせください。",
    "myLevel": "自分のレベル",
    "myLevelHelp": "ユーザーがフォルダにレベル別のアクセス権限を設定した場合、自分のレベルが必要です。自分のレベルは管理者によって1から10まで設定可能で、設定されたレベルに応じてフォルダにアクセスできます。"
  },
  "tableEditor": {
    "addTop": "上に追加",
    "addBottom": "下に追加",
    "addCopyTop": "一番上にコピーして追加",
    "addCopyBottom": "一番下にコピーして追加",
    "addCopyAbove": "選択の上にコピーして追加",
    "addCopyBelow": "選択の下にコピーして追加",
    "delRow": "選択した行を削除",
    "modified": "修正済み",
    "addSchema": "スキーマ作成",
    "convertor": "コンバータ",
    "name": "名前"
  },
  "workflow": {
    "existWorksReady": "合計{cnt}件の作業が準備されました。",
    "existWorksComplete": "合計{cnt}件の作業が完了しました。",
    "overwriteFileCheckMsg": "同じファイルが存在する場合は上書きします",
    "destinationPath": "保存先フォルダの設定",
    "cancelExe": "実行中止",
    "genExecution": "ジェネレータ実行",
    "createFolder": "フォルダ作成",
    "createFile": "ファイル作成"
  },
  "buttons": {
    "list": "リスト",
    "add": "追加",
    "back": "戻る",
    "cancel": "キャンセル",
    "clear": "クリア",
    "close": "閉じる",
    "continue": "続ける",
    "copy": "コピー",
    "copyFile": "ファイルをコピー",
    "copyToClipboard": "クリップボードにコピー",
    "copyDownloadLinkToClipboard": "ダウンロードリンクをコピー",
    "create": "作成",
    "delete": "削除",
    "download": "ダウンロード",
    "file": "ファイル",
    "folder": "フォルダ",
    "fullScreen": "全画面",
    "hideDotfiles": "隠しファイルを非表示にする",
    "info": "情報",
    "more": "もっと見る",
    "move": "移動",
    "moveFile": "ファイルを移動",
    "new": "新規",
    "next": "次へ",
    "ok": "OK",
    "permalink": "링크 생성",
    "previous": "前へ",
    "publish": "배포",
    "rename": "名前を変更",
    "replace": "置き換え",
    "reportIssue": "問題を報告",
    "save": "保存",
    "schedule": "スケジュール",
    "search": "検索",
    "select": "選択",
    "selectMultiple": "複数選択",
    "share": "共有",
    "shell": "シェル切り替え",
    "submit": "送信",
    "switchView": {
      "title": "表示オプション",
      "list": "リスト",
      "tiles": "タイル",
      "gallery": "ギャラリー"
    },
    "toggleSidebar": "サイドバーを切り替え",
    "update": "更新",
    "upload": "アップロード",
    "openFile": "ファイルを開く",
    "discardChanges": "変更を破棄"
  },
  "level": {
    "bulkApply": "レベル一括適用",
    "auth_list": "ファイルリスト表示",
    "auth_view": "ファイル閲覧権限",
    "auth_write": "ファイル書き込み権限",
    "above": "レベル以上"
  },
  "download": {
    "downloadFile": "ファイルをダウンロード",
    "downloadFolder": "フォルダをダウンロード",
    "downloadSelected": "選択した項目をダウンロード"
  },
  "upload": {
    "abortUpload": "進行中を中止しますか？"
  },
  "errors": {
    "forbiddenLevel": "特定のレベル以上のみアクセス可能です。",
    "conflict": "同じ内容が既に存在します。",
    "forbidden": "アクセス権限がありません。",
    "internal": "エラーが発生しました。",
    "notFound": "指定されたパスが見つかりません。",
    "connection": "サーバーに接続できません。"
  },
  "files": {
    "body": "本文",
    "creator": "作成者",
    "explain": "説明",
    "closePreview": "プレビューを閉じる",
    "files": "ファイル",
    "folders": "フォルダ",
    "levelFolders": "レベルフォルダ",
    "home": "ホーム",
    "lastModified": "最終更新",
    "loading": "読み込み中...",
    "listEmpty": "アップロードするファイルやフォルダをここにドラッグしてください。",
    "lonely": "データがありません。",
    "metadata": "メタデータ",
    "multipleSelectionEnabled": "複数選択が有効です",
    "name": "名前",
    "size": "サイズ",
    "countDirEntries": "項目数 {cnt}個",
    "sortByLastCreated": "作成日時順で並べ替え",
    "sortByLastModified": "更新日時順で並べ替え",
    "sortByName": "名前順で並べ替え",
    "sortBySize": "サイズ順で並べ替え",
    "sortAsc": "昇順",
    "sortDesc": "降順",
    "creatorBySize": "作成者順",
    "noPreview": "このファイルはプレビューできません。",
    "selected": "{0}個選択されました",
    "storage" : {
      "title": "ストレージ",
      "usage": "{total} のうち {used} 使用中"
    }
  },
  "content": {
    "noLatestFiles": "最近使用されたファイルはありません。"
  },
  "help": {
    "click": "ファイルまたはフォルダを選択",
    "ctrl": {
      "click": "複数のファイルまたはフォルダを選択",
      "f": "検索ウィンドウを開く",
      "s": "ファイルまたはフォルダをダウンロード"
    },
    "del": "選択したファイルを削除",
    "doubleClick": "ファイルまたはフォルダを開く",
    "esc": "選択解除/プロンプトを閉じる",
    "f1": "情報",
    "f2": "ファイル名を変更",
    "help": "ヘルプ",
    "n": "新しいファイル"
  },
  "permanent": "永久",
  "prompts": {
    "copyToFolder": "フォルダーにコピー",
    "copyToMessage": "選択したファイルまたはフォルダをコピーする対象のフォルダを選択してください:",
    "currentlyNavigating": "現在の位置:",
    "deleteMessageMultiple": "{count}個のファイルを削除しますか？",
    "deleteMessageSingle": "本当に削除しますか？",
    "deleteMessageShare": "'{path}'の共有を解除しますか？",
    "deleteUser": "このユーザーを削除しますか？",
    "deleteTitle": "ファイル削除",
    "deleteTitle": "ファイル削除",
    "displayName": "表示名",
    "download": "ファイルダウンロード",
    "downloadMessage": "ダウンロードする圧縮フォーマットを選択してください。",
    "error": "エラー発生！",
    "folderInfo": "フォルダ情報",
    "fileInfo": "ファイル情報",
    "fileHistory": "変更記録",
    "fileDetailInfo": "ファイル詳細情報",
    "fileOptions": "ファイル表示オプション",
    "filesSelected": "{count}個のファイルが選択されました。",
    "itemSelected": "{count}個の項目が選択されました",
    "lastModified": "最終更新",
    "move": "移動",
    "moveMessage": "移動するファイルまたはフォルダを選択してください:",
    "newArchetype": "原型を保持してポストを作成します。ファイルはコンテンツフォルダに作成されます。",
    "newDir": "新しいフォルダ",
    "newDirMessage": "新しいフォルダ名を入力してください。",
    "newFile": "新しいファイル",
    "newSchemaFile": "新しいスキーマ（TPL）",
    "screenRecorder": "スクリーン録画",
    "newFileMessage": "新しいファイル名を入力してください。",
    "numberDirs": "フォルダ数",
    "numberFiles": "ファイル数",
    "rename": "名前変更",
    "renameMessage": "変更する名前を入力してください。",
    "replace": "置き換え",
    "replaceMessage": "同じ名前のファイルが存在します。現在のファイルを上書きしますか？\n",
    "schedule": "スケジュール",
    "scheduleMessage": "この記事を公開する時間を教えてください。",
    "show": "表示",
    "size": "サイズ",
    "upload": "アップロード",
    "uploadFiles": "{files}ファイルをアップロード中..",
    "uploadMessage": "アップロードするオプションを選択してください。",
    "optionalPassword": "オプションパスワード",
    "resolution": "解像度",
    "discardEditorChanges": "変更を破棄しますか？"
  },
  "search": {
    "images": "画像",
    "music": "音楽",
    "pdf": "PDF",
    "searchNoResult": "検索結果がありません。",
    "searchPath": "検索パス",
    "fileSearch": "ファイル名検索",
    "typeToSearch": "検索キーワードを入力",
    "types": "タイプ",
    "video": "ビデオ",
    "md": "マークダウン"
  },
  "uploadFile": {
    "remaining": "残り",
    "calculating": "残り時間を計算中"
  },
  "settings": {
    "sort": "並べ替え",
    "currentFolder": "フォルダ設定",
    "defaultSwitchView": "表示オプション",
    "admin": "管理者",
    "administrator": "管理者",
    "allowCommands": "コマンド実行を許可",
    "allowEdit": "ファイル/フォルダの修正/変更/削除を許可",
    "allowNew": "ファイル/フォルダの作成を許可",
    "allowPublish": "新しい投稿/ページの作成を許可",
    "allowSignup": "ユーザー登録を許可",
    "avoidChanges": "（変更しない場合は空欄のままにしてください）",
    "changePassword": "パスワードを変更する",
    "commandRunner": "コマンドランナー",
    "commandRunnerHelp": "イベントに対応するコマンドを設定してください。一行につき一つのコマンドを記載します。環境変数 {0} および {1} を使用できます。 {0} は {1} に相対するパスです。詳細は {2} を参照してください。",
    "commandsUpdated": "コマンドが更新されました！",
    "tusUploads": "分割アップロード",
    "tusUploadsChunkSize": "一度にアップロードするデータのサイズを設定します。サイズが大きいほどアップロード速度が向上する可能性がありますが、ネットワークの状態やサーバーの処理能力（同時接続数、帯域幅など）によってはアップロード失敗の確率が高くなることがあります。「10MB」や「1GB」といった文字列、またはバイト単位の数字を入力できます。",
    "tusUploadsRetryCount": "アップロードが失敗した場合に再試行する回数を設定します。",
    "userScopeGenerationPlaceholder": "入力がない場合は自動生成されます。",
    "createUserHomeDirectory": "ユーザーホームフォルダを作成",
    "customStylesheet": "カスタムスタイルシート",
    "defaultUserDescription": "新規登録ユーザーの権限、レベルなどは、以下に設定されたデフォルト値が自動的に適用されます。",
    "disableUsedDiskPercentage": "使用済みディスク容量グラフを無効化",
    "documentation": "ドキュメント",
    "examples": "例",
    "hideDotfiles": "隠しファイルを非表示",
    "insertPath": "パスを入力",
    "insertRegex": "正規表現を入力",
    "instanceName": "インスタンス名",
    "language": "言語",
    "newPassword": "新しいパスワード",
    "newPasswordConfirm": "新しいパスワード（確認）",
    "newUser": "新規ユーザー",
    "password": "パスワード",
    "passwordUpdated": "パスワードが更新されました！",
    "path": "パス",
    "perm": {
      "list": "ファイルリスト表示",
      "create": "ファイル/フォルダ作成",
      "delete": "ファイル/フォルダ削除",
      "rename": "名前の変更と移動",
      "download": "ダウンロード",
      "execute": "コマンド実行",
      "modify": "ファイル内容編集",
      "view": "ファイル内容表示",
      "share": "ファイル共有"
    },
    "permissions": "権限",
    "adminPermHelp": "メンバー管理とファイル権限を含む全ての権限が有効になります。",
    "permissionsHelp": "基本権限を設定します。フォルダごとに細かい権限はフォルダ作成後に追加で設定可能です。",
    "profileSettings": "プロフィール設定",
    "ruleExample1": "ピリオド（.）で始まるすべてのファイルへのアクセスを禁止します（例：.git, .gitignore）。",
    "ruleExample2": "Caddyfileファイルへのアクセスを禁止します。",
    "rules": "ルール",
    "rulesHelp": "ユーザーごとに許可または禁止するルールを指定できます。禁止されたファイルは表示されず、ユーザーはアクセスできません。ユーザーのアクセス許可範囲に関する正規表現（regex）とパスをサポートしています。",
    "scope": "パス",
    "setDateFormat": "正確な日付形式を設定",
    "settingsUpdated": "設定が適用されました！",
    "shareDuration": "共有期間",
    "shareManagement": "共有管理",
    "shareDeleted": "共有から削除されました。",
    "shareAdded": "共有に追加されました。",
    "singleClick": "ワンクリックでファイルやフォルダを開く",
    "themes": {
      "default": "システム",
      "dark": "ダーク",
      "light": "ライト",
      "title": "テーマ"
    },
    "user": "ユーザー",
    "userCommands": "コマンド",
    "userCommandsHelp": "ユーザーに許可するコマンドをスペース区切りで入力してください。例：",
    "userCreated": "ユーザーが作成されました！",
    "userDefaults": "ユーザーデフォルト設定",
    "userDeleted": "ユーザーが削除されました！",
    "userManagement": "ユーザー管理",
    "userUpdated": "ユーザーが更新されました！",
    "username": "ユーザー名",
    "nick": "ニックネーム",
    "users": "ユーザー",
    "isActive": "アクティブ",
    "isActiveDefaultHelp": "登録時にすぐアカウントを有効化し、ログインを可能にします。",
    "level": "レベル",
    "currentPassword": "現在のパスワード",
    "status": "ステータス",
    "action": "アクション",
    "timestamp": "タイムスタンプ"
  },
  "sidebar": {
    "help": "ヘルプ",
    "hugoNew": "新しいコンテンツ",
    "login": "ログイン",
    "logout": "ログアウト",
    "myFiles": "マイファイル",
    "teamFolder": "チームフォルダ",
    "newFile": "新しいファイル",
    "newFolder": "新しいフォルダ",
    "preview": "プレビュー",
    "settings": "設定",
    "signup": "サインアップ",
    "siteSettings": "サイト設定",
    "myContent": "マイコンテンツ",
    "make": "作成",
    "favFolder": "お気に入り",
    "latestFolder": "最近のドキュメント"
  },
  "adminMenu": {
    "home": "ホーム",
    "authLogs": "アクセス記録",
    "users": "ユーザー管理",
    "configSettings": "設定",
    "globalSettings": "グローバル設定"
  },
  "pageMenu": {
    "home": "ホーム",
    "ai": "AI",
    "team": "チーム"
  },
  "time": {
    "days": "日",
    "hours": "時",
    "hours2": "時間",
    "minutes": "分",
    "seconds": "秒",
    "unit": "時間単位"
  }
}
