<template>
  <div v-if="layoutStore.initMessage.length > 0" class="message-container">
    <div class="title">
      <slot />
    </div>
    <div class="message-display">
      {{ lastMessage }}
    </div>
  </div>
</template>

<script setup lang="ts">
import { useLayoutStore } from "@/stores/layout";
import { computed, watch } from "vue";

const layoutStore = useLayoutStore();

// 마지막 메시지 계산
const lastMessage = computed(() => {
  return layoutStore.initMessage.length > 0
    ? layoutStore.initMessage[layoutStore.initMessage.length - 1]
    : "";
});

// 스크롤 애니메이션을 추가할 경우 (옵션)
watch(
  () => layoutStore.initMessage,
  () => {
    // 메시지 업데이트 시 애니메이션 트리거
    const container = document.querySelector(".message-display");
    if (container) {
      container.classList.add("animate");
      setTimeout(() => container.classList.remove("animate"), 300);
    }
  }
);
</script>
<style lang="scss" scoped>
.message-container {
  opacity: 80%;
  //display: flex;
  //justify-content: center;
  //align-items: center;
  //height: 50px;
  //margin: 10px auto;
  overflow: hidden;
  font-size: 12px;
  //font-weight: bold;
  //color: #4a4a4a;
  .title {
    display: flex;
    justify-items: center;
    align-items: center;
    gap: 5px;
  }
}

.message-display {
  //padding: 10px 20px;
  //border: 1px solid #e5e5e5;
  white-space: nowrap;
  //background-color: #f9f9f9;
  border-radius: 5px;
  animation: fadeIn 0.3s ease-in-out;
}

.message-display.animate {
  animation: slideIn 0.5s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideIn {
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
</style>
