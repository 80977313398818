import { Password } from "@/class/Password";
import { fetchJSON, fetchURL, StatusError } from "./utils";

const oPassword = new Password();

export async function getAll(limit: number, offset: number) {
  let addUrl = "";
  if (limit && offset !== null) {
    addUrl = `?limit=${limit}&offset=${offset}`;
  }
  return fetchJSON<IUser[]>(`/api/users${addUrl}`, {});
}

export async function get(id: number) {
  return fetchJSON<IUser>(`/api/users/${id}`, {});
}

export async function create(user: IUser) {
  if (user.password) {
    user.password = oPassword.getSha3And256(user.password);
  }

  const res = await fetchURL(`/api/users`, {
    method: "POST",
    body: JSON.stringify({
      table: "user",
      field: [],
      data: user,
    }),
  });

  if (res.status === 201) {
    return res.headers.get("Location");
  }

  throw new StatusError(await res.text(), res.status);
}

export async function update(user: Partial<IUser>, field = ["all"]) {
  if (user.password) {
    user.password = oPassword.getSha3And256(user.password);
  }
  if (user.passwordConfirm) {
    user.passwordConfirm = oPassword.getSha3And256(user.passwordConfirm);
  }
  await fetchURL(`/api/users/${user.id}`, {
    method: "PUT",
    body: JSON.stringify({
      table: "user",
      field: field,
      data: user,
    }),
  });
}

export async function remove(id: number) {
  await fetchURL(`/api/users/${id}`, {
    method: "DELETE",
  });
}
