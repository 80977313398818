<script setup>
import { ValidateChecker } from '@/class/ValidateChecker';
import InvalidFeedback from '@/components/form/InvalidFeedback.vue';
import { reactive, ref, watch } from 'vue';

const props = defineProps({
  input: {
    type: String || Boolean || Array,
    default: '',
  },
  rules: {
    type: Array,
    default: null,
  },
  onStatus: {
    type: String,
    default: 'input',
  },
  addInvalidMsg: {
    default: null,
  },
});

let validateError = reactive([]);
const validation = new ValidateChecker();
const errorMessage = ref('');
// const emitError = defineEmits<{
//   (e: 'onError', error: string[]): void;
// }>();

const emitError = defineEmits(['onError']);

watch(
  () => props.onStatus,
  value => {
    if (value === 'blur') {
      checkValidation();
      showAllErrMsg();
    }
  }
);

watch(
  () => props.input,
  value => {
    checkValidation(value);
  }
);

watch(
  () => props.addInvalidMsg,
  () => {
    if (props.addInvalidMsg) {
      validateError = [props.addInvalidMsg];
      showAllErrMsg();
    }
  }
);

const showAllErrMsg = () => {
  if (validateError.length > 0) {
    errorMessage.value = validateError.join('<br />');
  }
};

const checkValidation = v => {
  if (!v) v = props.input; //입력값

  if (!props.rules || props.rules.length === 0) {
    errorMessage.value = '';
  } else {
    validateError = [];
    errorMessage.value = '';

    for (let i = 0; i < props.rules.length; i++) {
      const options = props.rules[i];

      // option check
      for (const [var_key, val_value] of Object.entries(options)) {
        if (
          var_key === 'type' ||
          var_key === 'message' ||
          var_key === 'trigger'
        ) {
          // skip
        } else if (var_key === 'validator') {
          options.validator(options, v, error => {
            if (error) {
              if (options.trigger !== 'blur')
                errorMessage.value = error.message;
              validateError.push(error.message);
            }
          });
        } else {
          validation.setValue(v);
          const ck = validation.isValidParamRules(var_key, val_value);
          // error+
          if (!ck) {
            const errorMsg = Object.hasOwn(options, 'message')
              ? options.message
              : '[]' + var_key;

            if (options['trigger'] !== 'blur') errorMessage.value = errorMsg;
            validateError.push(options.message || `${errorMsg}`);
          }
        }
      }
    }
    emitError('onError', validateError);
  }
};
</script>
<template>
  <invalid-feedback :invalid-msg="errorMessage" />
</template>
