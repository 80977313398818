<script setup lang="ts">
import Action from "@/components/files/Action.vue";
import { useLayoutStore } from "@/stores/layout";
import { computed, useAttrs } from "vue";
import { useI18n } from "vue-i18n";

const props = defineProps({
  action: {
    type: String,
    required: true,
  },
  label: {
    type: String,
    required: true,
  },
  showLabel: {
    type: Boolean,
    default: true,
  },
  onClick: {
    type: Function,
    required: false,
  },
  iconComponent: {},
  iconSize: {
    type: Number,
    default: 20,
  },
});

const { t } = useI18n();
const attrs = useAttrs();

const emit = defineEmits<{
  (e: "action");
}>();

const layoutStore = useLayoutStore();

const action = () => {
  if (props.onClick && typeof props.onClick === "function") {
    return props.onClick();
  } else if (props.action) {
    layoutStore.showHover(props.action);
  }
  emit("action");
};
</script>

<template>
  <div
    v-bind="attrs"
    :aria-label="t(label)"
    :title="t(label)"
    class="action relative inline-flex items-center"
    @click="action"
  >
    <component :is="iconComponent" :size="iconSize" weight="thin" />
    <span v-if="showLabel">{{ t(props.label) }}</span>
  </div>

  <!--  <action-->
  <!--    v-bind="attrs"-->
  <!--    v-if="show"-->
  <!--    :key="item.key"-->
  <!--    :label="t(item.label)"-->
  <!--    :show-label="-->
  <!--      typeof item.showLabel === 'function' ? item.showLabel() : item.showLabel-->
  <!--    "-->
  <!--    :show="item['onClick'] ? null : item.key"-->
  <!--    @click=""-->
  <!--  >-->
  <!--    <template #icon>-->
  <!--      <component :is="item.component" :size="item.iconSize" weight="thin" />-->
  <!--    </template>-->
  <!--  </action>-->
</template>
<style scoped lang="scss">
//div {
//  @apply space-x-2 inline-block hover:bg-neutral-100 dark:hover:bg-black rounded-md py-1 px-2 mx-1;
//  svg {
//    display: inline-block;
//  }
//}
</style>
