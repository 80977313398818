import { Directories } from '@/utils/constants';
import url from '../utils/url';
import { fetchURL, removePrefixUrl } from './utils';

export default async function search(base: string, query: string) {
  base = removePrefixUrl(base);
  query = encodeURIComponent(query);

  if (!base.endsWith('/')) {
    base += '/';
  }

  const res = await fetchURL(`/api/search${base}?query=${query}`, {});

  let data = await res.json();

  data = data.map((item: UploadItem) => {
    item.url = `${Directories.base}${base}` + url.encodePath(item.path);

    if (item.dir) {
      item.url += '/';
    }

    return item;
  });

  return data;
}
