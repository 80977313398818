<template>
  <div
    v-if="filesInUploadCount > 0"
    class="upload-message"
    :class="{ closed: !open }"
  >
    <div class="card floating">
      <h2>{{ $t('prompts.uploadFiles', { files: filesInUploadCount }) }}</h2>
      <div class="card-title">
        <div class="upload-info">
          <div v-if="formattedETA">
            {{ formattedETA }} {{ t('uploadFile.remaining') }}
          </div>
          <div v-else>
            {{ t('uploadFile.calculating') }}
          </div>
          <div class="text-sm text-gray-500">
            {{ uploadSpeed.toFixed(2) }} MB/s
          </div>
        </div>
        <button
          class="action"
          aria-label="Abort upload"
          title="Abort upload"
          @click="abortAll"
        >
          <i class="material-icons">{{ 'cancel' }}</i>
        </button>
        <button
          class="action"
          aria-label="Toggle file upload list"
          title="Toggle file upload list"
          @click="toggle"
        >
          <i class="material-icons">{{
            open ? 'keyboard_arrow_down' : 'keyboard_arrow_up'
          }}</i>
        </button>
      </div>

      <div class="card-content file-display-section">
        <div
          v-for="file in filesInUpload"
          :key="file.id"
          class="file"
          :data-dir="file.isDir"
          :data-type="file.type"
          :aria-label="file.name"
        >
          <div class="file-name">
            <i class="material-icons"></i> {{ file.name }}
          </div>
          <div class="file-progress">
            <div :style="{ width: file.progress + '%' }"></div>
          </div>
          <div class="flex items-center justify-between">
            <div class="text-gray-600">
              {{ getTotalProgressBytes }} / {{ getTotalSize }}
            </div>
            <div class="text-gray-600">{{ getProgressDecimal }}%</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { abortAllUploads } from '@/api/tus';
import { useFileStore } from '@/stores/file';
import { useUploadStore } from '@/stores/upload';
import buttons from '@/utils/buttons';
import { storeToRefs } from 'pinia';

const { t } = useI18n();

const open = ref(false);

const uploadStore = useUploadStore();
const fileStore = useFileStore();

const { reset } = uploadStore;
const {
  filesInUpload,
  filesInUploadCount,
  uploadSpeed,
  getETA,
  getProgressDecimal,
  getTotalProgressBytes,
  getTotalSize,
} = storeToRefs(uploadStore);

const { reload } = storeToRefs(fileStore);

const formattedETA = computed(() => {
  const eta = getETA.value;

  if (!eta || eta === Infinity) {
    return '';
  }

  const totalSeconds = Math.round(eta);

  if (totalSeconds < 60) {
    return `${totalSeconds}${t('time.seconds')}`;
  } else if (totalSeconds < 3600) {
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    return `${minutes}${t('time.minutes')} ${seconds}${t('time.seconds')}`;
  } else {
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    return `${hours}${t('time.hours2')} ${minutes}${t('time.minutes')}`;
  }
});

function toggle() {
  open.value = !open.value;
}

function abortAll() {
  if (confirm(t('upload.abortUpload'))) {
    abortAllUploads();
    buttons.done('upload');
    open.value = false;
    reset(); // 업로드 스토어 상태 초기화
    reload.value = true; // 파일 스토어에서 리로드 트리거
  }
}
</script>

<style lang="scss" scoped>
.card {
  position: relative;
  margin: 0 0 0rem 0;
  background: var(--surfacePrimary);
  color: var(--textSecondary);
  border-radius: 2px;
  //box-shadow:
  //  0 2px 2px 0 rgba(0, 0, 0, 0.14),
  //  0 1px 5px 0 rgba(0, 0, 0, 0.12),
  //  0 3px 1px -2px rgba(0, 0, 0, 0.2);
  overflow: auto;

  &.floating {
    position: fixed;
    top: 40%;
    left: 50%;
    transform: translate(-40%, -50%);
    max-width: 25em;
    width: 90%;
    max-height: 95%;
    /* animation-duration: 0.3s;
    animation-fill-mode: forwards; */
  }

  & > * > *:first-child {
    margin-top: 0;
  }

  & > * > *:last-child {
    margin-bottom: 0;
  }

  & .card-title {
    padding: 1em 1em 0.5em;
    display: flex;
  }

  & .card-title > *:first-child {
    margin-right: auto;
  }

  & > div {
    padding: 1em 1em;
  }

  & > div:first-child {
    padding-top: 1.5em;
  }

  & > div:last-child {
    padding-bottom: 1.5em;
  }

  & .card-title * {
    margin: 0;
  }

  & .card-action {
    text-align: right;
  }

  & .card-content.full {
    padding-bottom: 0;
    overflow: auto;
  }

  & h2 {
    font-weight: 500;
  }

  & h3 {
    color: var(--textPrimary);
    font-size: 1em;
    font-weight: 500;
    margin: 2em 0 1em;
  }

  .card-content table {
    margin: 0 -1em;
    width: calc(100% + 2em);
  }
}
.upload-message .card.floating {
  //position: absolute;
  z-index: 1000;
  left: auto;
  top: auto;
  margin: 0;
  right: 10px;
  bottom: 10px;
  transform: none;
  @apply shadow-xl rounded-md border border-neutral-200 dark:border-neutral-600;
  h2 {
    @apply font-bold px-4 py-2 border-b border-neutral-200 dark:border-neutral-600;
  }
  & .file {
    margin-bottom: 8px;

    & .file-name {
      font-size: 1.1em;
      display: flex;
      align-items: center;
    }

    & .file-name i {
      margin-right: 5px;
    }

    & .file-progress {
      margin-top: 2px;
      width: 100%;
      height: 5px;
      background-color: #eee;
    }

    & .file-progress div {
      height: 100%;
      background-color: var(--dark-blue);
      width: 0;
      transition: 0.2s ease width;
      border-radius: 10px;
    }
  }

  &.closed .card-content {
    display: none;
    padding: 0em 1em 1em 1em;
  }

  & .card .card-title {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.8em;
    padding: 1em 1em 0em;
  }

  &.closed .card-title {
    font-size: 0.7em;
    padding: 0.5em 1em;
  }
}

@media (max-width: 450px) {
  .upload-message .card.floating {
    max-width: 100%;
    width: 100%;
  }
}
</style>
