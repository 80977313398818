<template>
  <div class="relative">
    <!-- 타임라인 세로선 -->
    <div
      class="absolute left-5 top-0 w-0.5 h-full z-0 border-s border-gray-200"
    ></div>

    <ul class="space-y-6 z-1">
      <template v-for="(group, groupName) in groupedHistory" :key="groupName">
        <!-- 그룹 이름 표시 -->
        <li
          class="pb-0.5 text-lg font-semibold text-gray-800 relative z-10 bg-neutral-100 dark:bg-dark-surface"
        >
          {{ groupName }}
        </li>

        <!-- 그룹의 아이템 목록 -->
        <li
          v-for="(item, index) in group"
          :key="item.id"
          class="relative flex items-start"
        >
          <!-- 아이콘 -->
          <div
            class="flex flex-col items-center rounded-full border-4 border-gray-200"
          >
            <div
              class="w-8 h-8 flex items-center justify-center text-white rounded-full"
              :class="getIconBgColor(item.details.ch_type)"
            >
              <span class="material-icons text-white">
                {{ getActionIcon(item.details.ch_type) }}
              </span>
            </div>
          </div>

          <!-- 내용 -->
          <div class="ml-2">
            <div class="space-y-1">
              <p class="text-sm text-gray-700">
                {{ t(`fileMessages.history.${item.details.ch_type}`) }}
              </p>
              <p class="text-xs text-gray-500">
                {{ formatDate(item.timestamp) }}
              </p>
              <p class="text-sm font-semibold text-gray-800">
                {{ item.creator }}
              </p>
            </div>
            <div>
              <p v-if="item.details.source" class="mt-1 text-xs text-gray-500">
                <strong>From:</strong> {{ item.details.source }}
              </p>
              <p v-if="item.details.dest" class="mt-1 text-xs text-gray-500">
                <strong>To:</strong> {{ item.details.dest }}
              </p>
            </div>
          </div>
        </li>
      </template>
    </ul>
  </div>
</template>

<script setup lang="ts">
import { humanTime, localDetailTime } from "@/class/FormatData";
import {
  HistoryItem,
  ParsedHistoryItem,
} from "@/class/interface/drive/metadata";
import moment from "moment";
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const props = defineProps<{
  history: HistoryItem[];
}>();

// Action에 따른 아이콘 매핑
const getActionIcon = (ch_type: string): string => {
  // const icons: Record<string, string> = {
  //   copy: "file_copy",
  //   "copy+override": "content_copy",
  //   "copy+rename": "drive_file_rename_outline",
  //   move: "swap_horiz",
  //   "move+override": "drive_file_move",
  //   "move+rename": "drive_file_move_outline",
  //   edit: "edit",
  //   rename: "drive_file_rename_outline",
  // };
  const icons: Record<string, string> = {
    copy: "file_copy",
    "copy+override": "file_copy",
    "copy+rename": "file_copy",
    move: "swap_horiz",
    "move+override": "swap_horiz",
    "move+rename": "swap_horiz",
    edit: "edit",
    rename: "edit",
  };
  return icons[ch_type] || "help_outline";
};

const getIconBgColor = (ch_type: string): string => {
  const icons: Record<string, string> = {
    copy: "bg-blue-400",
    "copy+override": "bg-orange-400",
    "copy+rename": "bg-yellow-400",
    move: "bg-blue-400",
    "move+override": "bg-orange-500",
    "move+rename": "bg-yellow-400",
    edit: "bg-blue-400",
    rename: "bg-blue-400",
  };
  return icons[ch_type] || "help_outline";
};

// Timestamp 포맷팅 함수
const formatDate = (timestamp: number): string => {
  return localDetailTime(timestamp * 1000);
};

// details 파싱 및 확장
const parsedHistory = computed(() =>
  props.history?.map(item => ({
    ...item,
    details: JSON.parse(item.details || "{}"),
  }))
);

// History를 그룹화하여 '오늘', '어제', '지난달' 등으로 분류
const groupedHistory = computed(() => {
  const grouped: Record<string, ParsedHistoryItem[]> = {};

  parsedHistory.value?.forEach(item => {
    const timeDescription = humanTime(item.timestamp * 1000); // 예: "a few hours ago", "어제", "오늘" 등

    // humanTime 결과를 그대로 그룹 이름으로 사용
    const groupName = timeDescription;

    // 그룹이 없다면 새로 생성
    if (!grouped[groupName]) {
      grouped[groupName] = [];
    }

    // 그룹에 아이템 추가
    grouped[groupName].push(item);
  });

  return grouped;
});
</script>
<style scoped>
.material-icons {
  font-size: 16px;
  line-height: 1;
}
</style>
