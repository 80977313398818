import { StatusError } from "@/api/utils";
import { ref } from "vue";

export const statusError = ref<StatusError | null>(null);
export const scriptError = ref<Error | null>(null);

export function componentHandleError(err: unknown) {
  console.log("err", err, err instanceof StatusError);
  if (err instanceof StatusError) {
    statusError.value = err;
    // console.log("status", err);
  } else if (err instanceof Error) {
    scriptError.value = err;
    // console.error("err", err.message);
  } else {
    scriptError.value = new Error("An unknown error occurred");
  }
}

export function componentResetErrors() {
  statusError.value = null;
  scriptError.value = null;
}
