<script setup lang="ts">
// import { ValidateChecker } from "@/class/ValidateChecker";
// import OcInputValidFeedback from "@/components/form/CusInputValidFeedback.vue";

import CusInputValidFeedback from '@/components/form/CusInputValidFeedback.vue';
import { nextTick, onMounted, reactive, ref, useAttrs } from 'vue';

interface Style {
  default: { [key: string]: string | number | undefined };
}

const attrs = useAttrs();
const props = defineProps({
  modelValue: {
    type: [String, Boolean, Number, Array],
  },
  type: {
    type: String,
    default() {
      return 'input';
    },
  },
  rules: {
    type: Array,
    default: null,
  },
  placeholder: {
    type: String,
    default: '',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  label: {
    type: String,
    default: '',
  },
  name: {
    type: String,
    default: null,
  },
  className: {
    type: String,
    default: 'form-control',
  },
  invalidClass: {
    type: String,
    default: 'border-red-500',
  },
  validClass: {
    type: String,
    default: 'border-blue-500',
  },
  addInvalidMsg: {
    type: String,
    default: null,
  },
  // style: {
  //   type: Object as PropType<Style>,
  //   required: false,
  //   default: null,
  // },
  style: {
    type: Object,
    required: false,
    default: null,
  },
  focus: {
    type: Boolean,
    default: false,
  },
  selectText: {
    type: Boolean,
    default: false,
  },
});

// Validation
const onStatus = ref('');
const isInputComplete = ref(false);
const isInputError = ref(false);
const validateError = reactive<string[]>([]);

// model 상호작용
const emitModel = defineEmits<{
  (e: 'update:modelValue', data): void;
}>();
const updateModelValue = value => {
  emitModel('update:modelValue', value);
};

const onInput = event => {
  const value = event.target.value;
  setInputStatusStyle('input');
  updateModelValue(value); //v-model
};

const onFocus = () => {
  setInputStatusStyle('focus');
};

const onBlur = () => {
  setInputStatusStyle('blur');
  if (props.rules) {
    if (
      (!validateError || validateError.length === 0) &&
      !props.addInvalidMsg
    ) {
      if (props.modelValue) setInputStatusStyle('blur', false, true);
    } else {
      setInputStatusStyle('blur', true, false);
    }
  }
};

const setInputStatusStyle = (
  eventStatus: string,
  error = false,
  success = false
) => {
  onStatus.value = eventStatus;
  isInputComplete.value = success;
  isInputError.value = error;
};

onMounted(() => {
  if (props.selectText) {
    eventSelectText();
  }
  if (props.focus) eventFocus();
});

const innerInput = ref(null);
// 텍스트 선택 함수 (포커스될 때) 확장자 전까지
const eventSelectText = async () => {
  if (innerInput.value) {
    await nextTick();
    const input = innerInput.value;
    const dotIndex = input.value.lastIndexOf('.');
    input.select();
    if (dotIndex !== -1) {
      input.setSelectionRange(0, dotIndex);
    }
  }
};

const eventFocus = async () => {
  await nextTick();
  if (innerInput.value) {
    innerInput.value.focus();
  }
};
const onValidError = e => {
  if (Array.isArray(e) && e.length > 0) {
    validateError.push(String(e));
  } else {
    validateError.splice(0);
  }
};
</script>

<template>
  <div v-bind="attrs">
    <label v-if="label" class="block text-900 text-xl font-medium mb-2">{{
      label
    }}</label>
    <input
      ref="innerInput"
      autocomplete="off"
      spellcheck="false"
      :class="{
        [className]: true,
        [invalidClass]: isInputError,
        [validClass]: isInputComplete,
      }"
      :type="type"
      :name="name"
      :value="modelValue"
      :placeholder="placeholder"
      :disabled="disabled"
      :style="style"
      @input="onInput"
      @blur="onBlur"
      @focus="onFocus"
    />
    <cus-input-valid-feedback
      :input="String(modelValue)"
      :rules="props.rules"
      :on-status="onStatus"
      :add-invalid-msg="props.addInvalidMsg"
      @on-error="onValidError"
    />
  </div>
</template>
