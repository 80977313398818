<template>
  <div class="m-2">
    <div class="min-h-36 mt-3 text-center">
      <div v-if="selected.length < 2">
        <div class="file-display-section">
          <div
            class="icon"
            :data-ext="itemOne?.extension"
            :data-dir="itemOne?.isDir"
            :data-type="itemOne?.type"
          >
            <img
              v-if="itemOne?.type === 'image' && isThumbsEnabled"
              v-lazy="thumbnailUrl"
              class="min-h-36 rounded border p-1 m-auto"
              alt=""
            />
            <i
              v-else
              :class="{
                'material-icons': true,
                'folder filled': true,
                ' text-8xl': true,
              }"
            ></i>
          </div>
          <span class="break-word ml-1 text-sm font-medium">{{ name }}</span>
        </div>
      </div>
    </div>

    <div class="file-detail-info mt-5 py-5 border-t">
      <h3
        v-if="selected.length < 2 || (!dir && humanSize)"
        class="block mb-3 text-lg font-medium text-gray-900 dark:text-white"
      >
        {{ t("prompts.fileDetailInfo") }}
      </h3>
      <dl>
        <template v-if="!dir && humanSize">
          <dt>{{ t("prompts.size") }}:</dt>
          <dd>{{ humanSize }}</dd>
        </template>

        <!-- 해상도 정보 -->
        <template v-if="resolution">
          <dt>{{ t("prompts.resolution") }}:</dt>
          <dd>{{ resolution.width }} x {{ resolution.height }}</dd>
        </template>

        <!-- 마지막 수정 정보 -->
        <template v-if="selected.length < 2" :title="modTime">
          <dt>{{ t("prompts.lastModified") }}:</dt>
          <dd>{{ defaultTime }}</dd>
        </template>

        <!-- 디렉토리 정보 -->
        <template v-if="dir && selected.length === 0">
          <dt>{{ t("prompts.numberFiles") }}:</dt>
          <dd>{{ req.listInfo.totalFiles }}</dd>

          <dt>{{ t("prompts.numberDirs") }}:</dt>
          <dd>{{ req.listInfo.totalDirs }}</dd>
        </template>
        <!--          <template v-if='req.nick'>-->
        <!--            <dt>{{ t('files.creator') }}:</dt>-->
        <!--            <dd>{{ req.nick }}</dd>-->
        <!--          </template>-->
        <template v-if="folderInfo?.creator">
          <dt>{{ t("files.creator") }}:</dt>
          <dd>{{ folderInfo.creator }}</dd>
        </template>

        <!-- 설명 정보 -->
        <template v-if="folderInfo">
          <dt>{{ t("files.explain") }}:</dt>
          <dd>
            <AiaInput
              v-model:model-value="explain"
              class="bg-opacity-30"
              class-name="px-2 py-1 w-full rounded font-normal border-0 ring-1 ring-inset ring-gray-300 bg-opacity-10"
              placeholder="Add explain"
            />
          </dd>
        </template>
      </dl>

      <!-- 권한 및 설명 정보 -->
      <template v-if="hasLevelPermissions">
        <div class="mt-5">
          <h3
            class="flex items-center space-x-2 mb-3 text-lg font-medium text-gray-900 dark:text-white"
          >
            <span>{{ t("accessLevel.title") }}</span>
            <action
              icon="info"
              :label="$t('prompts.fileOptions')"
              show="file-options"
            >
              <template #icon>
                <PhGearSix :size="20" weight="thin" />
              </template>
            </action>
          </h3>
          <FileLevelInfo :model-value="folderInfo.levelPerm" view-mode />
        </div>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import { files as api } from "@/api";
import { fetchMetadata } from "@/api/metadata";
import { Resource } from "@/class/interface/drive/file";
import Action from "@/components/files/Action.vue";
import AiaInput from "@/components/form/AiaInput.vue";
import { filesize } from "@/utils";
import { Directories, enableThumbs } from "@/utils/constants";
import FileLevelInfo from "@/views/files/FileLevelInfo.vue";
import { PhGearSix } from "@phosphor-icons/vue";
import { computed, nextTick, onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const props = defineProps<{
  req: Resource | null;
  selected: number[];
  selectedCount: number;
  isListing: boolean;
}>();

const explain = ref<string>("");

const humanSize = computed(() => {
  if (!props.req?.size) return null;

  if (props.selectedCount === 0 || !props.isListing) {
    return filesize(props.req?.size);
  }

  let sum = 0;

  for (const selectedItem of props.selected) {
    sum += props.req?.items[selectedItem].size;
  }

  return filesize(sum);
});

const hasLevelPermissions = computed(() => {
  const levelPerm = folderInfo.value?.levelPerm;
  if (!levelPerm) return false;

  // 모든 속성 값을 합산하여 0보다 큰지 확인
  const total = Object.values(levelPerm).reduce((sum, value) => sum + value, 0);
  return total > 0;
});

const defaultTime = computed(() => {
  if (props.selectedCount === 0) {
    return new Date(Date.parse(props.req?.modified)).toLocaleString();
  }

  return new Date(
    Date.parse(props.req?.items[props.selected[0]].modified)
  ).toLocaleString();
});

const modTime = computed(() => {
  if (props.selectedCount === 0) {
    return new Date(Date.parse(props.req?.modified)).toLocaleString();
  }

  return new Date(
    Date.parse(props.req?.items[props.selected[0]].modified)
  ).toLocaleString();
});

const name = computed(() => {
  if (props.selectedCount === 0 && "/" + props.req?.name === Directories.base)
    return "/";
  return props.selectedCount === 0
    ? props.req?.name
    : props.req?.items[props.selected[0]].name;
});

const itemOne = computed(() => {
  return props.selectedCount === 0
    ? props.req
    : props.req?.items[props.selected[0]];
});

const dir = computed(() => {
  if (props.selectedCount === 0) {
    // 선택된 항목이 없을 때 기본값을 반환
    return props.req?.isDir;
  } else if (props.selectedCount > 1) {
    folderInfo.value = null;
    // 선택된 항목이 1개 이상일 때 모든 항목의 isDir을 검사
    return props.selected.every(index => props.req.items[index].isDir);
  } else {
    // 선택된 항목이 1개일 때 해당 항목의 isDir을 반환
    return props.req?.items[props.selected[0]].isDir;
  }
});

const resolution = computed(() => {
  if (props.selectedCount === 1) {
    const selectedItem = props.req?.items[props.selected[0]];
    if (selectedItem && selectedItem.type === "image") {
      return selectedItem["resolution"];
    }
  } else if (props.req && props.req?.type === "image") {
    return props.req["resolution"];
  }
  return null;
});

const isThumbsEnabled = computed(() => {
  return enableThumbs;
});
const thumbnailUrl = computed(() => {
  const item =
    props.selectedCount === 0 ? props.req : props.req?.items[props.selected[0]];

  const file = {
    path: item?.path,
    modified: item?.modified,
  };

  return api.getPreviewURL(file as Resource, "thumb");
});

const folderInfo = ref<Metadata>(null);
onMounted(() => {
  nextTick(async () => {
    try {
      await initFileInfo();
    } catch (e) {}
  });
});
/**
 * 파일 메타 정보 가져오기
 */
const initFileInfo = async () => {
  if (dir.value && props.selected.length < 2) {
    let folderPath = null;
    if (props.selected.length === 1) {
      const selectedItem = props.req.items[props.selected[0]];
      folderPath = selectedItem.path;
    } else if (props.selected.length === 0) {
      folderPath = props.req?.path;
    }

    if (folderPath && folderPath !== "/") {
      try {
        folderInfo.value = await fetchMetadata(folderPath);
      } catch (e) {}
    }
  }
};
</script>
<style lang="scss" scoped>
.file-detail-info {
  border-color: var(--divider);
  padding: 10px;
  dl {
    word-break: break-all;
    dt {
      font-size: 12px;
      @apply block text-gray-500 dark:text-gray-200;
    }
    dd {
      font-size: 14px;
      //margin-bottom: 20px;
      @apply mb-3 font-medium text-gray-700 dark:text-white;
    }
  }
}
</style>
