<template>
  <button
    :aria-label="label"
    :title="label"
    class="action relative inline-flex items-center"
    @click="action"
  >
    <slot name="icon">
      <i class="material-icons">{{ icon }}</i>
    </slot>
    <span v-if="showLabel">{{ label }}</span>
    <span v-if="badge && badge > 0" class="badge-count">{{ badge }}</span>
  </button>
</template>

<script setup lang="ts">
import { useLayoutStore } from '@/stores/layout';

const props = defineProps<{
  icon?: string;
  label?: string;
  badge?: number;
  show?: string;
  showLabel?: boolean;
}>();

const emit = defineEmits<{
  (e: 'action');
}>();

const layoutStore = useLayoutStore();

const action = () => {
  if (props.show) {
    layoutStore.showHover(props.show);
  }

  emit('action');
};
</script>
<style scoped lang="scss">
.action .badge-count {
  //@apply inline-flex items-center justify-center w-4 h-4 ms-2 text-xs font-semibold text-blue-800 bg-blue-200 rounded-full;
  @apply absolute inline-flex items-center justify-center w-5 h-5 text-xs font-bold text-white bg-red-500 border-2 border-white rounded-full -top-2 -end-2 dark:border-gray-900;
}
</style>
