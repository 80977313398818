<template>
  <AiaModalContainer @close="layoutStore.closeHovers">
    <template #title>{{ t('prompts.replace') }}</template>
    <template #body>
      <div class="pb-5">
        <p>{{ t('prompts.replaceMessage') }}</p>
      </div>
      <div class="flex justify-end mb-2 space-x-2">
        <AiaButton
          type="info"
          :aria-label="t('buttons.cancel')"
          :title="t('buttons.cancel')"
          tabindex="3"
          @click="layoutStore.closeHovers"
        >
          {{ t('buttons.cancel') }}
        </AiaButton>
        <AiaButton
          :aria-label="t('buttons.continue')"
          :title="t('buttons.continue')"
          tabindex="2"
          @click="currentPrompt.action"
        >
          {{ t('buttons.continue') }}
        </AiaButton>
        <AiaButton
          type="danger"
          :aria-label="t('buttons.replace')"
          :title="t('buttons.replace')"
          tabindex="1"
          @click="currentPrompt.confirm"
        >
          {{ t('buttons.replace') }}
        </AiaButton>
      </div>
    </template>
  </AiaModalContainer>
</template>

<script setup lang="ts">
import AiaModalContainer from '@/components/dialog/AiaModalContainer.vue';
import AiaButton from '@/components/form/AiaButton.vue';
import { useLayoutStore } from '@/stores/layout';
import { storeToRefs } from 'pinia';
import { useI18n } from 'vue-i18n';
// Store 사용
const layoutStore = useLayoutStore();
const { currentPrompt } = storeToRefs(layoutStore);
const { t } = useI18n();

//TODO ??
// const emit = defineEmits(['action']);
//
// const actionEmit = (event, nm, prompt) => {
//   if (nm === 'overwrite') {
//     isBtnOverwrite.value = true;
//   } else if (nm === ' rename') {
//     isBtnRename.value = true;
//   }
//   emit('action', nm);
//   if (prompt && typeof prompt === 'function') prompt(event, nm);
// };
</script>
