<template>
  <div class="flex justify-center items-center h-full w-full">
    <div
      role="status"
      class="w-full mr-6 p-4 space-y-4 border border-gray-200 divide-y divide-gray-300 rounded shadow animate-pulse dark:divide-gray-700 md:p-6 dark:border-gray-700"
    >
      <div class="flex items-center justify-between">
        <div class="w-32 h-2 bg-gray-300 rounded-full dark:bg-gray-700"></div>
        <div class="w-14 h-2 bg-gray-300 rounded-full dark:bg-gray-700"></div>
        <div class="w-20 h-2.5 bg-gray-300 rounded-full dark:bg-gray-700"></div>
      </div>
      <div class="flex items-center justify-between pt-4">
        <div class="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
        <div class="w-14 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
        <div class="w-20 h-2.5 bg-gray-200 rounded-full dark:bg-gray-700"></div>
      </div>
      <div class="flex items-center justify-between pt-4">
        <div class="w-32 h-2 bg-gray-300 rounded-full dark:bg-gray-700"></div>
        <div class="w-14 h-2 bg-gray-300 rounded-full dark:bg-gray-700"></div>
        <div class="w-20 h-2.5 bg-gray-300 rounded-full dark:bg-gray-700"></div>
      </div>
      <div class="flex items-center justify-between pt-4">
        <div class="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
        <div class="w-14 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
        <div class="w-20 h-2.5 bg-gray-200 rounded-full dark:bg-gray-700"></div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts"></script>
