<script setup lang="ts">
import AiaButton from "@/components/form/AiaButton.vue";
import { useLayoutStore } from "@/stores/layout";
import NewFile from "@/views/files/modals/NewFile.vue";
import { PhCircleNotch, PhPlayCircle, PhStopCircle } from "@phosphor-icons/vue";
import moment from "moment";
import { onUnmounted, ref } from "vue";
import { toast } from "vue3-toastify";

const layoutStore = useLayoutStore();
const isPreparing = ref(false);
const isRecording = ref(false);
const recordedBlob = ref<Blob | null>(null);

let mediaStream: MediaStream | null = null;
let mediaRecorder: MediaRecorder | null = null;
let recordedChunks: Blob[] = [];

const emit = defineEmits(["saved"]);

// 현재 날짜로 기본 파일명 생성
const getDefaultFilename = () => {
  const dt = new Date();
  const date = moment(dt).format("YY-MM-DD");
  const timestampInSeconds = Math.floor(new Date().getTime() / 1000);
  return `ScreenRecord_${date}_${timestampInSeconds}.webm`;
};

// 녹화를 위한 준비 함수
const prepareRecording = async () => {
  isPreparing.value = true;
  try {
    // 새로운 스트림을 요청
    mediaStream = await navigator.mediaDevices.getDisplayMedia({ video: true });

    // MIME 타입 지원 여부 확인
    let mimeType = "";
    if (MediaRecorder.isTypeSupported("video/webm;codecs=vp9")) {
      mimeType = "video/webm;codecs=vp9";
    } else if (MediaRecorder.isTypeSupported("video/webm;codecs=vp8")) {
      mimeType = "video/webm;codecs=vp8";
    } else if (MediaRecorder.isTypeSupported("video/webm")) {
      mimeType = "video/webm";
    } else {
      console.error("No supported MIME type found for MediaRecorder");
      toast.error("No supported MIME type found for MediaRecorder");
      isPreparing.value = false;
      return;
    }

    // 새로운 MediaRecorder 객체 생성
    mediaRecorder = new MediaRecorder(mediaStream, { mimeType });
    mediaRecorder.ondataavailable = handleDataAvailable;
    mediaRecorder.onstop = handleRecordingStop;
  } catch (err: any) {
    if (err.name === "NotAllowedError") {
      toast.error("Screen recording permissions were denied.");
    } else if (err.name === "NotFoundError") {
      toast.error("No screen found to record.");
    } else {
      console.error("Error preparing recording:", err);
      toast.error("Unable to prepare screen recording.");
    }
  } finally {
    isPreparing.value = false;
  }
};

// 녹화 시작 함수
const startCapture = async () => {
  if (isPreparing.value || isRecording.value) {
    return; // 준비 중이거나 이미 녹화 중인 경우 중복 호출 방지
  }

  // 준비되지 않은 경우, 먼저 준비
  if (!mediaRecorder || mediaRecorder.state === "inactive") {
    await prepareRecording();
  }

  if (mediaRecorder && mediaRecorder.state === "inactive") {
    try {
      isRecording.value = true;
      recordedChunks = [];
      mediaRecorder.start();
    } catch (error) {
      console.error("Error starting MediaRecorder:", error);
      toast.error("Unable to start screen recording.");
      isRecording.value = false;
    }
  } else {
    console.warn("MediaRecorder is already in a state that cannot start.");
  }
};

// 녹화 데이터가 준비될 때 호출되는 함수
const handleDataAvailable = (event: BlobEvent) => {
  if (event.data && event.data.size > 0) {
    recordedChunks.push(event.data); // 유효한 데이터만 추가
  } else {
    toast.warning("Received empty recording data.");
  }
};

// 녹화 중지 함수
const stopCapture = () => {
  if (mediaRecorder && mediaRecorder.state !== "inactive") {
    mediaRecorder.stop();
  }
  if (mediaStream) {
    mediaStream.getTracks().forEach(track => track.stop());
    mediaStream = null; // 스트림 해제
  }
  isRecording.value = false;
};

// 녹화 중지 후 호출되는 함수
const handleRecordingStop = () => {
  // 녹화된 데이터가 있는지 확인
  if (recordedChunks.length === 0) {
    toast.error("Recording failed or no data captured.");
  } else {
    // 녹화된 비디오 생성 및 blob 설정
    recordedBlob.value = new Blob(recordedChunks, { type: "video/webm" });
    recordedChunks = []; // 녹화 데이터 초기화

    const filename = getDefaultFilename();
    // 파일 생성 컴포넌트를 호출하여 파일 생성 절차 시작
    layoutStore.showHover({
      prompt: "newFile",
      props: {
        redirect: true,
        filename: filename,
        filetype: "video",
        fileBlob: recordedBlob.value,
      },
      action: param => {
        // param: uri
        emit("saved", filename);
      },
    });
  }
};

// 컴포넌트가 파괴될 때 자원 해제
onUnmounted(() => {
  if (mediaStream) {
    mediaStream.getTracks().forEach(track => track.stop());
  }
  if (mediaRecorder && mediaRecorder.state !== "inactive") {
    mediaRecorder.stop();
  }
});
</script>

<template>
  <div>
    <button
      v-if="!isRecording && !isPreparing"
      type="button"
      class="shadow text-white bg-purple-400 hover:bg-purple-500 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-3 py-2.5 text-center inline-flex items-center"
      @click="startCapture"
    >
      <PhPlayCircle :size="19" />
      <span class="ml-1">Start</span>
    </button>
    <button
      v-else-if="isPreparing"
      type="button"
      :disabled="true"
      class="shadow text-white bg-purple-400 font-medium rounded-lg text-sm px-3 py-2.5 text-center inline-flex items-center"
    >
      <PhCircleNotch :size="19">
        <animate
          attributeName="opacity"
          values="0;1;0"
          dur="2s"
          repeatCount="indefinite"
        />
        <animateTransform
          attributeName="transform"
          attributeType="XML"
          type="rotate"
          dur="5s"
          from="0 0 0"
          to="360 0 0"
          repeatCount="indefinite"
        />
      </PhCircleNotch>
      <span class="ml-1">
        <span class="dot-loading text-center py-2">
          Loading <span class="w-6 inline-block dots"></span>
        </span>
      </span>
    </button>

    <button
      v-else
      :disabled="!isRecording"
      type="button"
      class="shadow text-white bg-red-400 hover:bg-red-500 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-3 py-2.5 text-center inline-flex items-center"
      @click="stopCapture"
    >
      <PhStopCircle :size="19" />
      <span class="ml-1">Stop</span>
    </button>
  </div>
</template>
