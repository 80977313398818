<template>
  <div
    v-if="currentComponent"
    ref="modalElement"
    class="aia-modal-wrapper"
    tabindex="-1"
  >
    <component :is="currentComponent"></component>
  </div>
</template>

<script setup lang="ts">
import { useLayoutStore } from "@/stores/layout";
import FileOptionSetting from "@/views/files/modals/FileOptionSetting.vue";
import ProfileSettingModal from "@/views/mypage/ProfileSettingModal.vue";
import { Modal } from "flowbite";
import { storeToRefs } from "pinia";
import { h, onMounted, ref, shallowRef, watch } from "vue";
import Copy from "./Copy.vue";
import Delete from "./Delete.vue";
import DiscardEditorChanges from "./DiscardEditorChanges.vue";
import Download from "./Download.vue";

import Help from "./Help.vue";
import Info from "./Info.vue";
import Move from "./Move.vue";
import NewDir from "./NewDir.vue";
import NewFile from "./NewFile.vue";
import Rename from "./Rename.vue";
import ReplacePrompt from "./ReplacePrompt.vue";
import ReplaceRename from "./ReplaceRename.vue";
import Share from "./Share.vue";
import ShareDelete from "./ShareDelete.vue";
import Upload from "./Upload.vue";

const modalElement = ref(null);
let modal;

const layoutStore = useLayoutStore();
const { currentPromptName } = storeToRefs(layoutStore);
const currentComponent = shallowRef(null);
// const closeModal = ref<() => Promise<string>>();

const components = new Map<string, any>([
  ["info", Info],
  ["help", Help],
  ["delete", Delete],
  ["rename", Rename],
  ["move", Move],
  ["copy", Copy],
  ["newFile", NewFile],
  ["newDir", NewDir],
  ["download", Download],
  ["replace", ReplacePrompt],
  ["replace-rename", ReplaceRename],
  ["share", Share],
  ["upload", Upload],
  ["share-delete", ShareDelete],
  // ['deleteUser', DeleteUser],
  ["discardEditorChanges", DiscardEditorChanges],
  // setting
  ["file-options", FileOptionSetting],
  ["profile", ProfileSettingModal],
]);

// watch(currentPromptName, newValue => {
//   currentComponent.value = components.get(newValue) || '';
// });
watch(currentPromptName, newValue => {
  const component = components.get(newValue);
  if (component) {
    currentComponent.value = h(
      component,
      layoutStore.prompts[layoutStore.prompts.length - 1]?.props || {}
    );
  } else {
    currentComponent.value = "";
  }
});

window.addEventListener("keydown", event => {
  if (!layoutStore.currentPrompt) return;

  if (event.key === "Escape") {
    event.stopImmediatePropagation();
    layoutStore.closeHovers();
  }
});

onMounted(() => {
  const modalOptions = {
    // backdropClasses: 'bg-gray-900 bg-opacity-50 dark:bg-opacity-80 fixed inset-0 z-40'
  };
  if (modalElement.value) {
    modal = new Modal(modalElement.value, modalOptions);
  }
});
</script>
<style lang="scss" scoped>
.aia-modal-wrapper {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  height: 100vh;
  background: rgba(0, 0, 0, 0.3);
  animation-duration: 0.3s;
  z-index: 1001;
  animation-fill-mode: forwards;
}
</style>
