import { defineStore } from "pinia";

export const useLayoutStore = defineStore("layout", {
  // convert to a function
  state: (): {
    loading: boolean;
    initMessage: string[];
    prompts: PopupProps[];
    showShell: boolean | null;
    showFileInfo: boolean | null;
    showSidebar: boolean | null;
    scrollPosition: number;
  } => ({
    loading: false,
    initMessage: [],
    prompts: [],
    showShell: false,
    showFileInfo: false,
    showSidebar: true,
    scrollPosition: 0,
  }),
  getters: {
    currentPrompt(state) {
      return state.prompts.length > 0
        ? state.prompts[state.prompts.length - 1]
        : null;
    },
    currentPromptName(): string | null | undefined {
      return this.currentPrompt?.prompt;
    },
    getScrollPosition(state): number {
      return state.scrollPosition;
    },
  },
  actions: {
    // no context as first argument, use `this` instead
    toggleShell() {
      this.showShell = !this.showShell;
    },
    toggleSidebar() {
      this.showSidebar = !this.showSidebar;
    },
    toggleFileInfo() {
      this.showFileInfo = !this.showFileInfo;
    },
    hideFileInfo() {
      this.showFileInfo = false;
    },
    addInitMessage(msg: string) {
      this.initMessage.push(msg);
    },
    clearInitMessage() {
      this.initMessage = [];
    },
    showHover(value: PopupProps | string) {
      if (typeof value !== "object") {
        this.prompts.push({
          prompt: value,
          confirm: null,
          action: undefined,
          props: {},
        });
        return;
      }

      this.prompts.push({
        prompt: value.prompt,
        confirm: value?.confirm,
        action: value?.action,
        props: value?.props,
      });
    },
    showError() {
      this.prompts.push({
        prompt: "error",
        confirm: null,
        action: undefined,
        props: null,
      });
    },
    showSuccess() {
      this.prompts.push({
        prompt: "success",
        confirm: null,
        action: undefined,
        props: null,
      });
    },
    closeHovers() {
      this.prompts.pop();
    },
    // easily reset state using `$reset`
    clearLayout() {
      this.$reset();
    },
    saveScrollPosition(scrollTop: number) {
      if (scrollTop) {
        this.scrollPosition = scrollTop;
      }
    },
  },
});
