<script setup lang="ts">
import { files as api } from "@/api";
import { addFavorite, removeFavorite } from "@/api/favorite";
import { ContextItemInter } from "@/class/interface/MenuInter";
import { useActionMenuPerm } from "@/composable/useActionMenuPerm";
import { useAuthStore } from "@/stores/auth";
import { useFileStore } from "@/stores/file";
import { useLayoutStore } from "@/stores/layout";
import FileListingItem from "@/views/files/listing/FileListingItem.vue";
import FileItemContextMenu from "@/views/files/menu/FileItemContextMenu.vue";
import { PhStar } from "@phosphor-icons/vue";
import { Base64 } from "js-base64";
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
const { t } = useI18n();
import { toast } from "vue3-toastify";

const base64 = (name: string) => Base64.encodeURI(name);

const fileStore = useFileStore();
const layoutStore = useLayoutStore();
const authStore = useAuthStore();

const dirs = computed(() => {
  // `fileStore.dirs`에서 `showLimit` 적용 후 `displayIndex` 추가
  return fileStore.dirs.map((item, index) => {
    return {
      ...item,
      displayIndex: index, // Folders start from index 0
    };
  });
});
const files = computed(() => {
  return fileStore.files.map((item, index) => {
    return {
      ...item,
      displayIndex: fileStore.dirs.length + index, // Files continue after folders
    };
  });
});

const actionMenuPerm = useActionMenuPerm();
/**
 * 컨텍스트 메뉴
 */
const contextSelectedMenu = ref<ContextItemInter[]>([]);
const contextMenu = ref<typeof FileItemContextMenu>();

function openContextMenu(event: MouseEvent) {
  contextSelectedMenu.value = actionMenuPerm.contextMenu.value;
  try {
    contextMenu.value.openMenu(event);
  } catch (e) {
    console.error(e);
  }
}

function onSelectContextMenu(event: MouseEvent) {
  contextSelectedMenu.value = actionMenuPerm.contextMenu.value;
  contextSelectedMenu.value.splice(0, 2);

  try {
    contextMenu.value.openMenu(event, 30, -10);
  } catch (e) {
    console.error(e);
  }
}

/**
 * 컨텍스트 메뉴 선택시
 * @param item
 */
const onContextMenuSelected = async (item: ContextItemInter) => {
  if (item.value === "add-favorite" || item.value === "del-favorite") {
    await toggleActionFavorite();
  } else if (item.value === "download") {
    downloadAction();
  } else if (item.value === "info") {
    layoutStore.showFileInfo = true;
  } else {
    layoutStore.showHover(item.value);
  }
};
/**
 * 즐겨찾기 추가/삭제
 * @param action
 */
const toggleActionFavorite = async () => {
  const selIdx = fileStore.selected[0];
  const selItem = fileStore.req.items[selIdx];

  if (!selItem.isFav) {
    try {
      await addFavorite(selItem.path, selItem.isDir);
      toast.success(
        t("message.favoriteActionSuccess", { action: t("message.actionAdd") })
      );
      selItem.isFav = true;
    } catch (e) {
      const errorMessage = e instanceof Error ? e.message : String(e);
      toast.error(
        `${t("message.favoriteActionFail", { action: t("message.actionAdd") })} ${errorMessage}`
      );
    }
  } else {
    try {
      await removeFavorite(selItem.path);
      toast.success(
        t("message.favoriteActionSuccess", {
          action: t("message.actionRemove"),
        })
      );
      selItem.isFav = false;
    } catch (e) {
      const errorMessage = e instanceof Error ? e.message : String(e);
      `${t("message.favoriteActionFail", { action: t("message.actionRemove") })} ${errorMessage}`;
    }
  }
};

const downloadAction = () => {
  if (fileStore.req === null) return;

  if (
    fileStore.selectedCount === 1 &&
    !fileStore.req?.items[fileStore.selected[0]].isDir
  ) {
    api.download(null, fileStore.req?.items[fileStore.selected[0]].url);
    return;
  }

  layoutStore.showHover({
    prompt: "download",
    confirm: format => {
      layoutStore.closeHovers();

      const files = [];

      if (fileStore.selectedCount > 0 && fileStore.req !== null) {
        for (const i of fileStore.selected) {
          files.push(fileStore.req?.items[i].url);
        }
      }

      api.download(format, ...files);
    },
  });
};
</script>

<template>
  <div>
    <FileItemContextMenu
      ref="contextMenu"
      :key="'k' + fileStore.selectedCount"
      :items="contextSelectedMenu"
      @select="onContextMenuSelected"
    />

    <section
      v-if="fileStore.req?.listInfo?.totalDirs ?? false"
      class="s-folder"
    >
      {{ t("files.folders") }}
    </section>
    <div
      v-if="fileStore.req?.listInfo?.totalDirs ?? false"
      class="file-display-section"
      :class="{ last: !fileStore.req?.listInfo?.totalFiles }"
      @contextmenu.prevent="openContextMenu"
    >
      <FileListingItem
        v-for="(item, idx) in dirs"
        :key="base64(item.name) + idx"
        :item="item"
        :meta-info="fileStore.req?.metaInfo"
        :url="item.url"
        @select-menu="onSelectContextMenu"
      >
        <PhStar v-if="item.isFav" :size="14" weight="fill" class="ml-1 mb-1" />
      </FileListingItem>
    </div>
    <section v-if="fileStore.req?.listInfo?.totalDirs ?? false" class="s-files">
      {{ t("files.files") }}
    </section>
    <div
      v-if="fileStore.req?.listInfo?.totalFiles ?? false"
      class="file-display-section last"
      @contextmenu.prevent="openContextMenu"
    >
      <FileListingItem
        v-for="(item, idx) in files"
        :key="base64(item.name) + idx"
        :item="item"
        :meta-info="fileStore.req?.metaInfo"
        :url="item.url"
        @select-menu="onSelectContextMenu"
      >
        <PhStar v-if="item.isFav" :size="14" weight="fill" class="ml-1 mb-1" />
      </FileListingItem>
    </div>
  </div>
</template>

<style scoped lang="scss"></style>
