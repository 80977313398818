import { StatusError } from "@/api/utils";
import { router } from "@/routes.js";
import { useAuthStore } from "@/stores/auth";
import { jwtDecode, JwtPayload } from "jwt-decode";
import { baseURL, noAuth } from "./constants";

export function setAuthToken(token: string) {
  // falsy or malformed jwt will throw InvalidTokenError
  const data = jwtDecode<JwtPayload & { user: IUser }>(token);
  // console.log('data',data)
  document.cookie = `auth=${token}; Path=/; SameSite=Strict;`;

  localStorage.setItem("jwt", token);

  const authStore = useAuthStore();
  authStore.jwt = token;
  authStore.setUser(data.user);
}

export async function validateLogin() {
  try {
    if (localStorage.getItem("jwt")) {
      await renew(<string>localStorage.getItem("jwt"));
    }
  } catch (error) {
    console.warn("Invalid token in storage. please login"); // eslint-disable-line
    throw error;
  }
}

// export async function login(
//   username: string,
//   password: string,
//   recaptcha: string
// ) {
//   const data = { username, password, recaptcha };
//
//   const res = await fetch(`${baseURL}/api/login`, {
//     method: "POST",
//     headers: {
//       "Content-Type": "application/json",
//     },
//     body: JSON.stringify(data),
//   });
//
//   const body = await res.text();
//
//   if (res.status === 200) {
//     setAuthToken(body);
//   } else {
//     throw new StatusError(
//       body || `${res.status} ${res.statusText}`,
//       res.status
//     );
//   }
// }

export async function renew(jwt: string) {
  const res = await fetch(`${baseURL}/api/auth`, {
    method: "POST",
    headers: {
      "SetFn-Auth": jwt,
    },
  });

  const body = await res.json();

  if (res.status === 200) {
    if (body["success"] === true && body["data"]) setAuthToken(body["data"]);
  } else {
    throw new StatusError(
      body || `${res.status} ${res.statusText}`,
      res.status
    );
  }
}

export async function signup(username: string, password: string) {
  const data = { username, password };

  const res = await fetch(`${baseURL}/api/signup`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });

  if (res.status !== 200) {
    throw new StatusError(`${res.status} ${res.statusText}`, res.status);
  }
}

export function logout() {
  document.cookie = "auth=; Max-Age=0; Path=/; SameSite=Strict;";

  const authStore = useAuthStore();
  authStore.clearUser();

  localStorage.setItem("jwt", "");
  if (noAuth) {
    window.location.reload();
  } else {
    router.push({ path: "/login" });
  }
}
