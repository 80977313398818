<template>
  <div class="search-modal-background" :class="{ active }" @click="close"></div>
  <div id="main-search" :class="{ active, ongoing }" @click="open">
    <div id="input-wrap">
      <button
        v-if="active"
        class="action mr-2 mt-1"
        :aria-label="t('buttons.close')"
        :title="t('buttons.close')"
        @click="close"
      >
        <i class="material-icons">arrow_back</i>
      </button>
      <i v-else class="material-icons">search</i>
      <input
        ref="input"
        v-model.trim="prompt"
        type="text"
        :autofocus="active"
        :aria-label="$t('search.fileSearch')"
        :placeholder="$t('search.fileSearch')"
        @keyup.exact="keyup"
        @keyup.enter="submit"
      />
      <div v-if="isTyping" class="dot-loading absolute right-12">
        <span class="w-6 inline-block dots"></span>
      </div>
      <button
        v-if="active"
        class="pl-4 pt-1"
        type="reset"
        aria-label="Search(Enter)"
        title="Search(Enter)"
        @click="submit"
      >
        <span>
          <i class="material-icons">search</i>
        </span>
      </button>
      <!--      <button-->
      <!--        v-if="active"-->
      <!--        class="action"-->
      <!--        type="reset"-->
      <!--        aria-label="Cancel"-->
      <!--        @click="close"-->
      <!--      >-->
      <!--        <span-->
      <!--          class="ml-auto pl-3 flex-none text-xs font-semibold text-slate-400 mr-2"-->
      <!--        >-->
      <!--          ESC-->
      <!--        </span>-->
      <!--      </button>-->
    </div>

    <div id="result" ref="result" class="scroll-s-project">
      <div>
        <template v-if="isEmpty">
          <template v-if="prompt.length === 0"> </template>
          <div
            class="flex items-center justify-between w-full whitespace-nowrap mb-2 truncate"
          >
            <div>
              <div>
                <span>{{ t("search.searchPath") }} : </span>
                <Breadcrumbs
                  :base="Directories.base"
                  class-name="text-sm space-x-1"
                />
              </div>
            </div>

            <div class="flex items-center space-x-2 ml-3">
              <h3>{{ t("search.types") }}</h3>
              <AiaButton
                v-for="(v, k) in boxes"
                :key="k"
                type="info"
                size="sm"
                tabindex="0"
                role="button"
                :aria-label="$t('search.' + v.label)"
                @click="init('type:' + k)"
              >
                {{ t("search." + v.label) }}
              </AiaButton>
            </div>

            <!--            <div class="w-20">-->
            <!--              <p>-->
            <!--                <span-->
            <!--                  class="ml-auto pl-3 flex-none text-xs font-semibold text-slate-400 mr-2"-->
            <!--                >-->
            <!--                  ESC-->
            <!--                </span>-->
            <!--                Close-->
            <!--              </p>-->
            <!--            </div>-->
          </div>
        </template>
        <ul v-show="results.length > 0">
          <li v-for="(s, k) in filteredResults" :key="k">
            <router-link :to="s.url" @click="close">
              <PhFolderSimple v-if="s.dir" :size="20" weight="fill" />
              <PhFile v-else :size="20" weight="thin" />
              <span class="ml-1">/{{ s.path }}</span>
            </router-link>
          </li>
        </ul>
      </div>
      <div id="renew">
        <div class="dot-loading text-center py-2">
          Loading <span class="w-6 inline-block dots"></span>
        </div>
        <!--        <i class="material-icons spin">autorenew</i>-->
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { search } from "@/api";
import AiaButton from "@/components/form/AiaButton.vue";
import { useFileStore } from "@/stores/file";
import { useLayoutStore } from "@/stores/layout";
import { Directories } from "@/utils/constants";

import url from "@/utils/url";
import Breadcrumbs from "@/views/files/Breadcrumbs.vue";
import { PhFile, PhFolderSimple } from "@phosphor-icons/vue";
import { debounce } from "lodash-es";
import { storeToRefs } from "pinia";
import { computed, onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import { toast } from "vue3-toastify";

const boxes = {
  image: { label: "images", icon: "insert_photo" },
  audio: { label: "music", icon: "volume_up" },
  video: { label: "video", icon: "movie" },
  pdf: { label: "pdf", icon: "picture_as_pdf" },
  md: { label: "md", icon: "picture_as_pdf" },
  // json: { label: 'json', icon: 'picture_as_pdf' },
};

const layoutStore = useLayoutStore();
const fileStore = useFileStore();

const { currentPromptName } = storeToRefs(layoutStore);

const prompt = ref<string>("");
const active = ref<boolean>(false);
const ongoing = ref<boolean>(false);
const results = ref<any[]>([]);
const reload = ref<boolean>(false);
const resultsCount = ref<number>(50);

const input = ref<HTMLInputElement | null>(null);
const result = ref<HTMLElement | null>(null);

const { t } = useI18n();

const route = useRoute();

const isTyping = ref<boolean>(false);
const navPath = computed(() => {
  const path = decodeURIComponent(route.path).replace(Directories.base, "");
  return path ? path : "/";
});

watch(currentPromptName, (newVal, oldVal) => {
  active.value = newVal === "search";
  if (oldVal === "search" && !active.value) {
    if (reload.value) {
      // fileStore.reload = true;
    }
    reset();
    prompt.value = "";
    active.value = false;
    // input.value?.blur();
  } else if (active.value) {
    reload.value = false;
    input.value?.focus();
  }
});

watch(prompt, () => {
  if (results.value.length) {
    reset();
  }
});

// ...mapState(useFileStore, ["isListing"]),
// ...mapState(useLayoutStore, ["show"]),
// ...mapWritableState(useFileStore, { sReload: "reload" }),

const isEmpty = computed(() => {
  return results.value.length === 0;
});
// const text = computed(() => {
//   if (ongoing.value) {
//     return '';
//   }
//
//   return prompt.value === ''
//     ? t('search.typeToSearch')
//     : t('search.searchNoResult');
// });
const filteredResults = computed(() => {
  return results.value.slice(0, resultsCount.value);
});

onMounted(() => {
  if (result.value === null) {
    return;
  }
  result.value.addEventListener("scroll", (event: Event) => {
    if (
      (event.target as HTMLElement).offsetHeight +
        (event.target as HTMLElement).scrollTop >=
      (event.target as HTMLElement).scrollHeight - 100
    ) {
      resultsCount.value += 50;
    }
  });
});

const open = () => {
  !active.value && layoutStore.showHover("search");
};

const close = (event: Event) => {
  event.stopPropagation();
  event.preventDefault();
  layoutStore.closeHovers();
};

// const keyup = (event: KeyboardEvent) => {
//   if (event.key === 'Escape') {
//     close(event);
//     return;
//   }
//   // results.value.length = 0;
// };

const keyup = (event: KeyboardEvent) => {
  if (event.key === "Escape") {
    close(event);
    return;
  } else {
    isTyping.value = true;
    debouncedSearch(event);
    return;
  }
  //results.value.length = 0;
};

const debouncedSearch = debounce(event => {
  submit(event);
  isTyping.value = false;
}, 300); // 지연

const init = (string: string) => {
  prompt.value = `${string} `;
  input.value !== null ? input.value.focus() : "";
};

const reset = () => {
  ongoing.value = false;
  resultsCount.value = 50;
  results.value = [];
};

const submit = async (event: Event) => {
  event.preventDefault();

  if (prompt.value === "") {
    return;
  }

  let path = route.path;
  if (!fileStore.isListing) {
    path = url.removeLastDir(path) + "/";
  }

  ongoing.value = true;

  try {
    results.value = await search(path, prompt.value);
  } catch (error) {
    toast.error(String(error));
  } finally {
    isTyping.value = false;
  }

  ongoing.value = false;
};
</script>
<style lang="scss" scoped>
#main-search {
  margin: 0 auto;
  position: relative;
  //height: 100%;
  width: 100%;
  height: 32px;
  max-width: 150px;
  border: 1px solid var(--divider);
  border-radius: 5px;
  outline: none;
  background: var(--surfaceEditor);

  &.active {
    position: fixed;
    top: 5px;
    right: calc(100% - 90%);
    width: 80%;
    //transform: translate(-40%, -50%);
    max-width: 100%;
    height: 99%;
    z-index: 1000;
  }

  & #input-wrap {
    background: var(--surfaceEditor);
    border-color: var(--surfacePrimary);
    display: flex;
    //max-height: 50px;
    height: 30px;
    padding: 0 1em;
    border-radius: 0.3em;
    transition: 0.1s ease all;
    align-items: center;
    z-index: 2;
  }

  & #input-wrap {
    & > i {
      margin-right: 0.3em;
      user-select: none;
      color: var(--textSecondary);
      opacity: 0.8;
      font-size: 1.2rem;
      margin-top: 1px;
      font-weight: 100 !important;
    }
    & > .action {
    }
  }

  & #input-wrap input::placeholder {
    color: var(--textSecondary);
  }

  &.active {
    & #input-wrap {
      border-bottom: 1px solid var(--borderPrimary);
      box-shadow: 0 0 5px var(--borderPrimary);
      background: var(--surfacePrimary);
      height: 3.6em;
    }

    & > div {
      border-radius: 0 !important;
    }
  }

  & input {
    width: 100%;
    border: 0;
    background-color: transparent;
    padding: 0;
    font-size: 1em;
    color: var(--textSecondary);
  }

  &.active {
    & input {
      padding: 0 10px;
      outline: none;
      border: none;

      &:focus {
        border: 0px solid transparent;
      }

      &:focus-within {
        //border: 2px solid red;
        border: 0px solid transparent;
        //outline-color: red;
        //outline-width: 5px;
      }
    }
  }

  &.active #result {
    padding: 0.5em;
    height: calc(100% - 3.6em);
  }

  & ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  & li {
    margin-bottom: 0.5em;
  }

  & #result {
    visibility: visible;
    max-height: none;
    background: var(--list-background);
    text-align: left;
    padding: 0;
    color: var(--textPrimary);
    height: 0;
    transition:
      0.1s ease height,
      0.1s ease padding;
    overflow-x: hidden;
    overflow-y: auto;
    z-index: 1;
  }

  & #result > div {
    max-width: 96%;
    margin: 0 auto;
  }

  & #result #renew {
    width: 100%;
    text-align: center;
    display: none;
    margin: 0;
    max-width: none;
  }

  &.ongoing #result #renew {
    display: block;
  }

  &.active #result i {
    color: var(--iconTertiary);
  }

  &.active #result > p > i {
    text-align: center;
    margin: 0 auto;
    display: table;
  }

  &.active #result ul li a {
    display: flex;
    align-items: center;
    padding: 0.3em 0;
  }

  &.active #result ul li a i {
    margin-right: 0.3em;
  }

  & .boxes {
    //border: 1px solid var(--borderPrimary);
    //box-shadow: 0 0 5px var(--borderPrimary);
    //background: var(--surfacePrimary);
    //margin: 1em 0;

    //& h3 {
    //  margin: 0;
    //  font-weight: 500;
    //  font-size: 1em;
    //  color: var(--textSecondary);
    //  padding: 0.5em;
    //}

    //& > div {
    //  display: flex;
    //  flex-wrap: wrap;
    //  justify-content: space-between;
    //  margin-right: -1em;
    //  margin-bottom: -1em;
    //}

    //& > div > div {
    //  //background: var(--blue);
    //  color: #fff;
    //  text-align: center;
    //  width: 5em;
    //  padding: 1em;
    //  cursor: pointer;
    //  margin-bottom: 1em;
    //  margin-right: 1em;
    //  flex-grow: 1;
    //}
    //
    //& p {
    //  margin: 1em 0 0;
    //}
    //
    //& i {
    //  color: #fff !important;
    //  font-size: 3.5em;
    //}
  }
}
</style>
