<script setup lang="ts">
import { useFileStore } from '@/stores/file';
import { PhCheckCircle, PhCircle } from '@phosphor-icons/vue';

// const { t } = useI18n();
const fileStore = useFileStore();
</script>

<template>
  <div>
    <!--        <div-->
    <!--          class="btn-create flex items-center px-3"-->
    <!--        >-->
    <!--        </div>-->

    <span
      :title="$t('buttons.selectMultiple')"
      :class="{
        'border-solid bg-opacity-70 text-gray-700': fileStore.multiple,
      }"
      @click="fileStore.toggleMultiple()"
    >
      <PhCircle v-if="!fileStore.multiple" :size="20" weight="thin" />
      <PhCheckCircle v-else :size="20" weight="thin" />
    </span>
  </div>
</template>
