<script setup lang="ts">
import { files as api } from '@/api';
// import { useAuthStore } from "@/stores/auth";
import { useFileStore } from '@/stores/file';
import { Directories } from '@/utils/constants';
import url from '@/utils/url';
import { computed, onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import { toast } from 'vue3-toastify';

// Pinia 스토어 사용
const fileStore = useFileStore();

// Props 및 emit 정의
const props = defineProps<{ selected: string | null }>();
const emit = defineEmits(['update:selected']);

// 반응형 변수 설정
const items = ref([]);
const touches = ref({ id: '', count: 0 });
let current = window.location.pathname;

// const authStore = useAuthStore();
// 계산된 속성 설정
// const user = computed(() => authStore.user);
const req = computed(() => fileStore.req);
const nav = computed(() => {
  const path = decodeURIComponent(current).replace(Directories.base, '');
  return path ? path : '/';
});
// 라우터 사용
const route = useRoute();

// fillOptions 함수 정의
const fillOptions = req => {
  // 현재 경로 설정 및 현재 항목 재설정
  current = req.url;
  items.value = [];

  emit('update:selected', current);

  // 경로가 루트 경로가 아닌 경우 이전 디렉토리로 이동하는 버튼 표시
  if (req.url !== Directories.base + '/') {
    items.value.push({
      name: '..',
      url: url.removeLastDir(req.url) + '/',
    });
  }

  // 이 폴더가 비어 있는 경우 여기서 종료
  if (req.items === null) return;

  // 그렇지 않으면 모든 디렉토리를 이동 옵션에 추가
  for (const item of req.items) {
    if (!item.isDir) continue;

    items.value.push({
      name: item.name,
      url: item.url,
    });
  }
};

// 다음 함수 정의
const next = async (event: Event) => {
  // 사용자가 클릭한 디렉토리의 URL을 가져와서 해당 내용으로 옵션을 채움
  const uri = (event.currentTarget as HTMLElement).dataset.url;

  try {
    const data = await api.fetch(uri);
    fillOptions(data);
  } catch (e) {
    toast.error(e);
  }
};

// 터치 시작 함수 정의
const touchstart = (event: Event) => {
  const url = (event.currentTarget as HTMLElement).dataset.url;

  // 300 밀리초 후에 카운트를 재설정
  setTimeout(() => {
    touches.value.count = 0;
  }, 300);

  // 사용자가 터치하는 요소가 마지막에 터치한 것과 다르면 카운트를 재설정
  if (touches.value.id !== url) {
    touches.value.id = url;
    touches.value.count = 1;
    return;
  }

  touches.value.count++;

  // 이미 터치가 두 번 이상인 경우 다음 화면을 염
  if (touches.value.count > 1) {
    next(event);
  }
};

// 아이템 클릭 함수 정의
const itemClick = (event: Event) => {
  // if (user.value.singleClick) next(event);
  // else select(event);
  select(event);
};

// 선택 함수 정의
const select = (event: Event) => {
  const targetUrl = (event.currentTarget as HTMLElement).dataset.url;

  // 요소가 이미 선택된 경우 선택 해제
  if (props.selected === targetUrl) {
    emit('update:selected', null);
    return;
  }

  // 그렇지 않으면 요소를 선택
  emit('update:selected', targetUrl);
};

// 디렉토리 생성 함수 정의
const createDir = async () => {
  window.dispatchEvent(
    new CustomEvent('showHover', {
      detail: {
        prompt: 'newDir',
        action: null,
        confirm: null,
        props: {
          redirect: false,
          base: current === route.path ? null : current,
        },
      },
    })
  );
};

// 컴포넌트가 마운트될 때 fillOptions 함수 호출
onMounted(() => {
  fillOptions(req.value);
});
</script>

<template>
  <div>
    <ul class="contain-file-list scroll-s-project my-2">
      <li
        v-for="item in items"
        :key="item.name"
        role="option"
        tabindex="0"
        :aria-label="item.name"
        :aria-selected="selected == item.url"
        :data-url="item.url"
        @click="itemClick"
        @touchstart="touchstart"
        @dblclick="next"
      >
        {{ item.name }}
      </li>
    </ul>
    <p>
      {{ $t('prompts.currentlyNavigating') }}
      <code class="px-2 pt-1 rounded text-xs">{{ nav }}</code>
    </p>
  </div>
</template>
