<template>
  <div>
    <form @submit="updatePassword">
      <h3 class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
        {{ t('settings.currentPassword') }}
      </h3>
      <div class="space-y-1 w-60">
        <AiaInput
          v-model="curPassword"
          :rules='rules.curPassword'
          class-name="text-sm px-3 w-full rounded-md border-0 ring-1 ring-inset ring-gray-300 dark:bg-gray-800"
          type="password"
          :placeholder="t('settings.currentPassword')"
          name="password"
        />
      </div>
      <div class="mt-2 float-none">
        <h3
          class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          {{ t('settings.changePassword') }}
        </h3>
        <div class="space-y-1 w-60">
          <AiaInput
            v-model="password"
            :rules='rules.passwd'
            class-name="text-sm px-3 w-full rounded-md border-0 ring-1 ring-inset ring-gray-300 dark:bg-gray-800"
            type="password"
            :placeholder="t('settings.newPassword')"
            name="password"
          />
          <AiaInput
            v-model="passwordConfirm"
            :rules='rules.confirmPasswd'
            class-name="text-sm px-3 w-full rounded-md border-0 ring-1 ring-inset ring-gray-300 dark:bg-gray-800"
            type="password"
            :placeholder="t('settings.newPasswordConfirm')"
            name="passwordConfirm"
          />

          <div class="absolute bottom-5 right-5">
            <AiaButton
              type="primary"
              size="md"
              :disabled='isDisabled'
              name="submitPassword"
              :value="t('buttons.update')"
            >
              {{ t('buttons.update') }}
            </AiaButton>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script setup lang="ts">
import { users as api } from '@/api';
import command from '@/api/commands';
import { StatusError } from '@/api/utils';
import AiaButton from '@/components/form/AiaButton.vue';
import AiaInput from '@/components/form/AiaInput.vue';
import { useAuthStore } from '@/stores/auth';
import { useLayoutStore } from '@/stores/layout';
import { computed, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { toast } from 'vue3-toastify';

const layoutStore = useLayoutStore();
const authStore = useAuthStore();
const { t } = useI18n();

const curPassword = ref<string>('');
const password = ref<string>('');
const passwordConfirm = ref<string>('');

onMounted(() => {
  layoutStore.loading = true;
  if (authStore.user === null) return false;
  layoutStore.loading = false;
  return true;
});

const isMatchPassword = (value: string) => {
  if (value !== password.value) return false;
  return true;
};
const rules = {
  curPassword: [
    {
      required: true,
      message: t('auth.invalid-msg.password'),
      trigger: 'blur',
    },
    {
      minlength: 4,
      message: t('auth.invalid-msg.min', { min: 4 }),
      trigger: 'blur',
    },
  ],
  passwd: [
    {
      required: true,
      message: t('auth.invalid-msg.password'),
      trigger: 'blur',
    },
    {
      minlength: 4,
      message: t('auth.invalid-msg.min', { min: 4 }),
      trigger: 'blur',
    },
  ],
  confirmPasswd: [
    {
      required: true,
      message: t('auth.invalid-msg.password'),
      trigger: 'blur',
    },
    {
      message: t('auth.invalid-msg.unMatchPassword'),
      function: isMatchPassword,
      trigger: 'live',
    },
  ],
};

const isDisabled = computed(()=>{
  return authStore.user === null ||
    curPassword.value === '' ||
    password.value === '' ||
    password.value !== passwordConfirm.value ||
    curPassword.value.length < 4 ||
    passwordConfirm.value.length < 4
})

const updatePassword = async (event: Event) => {
  event.preventDefault();

  if (
    authStore.user === null ||
    curPassword.value === '' ||
    password.value === '' ||
    password.value !== passwordConfirm.value ||
    curPassword.value.length < 4 ||
    passwordConfirm.value.length < 4
  ) {
    return;
  }

  try {
    const data = {
      ...authStore.user,
      id: authStore.user.id,
      password: password.value,
      passwordConfirm: curPassword.value, // 여기에 현재의 비밀번호
    };
    await api.update(data, ['password']);
    authStore.updateUser(data);
    toast.success(t('settings.passwordUpdated'));
    curPassword.value = password.value = passwordConfirm.value = '';
  } catch (e) {
    if (e instanceof StatusError) {
      if (e.status === 403) {
        toast.error(
          t('message.passwordForbiddenMsg')
        );
      } else {
        toast.error(String(e));
      }
    } else {
      toast.error(String(e));
    }
  }
};
</script>
