<script setup>
import logoImage from "@/assets/images/logo/additai.png";
import ThemeSwitcher from "@/components/common/ThemeSwitcher.vue";
import AiaButton from "@/components/form/AiaButton.vue";
import { useLayoutStore } from "@/stores/layout";
import { Directories } from "@/utils/constants";
import UserDropdown from "@/views/mypage/UserDropdown.vue";
import { PhHeadCircuit } from "@phosphor-icons/vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

const { t } = useI18n();
const router = useRouter();

function goToPage(path) {
  if (!path) path = "/";
  router.push({ path: path });
}

const layoutStore = useLayoutStore();
</script>

<template>
  <header class="base-head">
    <div class="flex justify-between">
      <div class="flex items-center w-52">
        <button class="mr-2" @click="layoutStore.toggleSidebar()">
          <svg width="24" height="24" viewBox="0 0 24 24" class="fill-gray-600">
            <path d="M3 6h18v2H3zm0 5h18v2H3zm0 5h18v2H3z" />
          </svg>
        </button>

        <a
          href="/"
          class="flex items-center text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
        >
          <img class="h-5 w-5" :src="logoImage" alt="" />
          <span class="ml-2 logo-text">{{ $t("title") }}</span>
        </a>

        <span class="ml-2 logo-sub-text font-sans hidden md:block">{{
          $t("subTitle")
        }}</span>
      </div>
      <div class="flex items-center pr-4 space-x-1">
        <theme-switcher
          class="rounded hover:bg-blue-100 dark:hover:bg-gray-700"
        />
        <div class="mx-2 pl-3">
          <UserDropdown />
        </div>
      </div>
    </div>
  </header>
</template>
<style lang="scss" scoped>
.base-head {
  //@apply ml-12 bg-neutral-50 dark:bg-gray-900 text-gray-900 dark:text-gray-200 border-b border-gray-200 dark:border-neutral-600;
  @apply ml-4 border-gray-200 dark:border-neutral-600;
}

//@import url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@200..900&family=JetBrains+Mono:ital,wght@0,100..800;1,100..800&family=Saira+Stencil+One&display=swap');
//.logo-text2 {
//  font-family: "Saira Stencil One", sans-serif;
//  font-weight: 100;
//  font-style: normal;
//  font-size: 20px;
//  color: #333;
//}
//
//@import url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@200..900&family=JetBrains+Mono:ital,wght@0,100..800;1,100..800&family=Michroma&family=Saira+Stencil+One&display=swap');
//.logo-text3 {
//  font-family: "Michroma", sans-serif;
//  font-weight: 200;
//  font-style: normal;
//  font-size: 18px;
//  //color: #333;
//}

@import url("https://fonts.googleapis.com/css2?family=Bruno+Ace+SC&family=Inconsolata:wght@200..900&family=JetBrains+Mono:ital,wght@0,100..800;1,100..800&family=Michroma&family=Saira+Stencil+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bruno+Ace+SC&family=Inconsolata:wght@200..900&family=JetBrains+Mono:ital,wght@0,100..800;1,100..800&family=Michroma&family=Montserrat+Alternates:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Saira+Stencil+One&display=swap");

.logo-text {
  //font-family: "Bruno Ace SC", sans-serif;
  font-family: "Montserrat Alternates", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 18px;
  //color: #000;
}

.logo-sub-text {
  font-size: 14px;
  opacity: 0.7;
}
</style>
