<template>
  <div class="wrap flex items-center cursor-pointer" :title="lastItem.url">
    <PhFolderSimple :size="20" weight="fill" class="inline-block mr-1" />
    <router-link :to="lastItem.url">{{ lastItem.name }}</router-link>
  </div>
</template>

<script setup lang="ts">
import { PhFolderSimple } from '@phosphor-icons/vue';
import { computed } from 'vue';

const props = withDefaults(
  defineProps<{
    className?: string;
    base: string;
    path: string;
    noLink?: boolean;
  }>(),
  {
    className: '',
    path: '',
    noLink: false,
  }
);

// const showTooltip = ref(false);

// 파일 여부를 판단하는 함수
const isFile = (pathSegment: string): boolean => {
  return /\.[\w-]+$/.test(pathSegment);
};

// 경로에서 파일명을 제거하고 폴더 경로만 추출
const items = computed(() => {
  let relativePath = props.path.replace(props.base, '');
  relativePath = relativePath.replace(/^\/|\/$/g, '');

  const parts = relativePath.split('/').filter(part => part !== '');

  // 마지막 부분이 파일이면 제거
  if (parts.length > 0 && isFile(parts[parts.length - 1])) {
    parts.pop();
  }

  const breadcrumbs: { name: string; url: string }[] = [
    { name: '/', url: props.base + '/' },
  ];

  parts.forEach((part, index) => {
    const name = decodeURIComponent(part);
    const url = `${props.base}/${parts.slice(0, index + 1).join('/')}`;
    breadcrumbs.push({ name, url });
  });

  return breadcrumbs;
});

// 마지막 폴더명 추출
const lastItem = computed(() => {
  if (items.value.length === 0) {
    return { name: '/', url: props.base + '/' };
  }
  return items.value[items.value.length - 1];
});

// 링크 여부에 따른 컴포넌트 결정
const element = computed(() => (props.noLink ? 'span' : 'router-link'));
</script>
<style lang="scss" scoped>
//.breadcrumbs {
//  @apply inline-flex items-center;
//
//  li a,
//  li span {
//    @apply inline-flex items-center px-1 text-gray-700 hover:bg-gray-200;
//  }
//}
</style>
