import { PageListInfo } from "@/class/interface/drive/metadata";

export function usePagination(callback: any) {
  // const route = useRoute();
  // const router = useRouter();

  const pageInfo = {
    limit: 50,
    currentPage: 1,
    total: 0,
    offset: 0,
  } as PageListInfo;

  const goPage = async (page: number, limit: number, offset: number) => {
    pageInfo.currentPage = page;
    pageInfo.limit = limit;
    pageInfo.offset = offset;

    const pageUrl = `?limit=${limit}&offset=${offset}`;
    // // URL 이동
    // if (router.currentRoute.value.fullPath !== route.path + pageUrl) {
    //   await router.push({
    //     path: route.path,
    //     query: { limit: limit, offset: offset },
    //   });
    // }

    // 데이터 가져오기
    if (callback && typeof callback === "function") {
      callback(limit, offset);
    }
  };
  return {
    goPage,
    pageInfo,
  };
}
