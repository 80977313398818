<script setup lang="ts">
import logoImage from "@/assets/images/logo/additai.png";
// import { ViewUtils } from "@/class/ViewUtils";
import { onMounted, ref } from "vue";

const canvas = ref(null);

const canvasWatermark = (canvas, src) => {
  const ctx = canvas.value.getContext("2d");

  canvas.value.width = window.innerWidth;
  canvas.value.height = window.innerHeight;

  const watermark = new Image();
  watermark.src = src;
  watermark.onload = () => {
    const patternCanvas = document.createElement("canvas");
    const patternCtx = patternCanvas.getContext("2d");

    // 패턴 캔버스 크기 설정
    patternCanvas.width = 200; // 로고 사이즈 + 사선 간격
    patternCanvas.height = 200;

    // 로고 이미지 투명도를 20%로 설정
    patternCtx.globalAlpha = 0.05;

    // 로고 이미지를 10x10 크기로 조정하여 패턴 캔버스에 그리기
    patternCtx.drawImage(watermark, 0, 0, 30, 30);

    // 그레이스케일 처리를 위한 이미지 데이터 로드
    const imgData = patternCtx.getImageData(0, 0, 30, 30);

    // 그레이스케일 처리
    for (let i = 0; i < imgData.data.length; i += 4) {
      const avg =
        (imgData.data[i] + imgData.data[i + 1] + imgData.data[i + 2]) / 3;
      imgData.data[i] = imgData.data[i + 1] = imgData.data[i + 2] = avg;
    }
    patternCtx.putImageData(imgData, 0, 0);

    // 패턴으로 사용할 준비
    const pattern = ctx.createPattern(patternCanvas, "repeat");
    ctx.fillStyle = pattern;
    ctx.fillRect(0, 0, canvas.value.width, canvas.value.height);
  };
};

onMounted(() => {
  canvasWatermark(canvas, logoImage);
});
</script>

<template>
  <canvas
    ref="canvas"
    style="z-index: -1; position: absolute; top: 0; bottom: 0"
  ></canvas>
</template>

<style scoped lang="scss"></style>
