{
  "title": "setFn",
  "subTitle": "Team Space",
  "connection": {
    "failedMsg": "Failed to connect to the server."
  },
  "auth": {
    "login": {
      "createAnAccount": "Create an account",
      "loginInstead": "Already have an account",
      "password": "Password",
      "passwordsDontMatch": "Passwords don't match",
      "signup": "Signup",
      "submit": "Login",
      "username": "Username",
      "usernameTaken": "Username already taken",
      "wrongCredentials": "Wrong credentials"
    },
    "signIn": {
      "title": "Sign in",
      "explain": "",
      "agree": "I confirm that I have read, consent and agree to setFn's Terms of Use and Privacy Policy",
      "forgetPwd": "Forgot password?",
      "submit": "Log in",
      "signUpBtn": "Sign up",
      "errorOccurred": "Login failed.",
      "forbiddenAccount": "Please check your account information.",
      "unActivated": "You have logged in, but the account is not yet activated."
    },
    "signUp": {
      "title": "Sign up",
      "nickname": "Nickname",
      "password": "Password",
      "passwordConfirm": "Confirm password",
      "submit": "Sign up",
      "completed": "You have successfully registered.",
      "response": {
        "notAllowed": "Registration is currently restricted.",
        "errorOccurred": "Registration failed.",
        "forbidden": "You do not have permission to register.",
        "conflictNick": "The nickname is already in use.",
        "conflictUserName": "The username is already in use."
      }
    },
    "invalid-msg": {
      "username": "Please enter a valid username.",
      "nickname": "Please enter a nickname.",
      "password": "Please enter a password.",
      "unMatchPassword": "Passwords do not match.",
      "email": "Please enter a valid email address.",
      "max": "You can enter up to {max} characters.",
      "min": "Please enter at least {min} characters."
    }
  },
  "invalid": {
    "required": "This field is required.",
    "only-type": "Only {type} type is allowed.",
    "min": "Please enter at least {min} characters.",
    "max": "You can enter up to {max} characters.",
    "duplicatedName": "This field is duplicated.",
    "underscoresAlpha": "This field must consist of only English letters and underscores."
  },
  "fileMessage": {
    "conflict": "A folder or file with this name already exists.",
    "invalidName": "The name contains invalid characters.",
    "clipboardCopySuccess": "{cnt} items have been copied to the clipboard.",
    "clipboardCutSuccess": "{cnt} items have been cut to the clipboard.",
    "moveSuccess": "Moved {cnt} files successfully.",
    "copySuccess": "Copied {cnt} files successfully.",
    "deleteSuccess": "Successfully deleted {cnt} items.",
    "noSelectedItem": "No items selected.",
    "history": {
      "copy": "Copied.",
      "copy+override": "Copied and overwritten.",
      "copy+rename": "Copied and renamed.",
      "move": "Moved.",
      "move+override": "Moved and overwritten.",
      "move+rename": "Moved and renamed.",
      "edit": "Edited.",
      "rename": "Renamed.",
      "undefined": "-"
    }
  },
  "message": {
    "inValidFileName": "사용할 수 없는 문자가 포함되어 있습니다.",
    "multiTask": "Successfully {action} {cnt} items.",
    "folderTask": "Successfully {action} the folder.",
    "fileTask": "Successfully {action} the file.",
    "linkCopied": "Link copied!",
    "createFail": "Failed to create.",
    "favoriteActionSuccess": "Favorite {action} successfully.",
    "favoriteActionFail": "Failed to {action} favorite.",
    "conflictNameMsg": "An item with the same name already exists.",
    "forbiddenMsg": "You do not have permission to {action}.",
    "updateOnlyCreatorMsg": "Only the creator can set this.",
    "updateWarnAdminMsg": "Only the creator can set this, but administrator permission is allowed.",
    "passwordForbiddenMsg": "The current password is incorrect, or you do not have the necessary permissions.",
    "actionNewFolder": "create folder",
    "actionNewFile": "create file",
    "actionAdd": "add",
    "actionDelete": "delete",
    "actionModify": "modify",
    "actionRemove": "remove",
    "loading": "Loading",
    "initializing": "Initializing"
  },
  "logsActions": {
    "register": "Sign Up",
    "login": "Login",
    "logout": "Logout",
    "update": "Update Information",
    "delete": "Delete Account"
  },
  "logsStatus": {
    "unactivated": "Account Inactive",
    "success": "Success",
    "fail": "Failure"
  },
  "accessLevel": {
    "setting": "Access Permission",
    "title": "Member Access Level Permissions",
    "settingHelp": "Can be set up to my level",
    "forbiddenByLevelExplain": "Access requires level {level} or higher. Please contact the administrator.",
    "forbiddenExplain": "You do not have access permissions. Please contact the administrator.",
    "myLevel": "My Level",
    "myLevelHelp": "If the user sets folder-level access permissions, my level is required. My level can be set by an administrator from 1 to 10, and it determines access to folders."
  },
  "tableEditor": {
    "addTop": "Add at Top",
    "addBottom": "Add at Bottom",
    "addCopyTop": "Copy and Add to Top",
    "addCopyBottom": "Copy and Add to Bottom",
    "addCopyAbove": "Copy and Add Above",
    "addCopyBelow": "Copy and Add Below",
    "delRow": "Delete row",
    "modified": "Modified",
    "addSchema": "Add Schema",
    "convertor": "Convertor",
    "name": "name"
  },
  "workflow": {
    "existWorksReady": "A total of {cnt} tasks are ready.",
    "existWorksComplete": "A total of {cnt} tasks have been completed.",
    "overwriteFileCheckMsg": "Overwrite if the same file exists",
    "destinationPath": "Set Destination",
    "cancelExe": "Cancel Execution",
    "genExecution": "Execute Generation",
    "createFolder": "Create Folder",
    "createFile": "Create File"
  },
  "buttons": {
    "list": "Lists",
    "add": "Add",
    "back": "Back",
    "cancel": "Cancel",
    "clear": "Clear",
    "close": "Close",
    "continue": "Continue",
    "copy": "Copy",
    "copyFile": "Copy file",
    "copyToClipboard": "Copy to clipboard",
    "copyDownloadLinkToClipboard": "Copy download link to clipboard",
    "create": "Create",
    "delete": "Delete",
    "download": "Download",
    "file": "File",
    "folder": "Folder",
    "fullScreen": "Toggle full screen",
    "hideDotfiles": "Hide dotfiles",
    "info": "Info",
    "more": "More",
    "move": "Move",
    "moveFile": "Move file",
    "new": "New",
    "next": "Next",
    "ok": "OK",
    "permalink": "Get Permanent Link",
    "previous": "Previous",
    "publish": "Publish",
    "rename": "Rename",
    "replace": "Replace",
    "reportIssue": "Report Issue",
    "save": "Save",
    "schedule": "Schedule",
    "search": "Search",
    "select": "Select",
    "selectMultiple": "Select multiple",
    "share": "Share",
    "shell": "Toggle shell",
    "submit": "Submit",
    "switchView": {
      "title": "Switch view",
      "list": "List",
      "tiles": "Tiles",
      "gallery": "Gallery"
    },
    "toggleSidebar": "Toggle sidebar",
    "update": "Update",
    "upload": "Upload",
    "openFile": "Open file",
    "discardChanges": "Discard"
  },
  "level": {
    "bulkApply": "Apply Level in Bulk",
    "auth_list": "List View Access",
    "auth_view": "File Read Access",
    "auth_write": "File Write Access",
    "above": "Level or higher"
  },
  "download": {
    "downloadFile": "Download File",
    "downloadFolder": "Download Folder",
    "downloadSelected": "Download Selected"
  },
  "upload": {
    "abortUpload": "Are you sure you wish to abort?"
  },
  "errors": {
    "forbiddenLevel": "Access is restricted to certain levels or higher.",
    "conflict": "An item with the same content already exists.",
    "forbidden": "You don't have permissions to access this.",
    "internal": "Something really went wrong.",
    "notFound": "This location can't be reached.",
    "connection": "The server can't be reached."
  },
  "files": {
    "body": "Body",
    "creator": "Creator",
    "explain": "Explain",
    "closePreview": "Close preview",
    "files": "Files",
    "folders": "Folders",
    "levelFolders": "Level Folders",
    "home": "Home",
    "lastModified": "Last modified",
    "loading": "Loading...",
    "listEmpty": "Drag and drop files or folders here to upload.",
    "lonely": "No data.",
    "metadata": "Metadata",
    "multipleSelectionEnabled": "Multiple selection enabled",
    "name": "Name",
    "size": "Size",
    "countDirEntries": "{cnt} items",
    "sortByLastCreated": "Sort by Last Created",
    "sortByLastModified": "Sort by last modified",
    "sortByName": "Sort by name",
    "sortBySize": "Sort by size",
    "sortAsc": "Ascending",
    "sortDesc": "Descending",
    "creatorBySize": "Sort by creator",
    "noPreview": "Preview is not available for this file.",
    "selected": "{0} items selected",
    "storage" : {
      "title": "Storage",
      "usage": "{used} of {total} used"
    }
  },
  "content": {
    "noLatestFiles": "There are no recently used files."
  },
  "help": {
    "click": "select file or directory",
    "ctrl": {
      "click": "select multiple files or directories",
      "f": "opens search",
      "s": "save a file or download the directory where you are"
    },
    "del": "delete selected items",
    "doubleClick": "open a file or directory",
    "esc": "clear selection and/or close the prompt",
    "f1": "this information",
    "f2": "rename file",
    "help": "Help",
    "n": "new file"
  },
  "permanent": "Permanent",
  "prompts": {
    "copyToFolder": "Copy to Folder",
    "copyToMessage": "Choose the destination folder to copy the selected files or folders:",
    "currentlyNavigating": "Currently navigating on:",
    "deleteMessageMultiple": "Are you sure you wish to delete {count} file(s)?",
    "deleteMessageSingle": "Are you sure you wish to delete this file/folder?",
    "deleteMessageShare": "Are you sure you wish to delete this share '{path}'?",
    "deleteUser": "Are you sure you want to delete this user?",
    "deleteTitle": "Delete files",
    "displayName": "Display Name",
    "download": "Download files",
    "downloadMessage": "Choose the format you wish to download.",
    "error": "Something went wrong",
    "folderInfo": "Folder information",
    "fileInfo": "File information",
    "fileHistory": "Change Log",
    "fileDetailInfo": "File detail information",
    "fileOptions": "File view options",
    "filesSelected": "{count} files selected.",
    "itemSelected": "{count} items selected",
    "lastModified": "Last Modified",
    "move": "Move",
    "moveMessage": "Choose new home for your file(s)/folder(s):",
    "newArchetype": "Create a new post based on an archetype. Your file will be created on content folder.",
    "newDir": "New directory",
    "newDirMessage": "Name your new directory.",
    "newFile": "New file",
    "newSchemaFile": "New Schema TPL",
    "screenRecorder": "Screen Recording",
    "newFileMessage": "Name your new file.",
    "numberDirs": "Number of directories",
    "numberFiles": "Number of files",
    "rename": "Rename",
    "renameMessage": "Insert a new name for",
    "replace": "Replace",
    "replaceMessage": "One of the files you're trying to upload has a conflicting name. Do you wish to skip this file and continue to upload or replace the existing one?\n",
    "schedule": "Schedule",
    "scheduleMessage": "Pick a date and time to schedule the publication of this post.",
    "show": "Show",
    "size": "Size",
    "upload": "Upload",
    "uploadFiles": "Uploading {files} files...",
    "uploadMessage": "Select an option to upload.",
    "optionalPassword": "Optional password",
    "resolution": "Resolution",
    "discardEditorChanges": "Are you sure you wish to discard the changes you've made?"
  },
  "search": {
    "images": "Images",
    "music": "Music",
    "pdf": "PDF",
    "searchNoResult": "\"No search results found",
    "searchPath": "Search Path",
    "fileSearch": "File Search...",
    "typeToSearch": "Type to search...",
    "types": "Types",
    "video": "Video",
    "md": "Markdown"
  },
  "uploadFile": {
    "remaining": "Remaining",
    "calculating": "Calculating"
  },
  "settings": {
    "sort": "Sort",
    "currentFolder": "Folder setting",
    "defaultSwitchView": "Folder view",
    "admin": "Admin",
    "administrator": "Administrator",
    "allowCommands": "Execute commands",
    "allowEdit": "Edit, rename and delete files or directories",
    "allowNew": "Create new files and directories",
    "allowPublish": "Publish new posts and pages",
    "allowSignup": "Allow Signup",
    "avoidChanges": "(leave blank to avoid changes)",
    "changePassword": "Change Password",
    "commandRunner": "Command runner",
    "commandRunnerHelp": "Here you can set commands that are executed in the named events. You must write one per line. The environment variables {0} and {1} will be available, being {0} relative to {1}. For more information about this feature and the available environment variables, please read the {2}.",
    "commandsUpdated": "Commands updated!",
    "tusUploads": "Chunked Uploads",
    "tusUploadsChunkSize": "Sets the size of the data to upload at one time. Larger sizes can improve upload speed, but may increase the risk of upload failure depending on network conditions and server capacity (e.g., concurrent connections, bandwidth). You can input values like '10MB', '1GB', or use byte-sized numbers.",
    "tusUploadsRetryCount": "Sets the number of retries when an upload fails.",
    "userScopeGenerationPlaceholder": "The scope will be auto generated",
    "createUserHomeDirectory": "Create user home directory",
    "customStylesheet": "Custom Stylesheet",
    "defaultUserDescription": "The permissions, levels, etc., for new users will be automatically applied based on the default values set below.",
    "disableUsedDiskPercentage": "Disable used disk percentage graph",
    "documentation": "documentation",
    "examples": "Examples",
    "hideDotfiles": "Hide dotfiles",
    "insertPath": "Insert the path",
    "insertRegex": "Insert regex expression",
    "instanceName": "Instance name",
    "language": "Language",
    "newPassword": "Your new password",
    "newPasswordConfirm": "Confirm your new password",
    "newUser": "New User",
    "password": "Password",
    "passwordUpdated": "Password updated!",
    "path": "Path",
    "perm": {
      "list": "View file list",
      "create": "Create files and directories",
      "delete": "Delete files and directories",
      "rename": "Rename and move",
      "download": "Download",
      "execute": "Execute commands",
      "modify": "Edit files",
      "view": "View Files",
      "share": "Share files"
    },
    "permissions": "Permissions",
    "adminPermHelp": "All permissions, including member management and file access, will be enabled.",
    "permissionsHelp": "Basic permissions are granted. Detailed permissions for each folder can be set by level after the folder is created.",
    "profileSettings": "Profile Settings",
    "ruleExample1": "prevents the access to any dotfile (such as .git, .gitignore) in every folder.\n",
    "ruleExample2": "blocks the access to the file named Caddyfile on the root of the scope.",
    "rules": "Rules",
    "rulesHelp": "Here you can define a set of allow and disallow rules for this specific user. The blocked files won't show up in the listings and they wont be accessible to the user. We support regex and paths relative to the users scope.\n",
    "scope": "Scope",
    "setDateFormat": "Set exact date format",
    "settingsUpdated": "Settings updated!",
    "shareDuration": "Share Duration",
    "shareManagement": "Share Management",
    "shareDeleted": "Share deleted",
    "shareAdded": "Share added",
    "singleClick": "Use single clicks to open files and directories",
    "themes": {
      "default": "System default",
      "dark": "Dark",
      "light": "Light",
      "title": "Theme"
    },
    "user": "User",
    "userCommands": "Commands",
    "userCommandsHelp": "A space separated list with the available commands for this user. Example:\n",
    "userCreated": "User created!",
    "userDefaults": "User default settings",
    "userDeleted": "User deleted!",
    "userManagement": "User Management",
    "userUpdated": "User updated!",
    "username": "Username",
    "nick": "Nick name",
    "users": "Users",
    "isActive": "Is activate",
    "isActiveDefaultHelp": "Activates the account immediately upon registration to enable login.",
    "level": "Level",
    "currentPassword": "Current Password",
    "status": "Status",
    "action": "Action",
    "timestamp": "Timestamp"
  },
  "sidebar": {
    "help": "Help",
    "hugoNew": "Hugo New",
    "login": "Login",
    "logout": "Logout",
    "myFiles": "My files",
    "teamFolder": "Team folder",
    "newFile": "New file",
    "newFolder": "New folder",
    "preview": "Preview",
    "settings": "Settings",
    "signup": "Signup",
    "siteSettings": "Site Settings",
    "myContent": "My Contents",
    "make": "Create",
    "favFolder": "Favorites",
    "latestFolder": "Recent"
  },
  "adminMenu": {
    "home": "Home",
    "authLogs": "Access Logs",
    "users": "User Management",
    "configSettings": "Configuration Settings",
    "globalSettings": "Global Settings"
  },
  "pageMenu": {
    "home": "Home",
    "ai": "AI",
    "team": "Team"
  },
  "time": {
    "days": "Days",
    "hours": "Hours",
    "hours2": "Hours",
    "minutes": "Minutes",
    "seconds": "Seconds",
    "unit": "Time Unit"
  }
}
