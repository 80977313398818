<script setup lang="ts">
import AiaDropdown from "@/components/dropdown/AiaDropdown.vue";
import AiaButton from "@/components/form/AiaButton.vue";
import { router } from "@/routes";
import { useAuthStore } from "@/stores/auth";
import { useLayoutStore } from "@/stores/layout";
import * as auth from "@/utils/auth";
import { loginPage, noAuth } from "@/utils/constants";
import ProfileSettingModal from "@/views/mypage/ProfileSettingModal.vue";
import { PhGear, PhSignIn, PhSignOut, PhUserGear } from "@phosphor-icons/vue";
import { ref, useAttrs } from "vue";
import { useI18n } from "vue-i18n";
const attrs = useAttrs();

const authStore = useAuthStore();
const layoutStore = useLayoutStore();
const { t } = useI18n();
// const prefixNick = authStore.$id; //userStore.getUser.userId?.slice(0, 2);
// console.log(authStore.user);
const showMenu = ref(false);
const hideMenu = () => {
  setTimeout(() => {
    showMenu.value = false;
  }, 300);
};

const showProfileSetting = ref(false);
function goToProfile() {
  layoutStore.showHover("profile");
}

function goToPermission() {
  layoutStore.showHover({
    prompt: "profile",
    props: {
      menuKey: "3",
    },
  });
}

function goToManages() {
  router.push({ path: "/manages" });
}

function handleToLogout() {
  if (!noAuth && loginPage) {
    auth.logout();
  }
}
function handleToLogin() {
  router.push({ path: "/login" });
}
</script>

<template>
  <div v-bind="attrs">
    <div
      v-if="showProfileSetting"
      ref="modalElement"
      class="aia-modal-wrapper"
      tabindex="-1"
    >
      <ProfileSettingModal />
    </div>

    <AiaDropdown
      v-model:show-menu="showMenu"
      class-name="min-w-44"
      @select="hideMenu"
    >
      <div
        class="relative w-8 h-8 overflow-hidden bg-gray-400 rounded-full dark:bg-gray-600 cursor-pointer"
      >
        <svg
          class="absolute -left-1 text-white w-10 h-10"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
            clip-rule="evenodd"
          ></path>
        </svg>
      </div>
      <template #items>
        <div class="line-b-drop-menu px-4 py-3 space-y-2 whitespace-nowrap">
          <div class="font-semibold">
            {{
              authStore.user?.nick || authStore.user?.username || "Unknown name"
            }}
          </div>
          <div class="flex justify-between space-x-2">
            <div
              class="flex items-center text-sm font-medium text-gray-900 dark:text-white"
            >
              <span class="w-1"></span>
              <!--              <PhIdentificationCard :size="20" weight="thin" class='w-6 inline-block' />-->
              {{ t("accessLevel.myLevel") }} :
            </div>
            <div>
              <!--              <PhNumberSeven :size="20" weight="regular" />-->
              <span class="font-bold text-blue-500 dark:text-gray-300 mr-2">{{
                authStore.user?.level
              }}</span>
            </div>
            <!--            <span class="small text-gray-500 dark:text-gray-300 p-1"></span>-->
          </div>

          <div class="flex justify-between space-x-2">
            <div
              class="flex items-center text-sm font-medium text-gray-900 dark:text-white"
            >
              <span class="w-1"></span>
              {{ t("buttons.file") }} {{ t("settings.permissions") }} :
            </div>
            <div>
              <AiaButton size="xs" @click="goToPermission"> view </AiaButton>
            </div>
          </div>
        </div>
        <ul class="drop-menu-items w-full">
          <template v-if="authStore.isLoggedIn">
            <li class="line" @click="goToProfile">
              <PhUserGear :size="20" weight="thin" />
              <span>{{ t("settings.profileSettings") }}</span>
            </li>
            <template v-if="authStore.user.perm.admin">
              <li class="line" @click="goToManages">
                <PhGear :size="20" weight="thin" />
                <span>{{ t("settings.administrator") }}</span>
              </li>
            </template>
            <li @click="handleToLogout">
              <PhSignOut :size="20" weight="thin" />
              <span>{{ t("sidebar.logout") }}</span>
            </li>
          </template>
          <template v-else>
            <li @click="handleToLogin">
              <PhSignIn :size="20" weight="thin" />
              <span>{{ t("sidebar.login") }}</span>
            </li>
          </template>
        </ul>
      </template>
    </AiaDropdown>
  </div>
</template>

<style scoped lang="scss"></style>
