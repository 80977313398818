<template>
  <div class="level-access-errors relative">
    <div class="title-wrap">
      <h2 class="z-10">
        <!--        <PhFolderSimpleUser :size="28" weight="thin" class='mr-1' />-->
        <!--        <PhArrowLeft :size="28" weight="thin" class='mr-1'  />-->
        <AiaButton
          type="info"
          size="xs"
          class-name="rounded-xl"
          @click="goBack()"
        >
          <span class="flex items-center space-x-2">
            <PhArrowLeft :size="22" weight="thin" />
          </span>
        </AiaButton>
        <Breadcrumbs :base="Directories.base" :display-length="1" />
      </h2>

      <div
        v-if="jsonCode['id'] === 'level_access_denied'"
        class="flex items-center justify-center space-x-3 text-base"
      >
        <svg
          viewBox="0 0 2 2"
          width="3"
          height="3"
          aria-hidden="true"
          class="fill-gray-900"
        >
          <circle cx="1" cy="1" r="1" />
        </svg>
        <span>{{ t("files.levelFolders") }} : </span>
        <span class="text-base/7 font-semibold text-blue-600">
          {{ jsonCode["requiredLevel"] || "?" }} {{ t("level.above") }}
        </span>
      </div>
    </div>

    <div class="inner-wrap relative">
      <!-- SVG 배경 -->
      <svg
        class="absolute z-0 inset-0 w-full h-full stroke-gray-300/10 dark:stroke-gray-600/10"
        fill="none"
        aria-hidden="true"
      >
        <defs>
          <pattern
            id="pattern-d09edaee-fc6a-4f25-aca5-bf9f5f77e14a"
            x="0"
            y="0"
            width="10"
            height="10"
            patternUnits="userSpaceOnUse"
          >
            <path d="M-3 13 15-5M-5 5l18-18M-1 21 17 3"></path>
          </pattern>
        </defs>
        <rect
          stroke="none"
          fill="url(#pattern-d09edaee-fc6a-4f25-aca5-bf9f5f77e14a)"
          width="100%"
          height="100%"
        ></rect>
      </svg>
      <div class="mx-auto max-w-7xl px-6 lg:px-8">
        <div
          v-if="jsonCode['id'] === 'level_access_denied'"
          class="mx-auto max-w-2xl lg:text-center"
        >
          <h2>
            <span>Access Restricted by Access Level</span>
          </h2>
          <div class="explain">
            {{
              t("accessLevel.forbiddenByLevelExplain", {
                level: jsonCode["requiredLevel"] || "?",
              })
            }}
          </div>

          <div
            class="mt-2 flex items-center justify-center space-x-3 text-base"
          >
            <svg
              viewBox="0 0 2 2"
              width="3"
              height="3"
              aria-hidden="true"
              class="fill-gray-600 dark:fill-gray-400"
            >
              <circle cx="1" cy="1" r="1" />
            </svg>
            <div class="text-gray-600 dark:text-gray-400">
              {{ t("accessLevel.myLevel") }} :
            </div>
            <span class="text-base/7 font-semibold text-blue-600">
              {{ jsonCode["userLevel"] || "0" }} Level
            </span>
          </div>
        </div>
        <div v-else>
          <h2>
            <span>Access Denied</span>
          </h2>
          <div class="explain">
            {{
              t("accessLevel.forbiddenExplain", {
                level: jsonCode["requiredLevel"] || "?",
              })
            }}
          </div>
        </div>
      </div>
      <div class="absolute z-10 bottom-10 block space-x-2 w-full pr-5">
        <AiaButton type="info" @click="goBack()">
          <span class="flex items-center space-x-2">
            <PhArrowLeft :size="22" weight="thin" />
            <span>{{ t("buttons.back") }}</span>
          </span>
        </AiaButton>

        <AiaButton type="info" @click="goHome()">
          <span class="flex items-center space-x-2">
            <PhHouse :size="22" weight="thin" />
            <span>{{ t("sidebar.teamFolder") }}</span>
          </span>
        </AiaButton>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import AiaButton from "@/components/form/AiaButton.vue";
import Breadcrumbs from "@/views/files/Breadcrumbs.vue";
import { useRouter } from "vue-router";
import { Directories } from "@/utils/constants";
import {
  PhArrowCircleRight,
  PhArrowLeft,
  PhFolderLock,
  PhFolderSimpleUser,
  PhHouse,
  PhProhibit,
  PhStar,
} from "@phosphor-icons/vue";
import { useI18n } from "vue-i18n";
const { t } = useI18n({});
const props = withDefaults(
  defineProps<{
    errorCode?: number;
    jsonCode?: any;
    showHeader?: boolean;
  }>(),
  {
    errorCode: 500,
    jsonCode: {},
    showHeader: false,
  }
);
const router = useRouter();
const goBack = () => {
  router.go(-1);
};

const goHome = () => {
  router.push({ path: Directories.base });
};
</script>
<style lang="scss" scoped>
.level-access-errors {
  padding: 10px 20px 0 26px;

  div.title-wrap {
    @apply flex items-center justify-between space-x-4 font-medium text-xl pt-4 pb-3;
    h2 {
      @apply flex justify-between items-center space-x-1;
    }
  }
  .inner-wrap {
    @apply py-24 sm:py-32 border rounded-xl
    block text-center
    dark:border-white/10;
    h2 {
      @apply text-pretty text-2xl font-semibold tracking-tight text-gray-900 dark:text-white
      sm:text-3xl lg:text-balance;
    }
    .explain {
      @apply mt-4 text-lg/8 text-gray-600 dark:text-gray-400;
    }
  }
}
</style>
