<script setup lang="ts">
import SplitLayoutViewer from "@/components/SplitLayoutViewer.vue";
import { useDeviceStore } from "@/stores/deviceStore";
import { useFileStore } from "@/stores/file.js";
import { useLayoutStore } from "@/stores/layout.js";
import FileSplitLayoutViewer from "@/views/files/FileSplitLayoutViewer.vue";
import Prompts from "@/views/files/modals/Prompts.vue";
import UploadFiles from "@/views/files/modals/UploadFiles.vue";
import FileSidebarMain from "@/views/files/sidebar/FileSidebarMain.vue";
import FilesSidebar from "@/views/files/sidebar/FilesSidebar.vue";
import { onMounted, ref, watch } from "vue";
import { useRoute } from "vue-router";

const layoutStore = useLayoutStore();
const fileStore = useFileStore();
const route = useRoute();
const deviceStore = useDeviceStore();
let widthLeft = ref<number | string>(200);
let maxWidthLeft = ref<number | string>(400);

onMounted(() => {
  if (deviceStore.isMobile) {
    widthLeft.value = "90%";
    maxWidthLeft.value = deviceStore.width;
  } else if (deviceStore.isXl) {
    layoutStore.showFileInfo = true;
  }
});

watch(route, () => {
  fileStore.selected = [];
  fileStore.multiple = false;
  if (layoutStore.currentPromptName !== "success") {
    layoutStore.closeHovers();
  }
});
</script>
<template>
  <main>
    <FileSplitLayoutViewer
      save-key-name="f-l-width"
      :width="widthLeft"
      :left-max-width="maxWidthLeft"
      :left-min-width="200"
    >
      <template #aside>
        <FilesSidebar />
      </template>
      <template #content>
        <main>
          <router-view />
        </main>
        <Prompts></Prompts>
        <UploadFiles></UploadFiles>
      </template>
      <template #right>
        <!-- 슬라이드 애니메이션 컴포넌트 -->
        <transition
          enter-active-class="animate-slideInFromRight"
          leave-active-class="animate-slideOutToRight"
        >
          <div
            v-if="layoutStore.showFileInfo"
            class="file-info-panel shadow-lg scroll-s-project"
          >
            <FileSidebarMain />
          </div>
        </transition>
      </template>
    </FileSplitLayoutViewer>
  </main>
</template>

<style lang="scss">
@use "@/assets/styles/file-header";
@use "@/assets/styles/shell";
@use "@/assets/styles/file-dashboard";
</style>

<style lang="scss" scoped>
.file-info-panel {
  min-width: 310px;
  height: var(--height-main-content);
  overflow-y: auto;
  overflow-x: hidden;
}
</style>
