import { defineStore } from 'pinia';
import moment from 'moment/min/moment-with-locales';
import i18n from '@/i18n'; // i18n 인스턴스 가져오기
export enum ThemeModeValue {
  Dark = 'dark',
  Light = 'light',
  System = 'system',
}
export const useSettingsStore = defineStore('settings', {
  state: () => ({
    theme: ThemeModeValue.System,
    language: 'en-US',
  }),
  getters: {
    getAppliedTheme(state) {
      if (state.theme === 'system') {
        // Check if the user prefers dark mode
        if (
          window.matchMedia &&
          window.matchMedia('(prefers-color-scheme: dark)').matches
        ) {
          return ThemeModeValue.Dark;
        } else {
          return ThemeModeValue.Light;
        }
      }
      return state.theme;
    },
  },
  actions: {
    setTheme(theme: ThemeModeValue) {
      this.theme = theme;
      localStorage.setItem('theme', theme);
    },
    setLanguage(language: string) {
      this.language = language;
      localStorage.setItem('language', language);
      i18n.global.locale.value = language;
    },
    applyTheme(themeValue: string | ThemeModeValue) {
      const root = document.documentElement;
      if (
        themeValue === ThemeModeValue.Dark ||
        (themeValue === ThemeModeValue.System &&
          window.matchMedia('(prefers-color-scheme: dark)').matches)
      ) {
        root.classList.add(ThemeModeValue.Dark);
      } else {
        root.classList.remove(ThemeModeValue.Dark);
      }
    },
    applyDefaultLanguageCode(lang?: string) {
      const storedLang = lang || localStorage.getItem('language');
      const code =
        storedLang ||
        (navigator.languages && navigator.languages.length > 0
          ? navigator.languages[0]
          : navigator.language) ||
        'en-US';

      const langCode = code.split('-')[0];

      if (langCode && langCode.length === 2) {
        this.setLanguage(langCode);
        moment.locale(langCode);
        i18n.global.locale.value = langCode;
      }
    },
    loadSettings() {
      const theme = localStorage.getItem('theme');
      const language = localStorage.getItem('language');
      if (theme) {
        this.theme = theme as string as ThemeModeValue;
      }
      if (language) this.language = language;
    },
  },
});
