<template>
  <div v-bind="attrs" class="aia-number-lv">
    <button type="button" :disabled="disabled" class="" @click="decrement">
      <svg
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 18 2"
      >
        <path
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M1 1h16"
        />
      </svg>
    </button>

    <input
      v-model="currentValue"
      type="text"
      :disabled="disabled"
      class="flex-shrink-0 text-gray-900 dark:text-white border-0 bg-transparent text-sm font-normal focus:outline-none focus:ring-0 max-w-[2.5rem] h-5 text-center disabled:text-gray-300"
      @input="updateValue($event.target.value)"
      @blur="checkMinValue"
    />

    <button type="button" :disabled="disabled" @click="increment">
      <svg
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 18 18"
      >
        <path
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M9 1v16M1 9h16"
        />
      </svg>
    </button>
  </div>
</template>

<script setup>
import { toRefs, useAttrs } from "vue";
import { useVModel } from "@vueuse/core";

const attrs = useAttrs();
const props = defineProps({
  modelValue: {
    type: [Number, String], // 숫자와 문자열 모두 허용
    default: 0,
  },
  useEvent: {
    type: Boolean,
    default: true,
  },
  min: {
    type: Number,
    default: 0,
  },
  max: {
    type: Number,
    default: 100,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

const { modelValue, min, max } = toRefs(props);
const currentValue = useVModel(props, "modelValue", undefined, {
  passive: true,
});

const emit = defineEmits(["update:modelValue", "change"]);

const increment = () => {
  if (Number(currentValue.value) < max.value) {
    currentValue.value = Number(currentValue.value) + 1;
  } else {
    currentValue.value = 1; // 음수일 경우 0으로 처리
  }
  if (props.useEvent) emit("change", currentValue.value);
};

const decrement = () => {
  if (Number(currentValue.value) > min.value) {
    currentValue.value = Number(currentValue.value) - 1;
  } else {
    currentValue.value = 0; // 음수일 경우 0으로 처리
  }
  if (props.useEvent) emit("change", currentValue.value);
};

const updateValue = newVal => {
  const parsedValue = parseFloat(newVal);
  if (isNaN(parsedValue) || parsedValue < 0) {
    currentValue.value = 0; // 음수나 유효하지 않은 값은 0 처리
  } else {
    currentValue.value = Math.min(parsedValue, max.value); // 최대값 제한
  }

  if (props.useEvent) emit("change", currentValue.value);
};

const checkMinValue = () => {
  const parsedValue = parseFloat(currentValue.value);
  if (isNaN(parsedValue) || parsedValue < 0) {
    currentValue.value = "0"; // 음수는 0 처리
    if (props.useEvent) emit("change", currentValue.value);
    return;
  }

  if (parsedValue > max.value) {
    currentValue.value = max.value.toString();
    if (props.useEvent) emit("change", currentValue.value);
  }
};
</script>
<style lang="scss" scoped>
.aia-number-lv {
  //@apply flex items-center;
  button {
    @apply flex-shrink-0 bg-gray-50 dark:bg-gray-700 dark:hover:bg-gray-600 dark:border-gray-600 hover:bg-gray-200 inline-flex items-center justify-center border border-gray-300 rounded h-4 w-4 focus:ring-gray-100 dark:focus:ring-gray-700 focus:ring-2 focus:outline-none;
  }
  svg {
    @apply w-2 h-2 text-gray-900 dark:text-white;
  }
}
</style>
