import { createI18n } from 'vue-i18n';
import en from './locale/en';
import ko from './locale/ko';
import ja from './locale/ja';
const i18n = createI18n({
  legacy: false,
  locale: 'en',
  fallbackLocale: 'en',
  messages: {
    en,
    ko,
    ja,
  },
});
export default i18n;
