import { ContextItemInter } from "@/class/interface/MenuInter";
import { useAuthStore } from "@/stores/auth";
import { useFileStore } from "@/stores/file";
import { computed } from "vue";
import { useI18n } from "vue-i18n";

export function useActionMenuPerm() {
  const { t } = useI18n({});
  const authStore = useAuthStore();
  const fileStore = useFileStore();

  const showButtons = computed(() => {
    const selCnt = fileStore.selectedCount;
    return {
      notSelected: selCnt === 0,
      upload: selCnt === 0 && authStore.user?.perm.create,
      download: selCnt > 0 && authStore.user?.perm.download,
      create: authStore.user?.perm.create,
      multiSelect: selCnt === 0 && fileStore.req?.items?.length > 1,
      delete: selCnt > 0 && authStore.user?.perm.delete,
      rename: selCnt === 1 && authStore.user?.perm.rename,
      share: selCnt === 1 && authStore.user?.perm.share,
      move: selCnt > 0 && authStore.user?.perm.rename,
      copy: selCnt > 0 && authStore.user?.perm.create,
      addFavorite: selCnt === 1,
    };
  });

  // 컨텍스트 메뉴 데이터 생성
  const contextMenu = computed(() => {
    const selCnt = fileStore.selectedCount;
    console.log(authStore.user?.perm, selCnt > 0);
    const selItem = fileStore.req?.items[fileStore.selected[0]];
    const favAction = selItem?.isFav
      ? t("message.actionRemove")
      : t("message.actionAdd");

    return [
      {
        label: t("sidebar.newFolder"),
        value: "newFolder",
        icon: "PhFolderPlus",
        iconSize: 18,
        disabled: !showButtons.value.create,
      },
      {
        label: t("sidebar.newFile"),
        value: "newFile",
        icon: "PhFilePlus",
        shortcut: "N",
        line: true,
        disabled: !showButtons.value.create,
      },
      {
        label: t("buttons.rename"),
        value: "rename",
        icon: "PhCursorText",
        shortcut: "F2",
        disabled: !showButtons.value.rename,
        hide: selCnt !== 1,
      },
      {
        label: t("buttons.delete") + (selCnt > 1 ? ` (${selCnt})` : ""),
        value: "delete",
        icon: "PhTrash",
        iconSize: 16,
        shortcut: "Del",
        line: true,
        disabled: !showButtons.value.delete,
        hide: selCnt === 0,
      },
      {
        label: t("sidebar.favFolder") + " " + favAction,
        value: "add-favorite",
        icon: "PhStar",
        line: true,
        disabled: selCnt !== 1 || !showButtons.value.addFavorite,
        hide: selCnt !== 1,
      },
      {
        label: t("buttons.download"),
        value: "download",
        icon: "PhArrowLineDown",
        line: true,
        disabled: selCnt === 0 || !showButtons.value.download,
        hide: selCnt === 0,
      },
      {
        label: t("prompts.fileInfo"),
        value: "info",
        icon: "PhInfo",
        line: true,
        hide: selCnt !== 1,
      },
    ] as ContextItemInter[];
  });

  return {
    showButtons,
    contextMenu,
  };
}
