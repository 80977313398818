import { language } from '@codemirror/language';
import { defineStore } from 'pinia';
// import { detectLocale, setLocale } from "@/i18n";
import { cloneDeep } from 'lodash-es';
// import Cookies from "js-cookie";

export const useAuthStore = defineStore('auth', {
  // convert to a function
  state: (): {
    user: IUser | null;
    jwt: string;
    token;
  } => ({
    user: null,
    jwt: '',
    token: null,
  }),
  getters: {
    // getToken: (state) => state.token,
    // user and jwt getter removed, no longer needed
    isLoggedIn: state => state.user !== null,
  },
  actions: {
    // setToken(token: string) {
    //   this.token = token;
    //   return Cookies.set(this.tokenKey, token);
    // },
    // removeToken() {
    //   return Cookies.remove(this.tokenKey);
    // },
    // no context as first argument, use `this` instead

    setUser(user: IUser) {
      if (user === null) {
        this.user = null;
        return;
      }
      this.user = user;
    },
    setLanguage(language: string) {
      this.language = language;
      localStorage.setItem('language', language);
    },
    updateUser(user: Partial<IUser>) {
      if (user.locale) {
        // setLocale(user.locale);
        this.setLanguage(user.locale);
      }
      this.user = { ...this.user, ...cloneDeep(user) } as IUser;
    },
    // easily reset state using `$reset`
    clearUser() {
      this.$reset();
    },
  },
});
