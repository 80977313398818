import { files as api } from '@/api';
import { cleanSuffixUrl, removePrefixUrl } from '@/api/utils';

export const getQueryParams = (route: any) => {
  const params = new URLSearchParams();

  // 필요한 파라미터 추가하기 (string 또는 string[] 처리)
  const sort = Array.isArray(route.query.sort)
    ? route.query.sort[0]
    : route.query.sort;
  const asc = Array.isArray(route.query.asc)
    ? route.query.asc[0]
    : route.query.asc;
  const limit = Array.isArray(route.query.limit)
    ? route.query.limit[0]
    : route.query.limit;
  const offset = Array.isArray(route.query.offset)
    ? route.query.offset[0]
    : route.query.offset;

  if (sort) {
    params.append('sort', sort);
    params.append('asc', asc);
  }
  if (offset) {
    params.append('limit', limit);
    params.append('offset', offset);
  }

  return params.toString() ? `?${params.toString()}` : '';
};

export const changeResource = async (
  contextRoute: any,
  fileStore: any,
  pageUrl: string
) => {
  fileStore.reload = false;
  fileStore.selected = [];
  fileStore.multiple = false;

  // Set loading to true and reset the error.
  let url = contextRoute.path;
  url = removePrefixUrl(url);
  try {
    const res = await api.fetch(url, pageUrl);
    if (
      cleanSuffixUrl(res.path) !==
      cleanSuffixUrl(`/${[...contextRoute.params.path].join('/')}`)
    ) {
      throw new Error('Data Mismatch!');
    }
    fileStore.updateRequest(res);
  } catch (err) {
    if (err instanceof Error) {
      console.error(String(err));
    }
  }
};
