<script lang="ts" setup>
import { useDeviceStore } from "@/stores/deviceStore";
import { useLayoutStore } from "@/stores/layout";
import { PhX } from "@phosphor-icons/vue";
import { computed, onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";

const emit = defineEmits(["update:width"]);
const { t } = useI18n();
const props = withDefaults(
  defineProps<{
    saveKeyName?: string;
    width?: number | string;
    leftMinWidth?: number;
    leftMaxWidth?: number | string;
    className?: string;
    isPossLeftHide?: boolean;
  }>(),
  {
    saveKeyName: "left-with",
    width: "full",
    leftMinWidth: 300,
    leftMaxWidth: 500,
    className: "",
    isPossLeftHide: true,
  }
);

const deviceStore = useDeviceStore();
const layoutStore = useLayoutStore();

const SAVE_KEY = props.saveKeyName;
const MIN_WIDTH = props.leftMinWidth;
const sidebarWidth = ref(props.width); // 사이드바의 초기 너비

// const isSidebarOpen = ref(true); // 사이드바 상태
const maxWidth = ref(props.leftMaxWidth);

const toggleSidebar = () => {
  if (props.isPossLeftHide) layoutStore.toggleSidebar();
};

const startResize = event => {
  event.preventDefault(); // 기본 동작 방지 (텍스트 선택 등)
  if (typeof sidebarWidth.value !== "number") return;

  const startX =
    event.clientX || (event.touches && event.touches[0].clientX) || 0; // 터치 이벤트 지원
  const startWidth = Number(sidebarWidth.value);

  const doResize = moveEvent => {
    document.body.style.cursor = "ew-resize";
    const clientX =
      moveEvent.clientX ||
      (moveEvent.touches && moveEvent.touches[0].clientX) ||
      0; // 터치 이벤트 지원
    const newWidth = startWidth + clientX - startX;
    // 최소 너비를 px, 최대 너비를 px로 설정
    if (newWidth < MIN_WIDTH) {
      sidebarWidth.value = MIN_WIDTH;
      if (newWidth > 0 && newWidth < MIN_WIDTH / 5) {
        if (props.isPossLeftHide) layoutStore.showSidebar = false;
      }
    } else if (newWidth > Number(maxWidth.value)) {
      sidebarWidth.value = Number(maxWidth.value);
    } else {
      sidebarWidth.value = newWidth;
    }
    localStorage.setItem(SAVE_KEY, "" + sidebarWidth.value);
    emit("update:width", sidebarWidth.value);
    moveEvent.preventDefault();
  };

  const stopResize = () => {
    window.removeEventListener("mousemove", doResize);
    window.removeEventListener("mouseup", stopResize);

    // 마우스 이벤트를 복원
    document.body.style.pointerEvents = "auto";
    document.body.removeAttribute("style");
  };

  window.addEventListener("mousemove", doResize, { passive: false });
  window.addEventListener("mouseup", stopResize);

  // 마우스 이벤트를 막음
  document.body.style.pointerEvents = "none";
};

onMounted(() => {
  sidebarWidth.value =
    Number(localStorage.getItem(SAVE_KEY)) || props.width || MIN_WIDTH;
  emit("update:width", sidebarWidth.value);
  if (deviceStore.isMobile) {
    layoutStore.showSidebar = false;
  }
});
watch(
  () => props.width,
  newWidth => {
    sidebarWidth.value = newWidth;
  }
);

const widthString = computed(() => {
  if (props.width === "full") {
    return "100%";
  } else if (typeof props.width === "string") {
    return props.width;
  } else {
    return Math.round(Number(sidebarWidth.value)) + "px";
  }
});
</script>
<template>
  <main>
    <div class="app-container">
      <div
        v-show="layoutStore.showSidebar"
        class="sidebar-resizer"
        :style="{ width: widthString }"
      >
        <div class="sidebar-content">
          <button
            type="button"
            class="btn-close absolute right-4 top-4"
            aria-label="Close"
            :title="t('buttons.close')"
            @click="toggleSidebar"
          >
            <PhX
              size="26"
              class="fill-opacity-70 hover:bg-gray-500/10 hover:fill-gray-500 rounded"
            />
          </button>
          <slot name="aside"></slot>
        </div>
        <div
          v-if="widthString.includes('px')"
          class="resize-handle-wrap"
          @mousedown="startResize"
        >
          <div class="resize-handle"></div>
        </div>
      </div>

      <div class="main-content scroll-s-project">
        <div
          v-if="isPossLeftHide"
          class="fold-icon"
          style="top: calc(50% - 24px)"
          @click="toggleSidebar"
        >
          <svg
            class="svg-toggle text-gray-600 dark:text-gray-200"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <path
              fill="currentColor"
              d="M12 3 L12 21"
              stroke="currentColor"
              stroke-width="2"
            />
          </svg>
          <svg
            :class="['svg-toggle-arrow', { opened: layoutStore.showSidebar }]"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <path
              fill="currentColor"
              fill-rule="evenodd"
              d="M9.399 4.328a.75.75 0 0 1 1.06 0l6.364 6.363a1.75 1.75 0 0 1 0 2.475L10.46 19.53a.75.75 0 0 1-1.06-1.06l6.364-6.364a.25.25 0 0 0 0-.354L9.399 5.388a.75.75 0 0 1 0-1.06"
              clip-rule="evenodd"
            />
          </svg>
        </div>
        <slot name="content"></slot>
      </div>
      <slot name="right"></slot>
    </div>
  </main>
</template>

<style lang="scss" scoped>
.app-container {
  display: flex;
  //height: var(--project-height)
  height: 100vh;
}

.sidebar-resizer {
  overflow: hidden;
  position: relative;
  flex-shrink: 0; //크기 고정
  border-right: 1px solid var(--divider);
  .btn-close {
    display: none;
  }
}

.resize-handle-wrap {
  height: 100%;
  padding: 0 3px;
  //background: var(--surfacePrimary);
  cursor: ew-resize;
  position: absolute;
  right: 0;
  top: 0;
  width: 2px;
  &:hover {
    > .resize-handle {
      //background-color: var(--divider);
      //right: 3px;
      @apply bg-gray-300 dark:bg-gray-500;
    }
  }
  > .resize-handle {
    position: absolute;
    right: 0;
    top: 0;
    width: 1px;
    height: 100%;
  }
}

.main-content {
  flex-grow: 1;
  height: 100vh;
  overflow: auto;
  .fold-icon {
    position: absolute;
    cursor: pointer;
    top: 50%;
    //margin-left: -22px;
    svg {
      opacity: 30%;
    }
    .svg-toggle-arrow {
      display: none;
    }
    &:hover {
      .svg-toggle {
        display: none;
      }
      .svg-toggle-arrow {
        display: block;
        &.opened {
          transform: rotate(180deg);
        }
      }
    }
  }
  & > .header {
    padding: 5px;
  }
}

@media (max-width: 768px) {
  .sidebar-resizer {
    overflow: hidden;
    position: absolute;
    height: 100%;
    z-index: 100;
    background: var(--background);
    box-shadow:
      1px 1px 0px rgba(0, 0, 0, 0.06),
      0px 1px 6px rgba(0, 0, 0, 0.08);
    .btn-close {
      display: block;
    }
  }
}
</style>
