import { encodePath } from "@/utils/url";
import { extractInfoFromPath, fetchJSON, fetchURL, StatusError } from "./utils";

const favoriteSet = new Set<string>();
// 모든 즐겨찾기 가져오기

// export async function getFavoriteSetAll() {
//   try {
//     const lists = await fetchJSON(`/api/favorites`, {});
//
//     // lists가 배열인지 확인하고 배열이 아닌 경우 빈 배열로 초기화
//     if (!Array.isArray(lists)) {
//       // console.error('Invalid response format:', lists);
//       return new Set<string>();
//     }
//     favoriteSet = new Set(lists.map(fav => fav.path));
//     return favoriteSet;
//   } catch (error) {
//     console.error('Failed to fetch favorites:', error);
//     return new Set<string>();
//   }
// }

/**
 * 즐겨 찾기 목록
 */
export async function fetchFavorites(limit?: number, offset?: number) {
  let addUrl = "";
  if (limit && offset !== null) {
    addUrl = `?limit=${limit}&offset=${offset}`;
  }
  try {
    const res = await fetchJSON(`/api/favorites${addUrl}`, {});
    // lists가 배열인지 확인하고 배열이 아닌 경우 빈 배열로 초기화
    // if (!Array.isArray(lists)) {
    //   // console.error('Invalid response format:', lists);
    //   return [];
    // }
    if (!Array.isArray(res["data"]) || !res["pageInfo"]) {
      return { data: [], listInfo: {} };
    }

    return res;
  } catch (error) {
    console.error("Failed to fetch favorites:", error);
    return [];
  }
}

// export function getFavoriteSet(): Set<string> {
//   return favoriteSet;
// }

// export async function isFavorites(path: string) {
//   const fav = favoriteMap.get(item.path.toLowerCase());
//   if (fav) return true;
//   return false;
// }

// Simplified 'add' function
export async function addFavorite(path: string, isDir: boolean) {
  if (!path) {
    throw new Error("Path is required");
  }

  const { name } = extractInfoFromPath(path);

  // Prepare the request body
  const body = {
    path,
    isDir,
    name,
    // 'type' and 'hash' can be omitted if not required
  };

  const res = await fetchURL(`/api/favorites`, {
    method: "POST",
    body: JSON.stringify(body),
  });

  if (res.status === 200 || res.status === 201) {
    return res.headers.get("Location");
  }

  throw new StatusError(await res.text(), res.status);
}
// 즐겨찾기 삭제
export async function removeFavorite(path: string) {
  const encodedPath = encodePath(path);
  await fetchURL(`/api/favorites${encodedPath}`, {
    method: "DELETE",
  });
}
