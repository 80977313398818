<template>
  <div>
    <FileComponentErrors v-if="statusError" :error-code="statusError.status">
      <!--      <template #desciption>-->
      <!--        {{ errorMessage }}-->
      <!--      </template>-->
    </FileComponentErrors>
    <FileComponentErrors v-else-if="scriptError" :error-code="0">
      <template #title>
        <div class="inline-flex items-center space-x-3">
          <PhWarningCircle :size="46" weight="light" class="mt-1" />
          <span>Script Error</span>
        </div>
      </template>
      <template #desciption>
        <PhBug :size="20" weight="thin" />
        <span>{{ errorMessage }}</span>
      </template>
    </FileComponentErrors>
  </div>
</template>

<script setup lang="ts">
import { StatusError } from "@/api/utils";
import { scriptError, statusError } from "@/utils/errorHandler";
import FileComponentErrors from "@/views/error/FileComponentErrors.vue";
import { PhBug, PhWarningCircle } from "@phosphor-icons/vue";
import { computed } from "vue";

// Props
const props = defineProps({
  statusError: Object as () => StatusError | null,
  scriptError: Object as () => Error | null,
});

// 에러 메시지 계산
const errorMessage = computed(() => {
  return props.scriptError?.message || "An unexpected error occurred.";
});
</script>
