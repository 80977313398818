<template>
  <FilesViewErrorGroup
    v-if="statusError || scriptError"
    :status-error="statusError"
    :script-error="scriptError"
  />
  <div v-else-if="!layoutStore.loading">
    <div class="file-table-resources padding">
      <div class="title-wrap">
        <PhStar :size="28" weight="thin" />
        <h2>
          {{ t("sidebar.favFolder") }}
        </h2>
        <div
          v-if="pageInfo?.total && pageInfo?.total > 0"
          class="flex-1 text-right pr-3 font-sans text-xs"
        >
          Total <span class="font-extrabold">{{ pageInfo.total }}</span>
        </div>
      </div>

      <div v-if="favoriteItems.length > 0">
        <table class="file-table-lists">
          <thead>
            <tr>
              <th></th>
              <th>{{ t("files.name") }}</th>
              <th class="w-3/12">{{ t("settings.path") }}</th>
              <th class="w-10 text-center">{{ t("message.actionRemove") }}</th>
            </tr>
          </thead>

          <tbody class="file-display-section last">
            <tr v-for="item in favoriteItems" :key="item.path">
              <td
                class="icon"
                :data-ext="item.extension"
                :data-dir="item.isDir"
                :data-type="item.type"
              >
                <i
                  :class="{ 'material-icons': true, 'folder filled': true }"
                ></i>
              </td>
              <td class="filename">
                <router-link :to="item.path">{{ item.name }}</router-link>
              </td>
              <td>
                <LocalBreadcrumbs base="" :path="item.path"></LocalBreadcrumbs>
              </td>
              <td>
                <AiaButton
                  type="info"
                  plain
                  @click="handleRemoveFavorite(item)"
                >
                  <PhEraser :size="20" weight="thin" />
                </AiaButton>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <h2 v-else class="message">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="64"
          height="64"
          viewBox="0 0 2048 2048"
        >
          <path
            fill="currentColor"
            d="M1664 896q79 0 149 30t122 82t83 123t30 149q0 80-30 149t-82 122t-123 83t-149 30q-60 0-117-18t-105-53l-437 436q-19 19-45 19t-45-19t-19-45t19-45l436-437q-35-48-53-105t-18-117q0-79 30-149t82-122t122-83t150-30m0 640q53 0 99-20t82-55t55-81t20-100q0-53-20-99t-55-82t-81-55t-100-20q-53 0-99 20t-82 55t-55 81t-20 100q0 53 20 99t55 82t81 55t100 20m-1280 64q0 15 8 27t19 23q29 29 73 51t95 37t100 26t89 16v128h-8q-6 0-8-1q-39-5-90-15t-107-27t-108-40t-95-56t-68-74t-26-95V448q0-48 22-86t58-70t83-54t96-41t95-29t84-18q66-11 132-16t132-6q67 0 133 5t132 18q36 6 83 17t95 29t96 40t83 55t59 69t23 87v320h-128V637q-58 37-130 62t-148 40t-153 22t-145 7t-144-6t-153-22t-149-41t-130-62zM960 256q-57 0-130 6t-148 20t-143 40t-115 63q-14 11-27 27t-13 36q0 19 13 35t27 28q46 38 114 63t143 39t148 21t131 6q57 0 130-6t148-20t143-40t114-63q14-11 27-27t14-36q0-19-13-35t-28-28q-46-38-114-63t-142-39t-148-21t-131-6"
          />
        </svg>
        <span>{{ t("files.lonely") }}</span>
      </h2>
    </div>
    <div class="pt-5">
      <FavoritePagination
        :key="paginationKey"
        :page-info="pageInfo"
        @on-page="goPage"
      />
    </div>
    <div class="h-20"></div>
  </div>
</template>

<script setup lang="ts">
import { fetchFavorites, removeFavorite } from "@/api/favorite";
import { StatusError } from "@/api/utils";
import { PageListInfo } from "@/class/interface/drive/metadata";
import AiaButton from "@/components/form/AiaButton.vue";
import { useLayoutStore } from "@/stores/layout";
import {
  componentHandleError,
  statusError,
  scriptError,
} from "@/utils/errorHandler";
import FavoritePagination from "@/views/drive/latest/FavoritePagination.vue";
import LocalBreadcrumbs from "@/views/drive/latest/LocalBreadcrumbs.vue";
import FilesViewErrorGroup from "@/views/error/FilesViewErrorGroup.vue";
import { PhEraser, PhStar } from "@phosphor-icons/vue";
import { onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import { toast } from "vue3-toastify";

const { t } = useI18n();

const layoutStore = useLayoutStore();
const route = useRoute();
const router = useRouter();

const paginationKey = ref("paginationKey");
const error = ref<StatusError | null>(null);
const favoriteItems = ref<Favorite[]>([]);
const pageInfo = ref<PageListInfo>({
  limit: 50,
  currentPage: 1,
  total: 0,
  offset: 0,
});
onMounted(async () => {
  layoutStore.loading = true;

  const query = route.query;

  const limit = parseInt(query.limit as string);
  const offset = parseInt(query.offset as string);
  fetchLists(limit, offset).then();
});

const fetchLists = async (limit?: number, offset?: number) => {
  try {
    const data = await fetchFavorites(limit, offset);
    const newFavorite = data["data"];
    pageInfo.value = data["pageInfo"] as PageListInfo;
    const files = [];
    for (const item of newFavorite) {
      // const { ext, name } = extractInfoFromPath(item.Path);
      const fileData: Favorite = {
        // hash: item.hash,
        path: item.path,
        token: item.token ?? "",
        // username: item.username ?? undefined,
        isDir: item.isDir,
        type: item.type,
        name: item.name,
        extension: item.extension,
      };
      files.push(fileData);
    }

    favoriteItems.value = files;
  } catch (err) {
    componentHandleError(err);
  } finally {
    layoutStore.loading = false;
  }
};

const handleRemoveFavorite = async item => {
  try {
    await removeFavorite(item.path);
    favoriteItems.value = favoriteItems.value.filter(c => c.path !== item.path);

    if (pageInfo.value?.total > 0) {
      pageInfo.value.total = pageInfo.value.total - 1;
    }

    // 해당 페이지에 값이 없으면 이전으로 이동
    if (pageInfo.value?.currentPage > 1) {
      const offset = pageInfo.value.offset - pageInfo.value.limit; // 이전 페이지 offset 계산
      console.log("c offset", offset, pageInfo);
      if (offset >= 0) {
        // offset이 음수가 되지 않도록 조건 추가
        await router.push({
          path: route.path,
          query: { limit: pageInfo.value.limit, offset: offset },
        });
        console.log("m offset", offset);
      }
    }

    if (pageInfo.value?.currentPage > 1) {
      const page = pageInfo.value?.currentPage - 1;
      const currentOffset = page * pageInfo.value?.limit; // 현재 페이지 기준 offset 계산
      const previousOffset = currentOffset - pageInfo.value?.limit; // 이전 페이지 offset 계산

      if (previousOffset >= 0) {
        await goPage(page, pageInfo.value?.limit, previousOffset);
        paginationKey.value = "k" + new Date().getTime();
      }
    }

    toast.success(
      t("message.favoriteActionSuccess", {
        action: t("message.actionRemove"),
      })
    );
    // item.isFav = false;
  } catch (e) {
    const errorMessage = e instanceof Error ? e.message : String(e);
    `${t("message.favoriteActionFail", { action: t("message.actionRemove") })} ${errorMessage}`;
  }
};

const goPage = async (page: number, limit: number, offset: number) => {
  if (!pageInfo.value)
    pageInfo.value = { currentPage: 1, limit: 100, offset: 0 } as PageListInfo;
  pageInfo.value.currentPage = page;
  pageInfo.value.limit = limit;
  pageInfo.value.offset = offset;

  const pageUrl = `?limit=${limit}&offset=${offset}`;
  // URL 이동
  if (router.currentRoute.value.fullPath !== route.path + pageUrl) {
    await router.push({
      path: route.path,
      query: { limit: limit, offset: offset },
    });
  }

  // 데이터 가져오기
  await fetchLists(limit, offset);
};
// const buildLink = (share: Share) => {};
</script>
