<script setup lang="ts">
import AiaDropdown from "@/components/dropdown/AiaDropdown.vue";
import { useFileStore } from "@/stores/file";
import { useLayoutStore } from "@/stores/layout";
import * as upload from "@/utils/upload";
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";

const { t } = useI18n();
const route = useRoute();
const fileStore = useFileStore();
const layoutStore = useLayoutStore();

const showMenu = ref(false);

const openUpload = (isFolder: boolean) => {
  hideMenu();
  const input = document.createElement("input");
  input.type = "file";
  input.multiple = true;
  input.webkitdirectory = isFolder;
  // TODO: call the function in FileListing.vue instead
  input.onchange = uploadInput;
  input.click();
};

const createFile = () => {
  layoutStore.showHover("newFile");
  hideMenu();
};

const createFolder = () => {
  layoutStore.showHover("newDir");
  hideMenu();
};

const uploadFile = () => {
  openUpload(false);
};
const uploadFolder = () => {
  openUpload(true);
};

const uploadInput = (event: Event) => {
  layoutStore.closeHovers();

  const files = (event.currentTarget as HTMLInputElement)?.files;
  if (files === null) return;

  const folder_upload = !!files[0].webkitRelativePath;

  const uploadFiles: UploadList = [];
  for (let i = 0; i < files.length; i++) {
    const file = files[i];
    const fullPath = folder_upload ? file.webkitRelativePath : undefined;
    uploadFiles.push({
      file,
      name: file.name,
      size: file.size,
      isDir: false,
      fullPath,
    });
  }

  const path = route.path.endsWith("/") ? route.path : route.path + "/";
  const conflict = upload.checkConflict(uploadFiles, fileStore.req!.items);

  // TODO 수정 요망
  if (conflict) {
    layoutStore.showHover({
      prompt: "replace",
      action: (event: Event) => {
        event.preventDefault();
        layoutStore.closeHovers();
        upload.handleFiles(uploadFiles, path, false);
      },
      confirm: (event: Event) => {
        event.preventDefault();
        layoutStore.closeHovers();
        upload.handleFiles(uploadFiles, path, true);
      },
    });

    return;
  }

  upload.handleFiles(uploadFiles, path);
};
const hideMenu = () => {
  setTimeout(() => {
    showMenu.value = false;
  }, 300);
};
</script>

<template>
  <AiaDropdown
    v-model:show-menu="showMenu"
    class-name="min-w-24"
    @select="hideMenu"
  >
    <button
      type="button"
      class="border rounded border-dashed bg-blue-400 text-white dark:bg-gray-700 py-1 space-x-2 text-sm px-3 text-center inline-flex items-center"
    >
      <svg
        fill="currentColor"
        width="18"
        height="18"
        viewBox="0 0 24 24"
        focusable="false"
      >
        <path d="M20 13h-7v7h-2v-7H4v-2h7V4h2v7h7v2z"></path>
      </svg>
      <span>{{ t("sidebar.make") }}</span>
    </button>
    <template #items>
      <ul class="drop-menu-items w-full whitespace-nowrap">
        <li @click="createFolder">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20px"
            height="20px"
            viewBox="0 0 48 48"
          >
            <path
              fill="currentColor"
              d="M6.5 12.25V16h10.215c.331 0 .649-.132.883-.366l3.384-3.384l-3.384-3.384a1.25 1.25 0 0 0-.883-.366H10.25a3.75 3.75 0 0 0-3.75 3.75m-2.5 0A6.25 6.25 0 0 1 10.25 6h6.465a3.75 3.75 0 0 1 2.651 1.098L23.268 11H37.75A6.25 6.25 0 0 1 44 17.25v7.37a13 13 0 0 0-2.5-1.88v-5.49a3.75 3.75 0 0 0-3.75-3.75H23.268l-3.902 3.902a3.75 3.75 0 0 1-2.651 1.098H6.5v16.25a3.75 3.75 0 0 0 3.75 3.75H22.8c.325.882.744 1.72 1.244 2.5H10.25A6.25 6.25 0 0 1 4 34.75zM46 34c0 6.075-4.925 11-11 11s-11-4.925-11-11s4.925-11 11-11s11 4.925 11 11m-10-7a1 1 0 1 0-2 0v6h-6a1 1 0 1 0 0 2h6v6a1 1 0 1 0 2 0v-6h6a1 1 0 1 0 0-2h-6z"
            />
          </svg>
          <span>{{ t("sidebar.newFolder") }}</span>
        </li>
        <li class="line" @click="createFile">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20px"
            height="20px"
            viewBox="0 0 28 28"
          >
            <path
              fill="currentColor"
              d="M22.45 23.6a.9.9 0 0 1-.9.9h-7.704A7.5 7.5 0 0 1 12.6 26h8.95a2.4 2.4 0 0 0 2.4-2.4V11.395a2.4 2.4 0 0 0-.703-1.697l-6.994-6.994c-.052-.052-.11-.096-.167-.14l-.056-.043a2.4 2.4 0 0 0-1.004-.474A2.4 2.4 0 0 0 14.555 2H6.4A2.4 2.4 0 0 0 4 4.401v9.465a7.5 7.5 0 0 1 1.5-.596V4.401c0-.497.404-.901.9-.901h7.55V10a2 2 0 0 0 2 2l6.5-.001zm-7-19.577l6.478 6.476l-5.978.001a.5.5 0 0 1-.5-.5zM14 20.5a6.5 6.5 0 1 1-13 0a6.5 6.5 0 0 1 13 0m-6-4a.5.5 0 0 0-1 0V20H3.5a.5.5 0 0 0 0 1H7v3.5a.5.5 0 1 0 1 0V21h3.5a.5.5 0 1 0 0-1H8z"
            />
          </svg>
          <span>{{ t("sidebar.newFile") }}</span>
        </li>
        <li @click="uploadFile">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20px"
            height="20px"
            viewBox="0 0 20 20"
          >
            <path
              fill="currentColor"
              d="M6 2a2 2 0 0 0-2 2v5.207a5.5 5.5 0 0 1 1-.185V4a1 1 0 0 1 1-1h4v3.5A1.5 1.5 0 0 0 11.5 8H15v8a1 1 0 0 1-1 1h-3.6a5.5 5.5 0 0 1-.657 1H14a2 2 0 0 0 2-2V7.414a1.5 1.5 0 0 0-.44-1.06l-3.914-3.915A1.5 1.5 0 0 0 10.586 2zm8.793 5H11.5a.5.5 0 0 1-.5-.5V3.207zM5.5 19a4.5 4.5 0 1 0 0-9a4.5 4.5 0 0 0 0 9m2.354-4.853a.5.5 0 1 1-.708.707L6 13.707V16.5a.5.5 0 0 1-1 0v-2.793l-1.146 1.147a.5.5 0 1 1-.708-.707l2-2A.5.5 0 0 1 5.497 12h.006a.5.5 0 0 1 .348.144l.003.003z"
            />
          </svg>

          <span>{{ t("buttons.file") }} {{ t("buttons.upload") }}</span>
        </li>
        <li @click="uploadFolder">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20px"
            height="20px"
            viewBox="0 0 48 48"
          >
            <path
              fill="currentColor"
              d="M6.5 12.25V16h10.215c.331 0 .649-.132.883-.366l3.384-3.384l-3.384-3.384a1.25 1.25 0 0 0-.883-.366H10.25a3.75 3.75 0 0 0-3.75 3.75m-2.5 0A6.25 6.25 0 0 1 10.25 6h6.465a3.75 3.75 0 0 1 2.651 1.098L23.268 11H37.75A6.25 6.25 0 0 1 44 17.25v7.37a13 13 0 0 0-2.5-1.88v-5.49a3.75 3.75 0 0 0-3.75-3.75H23.268l-3.902 3.902a3.75 3.75 0 0 1-2.651 1.098H6.5v16.25a3.75 3.75 0 0 0 3.75 3.75H22.8c.325.882.744 1.72 1.244 2.5H10.25A6.25 6.25 0 0 1 4 34.75zM46 34c0 6.075-4.925 11-11 11s-11-4.925-11-11s4.925-11 11-11s11 4.925 11 11m-10.293-7.707a1 1 0 0 0-1.414 0l-5 5a1 1 0 0 0 1.414 1.414L34 29.414V41a1 1 0 1 0 2 0V29.414l3.293 3.293a1 1 0 0 0 1.414-1.414z"
            />
          </svg>
          <span>{{ t("buttons.folder") }} {{ t("buttons.upload") }} </span>
        </li>
      </ul>
    </template>
  </AiaDropdown>
</template>

<style scoped lang="scss"></style>
