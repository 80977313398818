<script setup lang="ts">
import { files as api } from "@/api";
import AiaPagnation from "@/components/basic/AiaPagnation.vue";
import { useAuthStore } from "@/stores/auth";
import { useClipboardStore } from "@/stores/clipboard";
import { useDeviceStore } from "@/stores/deviceStore";
import { useFileStore } from "@/stores/file";
import { useLayoutStore } from "@/stores/layout";
import css from "@/utils/css";
// import { enableExec } from '@/utils/constants';
import * as upload from "@/utils/upload";
import NoFilesAndUpload from "@/views/error/NoFilesAndUpload.vue";
import BreadcrumbsSection from "@/views/files/listing/BreadcrumbsSection.vue";
import FileListingLoading from "@/views/files/listing/FileListingLoading.vue";
import FileListsSection from "@/views/files/listing/FileListsSection.vue";
import FilePagination from "@/views/files/listing/FilePagination.vue";
import FileSelectActionBar from "@/views/files/menu/FileSelectActionBar.vue";
import throttle from "lodash/throttle";
import { storeToRefs } from "pinia";

import {
  computed,
  nextTick,
  onBeforeUnmount,
  onMounted,
  ref,
  watch,
} from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import { toast } from "vue3-toastify";

const listContainer = ref<HTMLElement | null>(null);
// const showLimit = ref<number>(100);
const columnWidth = ref<number>(240);
const dragCounter = ref<number>(0);
const width = ref<number>(window.innerWidth);
const itemWeight = ref<number>(0);

const clipboardStore = useClipboardStore();
const authStore = useAuthStore();
const fileStore = useFileStore();
const layoutStore = useLayoutStore();

const { req } = storeToRefs(fileStore);
const route = useRoute();
const { t } = useI18n();

const deviceStore = useDeviceStore();
watch(req, () => {
  // Reset the show value
  if (
    window.sessionStorage.getItem("listFrozen") !== "true" &&
    window.sessionStorage.getItem("modified") !== "true"
  ) {
    nextTick(() => {
      restoreScrollPosition();
    });
  }
  if (req.value?.isDir) {
    window.sessionStorage.setItem("listFrozen", "false");
    window.sessionStorage.setItem("modified", "false");
  }
});

onMounted(() => {
  // Check the columns size for the first time.
  if (!deviceStore.isMobile) {
    columnResize();

    window.addEventListener("keydown", keyEvent);
    window.addEventListener("resize", windowsResize);

    if (authStore.user?.perm.create) {
      document.addEventListener("dragover", preventDefault);
      document.addEventListener("dragenter", dragEnter);
      document.addEventListener("dragleave", dragLeave);
      document.addEventListener("drop", drop);
    }
  }
  window.addEventListener("scroll", scrollEvent);
  restoreScrollPosition();
  // fileStore.updateItems(items);
});

onBeforeUnmount(() => {
  if (!deviceStore.isMobile) {
    window.removeEventListener("keydown", keyEvent);
    window.removeEventListener("resize", windowsResize);

    if (authStore.user?.perm.create) {
      document.removeEventListener("dragover", preventDefault);
      document.removeEventListener("dragenter", dragEnter);
      document.removeEventListener("dragleave", dragLeave);
      document.removeEventListener("drop", drop);
    }
  }
  window.removeEventListener("scroll", scrollEvent);
});

const keyEvent = (event: KeyboardEvent) => {
  // No prompts are shown
  if (layoutStore.currentPrompt !== null) {
    return;
  }

  if (event.key === "Escape") {
    // Reset files selection.
    fileStore.selected = [];
  }

  if (event.key === "Delete") {
    if (!authStore.user?.perm.delete || fileStore.selectedCount == 0) return;

    // Show delete modals.
    layoutStore.showHover("delete");
  }

  if (event.key === "F2") {
    if (!authStore.user?.perm.rename || fileStore.selectedCount !== 1) return;

    // Show rename modals.
    layoutStore.showHover("rename");
  }

  if (event.key === "n") {
    event.preventDefault();
    layoutStore.showHover("newFile");
    return;
  }

  if (event.key === "f") {
    event.preventDefault();
    layoutStore.showHover("search");
    return;
  }

  // Ctrl is pressed
  if (!event.ctrlKey && !event.metaKey) {
    return;
  }

  switch (event.key) {
    case "f":
      event.preventDefault();
      layoutStore.showHover("search");
      break;
    case "c":
      copyCut(event, "c");
      break;
    case "x":
      copyCut(event, "x");
      break;
    case "v":
      paste(event);
      break;
    case "a":
      event.preventDefault();
      for (const file of fileStore.files) {
        if (fileStore.selected.indexOf(file.index) === -1) {
          fileStore.selected.push(file.index);
        }
      }
      for (const dir of fileStore.dirs) {
        if (fileStore.selected.indexOf(dir.index) === -1) {
          fileStore.selected.push(dir.index);
        }
      }
      break;
    case "s":
      event.preventDefault();
      document.getElementById("download-button")?.click();
      break;
  }
};
/**
 * 컨텍스트 메뉴
 */
const contextSelFixed = [
  {
    label: t("sidebar.newFolder"),
    value: "newFolder",
    icon: "PhFolderPlus",
    iconSize: 18,
  },
  {
    label: t("sidebar.newFile"),
    value: "newFile",
    icon: "PhFilePlus",
    shortcut: "N",
    line: true,
  },
];

const preventDefault = (event: Event) => {
  // Wrapper around prevent default.
  event.preventDefault();
};

const copyCut = (event: Event | KeyboardEvent, option: string): void => {
  if ((event.target as HTMLElement).tagName?.toLowerCase() === "input") return;

  if (fileStore.req === null) return;

  const items = [];

  for (const i of fileStore.selected) {
    items.push({
      from: fileStore.req.items[i].url,
      name: fileStore.req.items[i].name,
    });
  }

  if (items.length === 0) {
    return;
  }

  clipboardStore.$patch({
    key: (event as KeyboardEvent).key,
    items,
    path: route.path,
  });
  let msg = t("fileMessages.clipboardCopySuccess", { cnt: items.length });
  if (option === "x")
    msg = t("fileMessages.clipboardCutSuccess", { cnt: items.length });
  toast(msg, {
    position: "bottom-left",
    transition: "flip",
    autoClose: 4000,
  });
};

const paste = (event: Event) => {
  if ((event.target as HTMLElement).tagName?.toLowerCase() === "input") return;

  // TODO router location should it be
  const items = [];

  for (const item of clipboardStore.items) {
    const from = item.from.endsWith("/") ? item.from.slice(0, -1) : item.from;
    const toPath = route.path.endsWith("/") ? route.path : route.path + "/";
    if (route.path)
      items.push({
        from,
        to: toPath + encodeURIComponent(item.name),
        name: item.name,
      });
  }

  if (items.length === 0) {
    return;
  }

  let action = (overwrite: boolean, rename: boolean) => {
    api
      .copy(items, overwrite, rename)
      .then(() => {
        fileStore.reload = true;
        toast.success(t("fileMessages.copySuccess", { cnt: items.length }));
      })
      .catch(toast.error);
  };

  if (clipboardStore.key === "x") {
    action = (overwrite, rename) => {
      api
        .move(items, overwrite, rename)
        .then(() => {
          clipboardStore.resetClipboard();
          fileStore.reload = true;
          toast.success(t("fileMessages.moveSuccess", { cnt: items.length }));
        })
        .catch(toast.error);
    };
  }

  if (clipboardStore.path == route.path) {
    action(false, true);

    return;
  }

  const conflict = upload.checkConflict(items, fileStore.req!.items);

  let overwrite = false;
  let rename = false;

  if (conflict) {
    layoutStore.showHover({
      prompt: "replace-rename",
      action: (event: Event, option: string) => {
        overwrite = option == "overwrite";
        rename = option == "rename";
        // event.preventDefault();
        layoutStore.closeHovers();
        action(overwrite, rename);
      },
    });

    return;
  }

  action(overwrite, rename);
};

const columnResize = () => {
  // Update the columns size based on the window width.
  const items_ = css([".file-lists.mosaic .item", ".mosaic.file-lists .item"]);
  if (items_ === null) return;

  let columns = Math.floor(
    (document.querySelector("main")?.offsetWidth ?? 0) / columnWidth.value
  );
  if (columns === 0) columns = 1;
  const w = 100 / columns;
  items_.style.width = `calc(${w}% - 1em)`;
};

const scrollEvent = throttle(() => {
  const totalItems =
    (fileStore.req?.listInfo?.totalDirs ?? 0) +
    (fileStore.req?.listInfo?.totalFiles ?? 0);

  const currentPos = window.innerHeight + window.scrollY;
  // Trigger at the 75% of the window height
  const triggerPos = document.body.offsetHeight - window.innerHeight * 0.25;

  if (currentPos > triggerPos) {
    // Quantity of items needed to fill 2x of the window height
    const showQuantity = Math.ceil((window.innerHeight * 2) / itemWeight.value);

    // offset을 limit에 맞춰 증가

    // console.log('offset.value:',offset.value ,'showLimit.value:')
  }
}, 100);

const dragEnter = () => {
  dragCounter.value++;

  // When the user starts dragging an item, put every
  // file on the listing with 50% opacity.
  const items = document.getElementsByClassName("item");

  Array.from(items).forEach(item => {
    // Type assertion to HTMLElement
    const file = item as HTMLElement;
    file.style.opacity = "0.5";
  });
};

const dragLeave = () => {
  dragCounter.value--;

  if (dragCounter.value == 0) {
    resetOpacity();
  }
};

const drop = async (event: DragEvent) => {
  event.preventDefault();
  dragCounter.value = 0;
  resetOpacity();

  const dt = event.dataTransfer;
  let el: HTMLElement | null = event.target as HTMLElement;

  if (fileStore.req === null || dt === null || dt.files.length <= 0) return;

  for (let i = 0; i < 5; i++) {
    if (el !== null && !el.classList.contains("item")) {
      el = el.parentElement;
    }
  }

  const files: UploadList = (await upload.scanFiles(dt)) as UploadList;
  let items = fileStore.req.items;
  let path = route.path.endsWith("/") ? route.path : route.path + "/";

  if (
    el !== null &&
    el.classList.contains("item") &&
    el.dataset.dir === "true"
  ) {
    // Get url from FileListingItem instance
    // TODO: Don't know what is happening here
    path = el.__vue__.url;

    try {
      items = (await api.fetch(path)).items;
    } catch (error) {
      toast.error(String(error));
    }
  }

  const conflict = upload.checkConflict(files, items);

  if (conflict) {
    layoutStore.showHover({
      prompt: "replace",
      action: (event: Event) => {
        event.preventDefault();
        layoutStore.closeHovers();
        upload.handleFiles(files, path, false);
      },
      confirm: (event: Event) => {
        event.preventDefault();
        layoutStore.closeHovers();
        upload.handleFiles(files, path, true);
      },
    });

    return;
  }

  upload.handleFiles(files, path);
};

const uploadInput = (event: Event) => {
  layoutStore.closeHovers();

  const files = (event.currentTarget as HTMLInputElement)?.files;
  if (files === null) return;

  const folder_upload = !!files[0].webkitRelativePath;

  const uploadFiles: UploadList = [];
  for (let i = 0; i < files.length; i++) {
    const file = files[i];
    const fullPath = folder_upload ? file.webkitRelativePath : undefined;
    uploadFiles.push({
      file,
      name: file.name,
      size: file.size,
      isDir: false,
      fullPath,
    });
  }

  const path = route.path.endsWith("/") ? route.path : route.path + "/";
  const conflict = upload.checkConflict(uploadFiles, fileStore.req!.items);

  if (conflict) {
    layoutStore.showHover({
      prompt: "replace",
      action: (event: Event) => {
        event.preventDefault();
        layoutStore.closeHovers();
        upload.handleFiles(uploadFiles, path, false);
      },
      confirm: (event: Event) => {
        event.preventDefault();
        layoutStore.closeHovers();
        upload.handleFiles(uploadFiles, path, true);
      },
    });

    return;
  }

  upload.handleFiles(uploadFiles, path);
};

const resetOpacity = () => {
  const items = document.getElementsByClassName("item");

  Array.from(items).forEach((file: Element) => {
    (file as HTMLElement).style.opacity = "1";
  });
};

const onActionBar = funcName => {
  console.log("funcName", funcName);
};

const windowsResize = throttle(() => {
  columnResize();
  width.value = window.innerWidth;

  // Listing element is not displayed
  if (listContainer.value == null) return;
}, 100);

/**
 * 스크롤 저장
 */
const saveScrollPosition = () => {
  if (
    listContainer.value &&
    layoutStore.getScrollPosition !== listContainer.value.scrollTop
  ) {
    layoutStore.saveScrollPosition(listContainer.value.scrollTop);
    // console.log('saveScrollPosition', listContainer.value.scrollTop);
  }
};

const restoreScrollPosition = () => {
  nextTick(() => {
    if (listContainer.value) {
      listContainer.value.scrollTop = layoutStore.getScrollPosition;
      // console.log('restoreScrollPosition', layoutStore.getScrollPosition);
    }
  });
  // setTimeout(() => {
  // }, 1000);
};

const isEmpty = computed(() => {
  return (
    (fileStore.req?.listInfo?.totalDirs ?? 0) +
      (fileStore.req?.listInfo?.totalFiles ?? 0) ===
    0
  );
});
</script>
<template>
  <div class="file-resources">
    <div class="pr-5">
      <BreadcrumbsSection @action="onActionBar" />
      <div class="min-h-12 pr-1">
        <FileSelectActionBar :width="width" @action="onActionBar" />
      </div>
    </div>

    <template v-if="!layoutStore.loading">
      <NoFilesAndUpload v-if="isEmpty" />
      <div
        v-else
        ref="listContainer"
        class="list-scrolling scroll-s-chat"
        @scrollend="saveScrollPosition"
      >
        <FileListsSection />
        <FilePagination />
      </div>
    </template>

    <div v-else>
      <FileListingLoading />
    </div>
    <input
      style="display: none"
      type="file"
      multiple
      @change="uploadInput($event)"
    />
    <input
      style="display: none"
      type="file"
      webkitdirectory
      multiple
      @change="uploadInput($event)"
    />
  </div>
</template>
