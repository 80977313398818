export const PermissionDef = [
  { key: 'create', label: 'settings.perm.create' },
  { key: 'delete', label: 'settings.perm.delete' },
  { key: 'view', label: 'settings.perm.view' },
  { key: 'rename', label: 'settings.perm.rename' },
  { key: 'modify', label: 'settings.perm.modify' },
  { key: 'download', label: 'settings.perm.download' },
  { key: 'share', label: 'settings.perm.share' },
  { key: 'execute', label: 'settings.perm.execute' },
];
