<script setup lang="ts">
import { files as api } from "@/api";
import AiaDropdown from "@/components/dropdown/AiaDropdown.vue";
import { useActionMenuPerm } from "@/composable/useActionMenuPerm";
import { useDeviceStore } from "@/stores/deviceStore";
import { useFileStore } from "@/stores/file";
import { useLayoutStore } from "@/stores/layout";
import FileSearchBtn from "@/views/files/menu/FileSearchBtn.vue";
import FileSelectActionBarItem from "@/views/files/menu/FileSelectActionBarItem.vue";
import FixedActionBar from "@/views/files/menu/FixedActionBar.vue";
import NewFileBtn from "@/views/files/menu/NewFileBtn.vue";
import NewFolderBtn from "@/views/files/menu/NewFolderBtn.vue";
import UploadCreateBtn from "@/views/files/menu/UploadCreateBtn.vue";
import {
  PhArrowLineDown,
  PhCopy,
  PhDotsThreeVertical,
  PhShareFat,
  PhTextbox,
  PhTrash,
  PhX,
} from "@phosphor-icons/vue";
import { computed, markRaw, nextTick, ref, VNodeRef, watch } from "vue";
import { useRoute } from "vue-router";

const props = defineProps({
  width: {
    type: Number,
    required: true,
  },
});
const fileStore = useFileStore();
const layoutStore = useLayoutStore();
const route = useRoute();
const deviceStore = useDeviceStore();
const actionMenuPerm = useActionMenuPerm();

const downloadAction = () => {
  if (fileStore.req === null) return;

  if (
    fileStore.selectedCount === 1 &&
    !fileStore.req?.items[fileStore.selected[0]].isDir
  ) {
    api.download(null, fileStore.req?.items[fileStore.selected[0]].url);
    return;
  }

  layoutStore.showHover({
    prompt: "download",
    confirm: format => {
      layoutStore.closeHovers();

      const files = [];

      if (fileStore.selectedCount > 0 && fileStore.req !== null) {
        for (const i of fileStore.selected) {
          files.push(fileStore.req?.items[i].url);
        }
      } else {
        files.push(route.path);
      }

      api.download(format, ...files);
    },
  });
};

const refActionBar = ref<HTMLDivElement | VNodeRef>(null); // 타입 지정
const barWidth = ref<number>(props.width); // 275 차이
const checkComponentWidth = () => {
  if (refActionBar.value) {
    nextTick(() => {
      barWidth.value = refActionBar.value.offsetWidth; //props.width - 275;
    });
  }
};
watch(
  () => props.width,
  newValue => {
    if (newValue) checkComponentWidth();
  }
);
watch(
  () => layoutStore.showFileInfo,
  is => {
    console.log(is);
    if (is) checkComponentWidth();
    else {
      setTimeout(() => {
        checkComponentWidth();
      }, 200);
    }
  }
);

const showActionBarLabel = computed(() => {
  //barWidth.value > 570 &&
  return barWidth.value > 300 && !deviceStore.isMobile;
});

const checkLessWith = (width: number) => {
  return barWidth.value < width;
};

const showButtons = actionMenuPerm.showButtons;

const actionButtons = ref([
  {
    key: "rename",
    iconSize: 19,
    label: "buttons.rename",
    showLabel: showActionBarLabel,
    hideMenu: () => checkLessWith(300),
    component: markRaw(PhTextbox),
  },
  {
    key: "copy",
    iconSize: 18,
    label: "prompts.copyToFolder",
    showLabel: showActionBarLabel,
    hideMenu: () => checkLessWith(400),
    component: markRaw(PhCopy),
  },
  {
    key: "move",
    iconSize: 17,
    label: "buttons.move",
    showLabel: showActionBarLabel,
    hideMenu: () => checkLessWith(480),
    component: markRaw(PhShareFat),
  },
  {
    key: "delete",
    iconSize: 16,
    label: "buttons.delete",
    showLabel: showActionBarLabel,
    hideMenu: () => checkLessWith(550),
    component: markRaw(PhTrash),
  },
  {
    key: "downloadAc",
    iconSize: 17,
    label: "buttons.download",
    showLabel: showActionBarLabel,
    hideMenu: () => checkLessWith(620),
    onClick: () => downloadAction(),
    component: markRaw(PhArrowLineDown),
  },
]);

const iconSize = 20;
</script>

<template>
  <div
    ref="refActionBar"
    :class="['file-action-bar-wrap', { selected: fileStore.selectedCount > 0 }]"
  >
    <div>
      <div
        v-if="!deviceStore.isMobile"
        class="space-x-1 flex items-center relative"
      >
        <NewFolderBtn v-if="showButtons.notSelected" />
        <NewFileBtn v-if="showButtons.notSelected" />
      </div>
      <div v-else>
        <UploadCreateBtn v-if="showButtons.notSelected" />
      </div>
      <div v-if="fileStore.selectedCount > 0" class="flex items-center">
        <button
          class="inline-block action border border-neutral-50 hover:border-neutral-200 dark:border-neutral-600"
          @click="fileStore.selected = []"
        >
          <i18n-t keypath="files.selected" tag="span" scope="global">
            <code>{{ fileStore.selectedCount }}</code>
          </i18n-t>
          <ph-x :size="20" weight="thin" class="inline-block" />
        </button>

        <template v-for="item in actionButtons">
          <FileSelectActionBarItem
            class="select-ac-btn"
            v-show="!item.hideMenu()"
            :action="item.key"
            :label="item.label"
            :on-click="item['onClick']"
            :show-label="item.showLabel"
            :icon-component="item.component"
            :icon-size="item.iconSize"
          />
        </template>

        <AiaDropdown
          v-if="checkLessWith(620)"
          class="action-more inline-block whitespace-nowrap"
        >
          <ph-dots-three-vertical :size="iconSize" weight="bold" />
          <template #items>
            <ul class="drop-menu-items">
              <template v-for="item in actionButtons">
                <li v-if="item.hideMenu()">
                  <FileSelectActionBarItem
                    class="w-full text-left space-x-2"
                    :action="item.key"
                    :label="item.label"
                    :on-click="item['onClick']"
                    :show-label="true"
                    :icon-component="item.component"
                    :icon-size="item.iconSize"
                  />
                </li>
              </template>
            </ul>

            <!--            <ul class="drop-menu-items w-full p-0">-->
            <!--              <li-->
            <!--                v-if="showButtons.rename"-->
            <!--                class="p-1 space-x-2"-->
            <!--                @click="layoutStore.showHover('rename')"-->
            <!--              >-->
            <!--                <PhTextbox :size="iconSize" weight="thin" />-->
            <!--                <span>{{ t("buttons.rename") }}</span>-->
            <!--              </li>-->
            <!--              <li-->
            <!--                v-if="deviceStore.isMobile && showButtons.move"-->
            <!--                class="p-1 space-x-2"-->
            <!--                @click="layoutStore.showHover('move')"-->
            <!--              >-->
            <!--                <PhShareFat :size="iconSize" weight="thin" />-->
            <!--                <span>{{ t("buttons.share") }}</span>-->
            <!--              </li>-->
            <!--              <li-->
            <!--                v-if="showButtons.download"-->
            <!--                class="p-1 space-x-2"-->
            <!--                @click="downloadAction()"-->
            <!--              >-->
            <!--                <ph-arrow-line-down :size="iconSize" weight="thin" />-->
            <!--                <span>{{ t("buttons.download") }}</span>-->
            <!--              </li>-->
            <!--              <li-->
            <!--                v-if="showButtons.share"-->
            <!--                class="p-1 space-x-2"-->
            <!--                @click="layoutStore.showHover('share')"-->
            <!--              >-->
            <!--                <ph-hand-arrow-up :size="iconSize" weight="thin" />-->
            <!--                <span>{{ t("buttons.share") }}</span>-->
            <!--              </li>-->
            <!--            </ul>-->
          </template>
        </AiaDropdown>
      </div>
    </div>
    <div>
      <!-- fixed -->
      <div
        v-if="!deviceStore.isMobile && fileStore.selectedCount === 0"
        class="flex items-center"
      >
        <FileSearchBtn />
        <!--        <FileSearchSection />-->
      </div>
      <div v-if="deviceStore.isMobile && showButtons.upload" class="z-10">
        <FixedActionBar />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.file-action-bar-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  justify-items: center;
  padding: 5px 5px;
  white-space: nowrap;
  &.selected {
    @apply rounded-md shadow-md bg-white dark:bg-dark-surface-sec dark:shadow dark:shadow-neutral-600;
  }

  & button.action,
  & .select-ac-btn,
  div.action-more {
    @apply cursor-pointer space-x-2 inline-flex  hover:bg-neutral-100 dark:hover:bg-black rounded-md py-1 px-2 mx-1;
    svg {
      display: inline-block;
    }
  }
}
</style>
