<script setup lang="ts">
import { files as api } from "@/api";
import { cleanSuffixUrl, removePrefixUrl, StatusError } from "@/api/utils";
import AiaButton from "@/components/form/AiaButton.vue";
import { router } from "@/routes";
import { useFileStore } from "@/stores/file";
import { ViewerConst } from "@/views/viewer/ViewerConstance";
import { PhSpinner, PhX } from "@phosphor-icons/vue";
import { defineAsyncComponent, h, onMounted, ref, shallowRef } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import { toast } from "vue3-toastify";

const { t } = useI18n();
const fileStore = useFileStore();
const route = useRoute();

const SchemaEditViewerMain = defineAsyncComponent(
  () => import("@/views/viewer/schema/SchemaEditViewerMain.vue")
);

const JsonEditorViewerMain = defineAsyncComponent(
  () => import("@/views/viewer/json/JsonEditorViewerMain.vue")
);
const BasicEditor = defineAsyncComponent(
  () => import("@/views/viewer/basic/BasicEditorViewerMain.vue")
);
const MarkdownEditor = defineAsyncComponent(
  () => import("@/views/viewer/markdown/MarkdownEditorMain.vue")
);

const MediaPreview = defineAsyncComponent(
  () => import("@/views/viewer/media/MediaPreview.vue")
);

const LevelAccessErrors = defineAsyncComponent(
  () => import("@/views/error/LevelAccessErrors.vue")
);
const getComponent = () => {
  if (fileStore.req?.type === undefined) {
    return null;
  }

  if (fileStore.req.type === "text" || fileStore.req.type === "textImmutable") {
    if (fileStore.req.extension === ".md") {
      return MarkdownEditor;
    } else {
      return BasicEditor;
    }
  } else if (
    fileStore.req.type === "json" ||
    fileStore.req.type === "jsonImmutable"
  ) {
    return JsonEditorViewerMain;
  } else if (fileStore.req.type === "aia-json") {
    return SchemaEditViewerMain;
  } else {
    return MediaPreview;
  }
};

// 컴포넌트 준비 완료 시 호출될 메서드
const loading = ref(true);

onMounted(() => {
  resourceFetchData();
});
const onComponentReady = () => {
  loading.value = false;
};
const currentComponent = shallowRef(null);
window.addEventListener("keydown", event => {
  if (event.key === "Escape") {
    event.stopImmediatePropagation();
    close();
  }
});
const close = () => {
  router.go(-1);
};

const resourceFetchData = async () => {
  // Reset view information.
  fileStore.reload = false;
  fileStore.selected = [];
  fileStore.multiple = false;

  // Set loading to true and reset the error.
  // layoutStore.loading = true;
  let url = route.path;
  url = removePrefixUrl(url);
  let res;
  try {
    res = await api.fetch(url);

    if (
      cleanSuffixUrl(res.path) !==
      cleanSuffixUrl(`/${[...route.params.path].join("/")}`)
    ) {
      throw new Error("Data Mismatch!");
    }
    fileStore.updateRequest(res);
    if (res.path !== "/") document.title = `${res.name} - ${document.title}`; // `${res.name}`;

    // 데이터가 로드된 후에 컴포넌트를 설정
    currentComponent.value = getComponent();
  } catch (err) {
    if (err instanceof StatusError) {
      // error.value = err;
      if (err.jsonError) {
        /**
         * currentComponent.value = h(
         *       component,
         *       layoutStore.prompts[layoutStore.prompts.length - 1]?.props || {}
         *     );
         */
        //jsonCode
        currentComponent.value = h(LevelAccessErrors, {
          jsonCode: err.jsonError,
        });
      } else {
        handleError();
      }
    } else {
      handleError();
      toast.error(String(err));
    }
  } finally {
    // layoutStore.loading = false;
    loading.value = false;
  }
};

const handleError = () => {
  router.back(); // 이전 페이지로 돌아가기
  window.location.reload(); // 새로고침
};
</script>

<template>
  <div
    v-if="loading"
    class="aia-splash-wrapper"
    tabindex="-1"
    aria-hidden="true"
  >
    <header>
      <AiaButton type="plain" size="xs" class-name="p-1" @click="close()"
        ><PhX :size="24"
      /></AiaButton>

      <div class="flex items-center">
        <div class="dot-loading">
          {{ fileStore.req?.name }}
          <span class="w-6 inline-block dots"></span>
        </div>
      </div>
      <div></div>
    </header>
    <div class="body">
      <div>
        <PhSpinner :size="30" weight="thin" class="text-black dark:text-white">
          <animate
            attributeName="opacity"
            values="0;1;0"
            dur="3s"
            repeatCount="indefinite"
          />
          <animateTransform
            attributeName="transform"
            attributeType="XML"
            type="rotate"
            dur="3s"
            from="0 0 0"
            to="360 0 0"
            repeatCount="indefinite"
          />
        </PhSpinner>
      </div>

      <div class="dot-loading">
        {{ t("message.loading") }}
        <span class="w-6 inline-block dots"></span>
      </div>
    </div>
  </div>
  <component
    :is="currentComponent"
    v-else
    :from="ViewerConst.From.Files"
    @ready="onComponentReady"
  />
</template>
<style lang="scss">
@use "@/assets/styles/code-editor";
</style>
<style lang="scss" scoped>
.aia-splash-wrapper {
  @apply bg-white opacity-90 dark:bg-[#3c3f41];
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  //background: rgba(0, 0, 0, 0.3);
  animation-duration: 0.3s;
  z-index: 1001;
  animation-fill-mode: forwards;

  header {
    @apply flex items-center justify-between px-3
    border-b dark:border-neutral-600
    bg-dark-surface text-gray-500 dark:bg-neutral-900 dark:text-white;
    height: 45px;
  }
  .body {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 90vh;
  }
}
</style>
