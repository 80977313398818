<template>
  <div class="permission-view">
    <!--    <p class="mb-3">{{ t('settings.permissionsHelp') }}</p>-->

    <div :class="{ 'permission-form space-y-2 mb-5': true }">
      <h3 class="mb-3 text-lg font-medium text-gray-900 dark:text-white">
        {{ t("buttons.file") }} {{ t("settings.permissions") }}
      </h3>
      <div class="grid grid-cols-2 gap-2">
        <div v-for="toggle in PermissionDef" :key="toggle.key">
          <div
            :class="{
              'file-perm': true,
              checked: authStore?.user?.perm[toggle.key],
            }"
          >
            <PhCheck
              v-if="authStore?.user?.perm[toggle.key]"
              :size="16"
              class="fill-blue-600"
              weight="thin"
            />
            <PhDotOutline v-else :size="16" weight="thin" />
            <span>{{ t(toggle.label) }}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="pb-4">
      <div
        class="mb-1 text-lg font-medium text-gray-900 dark:text-white flex justify-between"
      >
        <h3>{{ t("accessLevel.myLevel") }}</h3>
        <span class="text-base/7 font-semibold text-blue-600 mb-2">
          {{ authStore?.user?.level }} Level
        </span>
      </div>
      <p>{{ t("accessLevel.myLevelHelp") }}</p>
    </div>

    <div class="py-4" v-if="authStore?.user?.perm.admin">
      <h3
        class="mb-1 text-lg font-medium text-gray-900 dark:text-white flex justify-between"
      >
        {{ t("settings.administrator") }} {{ t("settings.permissions") }}
        <span class="text-base/7 font-semibold text-red-600 mb-2"> ON </span>
      </h3>
      <p class="mb-3">{{ t("settings.adminPermHelp") }}</p>
    </div>
  </div>
</template>

<script setup lang="ts">
import { PermissionDef } from "@/components/common/UserConstance";
import { useAuthStore } from "@/stores/auth";
import { PhCheck, PhDotOutline } from "@phosphor-icons/vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const authStore = useAuthStore();
</script>
<style lang="scss" scoped>
.permission-view {
  .file-perm {
    @apply flex items-center space-x-1;
    // 체크가 아닐때
    &:not(.checked) {
      @apply text-gray-300 dark:text-white/30;
    }
    &.checked {
      //@apply text-gray-300;
    }
  }
}
</style>
