<template>
  <AiaModalContainer @close="closeHovers">
    <template #title>{{ t("buttons.share") }}</template>
    <template #body>
      <template v-if="listing">
        <div>
          <table>
            <thead>
              <tr class="bg-gray-100">
                <th><PhHash :size="20" weight="thin" /></th>
                <th>{{ t("settings.shareDuration") }}</th>
                <th colspan="2"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="link in links" :key="link.hash" class="border-b">
                <td>{{ link.hash }}</td>
                <td>
                  <template v-if="link.expire !== 0">{{
                    humanTimeExpire(link.expire)
                  }}</template>
                  <template v-else>{{ t("permanent") }}</template>
                </td>
                <td class="w-4">
                  <button
                    class="action copy-clipboard"
                    :aria-label="$t('buttons.copyToClipboard')"
                    :title="$t('buttons.copyToClipboard')"
                    @click="copyToClipboard(buildLink(link))"
                  >
                    <PhClipboardText :size="20" weight="thin" />
                    <!--                  <i class="material-icons">content_paste</i>-->
                  </button>
                </td>
                <td v-if="hasDownloadLink" class="w-4">
                  <button
                    class="action copy-clipboard"
                    :aria-label="$t('buttons.copyDownloadLinkToClipboard')"
                    :title="$t('buttons.copyDownloadLinkToClipboard')"
                    @click="copyToClipboard(buildDownloadLink(link))"
                  >
                    <PhLink :size="20" weight="thin" />
                    <!--                  <i class="material-icons">content_paste_go</i>-->
                  </button>
                </td>
                <td class="w-4">
                  <button
                    class="action"
                    :aria-label="$t('buttons.delete')"
                    :title="$t('buttons.delete')"
                    @click="deleteLink($event, link)"
                  >
                    <PhTrash :size="20" weight="thin" />
                    <!--                  <i class="material-icons">delete</i>-->
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="flex items-center justify-between my-4">
          <AiaButton
            type="info"
            :aria-label="t('buttons.close')"
            :title="t('buttons.close')"
            tabindex="2"
            @click="closeHovers"
          >
            {{ t("buttons.close") }}
          </AiaButton>

          <AiaButton
            :aria-label="t('buttons.new')"
            :title="t('buttons.new')"
            tabindex="1"
            @click="switchListing"
          >
            {{ t("buttons.new") }}
          </AiaButton>
        </div>
      </template>

      <template v-else>
        <div class="space-y-2">
          <p>
            {{ t("settings.shareDuration") }}
            <AiaButton
              type="info"
              size="sm"
              @click="showShareTimeInput = !showShareTimeInput"
            >
              <span v-if="!showShareTimeInput">{{ t("buttons.select") }}</span>
              <span v-else>{{ t("buttons.close") }}</span>
            </AiaButton>
          </p>
          <div v-if="showShareTimeInput" class="flex items-center">
            <AiaInput
              v-model="time"
              name="time"
              class="w-full"
              class-name="text-sm px-3 w-full rounded-md border-0 ring-1 ring-inset ring-gray-300 dark:bg-gray-800"
              center
              controls
              type="number"
              :max="2147483647"
              :min="0"
              tabindex="1"
              @keyup.enter="submit"
            />
            <select
              v-model="unit"
              class="ml-1 text-sm px-3 w-20 rounded-md border-0 ring-1 ring-inset ring-gray-300 dark:bg-gray-800"
              :aria-label="$t('time.unit')"
              tabindex="2"
            >
              <option value="seconds">{{ t("time.seconds") }}</option>
              <option value="minutes">{{ t("time.minutes") }}</option>
              <option value="hours">{{ t("time.hours") }}</option>
              <option value="days">{{ t("time.days") }}</option>
            </select>
          </div>
          <p>
            {{ $t("settings.password") }}
            <AiaButton
              type="info"
              size="sm"
              @click="showPwdInput = !showPwdInput"
            >
              <span v-if="!showPwdInput">{{ t("buttons.select") }}</span>
              <span v-else>{{ t("buttons.close") }}</span>
            </AiaButton>
          </p>
          <AiaInput
            v-if="showPwdInput"
            v-model.trim="password"
            class-name="text-sm px-3 w-full rounded-md border-0 ring-1 ring-inset ring-gray-300 dark:bg-gray-800"
            class="input input--block"
            type="password"
            tabindex="3"
          />
        </div>

        <div class="flex items-center justify-between my-4">
          <AiaButton
            type="info"
            :aria-label="t('buttons.cancel')"
            :title="t('buttons.cancel')"
            tabindex="5"
            @click="switchListing"
          >
            {{ t("buttons.cancel") }}
          </AiaButton>

          <AiaButton
            class="d-gray"
            :aria-label="t('buttons.share')"
            :title="t('buttons.share')"
            tabindex="4"
            @click="submit"
          >
            {{ t("buttons.share") }}
          </AiaButton>
        </div>
      </template>
    </template>
  </AiaModalContainer>
</template>

<script setup lang="ts">
import AiaModalContainer from "@/components/dialog/AiaModalContainer.vue";
import AiaButton from "@/components/form/AiaButton.vue";
import AiaInput from "@/components/form/AiaInput.vue";
import { PhClipboardText, PhHash, PhLink, PhTrash } from "@phosphor-icons/vue";
import { ref, computed, onBeforeMount } from "vue";
import { pub as pub_api, share as api } from "@/api";
import { useFileStore } from "@/stores/file";
import { useLayoutStore } from "@/stores/layout";
import { copy } from "@/utils/clipboard";
import moment from "moment/min/moment-with-locales";
import { useI18n } from "vue-i18n";
import { toast } from "vue3-toastify";
import { useRoute } from "vue-router";

const { t } = useI18n();

// State variables
const time = ref(0);
const unit = ref("hours");
const links = ref([]);
const password = ref("");
const listing = ref(true);
const showShareTimeInput = ref(false);
const showPwdInput = ref(false);

// Pinia stores
const fileStore = useFileStore();
const layoutStore = useLayoutStore();

// Mapped state
const req = computed(() => fileStore.req);
const selected = computed(() => fileStore.selected);
const selectedCount = computed(() => fileStore.selectedCount);
const isListing = computed(() => fileStore.isListing);

// Computed URL
const route = useRoute();
const url = computed(() => {
  if (!isListing.value) {
    return route.path;
  }

  if (selectedCount.value === 0 || selectedCount.value > 1) {
    return "";
  }

  return req.value.items[selected.value[0]].url;
});

// Fetch links on component mount
onBeforeMount(async () => {
  try {
    const fetchedLinks = await api.get(url.value);
    links.value = fetchedLinks;
    sort();

    if (links.value.length == 0) {
      listing.value = false;
    }
  } catch (e) {
    toast.error(e);
  }
});

// Methods
const copyToClipboard = (text: string) => {
  copy(text).then(
    () => {
      toast.success("Link copied!");
    },
    () => {
      toast.error("Failed to copy link");
    }
  );
};

const submit = async () => {
  try {
    let res;
    if (showShareTimeInput.value === false || !time.value) {
      res = await api.create(url.value, password.value);
    } else {
      res = await api.create(url.value, password.value, time.value, unit.value);
    }

    links.value.push(res);
    sort();

    // Reset form
    time.value = 0;
    unit.value = "hours";
    password.value = "";
    listing.value = true;
    toast.success(t("settings.shareAdded"));
    layoutStore.closeHovers();
  } catch (e) {
    toast.error(e);
  }
};

const deleteLink = async (event: Event, link) => {
  event.preventDefault();
  try {
    await api.remove(link.hash);
    links.value = links.value.filter(item => item.hash !== link.hash);

    if (links.value.length == 0) {
      listing.value = false;
    }
  } catch (e) {
    toast.error(e);
  }
};

const humanTimeExpire = (time: number) => {
  return moment(time * 1000).fromNow();
};

const buildLink = share => {
  return api.getShareURL(share);
};

const hasDownloadLink = computed(() => {
  return (
    selected.value.length === 1 && !req.value.items[selected.value[0]].isDir
  );
});

const buildDownloadLink = share => {
  return pub_api.getDownloadURL(share);
};

const sort = () => {
  links.value = links.value.sort((a, b) => {
    if (a.expire === 0) return -1;
    if (b.expire === 0) return 1;
    return new Date(a.expire).getTime() - new Date(b.expire).getTime();
  });
};

const switchListing = () => {
  if (links.value.length == 0 && !listing.value) {
    layoutStore.closeHovers();
  }
  listing.value = !listing.value;
};

const closeHovers = () => {
  layoutStore.closeHovers();
};
</script>
