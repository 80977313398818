<template>
  <div v-if="conflictError">
    <ReplaceRename @action="onReplaceRename" />
  </div>
  <!--  <div v-else-if="isOnRename">-->
  <!--    <Rename />-->
  <!--  </div>-->
  <AiaModalContainer
    v-else
    class="md:w-[500px]"
    @close="layoutStore.closeHovers"
  >
    <template #title>{{ t("prompts.move") }}</template>
    <template #body>
      <p>{{ t("prompts.moveMessage") }}</p>
      <div class="flex justify-items-start w-full">
        <div></div>
        <div class="flex-grow">
          <file-list
            ref="fileList"
            v-model:selected="current"
            tabindex="1"
            @update:selected="val => (dest = val)"
          />
        </div>
      </div>
      <div class="flex items-center justify-between my-4">
        <div></div>
        <AiaButton
          :disabled="route.path === dest"
          :aria-label="t('buttons.move')"
          :title="t('buttons.move')"
          :loading="isLoading"
          tabindex="2"
          @click="move"
        >
          {{ t("buttons.move") }}
        </AiaButton>
      </div>
    </template>
  </AiaModalContainer>
</template>
<script setup lang="ts">
import { files as api } from "@/api";
import { StatusError } from "@/api/utils";
import AiaModalContainer from "@/components/dialog/AiaModalContainer.vue";
import AiaButton from "@/components/form/AiaButton.vue";
import { useFileStore } from "@/stores/file";
import { useLayoutStore } from "@/stores/layout";
import * as upload from "@/utils/upload";
import ReplaceRename from "@/views/files/modals/ReplaceRename.vue";
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import { toast } from "vue3-toastify";
import FileList from "./FileList.vue";
// import Rename from "@/views/files/modals/Rename.vue";

const { t } = useI18n({});

// const router = useRouter();
const fileStore = useFileStore();
const layoutStore = useLayoutStore();
const route = useRoute();

const current = ref(window.location.pathname);
const dest = ref<string | null>(null);
const conflictError = ref("");
const isOnRename = ref(false);
const isLoading = ref(false);

let dstItems = []; // 목적 폴더의 파일들
let renameReqItems: { name: string; id: number }[] = []; // 원본 파일

const move = async (event: Event, overwrite = false, rename = false) => {
  if (event) event.preventDefault();
  const items = [];
  const reqItems: { name: string; id: number }[] = []; // 원본 파일

  for (const idNo of fileStore.selected) {
    const name = fileStore.req?.items[idNo].name;
    items.push({
      from: fileStore.req?.items[idNo].url,
      to: dest.value + encodeURIComponent(fileStore.req.items[idNo].name),
      name: fileStore.req?.items[idNo].name,
    });
    reqItems.push({
      id: idNo,
      name: name,
    });
  }

  const action = async (overwrite: boolean, rename: boolean) => {
    isLoading.value = true;

    try {
      await api.move(items, overwrite, rename);
      // 이동 후 해당 경로로 이동시 에러가 발생되는 문제
      // router.push({ path: dest.value as string });
      toast.success(t("fileMessages.moveSuccess", { cnt: items.length }));
      fileStore.reload = true;
    } catch (e) {
      if (e instanceof StatusError) {
        if (e.status === 403) {
          toast.error(t("message.forbiddenMsg", { action: t("prompts.move") }));
        } else if (e.status === 409) {
          toast.error(t("message.conflictNameMsg"));
        } else {
          toast.error(String(e));
        }
      } else {
        toast.error(String(e));
      }
    }
    isLoading.value = false;
  };

  // if (!overwrite) {
  //   const dstItems = (await api.fetch(dest.value as string)).items;
  //   const conflict = upload.checkConflict(items, dstItems);
  //
  //   if (conflict) {
  //     conflictError.value = t('prompts.replaceMessage');
  //     return;
  //   }
  // }

  if (!overwrite) {
    dstItems = (await api.fetch(dest.value as string)).items;
    const { isConflict, reFiles } = upload.checkConflictAndRename(
      reqItems,
      dstItems
    );

    if (isConflict) {
      renameReqItems = reFiles;
      conflictError.value = t("prompts.replaceMessage");
      return;
    }
  }

  await action(overwrite, rename);
};

const onReplaceRename = (nm: string) => {
  // console.log('from move', nm);
  if (nm === "overwrite") {
    move(null, true);
  } else if (nm === "rename") {
    // 이름 변경
    for (const item of renameReqItems) {
      fileStore.req.items[item.id].name = item.name;
    }
    renameReqItems = [];
    move(null, false, true);
  }
};
</script>
