<script setup lang="ts">
import { onBeforeUnmount, onMounted, PropType, ref } from "vue";
import { ContextItemInter } from "@/class/interface/MenuInter";
import {
  PhFilePlus,
  PhFolderPlus,
  PhCursorText,
  PhTrashSimple,
  PhTrash,
  PhStar,
  PhInfo,
  PhArrowLineDown,
} from "@phosphor-icons/vue";
const iconMap = {
  PhFolderPlus,
  PhFilePlus,
  PhCursorText,
  PhTrashSimple,
  PhTrash,
  PhStar,
  PhInfo,
  PhArrowLineDown,
};

const emit = defineEmits<{
  (e: "select", item: { label: string; value: string }): void;
}>();

defineProps({
  items: {
    type: Array as PropType<ContextItemInter[]>,
  },
});

const contextMenuRef = ref(null);

// State variables
const visible = ref(false);
const menuStyles = ref({ top: "0", left: "0", width: "0" });

const menuWidth = ref(160);
const menuHeight = ref(280);
onMounted(() => {
  if (contextMenuRef.value != null) {
    menuWidth.value = contextMenuRef.value.offsetWidth + 30;
    menuHeight.value = contextMenuRef.value.offsetHeight + 30;
  }
});

// Methods
function openMenu(event: MouseEvent, gapX: number = 0, gapY: number = 0) {
  event.preventDefault();

  // Get viewport dimensions
  const viewportWidth = window.innerWidth - gapX;
  const viewportHeight = window.innerHeight - gapY;

  // Define menu dimensions (approximate)

  // Calculate initial position
  let top = event.clientY;
  let left = event.clientX;

  // Adjust position if the menu goes beyond the viewport
  if (left + menuWidth.value > viewportWidth) {
    left = viewportWidth - (menuWidth.value + 20);
  }
  if (top + menuHeight.value > viewportHeight) {
    top = viewportHeight - menuHeight.value;
  }

  menuStyles.value = {
    top: `${top}px`,
    left: `${left}px`,
    width: `${menuWidth.value}px`,
    transform: "translate(0, 0)",
    transition: "none", // 이전 애니메이션 제거
  };

  visible.value = true;

  // Apply transition with gap
  setTimeout(() => {
    menuStyles.value = {
      top: `${top}px`,
      left: `${left}px`,
      width: `${menuWidth.value}px`,
      transform: "translate(0, 0)",
      transition: "transform 0.1s ease-in",
    };

    // Move to final position after transition
    setTimeout(() => {
      menuStyles.value = {
        top: `${top}px`,
        left: `${left}px`,
        width: `${menuWidth.value}px`,
        transition: "transform 0.1s ease-out",
        transform: `translate(${gapX}px, ${gapY}px)`,
      };
    }, 0);
  }, 0);
}

function closeMenu() {
  visible.value = false;
}

function handleItemClick(item: ContextItemInter) {
  if (!item.disabled) {
    emit("select", item);
    closeMenu();
  }
}

// Lifecycle hooks
onMounted(() => {
  window.addEventListener("click", closeMenu);
  // window.addEventListener('contextmenu', event => {
  //   event.preventDefault();
  // });
});

onBeforeUnmount(() => {
  window.removeEventListener("click", closeMenu);
  // window.removeEventListener('contextmenu', closeMenu);
});

defineExpose({ openMenu, closeMenu });
</script>
<template>
  <div>
    <ul
      v-if="visible"
      :style="menuStyles"
      class="aia-context-menu min-w-40 md:min-w-52"
      ref="contextMenuRef"
    >
      <li
        v-for="(item, index) in items"
        :key="index"
        :class="[
          { 'flex items-center px-3 py-2': true },
          { 'border-b': item.line },
          { 'disabled ': item.disabled },
        ]"
        @click="handleItemClick(item)"
      >
        <span class="w-7 h-5">
          <component
            :is="iconMap[item.icon]"
            v-if="item.icon"
            :size="item.iconSize ? item.iconSize : 17"
            weight="thin"
            class="h-full"
          />
        </span>
        <span class="flex justify-between w-full">
          <span>{{ item.label }}</span>
          <span v-if="item.shortcut" class="shortcut hidden md:block">
            {{ item.shortcut }}</span
          >
        </span>
      </li>
    </ul>
  </div>
</template>
<style lang="scss" scoped>
li {
  &.disabled {
    @apply text-black text-opacity-30;
    //color: #f9f9f9;
    cursor: not-allowed;
    .shortcut {
      display: none;
    }
  }
  .shortcut {
    @apply border border-gray-200 bg-gray-50 text-gray-500 rounded text-xs px-1;
  }
}
</style>
