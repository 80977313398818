<template>
  <AiaModalContainer @close="layoutStore.closeHovers">
    <template #title>{{ t('prompts.download') }}</template>
    <template #body>
      <p class="">{{ t('prompts.downloadMessage') }}</p>
      <div class="flex items-center my-4">
        <AiaButton
          v-for="(ext, format) in formats"
          :key="ext"
          size="sm"
          type="info"
          class="mr-1"
          @click="layoutStore.currentPrompt?.confirm(format)"
        >
          {{ ext }}
        </AiaButton>
      </div>
    </template>
  </AiaModalContainer>
</template>

<script setup lang="ts">
import AiaModalContainer from '@/components/dialog/AiaModalContainer.vue';
import AiaButton from '@/components/form/AiaButton.vue';
import { useLayoutStore } from '@/stores/layout';
import { useI18n } from 'vue-i18n';

const layoutStore = useLayoutStore();

const { t } = useI18n();

const formats = {
  zip: 'zip',
  tar: 'tar',
  targz: 'tar.gz',
  // tarbz2: 'tar.bz2',
  // tarxz: 'tar.xz',
  // tarlz4: 'tar.lz4',
  // tarsz: 'tar.sz',
};
</script>
