import { useAuthStore } from "@/stores/auth";
import { useFileStore } from "@/stores/file";
import { baseURL, Directories, fileListLimit } from "@/utils/constants";
import { data } from "autoprefixer";
import { upload as postTus, useTus } from "./tus";
import { createURL, fetchURL, removePrefixUrl, StatusError } from "./utils";

async function _resourceFetch(url: string, opts = {}) {
  const res = await fetchURL(`${url}`, opts);
  if (res && typeof res === "object") {
    return (await res.json()) as Resource;
  }
  throw await res;
}

export async function fetch(url: string, addParam = "") {
  if (!url) {
    console.error("url is empty");
    return null;
  }

  // drive/완 같은 경로 제거
  url = removePrefixUrl(url);
  // const res = await fetchURL(`/api/resources${url}`, {});
  // const data = (await res.json()) as Resource;
  let data;
  try {
    data = (await _resourceFetch(
      `/api/resources${url}${addParam}`
    )) as Resource;
    data.url = `${Directories.base}${url}`;
    if (data.isDir) {
      if (!data.url.endsWith("/")) data.url += "/";
      // Perhaps change the any
      data.items = data.items.map((item, index) => {
        item.index = index;
        item.url = `${data.url}${encodeURIComponent(item.name)}`;

        if (item.isDir) {
          item.url += "/";
        }

        return item;
      });
    }

    return data;
  } catch (e) {
    // console.log(e)
    throw e;
  }
}

// let favoriteSet = new Set<string>();
// async function initFavoriteSet() {
//   if (!favoriteSet || favoriteSet.size === 0) {
//     favoriteSet = await getFavoriteSetAll();
//   } else {
//     favoriteSet = await getFavoriteSet();
//   }
// }

export async function fetchRecentFiles(limit, offset) {
  // data.url = `${Directories.base}`;
  let addUrl = "";
  if (limit && offset !== null) {
    addUrl = `?limit=${limit}&offset=${offset}`;
  }
  const lists = await _resourceFetch(`/api/recent${addUrl}`, { limit });
  // await initFavoriteSet();

  if (!Array.isArray(lists)) {
    return [];
  }

  return lists;
}

export async function fetchDirectoryData() {
  const res = await fetchURL(`/api/tree-res`, {});
  if (res && typeof res === "object") {
    return (await res.json()) as Directory;
  }
  return res;
}

async function resourceAction(url: string, method: ApiMethod, content?) {
  url = removePrefixUrl(url);

  const opts: ApiOpts = {
    method,
  };

  if (content) {
    opts.body = content;
  }

  const res = await fetchURL(`/api/resources${url}`, opts);

  return res;
}

export async function remove(url: string) {
  return resourceAction(url, "DELETE");
}

export async function put(url: string, content = "") {
  return resourceAction(url, "PUT", content);
}

export function download(format, ...files: string[]) {
  let url = `${baseURL}/api/raw`;

  if (files.length === 1) {
    url += removePrefixUrl(files[0]) + "?";
  } else {
    let arg = "";

    for (const file of files) {
      arg += removePrefixUrl(file) + ",";
    }

    arg = arg.substring(0, arg.length - 1);
    arg = encodeURIComponent(arg);
    url += `/?files=${arg}&`;
  }

  if (format) {
    url += `algo=${format}&`;
  }

  const authStore = useAuthStore();
  if (authStore.jwt) {
    url += `auth=${authStore.jwt}&`;
  }

  window.open(url);
}

export async function post(
  url: string,
  content: ApiContent = "",
  overwrite = false,
  onupload = () => {}
) {
  // Use the pre-existing API if:
  const useResourcesApi =
    // a folder is being created
    url.endsWith("/") ||
    // We're not using http(s)
    (content instanceof Blob &&
      !["http:", "https:"].includes(window.location.protocol)) ||
    // Tus is disabled / not applicable
    !(await useTus(content));
  return useResourcesApi
    ? postResources(url, content, overwrite, onupload)
    : postTus(url, content, overwrite, onupload);
}

async function postResources(
  url: string,
  content: ApiContent = "",
  overwrite = false,
  onUpload
) {
  url = removePrefixUrl(url);

  let bufferContent: ArrayBuffer;
  if (
    content instanceof Blob &&
    !["http:", "https:"].includes(window.location.protocol)
  ) {
    bufferContent = await new Response(content).arrayBuffer();
  }

  const authStore = useAuthStore();
  return new Promise((resolve, reject) => {
    const request = new XMLHttpRequest();
    request.open(
      "POST",
      `${baseURL}/api/resources${url}?override=${overwrite}`,
      true
    );
    request.setRequestHeader("SetFn-Auth", authStore.jwt);

    if (typeof onUpload === "function") {
      request.upload.onprogress = onUpload;
    }

    request.onload = () => {
      if (request.status === 200) {
        resolve(request.responseText);
      } else if (request.status === 403) {
        reject(new StatusError(request.status, request.status));
      } else if (request.status === 409) {
        reject(new StatusError(request.status, request.status));
      } else {
        reject(request.responseText);
      }
    };

    request.onerror = () => {
      reject(new Error("001 Connection aborted"));
    };

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    request.send(bufferContent || content);
  });
}

function moveCopy(items: [], copy = false, overwrite = false, rename = false) {
  const promises = [];

  for (const item of items) {
    const from = item["from"];
    const to = encodeURIComponent(removePrefixUrl(item["to"] ?? ""));
    const url = `${from}?action=${
      copy ? "copy" : "rename"
    }&destination=${to}&override=${overwrite}&rename=${rename}`;
    promises.push(resourceAction(url, "PATCH"));
  }

  return Promise.all(promises);
}

export function move(items, overwrite = false, rename = false) {
  return moveCopy(items, false, overwrite, rename);
}

export function copy(items, overwrite = false, rename = false) {
  return moveCopy(items, true, overwrite, rename);
}

export async function checksum(url: string, algo: ChecksumAlg) {
  const data = await resourceAction(`${url}?checksum=${algo}`, "GET");
  return (await data.json()).checksums[algo];
}

export function getDownloadURL(file: ResourceItem, inline) {
  const params = {
    ...(inline && { view: "0" }),
  };

  return createURL("api/raw" + file.path, params);
}

export function getPreviewURL(file: ResourceItem, size: string) {
  const params = {
    view: "1",
    key: Date.parse(file.modified),
  };

  return createURL("api/preview/" + size + file.path, params);
}

export function getSubtitlesURL(file: ResourceItem) {
  const params = {
    view: "1",
  };

  return file.subtitles?.map(d => createURL("api/subtitle" + d, params));
}

export async function usage(url: string) {
  url = removePrefixUrl(url);

  const res = await fetchURL(`/api/usage${url}`, {});

  return await res.json();
}
