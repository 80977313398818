import * as jssha3 from 'js-sha3';

export class Password {
  // async getHash(plainPassword: string) {
  //   return bcrypt.hash(plainPassword, 10);
  // }
  // getVerify(plainPassword: string, hash: string) {
  //   return bcrypt.compare(plainPassword, hash);
  // }

  getSha3And256(plainPassword: string) {
    return jssha3.sha3_256(plainPassword);
  }
}
