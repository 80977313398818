<script setup lang="ts">
import Action from "@/components/files/Action.vue";
import ChangeViewAction from "@/components/files/ChangeViewAction.vue";
import { useAuthStore } from "@/stores/auth";
import { useFileStore } from "@/stores/file";
import { useLayoutStore } from "@/stores/layout";
import FileUploadBtn from "@/views/files/menu/FileUploadBtn.vue";
import { PhInfo } from "@phosphor-icons/vue";
import { computed } from "vue";

const layoutStore = useLayoutStore();
const authStore = useAuthStore();
const fileStore = useFileStore();
// const emit = defineEmits(['action']);

const iconSize = 20;
const iconWeight = computed(() => (layoutStore.showFileInfo ? "bold" : "thin"));
const showButtons = computed(() => {
  return {
    upload: fileStore.selectedCount === 0 && authStore.user?.perm.create,
    download: fileStore.selectedCount > 0 && authStore.user?.perm.download,
  };
});
</script>

<template>
  <div class="layout-action-wrap">
    <button class="action">
      <ChangeViewAction style="vertical-align: center; margin: 0; padding: 0" />
    </button>

    <action v-if="showButtons.upload">
      <template #icon>
        <FileUploadBtn class="inline-block action" />
      </template>
    </action>

    <action icon="info" @click="layoutStore.toggleFileInfo()">
      <template #icon>
        <ph-info
          :size="iconSize"
          :weight="iconWeight"
          :class="{
            'fill-white rounded-full bg-blue-400': layoutStore.showFileInfo,
          }"
        />
      </template>
    </action>
  </div>
</template>

<style scoped lang="scss">
.layout-action-wrap {
  display: flex;
  justify-content: center;
  button.action {
    @apply space-x-2 inline-block hover:bg-neutral-100 dark:hover:bg-blue-400 rounded-md p-1 mx-1;
    display: inline-block;
    svg {
      display: inline-block;
    }
    i {
      display: inline-block;
    }
  }
}
</style>
