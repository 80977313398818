<script setup>
import { onMounted, ref, watch } from 'vue';
import { Dropdown } from 'flowbite';

const props = defineProps({
  items: Array,
  showMenu: {
    type: Boolean,
    default: false,
  },
  position: {
    type: String,
    default: 'bottom',
  },
  className: {
    type: String,
    default: 'aia-drop-menu',
  },
});
const emits = defineEmits(['update:showMenu', 'select']);

const dropdownMenu = ref(null);
const dropdownButton = ref(null);

let dropdown;
onMounted(() => {
  const options = {
    placement: props.position,
    offsetSkidding: 5,
    offsetDistance: 10,
    onHide: () => {
      emits('update:showMenu', false);
      // console.log('dropdown has been hidden');
    },
    onShow: () => {
      emits('update:showMenu', true);
      // console.log('dropdown has been shown');
    },
  };

  if (dropdownMenu.value && dropdownButton.value) {
    dropdown = new Dropdown(dropdownMenu.value, dropdownButton.value, options);
  }

  if (props.showMenu) {
    dropdown.show();
  } else {
    dropdown.hide();
  }
});

const handleMenuClick = item => {
  emits('select', item);
  if (dropdown) dropdown.hide();
};

watch(
  () => props.showMenu,
  is => {
    if (is) {
      dropdown.show();
    } else {
      dropdown.hide();
    }
  }
);
</script>
<template>
  <div>
    <div ref="dropdownButton">
      <slot />
    </div>
    <div ref="dropdownMenu" :class="['aia-drop-menu', className]">
      <slot name="items" @blur="dropdown.hide()">
        <ul
          class="drop-menu-items whitespace-nowrap"
          aria-labelledby="dropdownButton"
        >
          <li
            v-for="(item, index) in items"
            :key="index"
            @click="handleMenuClick(item)"
          >
            <a :href="item.link">
              {{ item.label }}
            </a>
          </li>
        </ul>
      </slot>
    </div>
  </div>
</template>
