<template>
  <AiaModalContainer @close="layoutStore.closeHovers">
    <template #title>{{ t('help.help') }}</template>
    <template #body>
      <div>
        <ul class="space-y-1">
          <!--          <li><strong>F1</strong> - {{ $t('help.f1') }}</li>-->
          <li><strong>ESC</strong> - {{ $t('help.esc') }}</li>
          <li><strong>N</strong> - {{ $t('help.n') }}</li>
          <li><strong>F2</strong> - {{ $t('help.f2') }}</li>
          <li><strong>DEL</strong> - {{ $t('help.del') }}</li>
          <li><strong>CTRL + S</strong> - {{ $t('help.ctrl.s') }}</li>
          <li><strong>CTRL + F</strong> - {{ $t('help.ctrl.f') }}</li>
          <li><strong>CTRL + Click</strong> - {{ $t('help.ctrl.click') }}</li>
          <li><strong>Click</strong> - {{ $t('help.click') }}</li>
          <li><strong>Double click</strong> - {{ $t('help.doubleClick') }}</li>
        </ul>
      </div>

      <div class="flex items-center justify-between my-4">
        <div></div>
        <AiaButton
          type="info"
          :aria-label="$t('buttons.ok')"
          :title="$t('buttons.ok')"
          tabindex="1"
          @click="layoutStore.closeHovers"
        >
          {{ t('buttons.ok') }}
        </AiaButton>
      </div>
    </template>
  </AiaModalContainer>
</template>

<script setup>
import AiaModalContainer from '@/components/dialog/AiaModalContainer.vue';
import AiaButton from '@/components/form/AiaButton.vue';
import { useLayoutStore } from '@/stores/layout';
import { useI18n } from 'vue-i18n';
const { t } = useI18n();
const layoutStore = useLayoutStore();
</script>
