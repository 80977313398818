import { useAuthStore } from "@/stores/auth";
import { logout, renew } from "@/utils/auth";
import { baseURL, Directories } from "@/utils/constants";
import { encodePath } from "@/utils/url";
import i18n from "@/i18n";

export class StatusError extends Error {
  jsonError: any;
  constructor(
    message: any,
    public status?: number,
    jsonError?: any
  ) {
    super(message);
    this.name = "StatusError";
    this.jsonError = jsonError;
  }
}

export function extractInfoFromPath(path: string): {
  isDir: boolean;
  name: string;
  ext?: string;
} {
  const isGussDir = path.endsWith("/");
  const trimmedPath = isGussDir ? path.slice(0, -1) : path;
  const segments = trimmedPath.split("/");
  const fullName = segments.pop() || "";

  const nameParts = fullName.split(".");
  let name = fullName;
  let ext;

  if (nameParts.length > 1) {
    ext = nameParts.pop(); // 확장자 추출
    name = nameParts.join("."); // 파일 이름 추출
  }
  if (ext) ext = "." + ext;

  return { isDir: isGussDir, name, ext };
}

export async function fetchURL(
  url: string,
  opts: ApiOpts = {},
  auth = true
): Promise<Response> {
  const authStore = useAuthStore();
  opts.headers = opts.headers || {};

  const { headers, ...rest } = opts;
  let response: Response | null = null;

  try {
    response = await fetch(`${baseURL}${url}`, {
      headers: {
        "SetFn-Auth": authStore.jwt,
        ...headers,
      },
      ...rest,
    });
  } catch (error) {
    throw new StatusError("No connection", 0);
  }

  if (auth && response?.headers.get("SetFn-Auth-New-JWT") === "true") {
    await renew(authStore.jwt);
  }

  if (response?.status && (response.status < 200 || response.status > 299)) {
    if (auth && response.status === 401) {
      logout();
    }

    const errorBody = await response.text();
    let errorMessage = errorBody || `${response.status} ${response.statusText}`;

    // 403 에러일 때 errorBody가 JSON 타입인지 확인
    let errorJson;
    if (response.status === 403) {
      try {
        errorJson = JSON.parse(errorBody);
      } catch (e) {
        throw new StatusError(
          i18n.global.t("message.forbiddenMsg", {
            action: "Permission Error: ",
          }),
          response.status
        );
      }
      // JSON이 아니거나 필요한 정보가 없으면 기본 에러 메시지로 처리
      errorMessage = i18n.global.t("message.forbiddenMsg", {
        action: "Permission Error: ",
      });
    }

    throw new StatusError(errorMessage, response.status, errorJson);
  }

  return response;
}

export async function fetchJSON<T>(url: string, opts?): Promise<T> {
  const res = await fetchURL(url, opts);

  if (res.status === 200) {
    return res.json() as Promise<T>;
  }

  throw new StatusError(`${res.status} ${res.statusText}`, res.status);
}

export function removePrefixUrl(url: string): string {
  // url = url.split('/').splice(2).join('/');
  if (url.startsWith(Directories.base)) {
    url = url.replace(Directories.base, "");
  }

  if (url === "") url = "/";
  if (url[0] !== "/") url = "/" + url;
  return url;
}

export function cleanSuffixUrl(path: string) {
  return path.endsWith("/") ? path.slice(0, -1) : path;
}

export function createURL(endpoint: string, params = {}, auth = true): string {
  const authStore = useAuthStore();

  let prefix = baseURL;
  if (!prefix.endsWith("/")) {
    prefix = prefix + "/";
  }
  const url = new URL(prefix + encodePath(endpoint), origin);

  const searchParams: SearchParams = {
    ...(auth && { auth: authStore.jwt }),
    ...params,
  };

  for (const key in searchParams) {
    url.searchParams.set(key, searchParams[key]);
  }

  return url.toString();
}
