import { createURL, fetchJSON, fetchURL, removePrefixUrl } from './utils';

export async function list() {
  return fetchJSON<Share[]>('/api/shares');
}

export async function get(url: string) {
  url = removePrefixUrl(url);
  return fetchJSON<Share>(`/api/share${url}`);
}

export async function remove(hash: string) {
  await fetchURL(`/api/share/${hash}`, {
    method: 'DELETE',
  });
}

export async function create(
  url: string,
  password = '',
  expires = '',
  unit = 'hours'
) {
  url = removePrefixUrl(url);
  url = `/api/share${url}`;
  if (expires !== '') {
    url += `?expires=${expires}&unit=${unit}`;
  }
  let body = '{}';
  if (password != '' || expires !== '' || unit !== 'hours') {
    body = JSON.stringify({
      password: password,
      expires: expires.toString(), // backend expects string not number
      unit: unit,
    });
  }
  return fetchJSON(url, {
    method: 'POST',
    body: body,
  });
}

export function getShareURL(share: Share) {
  return createURL('share/' + share.hash, {}, false);
}
