import { ResourceItem } from "@/class/interface/drive/file";
import { defineStore } from "pinia";

export const useFileStore = defineStore("file", {
  // convert to a function
  state: (): {
    req: Resource | null;
    oldReq: Resource | null;
    reload: boolean;
    selected: number[];
    multiple: boolean;
    isFiles: boolean;
    dirs: ResourceItem[];
    files: ResourceItem[];
  } => ({
    req: null,
    oldReq: null,
    reload: false,
    selected: [],
    multiple: false,
    isFiles: false,
    dirs: [],
    files: [],
  }),
  getters: {
    selectedCount: state => state.selected.length,
    isListing: state => {
      return state.isFiles && state?.req?.isDir;
    },
  },
  actions: {
    // no context as first argument, use `this` instead
    toggleMultiple() {
      this.multiple = !this.multiple;
    },
    updateRequest(value: Resource | null) {
      const selectedItems = this.selected.map(i => this.req?.items[i]);
      this.oldReq = this.req;
      this.req = value;
      this.selected = [];

      if (!this.req?.items) return;

      // 폴더와 파일 분리하여 상태에 저장
      this.dirs = this.req.items
        .filter(item => item.isDir)
        .map((item, idx) => ({ ...item, index: idx }));
      this.files = this.req.items
        .filter(item => !item.isDir)
        .map((item, idx) => ({ ...item, index: this.dirs.length + idx }));

      this.req.items = [...this.dirs, ...this.files];

      // 이전 선택 항목들 복원
      this.selected = this.req.items
        .filter(item => selectedItems.some(rItem => rItem?.url === item.url))
        .map(item => item.index);
    },
    removeSelected(value) {
      const i = this.selected.indexOf(value);
      if (i === -1) return;
      this.selected.splice(i, 1);
    },
    // easily reset state using `$reset`
    clearFile() {
      this.$reset();
    },
  },
});
