<script setup lang="ts">
import { useFileStore } from '@/stores/file';
import { useLayoutStore } from '@/stores/layout';
import { Directories } from '@/utils/constants';
import FileSideBarHistory from '@/views/files/sidebar/FileSideBarHistory.vue';
import FileSidebarInfoMain from '@/views/files/sidebar/FileSidebarInfoMain.vue';
import { PhFile, PhFolder, PhX } from '@phosphor-icons/vue';
import { computed, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
const layoutStore = useLayoutStore();
const fileStore = useFileStore();
const req = computed(() => fileStore.req);
const selected = computed(() => fileStore.selected);
const selectedCount = computed(() => fileStore.selectedCount);
const isListing = computed(() => fileStore.isListing);

// 현재 선택된 탭 상태를 관리하기 위한 변수
const activeTab = ref<"FileInfo" | "FileHistory">("FileInfo");

// 탭 클릭 시 컴포넌트를 변경하는 함수
const setActiveTab = (tab: "FileInfo" | "FileHistory") => {
  activeTab.value = tab;
};

const dir = computed(() => {
  if (selectedCount.value === 0) {
    // 선택된 항목이 없을 때 기본값을 반환
    return req.value?.isDir;
  } else if (selectedCount.value > 1) {
    // 선택된 항목이 1개 이상일 때 모든 항목의 isDir을 검사
    return selected.value.every(index => req.value.items[index].isDir);
  } else {
    // 선택된 항목이 1개일 때 해당 항목의 isDir을 반환
    return req.value?.items[selected.value[0]].isDir;
  }
});

const name = computed(() => {
  if (selectedCount.value === 0 && "/" + req.value?.name === Directories.base) {
    return t("sidebar.teamFolder");
  }
  return selectedCount.value === 0
    ? req.value?.name
    : req.value?.items[selected.value[0]].name;
});

const nameClass = computed(() => {
  return name.value.length > 12 ? "text-base" : " text-xl";
});

const fileInfoKey = ref("fileInfoKey");

watch(selected, newValue => {
  if (newValue) {
    fileInfoKey.value = "K" + new Date().getTime();
  }
});
</script>

<template>
  <div class="file-info-sidebar relative min-w-[300px]">
    <!-- header -->
    <div   class="file-info-table">
      <table class="w-full mt-2">
        <thead>
          <tr>
            <!-- 아이콘 및 파일명 -->
            <td v-if="isListing" class="icon">
              <PhFolder v-if="dir" size="24" weight="thin" />
              <PhFile v-else size="22" weight="thin" />
            </td>
            <td class="text-left">
              <span v-if="selected.length > 1" class="name text-base">{{
                t("prompts.itemSelected", { count: selected.length })
              }}</span>
              <span v-else class="name text-base">{{ name }}</span>
            </td>
            <!-- 닫기 버튼 -->
            <td class="text-right">
              <button
                type="button"
                class="btn-close"
                aria-label="Close"
                :title="$t('buttons.close')"
                @click="layoutStore.toggleFileInfo()"
              >
                <PhX
                  size="26"
                  class="hover:bg-gray-500/10 hover:fill-gray-500 rounded"
                />
              </button>
            </td>
          </tr>
        </thead>
      </table>
    </div>

    <div v-if="isListing" class="file-info-contents relative" >
      <div v-if="selected.length > 1">
        <div class="text-center pt-5 text-gray-500/70 dark:text-gray-300/70">
          {{ t("prompts.filesSelected", { count: selected.length }) }}
        </div>
      </div>
      <div v-else>
        <div class="w-full text-center">
          <!-- tab -->
          <div
            class="text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:text-gray-400 dark:border-gray-700"
          >
            <ul class="flex justify-between -mb-px">
              <!-- FileInfo 탭 -->
              <li class="w-1/2">
                <a
                  href="#"
                  @click.prevent="setActiveTab('FileInfo')"
                  :class="[
                    'tab-link',
                    activeTab === 'FileInfo' ? 'active' : '',
                  ]"
                >
                  <span v-if="dir">{{ t("prompts.folderInfo") }}</span>
                  <span v-else>{{ t("prompts.fileInfo") }}</span>
                </a>
              </li>

              <!-- FileHistory 탭 -->
              <li class="w-1/2">
                <a
                  href="#"
                  @click.prevent="setActiveTab('FileHistory')"
                  :class="[
                    'tab-link',
                    activeTab === 'FileHistory' ? 'active' : '',
                  ]"
                >
                  <span>{{ t("prompts.fileHistory") }}</span>
                </a>
              </li>
            </ul>
          </div>

          <!-- 컴포넌트 렌더링 -->
        </div>
        <div class="mt-5">
          <FileSidebarInfoMain
            v-if="activeTab === 'FileInfo'"
            :key="fileInfoKey"
            :req="req"
            :selected="selected"
            :selected-count="selectedCount"
            :is-listing="isListing"
          />
          <FileSideBarHistory
            v-if="activeTab === 'FileHistory'"
            :selected-count="selectedCount"
          />
        </div>
      </div>
    </div>
    <div v-else class="w-full text-center h-full pt-5">
      <svg width="96" height="96" viewBox="0 0 24 24" class='m-auto'>
        <path class='m-auto fill-gray-200/70 stroke-gray-300/70 dark:fill-black/10 dark:stroke-gray-500/70' fill="none" stroke="#ddd" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="m10.25 10.25l3 3m0-3l-3 3M19 11.5a7.5 7.5 0 1 1-15 0a7.5 7.5 0 0 1 15 0m-2.107 5.42l3.08 3.08"/></svg>
      <h2 class="pt-1 text-lg font-medium  text-gray-300/70 dark:text-gray-400/70" >
        Not Found
      </h2>
    </div>
  </div>
</template>

<style scoped lang="scss">
.file-info-sidebar {
  .file-info-table {
    background: var(--background);
    padding: 10px 0 0 0;
    //margin-bottom: 10px;
    table {
      margin: 0;
      td {
        vertical-align: middle;
        &.icon {
          width: 42px;
          padding-top: 7px;
        }
        & span.name {
          display: block;
          width: 220px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
  }
  .file-info-contents {
    //margin-top: 43px;
  }
}

.tab-link {
  @apply space-x-2 inline-block p-2 border-b-2 border-transparent rounded-t-lg
  hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300;
}

.active {
  @apply text-blue-600 border-b-2 border-blue-600 dark:text-blue-500 dark:border-blue-500;
}
</style>
