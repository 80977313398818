<template>
  <AiaModalContainer @close="layoutStore.closeHovers">
    <template #title>{{ t('prompts.replace') }}</template>
    <template #body>
      <div class="pb-5">
        <p>{{ t('prompts.replaceMessage') }}</p>
      </div>
      <div class="flex justify-end mb-2 space-x-2">
        <AiaButton
          :aria-label="t('buttons.rename')"
          :title="t('buttons.rename')"
          tabindex="2"
          @click="event => actionEmit(event, 'rename', currentPrompt.action)"
        >
          {{ t('buttons.rename') }}
        </AiaButton>
        <AiaButton
          type="danger"
          :aria-label="t('buttons.replace')"
          :title="t('buttons.replace')"
          tabindex="1"
          @click="event => actionEmit(event, 'overwrite', currentPrompt.action)"
        >
          {{ t('buttons.replace') }}
        </AiaButton>
      </div>
    </template>
  </AiaModalContainer>
</template>

<script setup lang="ts">
import AiaModalContainer from '@/components/dialog/AiaModalContainer.vue';
import AiaButton from '@/components/form/AiaButton.vue';
import { useLayoutStore } from '@/stores/layout';
import { storeToRefs } from 'pinia';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const layoutStore = useLayoutStore();
const { currentPrompt } = storeToRefs(layoutStore);

const isBtnOverwrite = ref(false);
const isBtnRename = ref(false);

const emit = defineEmits(['action']);

const actionEmit = (event, nm, prompt) => {
  if (nm === 'overwrite') {
    isBtnOverwrite.value = true;
  } else if (nm === ' rename') {
    isBtnRename.value = true;
  }
  emit('action', nm);
  if (prompt && typeof prompt === 'function') prompt(event, nm);
};
</script>
