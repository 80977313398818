{
  "title": "setFn",
  "subTitle": "팀스페이스",
  "connection": {
    "failedMsg": "서버 연결에 실패하였습니다."
  },
  "auth": {
    "login": {
      "createAnAccount": "계정 생성",
      "loginInstead": "이미 계정이 있습니다",
      "password": "비밀번호",
      "passwordsDontMatch": "비밀번호가 일치하지 않습니다",
      "signup": "가입하기",
      "submit": "로그인",
      "username": "사용자 이름",
      "usernameTaken": "사용자 이름이 존재합니다",
      "wrongCredentials": "사용자 이름 또는 비밀번호를 확인하십시오"
    },
    "signIn": {
      "title": "로그인",
      "explain": "",
      "agree": "이용약관 및 개인정보 취급방침에 동의 하십니까?",
      "forgetPwd": "비밀번호가 생각나지 않습니까?",
      "submit": "로그인",
      "signUpBtn": "회원가입",
      "errorOccurred": "로그인에 실패하였습니다.",
      "forbiddenAccount": "계정정보를 확인해 주세요.",
      "unActivated": "로그인은 하였으나, 아직 활성화 상태가 아닙니다."
    },
    "signUp": {
      "title": "회원가입",
      "nickname": "닉네임",
      "password": "비밀번호",
      "passwordConfirm": "비밀번호 확인",
      "submit": "회원가입",
      "completed": "성공적으로 가입되었습니다.",
      "response": {
        "notAllowed": "현재 회원가입이 제한되어 있습니다.",
        "errorOccurred": "회원가입에 실패하였습니다.",
        "forbidden": "가입 권한이 없습니다.",
        "conflictNick": "다른 사람이 사용 중인 닉네임 입니다.",
        "conflictUserName": "다른 사람이 사용 중인 사용자 이름 입니다."
      }
    },
    "invalid-msg": {
      "username": "사용자명을 올바르게 입력해 주세요.",
      "nickname": "닉네임을 입력해 주세요.",
      "password": "비밀번호를 입력해 주세요.",
      "unMatchPassword": "비밀번호가 일치하지 않습니다.",
      "email": "이메일을 올바르게 입력해 주세요.",
      "max": "최대 {max}까지 입력 가능합니다.",
      "min": "최소 {min}자 이상 입력해 주세요."
    }
  },
  "invalid": {
    "required": "위 항목은 필수 입력입니다.",
    "only-type": "{type} 타입만 입력가능합니다.",
    "min": "최소 {min}자이상 입력해 주세요.",
    "max": "최대 {max}자 까지 입력가능합니다.",
    "duplicatedName": "중복된 이름입니다.",
    "underscoresAlpha": "영문자와 밑줄(_)로만 구성되어야 합니다."
  },
  "fileMessages": {
    "conflict": "폴더 혹은 파일이 이미 존재합니다.",
    "invalidName": "사용할 수 없는 문자가 포함되어 있습니다.",
    "clipboardCopySuccess": "{cnt}개 항목이 클립보드에 복사되었습니다.",
    "clipboardCutSuccess": "{cnt}개 항목을 클립보드에 잘라넣기를 하였습니다.",
    "moveSuccess": "{cnt}개 항목이 이동되었습니다.",
    "copySuccess": "{cnt}개 항목을 복사하였습니다.",
    "deleteSuccess": "{cnt}개 항목을 삭제하였습니다.",
    "noSelectedItem": "선택된 항목이 없습니다.",
    "history": {
      "copy": "복사되었습니다.",
      "copy+override": "복사 및 덮어쓰기가 되었습니다.",
      "copy+rename": "복사 및 이름이 변경되었습니다.",
      "move": "이동되었습니다.",
      "move+override": "이동 및 덮어쓰기가 되었습니다.",
      "move+rename": "이동 및 이름이 변경되었습니다.",
      "edit": "수정되었습니다.",
      "rename": "이름이 변경되었습니다.",
      "undefined": "-"
    }
  },
  "message": {
    "inValidFileName": "사용할 수 없는 문자가 포함되어 있습니다.",
    "multiTask": "{cnt}개 항목을 {action}하였습니다.",
    "folderTask": "폴더를 {action}하였습니다.",
    "fileTask": "파일을 {action}하였습니다.",
    "linkCopied": "링크가 복사되었습니다!",
    "createFail": "생성에 실패하였습니다.",
    "favoriteActionSuccess": "즐겨찾기가 {action}되었습니다.",
    "favoriteActionFail": "즐겨찾기 {action}에 실패했습니다.",
    "conflictNameMsg": "이미 같은 이름이 존재합니다.",
    "forbiddenMsg": "{action} 권한이 없습니다.",
    "updateOnlyCreatorMsg": "작성자 본인만 설정할 수 있습니다.",
    "updateWarnAdminMsg": "작성자 본인만 설정할 수 있으나, 관리자 권한은 설정 가능합니다.",
    "passwordForbiddenMsg": "현재 비밀번호가 올바르지 않거나 권한이 없습니다.",
    "actionNewFolder": "폴더 생성",
    "actionNewFile": "파일 생성",
    "actionAdd": "추가",
    "actionDelete": "삭제",
    "actionModify": "수정",
    "actionRemove": "제거",
    "loading": "불러오는 중",
    "initializing": "초기화 중"
  },
  "logsActions": {
    "register": "회원가입",
    "login":  "로그인",
    "logout": "로그아웃",
    "update": "정보 수정",
    "delete": "계정 삭제"
  },
  "logsStatus": {
    "unactivated": "계정 비활성 상태",
    "success": "성공",
    "fail": "실패"
  },
  "accessLevel": {
    "setting": "레벨 접근 권한",
    "title": "회원 레벨 접근 권한",
    "settingHelp": "최대 내 레벨까지 설정 가능",
    "forbiddenByLevelExplain": "{level} 레벨 이상의 접근 권한이 필요합니다. 관리자에게 요청하세요.",
    "forbiddenExplain": "접근 권한이 없습니다. 관리자에게 문의하세요.",
    "myLevel": "내 레벨",
    "myLevelHelp": "사용자가 폴더에 레벨별 접근 권한을 설정한 경우, 내 레벨이 필요합니다. 내 레벨은 관리자에 의해 1부터 10까지 설정될 수 있으며, 설정된 레벨에 따라 폴더에 접근할 수 있습니다."
  },
  "tableEditor": {
    "addTop": "상단에 추가",
    "addBottom": "하단에 추가",
    "addCopyTop": "맨 위에 복사 후 추가",
    "addCopyBottom": "맨 아래에 복사 후 추가",
    "addCopyAbove": "선택 위에 복사 후 추가",
    "addCopyBelow": "선택 아래에 복사 후 추가",
    "delRow": "선택 행 삭제",
    "modified": "수정됨",
    "addSchema": "스키마 생성",
    "convertor": "컨버터",
    "name": "이름"
  },
  "workflow": {
    "existWorksReady": "총 {cnt}개의 작업이 준비되었습니다.",
    "existWorksComplete": "총 {cnt}개의 작업을 완료하였습니다.",
    "overwriteFileCheckMsg": "같은 파일이 존재하면 덮어쓰기",
    "destinationPath": "저장될 폴더 설정",
    "cancelExe": "실행 중지",
    "genExecution": "제너레이터 실행",
    "createFolder": "폴더 생성",
    "createFile": "파일 생성"
  },
  "buttons": {
    "list": "목록",
    "add": "추가",
    "back": "뒤로",
    "cancel": "취소",
    "clear": "지우기",
    "close": "닫기",
    "continue": "계속",
    "copy": "복사",
    "copyFile": "파일 복사",
    "copyToClipboard": "클립보드 복사",
    "copyDownloadLinkToClipboard": "다운로드 링크 복사하기",
    "create": "생성",
    "delete": "삭제",
    "download": "다운로드",
    "file": "파일",
    "folder": "폴더",
    "fullScreen": "전체화면",
    "hideDotfiles": "숨김파일 숨기기",
    "info": "정보",
    "more": "더보기",
    "move": "이동",
    "moveFile": "파일 이동",
    "new": "신규",
    "next": "다음",
    "ok": "확인",
    "permalink": "링크 얻기",
    "previous": "이전",
    "publish": "게시",
    "rename": "이름 변경",
    "replace": "대체",
    "reportIssue": "이슈 보내기",
    "save": "저장",
    "schedule": "일정",
    "search": "검색",
    "select": "선택",
    "selectMultiple": "다중 선택",
    "share": "공유",
    "shell": "쉘 전환",
    "submit": "전송",
    "switchView": {
      "title": "보기 옵션",
      "list": "목록",
      "tiles": "타일",
      "gallery": "갤러리"
    },
    "toggleSidebar": "사이드바 전환",
    "update": "적용",
    "upload": "업로드",
    "openFile": "파일열기",
    "discardChanges": "버리기"
  },
  "level": {
    "bulkApply": "레벨 일괄 적용",
    "auth_list": "파일 목록 보기",
    "auth_view": "파일읽기 권한",
    "auth_write": "파일쓰기 권한",
    "above": "레벨 이상"
  },
  "download": {
    "downloadFile": "파일 다운로드",
    "downloadFolder": "폴더 다운로드",
    "downloadSelected": "선택된 다움로드"
  },
  "upload": {
    "abortUpload": "진행을 중단하시겠습니까?"
  },
  "errors": {
    "forbiddenLevel": "특정 레벨 이상만 접근 가능합니다.",
    "conflict": "이미 같은 내용이 존재합니다.",
    "forbidden": "접근 권한이 없습니다.",
    "internal": "오류가 발생하였습니다.",
    "notFound": "해당 경로를 찾을 수 없습니다.",
    "connection": "서버에 연결할 수 없습니다."
  },
  "files": {
    "body": "본문",
    "creator": "작성자",
    "explain": "설명",
    "closePreview": "미리보기 닫기",
    "files": "파일",
    "folders": "폴더",
    "levelFolders": "레벨 폴더",
    "home": "홈",
    "lastModified": "최종 수정",
    "loading": "로딩중...",
    "listEmpty": "업로드할 파일/폴더를 여기에 끌어다 놓으세요.",
    "lonely": "데이터가 없습니다.",
    "metadata": "메타데이터",
    "multipleSelectionEnabled": "다중 선택 켜짐",
    "name": "이름",
    "size": "크기",
    "countDirEntries": "항목 {cnt}개",
    "sortByLastCreated": "작성시간순 정렬",
    "sortByLastModified": "수정시간순 정렬",
    "sortByName": "이름순",
    "sortBySize": "크기순",
    "sortAsc": "오름차순",
    "sortDesc": "내림차순",
    "creatorBySize": "생성자순",
    "noPreview": "해당 파일은 미리보기를 할 수 없습니다.",
    "selected": "{0}개 선택됨",
    "storage" : {
      "title": "저장소",
      "usage": "{total} 중 {used} 사용 중"
    }
  },
  "content": {
    "noLatestFiles": "최근 사용된 파일이 없습니다."
  },
  "help": {
    "click": "파일이나 폴더를 선택",
    "ctrl": {
      "click": "여러 개의 파일이나 폴더를 선택",
      "f": "검색창 열기",
      "s": "파일 또는 폴더 다운로드"
    },
    "del": "선택된 파일 삭제",
    "doubleClick": "파일 또는 폴더 열기",
    "esc": "선택 취소/프롬프트 닫기",
    "f1": "정보",
    "f2": "파일 이름 변경",
    "help": "도움말",
    "n": "새 파일"
  },
  "permanent": "영구적",
  "prompts": {
    "copyToFolder": "폴더에 복사",
    "copyToMessage": "선택한 파일 또는 폴더를 복사할 대상 폴더를 선택하세요:",
    "currentlyNavigating": "현재 위치:",
    "deleteMessageMultiple": "{count} 개의 파일을 삭제하시겠습니까?",
    "deleteMessageSingle": "정말 삭제하시겠습니까?",
    "deleteMessageShare": "'{path}' 공유를 제거하시겠습니까?",
    "deleteUser": "해당 사용자를 삭제하시겠습니까?",
    "deleteTitle": "파일 삭제",
    "displayName": "게시 이름",
    "download": "파일 다운로드",
    "downloadMessage": "다운로드할 압축 포맷을 선택해 주세요.",
    "error": "에러 발생!",
    "folderInfo": "폴더 정보",
    "fileInfo": "파일 정보",
    "fileHistory": "변경 기록",
    "fileDetailInfo": "파일 세부 정보",
    "fileOptions": "파일 보기 옵션",
    "filesSelected": "{count}개의 파일이 선택되었습니다.",
    "itemSelected": "항목 {count}개 선택됨",
    "lastModified": "최종 수정",
    "move": "이동",
    "moveMessage": "이동할 파일 또는 폴더를 선택하세요:",
    "newArchetype": "원형을 유지하는 포스트를 생성합니다. 파일은 컨텐트 폴더에 생성됩니다.",
    "newDir": "새 폴더",
    "newDirMessage": "새 폴더 이름을 입력해주세요.",
    "newFile": "새 파일",
    "newSchemaFile": "새 스키마 (TPL)",
    "screenRecorder": "스크린 녹화",
    "newFileMessage": "새 파일 이름을 입력해주세요.",
    "numberDirs": "폴더 수",
    "numberFiles": "파일 수",
    "rename": "이름 변경",
    "renameMessage": "변경할 이름을 입력하세요.",
    "replace": "대체하기",
    "replaceMessage": "동일한 파일 이름이 존재합니다. 현재 파일을 덮어쓸까요?\n",
    "schedule": "일정",
    "scheduleMessage": "이 글을 공개할 시간을 알려주세요.",
    "show": "보기",
    "size": "크기",
    "upload": "업로드",
    "uploadFiles": "{files} 파일 업로드 중..",
    "uploadMessage": "업로드할 옵션을 선택하세요.",
    "optionalPassword": "비밀번호 선택",
    "resolution": "해상도",
    "discardEditorChanges": "수정한 내용을 무시하겠습니까?"
  },
  "search": {
    "images": "이미지",
    "music": "음악",
    "pdf": "PDF",
    "searchNoResult": "검색결과가 없습니다.",
    "searchPath": "검색 위치",
    "fileSearch": "파일명 검색",
    "typeToSearch": "검색어 입력",
    "types": "타입",
    "video": "비디오",
    "md": "마크다운"
  },
  "uploadFile": {
    "remaining": "남음",
    "calculating": "남은 시간 계산중"
  },
  "settings": {
    "sort": "정렬",
    "currentFolder": "폴더 설정",
    "defaultSwitchView": "폴더 보기 옵션",
    "admin": "관리자",
    "administrator": "관리자",
    "allowCommands": "명령 실행",
    "allowEdit": "파일/폴더의 수정/변경/삭제 허용",
    "allowNew": "파일/폴더 생성 허용",
    "allowPublish": "새 포스트/페이지 생성 허용",
    "allowSignup": "회원가입 허용",
    "avoidChanges": "(수정하지 않으면 비워두세요)",
    "changePassword": "비밀번호 변경",
    "commandRunner": "명령 실행기",
    "commandRunnerHelp": "이벤트에 해당하는 명령을 설정하세요. 줄당 1개의 명령을 적으세요. 환경 변수{0} 와 {1}이 사용가능하며,  {0} 은 {1}에 상대 경로 입니다. 자세한 사항은 {2} 를 참조하세요.",
    "commandsUpdated": "명령 수정됨!",
    "createUserDir": "새 사용자 추가 시 자동으로 사용자 홈 폴더 생성",
    "tusUploads": "분할 업로드",
    "tusUploadsChunkSize": "한 번에 업로드하는 데이터의 크기를 설정합니다. 크기가 클수록 업로드 속도가 빨라질 수 있지만, 네트워크 상태나 서버의 처리 능력(동시 접속자 수, 대역폭 등)에 따라 업로드 실패 확률이 높아질 수 있습니다. '10MB', '1GB'와 같은 문자열이나 바이트 단위의 숫자를 입력할 수 있습니다.",
    "tusUploadsRetryCount": "업로드가 실패했을 때 다시 시도하는 횟수를 설정합니다.",
    "userScopeGenerationPlaceholder": "입력이 없으면 자동으로 생성됩니다.",
    "createUserHomeDirectory": "사용자 홈 폴더 생성",
    "customStylesheet": "커스텀 스타일시트",
    "defaultUserDescription": "신규 가입자의 권한, 레벨 등은 아래 설정된 기본값으로 자동 적용됩니다.",
    "disableUsedDiskPercentage": "사용된 디스크 사용량 그래프 비활성화",
    "documentation": "문서",
    "examples": "예제",
    "hideDotfiles": "숨김 파일 숨기기",
    "insertPath": "경로 입력",
    "insertRegex": "정규식 입력",
    "instanceName": "인스턴스 이름",
    "language": "언어",
    "newPassword": "변경할 비밀번호",
    "newPasswordConfirm": "변경할 비밀번호 확인",
    "newUser": "신규 사용자",
    "password": "비밀번호",
    "passwordUpdated": "비밀번호 수정 완료!",
    "path": "경로",
    "perm": {
      "list": "파일 목록 보기",
      "create": "파일/폴더 생성하기",
      "delete": "파일/폴더 삭제하기",
      "rename": "이름 변경 및 이동",
      "download": "다운로드",
      "execute": "명령 실행",
      "modify": "파일 내용 편집",
      "view": "파일 내용 보기",
      "share": "파일 공유하기"
    },
    "permissions": "권한",
    "adminPermHelp": "회원 관리 및 파일 권한을 포함한 모든 권한이 활성화됩니다.",
    "permissionsHelp": "기본 권한을 설정합니다. 폴더별로 세부 권한은 폴더 생성 후 레벨에 따라 추가로 설정할 수 있습니다.",
    "profileSettings": "프로필 설정",
    "ruleExample1": "점(.)으로 시작하는 모든 파일의 접근을 방지합니다.(예 .git, .gitignore)\n",
    "ruleExample2": "Caddyfile파일의 접근을 방지합니다.",
    "rules": "규칙",
    "rulesHelp": "사용자별로 규칙을 허용/방지를 지정할 수 있습니다. 방지된 파일은 보이지 않고 사용자들은 접근할 수 없습니다. 사용자의 접근 허용 범위와 관련해 정규표현식(regex)과 경로를 지원합니다.\n",
    "scope": "경로",
    "setDateFormat": "정확한 날짜 형식 설정",
    "settingsUpdated": "설정 적용됨!",
    "shareDuration": "공유 기간",
    "shareManagement": "공유 관리",
    "shareDeleted": "공유에서 제거되었습니다",
    "shareAdded": "공유에 추가하였습니다.",
    "singleClick": "한번 클릭으로 파일 및 폴더 열기 사용",
    "themes": {
      "default": "시스템",
      "dark": "어둡게",
      "light": "밝게",
      "title": "테마"
    },
    "user": "사용자",
    "userCommands": "명령어",
    "userCommandsHelp": "사용에게 허용할 명령어를 공백으로 구분하여 입력하세요. 예:\n",
    "userCreated": "사용자 생성됨!",
    "userDefaults": "사용자 기본 설정",
    "userDeleted": "사용자 삭제됨!",
    "userManagement": "사용자 관리",
    "userUpdated": "사용자 수정됨!",
    "username": "사용자 이름",
    "nick": "닉네임",
    "users": "사용자",
    "isActive": "활성화",
    "isActiveDefaultHelp": "가입 즉시 계정을 활성화하여 로그인할 수 있도록 합니다.",
    "level": "레벨",
    "currentPassword": "현재 비밀번호",
    "status": "상태",
    "action": "활동",
    "timestamp": "일시"
  },
  "sidebar": {
    "help": "도움말",
    "hugoNew": "새 콘텐츠",
    "login": "로그인",
    "logout": "로그아웃",
    "myFiles": "내 파일",
    "teamFolder": "팀 폴더",
    "newFile": "새 파일",
    "newFolder": "새 폴더",
    "preview": "미리보기",
    "settings": "설정",
    "signup": "가입하기",
    "siteSettings": "사이트 설정",
    "myContent": "내 콘텐츠",
    "make": "만들기",
    "favFolder": "즐겨 찾기",
    "latestFolder": "최근 문서"
  },
  "adminMenu": {
    "home": "홈",
    "authLogs": "접속 기록",
    "users": "사용자 관리",
    "configSettings": "환경 설정",
    "globalSettings": "전역 설정"
  },
  "pageMenu": {
    "home": "홈",
    "ai": "AI",
    "team": "팀"
  },
  "time": {
    "days": "일",
    "hours": "시",
    "hours2": "시간",
    "minutes": "분",
    "seconds": "초",
    "unit": "시간단위"
  }
}
