<script setup lang="ts">
import { useFileStore } from "@/stores/file";
import { useFilesLayoutStore } from "@/stores/filesLayout";
import { changeResource } from "@/views/files/listing/fetchResource";
import FileItemsSection from "@/views/files/listing/FileItemsSection.vue";
import SortingColumn from "@/views/files/listing/SortingColumn.vue";
import MultiCheckBtn from "@/views/files/menu/MultiCheckBtn.vue";
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";

const filesLayoutStore = useFilesLayoutStore();
const fileStore = useFileStore();

const route = useRoute();
const { t } = useI18n();

const nameSorted = computed(() =>
  fileStore.req ? fileStore.req.listInfo?.sorting?.by === "name" : false
);

const creatorSorted = computed(() =>
  fileStore.req ? fileStore.req.listInfo?.sorting?.by === "creator" : false
);

const sizeSorted = computed(() =>
  fileStore.req ? fileStore.req.listInfo?.sorting?.by === "size" : false
);

const modifiedSorted = computed(() =>
  fileStore.req ? fileStore.req.listInfo?.sorting?.by === "modified" : false
);

const ascOrdered = computed(() => {
  if (fileStore.req) {
    if (fileStore.req.listInfo?.sorting?.asc === true) return true;
  }
  return false;
});

const sort = (by: string, event?: Event) => {
  // if (event) {
  //   event.stopPropagation();
  //   event.preventDefault();
  // }

  let asc: boolean | null = null;

  if (by === sortingState.value.by) {
    // 현재 정렬 기준과 동일하면 방향만 변경
    asc = !sortingState.value.asc;
  } else {
    // 새로운 기준으로 정렬할 경우 기본 오름차순 설정
    asc = true;
  }

  sortingState.value = { by, asc };

  // URL에 정렬 조건 반영
  const sortOrder = asc ? "1" : "0";
  // URL 상단에 필요한 파라미터 추가하기
  let pageUrl = `?limit=${fileStore.req.listInfo.limit}&offset=${fileStore.req.listInfo.offset}`;
  if (by && sortOrder) {
    pageUrl += `&sort=${by}&asc=${sortOrder}`;
  }
  console.log(pageUrl, sortingState, asc, event);
  changeResource(route, fileStore, pageUrl);
};

const sortingState = ref({
  by: fileStore.req.listInfo?.sorting?.by,
  asc: fileStore.req.listInfo?.sorting?.asc,
});

const cssFolderViewMode = computed(() => {
  const def = fileStore.req?.metaInfo?.viewMode;
  const tmpMode = filesLayoutStore.folderViewMode;
  if (!def && !tmpMode) {
    return "list";
  }
  if (tmpMode) {
    return tmpMode;
  }
  return def;
});
</script>

<template>
  <div :class="['file-lists', cssFolderViewMode ?? '']">
    <div>
      <div class="item header">
        <MultiCheckBtn class="file-icon" />
        <div>
          <SortingColumn
            class-name="filename"
            :sorted="nameSorted"
            :asc-ordered="ascOrdered"
            :title="t('files.sortByName')"
            @click.stop="sort('name', $event)"
          >
            <span>{{ t("files.name") }}</span>
          </SortingColumn>

          <SortingColumn
            class-name="size"
            :sorted="sizeSorted"
            :asc-ordered="ascOrdered"
            :title="t('files.sortBySize')"
            @click.stop="sort('size', $event)"
          >
            <span>{{ t("files.size") }}</span>
          </SortingColumn>

          <SortingColumn
            class-name="creator"
            :sorted="creatorSorted"
            :asc-ordered="ascOrdered"
            :title="t('files.sortBySize')"
            @click.stop="sort('creator', $event)"
          >
            <span>{{ t("files.creator") }}</span>
          </SortingColumn>

          <SortingColumn
            class-name="modified"
            :sorted="modifiedSorted"
            :asc-ordered="ascOrdered"
            :title="t('files.sortByLastModified')"
            @click.stop="sort('modified', $event)"
          >
            <span>{{ t("files.lastModified") }}</span>
          </SortingColumn>
        </div>
      </div>
    </div>
    <FileItemsSection class="item-body" />
  </div>
</template>
