<script setup lang="ts">
import { files as api } from "@/api";
import {
  getFileTaskSuccessMsg,
  showSuccess,
} from "@/class/FileTaskToastMessage";
import AiaModalContainer from "@/components/dialog/AiaModalContainer.vue";
import AiaButton from "@/components/form/AiaButton.vue";
import { useFileStore } from "@/stores/file";
import { useLayoutStore } from "@/stores/layout";
import * as upload from "@/utils/upload";
import ReplaceRename from "@/views/files/modals/ReplaceRename.vue";
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import { toast } from "vue3-toastify";
import FileList from "./FileList.vue";

const { t } = useI18n({});

const current = ref(window.location.pathname);
const dest = ref<string | null>(null);
const router = useRouter();

const fileStore = useFileStore();
const layoutStore = useLayoutStore();
const route = useRoute();
const conflictError = ref("");
const isLoading = ref(false);

let dstItems = []; // 목적 폴더의 파일들

let renameReqItems: { name: string; id: number }[] = []; // 원본 파일
const copy = async (event: Event, overwrite = false, rename = false) => {
  if (event) event.preventDefault();

  let isFirstOneDir = false;
  const items = [];
  const reqItems: { name: string; id: number }[] = []; // 원본 파일

  for (const idNo of fileStore.selected) {
    isFirstOneDir = fileStore.req?.items[idNo].isDir;
    const name = fileStore.req?.items[idNo].name;
    items.push({
      from: fileStore.req?.items[idNo].url,
      to: dest.value + encodeURIComponent(name),
      name: name,
    });
    reqItems.push({
      id: idNo,
      name: name,
    });
  }

  const action = async (overwrite: boolean, rename: boolean) => {
    isLoading.value = true;
    try {
      await api.copy(items, overwrite, rename);
      await router.push({ path: dest.value as string });
      // setTimeout(() => {
      const successMsg = getFileTaskSuccessMsg(
        items.length,
        t("buttons.copy"),
        isFirstOneDir
      );
      showSuccess(successMsg);
      // }, 1000);
    } catch (e) {
      toast.error(e);
    }
    isLoading.value = false;
  };

  if (!overwrite) {
    dstItems = (await api.fetch(dest.value as string)).items;
    // const conflict = upload.checkConflict(items, dstItems);
    const { isConflict, reFiles } = upload.checkConflictAndRename(
      reqItems,
      dstItems
    );

    if (isConflict) {
      renameReqItems = reFiles;
      conflictError.value = t("prompts.replaceMessage");
      return;
    }
  }

  await action(overwrite, rename);
};

const onReplaceRename = (nm: string) => {
  const result = null;
  if (nm === "overwrite") {
    copy(null, true);
  } else if (nm === "rename") {
    // 이름 변경
    for (const item of renameReqItems) {
      fileStore.req.items[item.id].name = item.name;
    }
    renameReqItems = [];
    copy(null, false, true);
  }
};
const selectFileList = val => {
  dest.value = val;
};
</script>
<template>
  <div v-if="conflictError">
    <ReplaceRename @action="onReplaceRename" />
  </div>
  <AiaModalContainer
    v-else
    class="md:w-[500px]"
    @close="layoutStore.closeHovers"
  >
    <template #title>{{ t("prompts.copyToFolder") }}</template>
    <template #body>
      <p>{{ t("prompts.copyToMessage") }}</p>
      <div class="flex justify-items-start w-full">
        <div></div>
        <div class="flex-grow">
          <file-list
            ref="fileList"
            v-model:selected="current"
            tabindex="1"
            @update:selected="selectFileList"
          />
        </div>
      </div>
      <div class="flex items-center justify-between my-4">
        <div></div>
        <AiaButton
          :disabled="route.path === dest"
          :aria-label="t('buttons.copy')"
          :title="t('buttons.copy')"
          :loading="isLoading"
          tabindex="2"
          @click="copy"
        >
          {{ t("buttons.copy") }}
        </AiaButton>
      </div>
    </template>
  </AiaModalContainer>
</template>
