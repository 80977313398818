<template>
  <div class="min-w-[300px]">
    <div class="px-5 py-2 rounded-xl mb-10">
      <div v-if="historyInfo && historyInfo.length > 0">
        <FileHistoryItem :history="historyInfo" />
        <div class="pt-5">
          <FavoritePagination
            :key="paginationKey"
            :page-info="pagination.pageInfo"
            size="small"
            @on-page="pagination.goPage"
          />
        </div>
      </div>
      <div v-else class="text-center text-gray-500/70 dark:text-gray-300/70">
        <div v-if="isLoading">
          <div class="dot-loading text-center py-2">
            Loading <span class="w-6 inline-block dots"></span>
          </div>
        </div>
        <div v-else>
          <span v-if="selectedCount === 0">{{
            t("fileMessages.noSelectedItem")
          }}</span>
          <span v-else>{{ t("files.lonely") }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { fetchMetaHistory } from "@/api/metadata";
import { HistoryItem, PageListInfo } from "@/class/interface/drive/metadata";
import { usePagination } from "@/composable/usePagination";
import { useFileStore } from "@/stores/file";
import FavoritePagination from "@/views/drive/latest/FavoritePagination.vue";
import FileHistoryItem from "@/views/files/sidebar/FileSidebarHistoryItem.vue";
import { computed, nextTick, onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";

const { t } = useI18n();
defineProps<{
  selectedCount: number;
}>();

const route = useRoute();
const fileStore = useFileStore();
const isLoading = ref(false);
const req = computed(() => fileStore.req);
const selected = computed(() => fileStore.selected);
const selectedCount = computed(() => fileStore.selectedCount);

const historyInfo = ref<HistoryItem[]>(null);
const paginationKey = ref("pk");
const pagination = usePagination(async (limit, offset) => {
  try {
    await fetchFileInfoHistory(limit, offset);
    paginationKey.value = "K" + new Date().getTime();
  } catch (e) {}
});
onMounted(() => {
  const query = route.query;

  // const limit = parseInt(query.limit as string) || pageInfo.value.limit;
  // const offset = parseInt(query.offset as string) || pageInfo.value.offset;
  const limit = parseInt(query.limit as string);
  const offset = parseInt(query.offset as string);

  nextTick(async () => {
    try {
      await fetchFileInfoHistory(limit, offset);
    } catch (e) {}
  });
});

/**
 * 파일 메타 정보 가져오기
 */
const fetchFileInfoHistory = async (limit?: number, offset?: number) => {
  isLoading.value = true;

  let folderPath = null;
  if (selected.value.length === 1) {
    const selectedItem = req.value.items[selected.value[0]];
    folderPath = selectedItem.path;
  } else if (selected.value.length === 0) {
    folderPath = req.value?.path;
  }

  if (folderPath && folderPath !== "/") {
    try {
      const data = await fetchMetaHistory(folderPath, limit, offset);
      historyInfo.value = data["data"];
      pagination.pageInfo = data["pageInfo"] as PageListInfo;
    } catch (e) {
    } finally {
      isLoading.value = false;
    }
  } else {
    isLoading.value = false;
  }
};
watch(selected, newValue => {
  if (newValue) {
    historyInfo.value = null;
    fetchFileInfoHistory();
  }
});
</script>
