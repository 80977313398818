<template>
  <!--  <div :class="{ active }">-->
  <div class="sidebar-wrap">
    <!--    <div class="mb-5"></div>-->
    <WorkCreateBtnMenu class="mk-btn" />

    <div class="sidebar-content scroll-s-project">
      <template v-if="isLoggedIn">
        <h2 class="font-display font-medium text-slate-400 dark:text-white">
          Team Space
        </h2>
        <ul
          class="mt-2 flex-column space-y-2 text-sm text-gray-500 dark:text-gray-400"
          style="margin-left: -19px"
        >
          <li class="pb-1">
            <DirectoryTreeItem
              :data="directoryData"
              :max-depth="0"
              :can-select="true"
              :select="selectedPath"
              is-root
              @selected="handleDirectorySelected"
            >
              <a href="#" class="menu-label" aria-current="page">
                <PhHouse :size="20" weight="thin" />
                <span>{{ t("sidebar.teamFolder") }}</span>
              </a>
            </DirectoryTreeItem>
          </li>
        </ul>
        <ul
          class="flex-column space-y space-y-2 text-sm text-gray-500 dark:text-gray-400"
        >
          <li
            :aria-label="t('sidebar.latestFolder')"
            :title="t('sidebar.latestFolder')"
            @click="toHomeMenu('latest')"
          >
            <a href="#" class="menu-label plain" aria-current="page">
              <PhClockCounterClockwise :size="20" weight="thin" />
              <span>{{ t("sidebar.latestFolder") }}</span>
            </a>
          </li>
        </ul>

        <h2
          class="mt-4 font-display font-medium text-slate-400 dark:text-white"
        >
          My Space
        </h2>
        <ul
          class="mt-3 flex-column space-y space-y-2 text-sm text-gray-500 dark:text-gray-400"
        >
          <li
            :aria-label="t('sidebar.favFolder')"
            :title="t('sidebar.favFolder')"
            @click="toHomeMenu('favorite')"
          >
            <a href="#" class="menu-label plain" aria-current="page">
              <PhFolderStar :size="20" weight="thin" />
              <span>{{ t("sidebar.favFolder") }}</span>
            </a>
          </li>

          <li
            :aria-label="t('settings.shareManagement')"
            :title="t('settings.shareManagement')"
            @click="toHomeMenu('share-list')"
          >
            <a href="#" class="menu-label plain" aria-current="page">
              <ph-folder-simple-user :size="20" weight="thin" />
              <span>{{ t("settings.shareManagement") }}</span>
            </a>
          </li>
        </ul>
      </template>
    </div>
  </div>

  <div class="reactive w-full px-3 mx-auto whitespace-nowrap">
    <div
      v-if="layoutStore.initMessage?.length === 0"
      class="quota mx-auto font-medium"
    >
      <div v-if="usage?.total !== ''" class="pt-3">
        <progress-bar :val="usage.usedPercentage" size="small"></progress-bar>
        <div class="pt-1 font-sans text-xs text-gray-500 dark:text-gray-300">
          {{
            t("files.storage.usage", { total: usage.total, used: usage.used })
          }}
        </div>
      </div>
    </div>
    <div v-else>
      <InitMessageBar>
        <PhPlug :size="14" weight="thin" />
        <div class="dot-loading">
          {{ t("message.initializing") }}
          <span class="w-6 inline-block dots"></span>
        </div>
      </InitMessageBar>
    </div>
  </div>
</template>

<script setup lang="ts">
import { files as api } from "@/api";
import { fetchDirectoryData } from "@/api/files";
import { Directory } from "@/class/interface/drive/file";
import DirectoryTreeItem from "@/components/files/DirectoryTreeItem.vue";
import InitMessageBar from "@/components/InitMessageBar.vue";
import ProgressBar from "@/components/ProgressBar.vue";
import { router } from "@/routes";
import { useAuthStore } from "@/stores/auth";
import { useFileStore } from "@/stores/file";
import { useLayoutStore } from "@/stores/layout";
import { Directories, version } from "@/utils/constants";
import WorkCreateBtnMenu from "@/views/files/menu/WorkCreateBtnMenu.vue";
import {
  PhClockCounterClockwise,
  PhFolder,
  PhFolderSimpleUser,
  PhFolderStar,
  PhHouse,
  PhPlug,
} from "@phosphor-icons/vue";
import prettyBytes from "pretty-bytes";
import { computed, onMounted, reactive, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { toast } from "vue3-toastify";
import { useRoute } from "vue-router";

const { t } = useI18n();
const route = useRoute();
// 스토어

const layoutStore = useLayoutStore();
const authStore = useAuthStore();
const fileStore = useFileStore();
// const layoutStore = useLayoutStore();

// 반응형 상태 정의
// 상수 정의
const USAGE_DEFAULT = { used: "", total: "", usedPercentage: 0 };
const usage = reactive(USAGE_DEFAULT);

// 컴포넌트 속성
const isLoggedIn = computed(() => authStore.isLoggedIn);
const isFiles = computed(() => fileStore.isFiles);
const isListing = computed(() => fileStore.reload);

const fetchUsage = async () => {
  // const path = window.location.pathname.endsWith('/')
  //   ? window.location.pathname
  //   : window.location.pathname + '/';
  let usageStats = { ...USAGE_DEFAULT };
  try {
    // const usage = await api.usage(path);
    const usage = await api.usage("/");
    usageStats = {
      used: prettyBytes(usage.used, { binary: true }),
      total: prettyBytes(usage.total, { binary: true }),
      usedPercentage: Math.round((usage.used / usage.total) * 100),
    };
  } catch (error) {
    toast.error(String(error));
  }
  Object.assign(usage, usageStats);
};

const toHomeMenu = sub => {
  // window.location.assign('/set/share-list');
  router.push({ path: `/page/${sub}` });
};

const directoryData = ref<Directory | null>(null);
const selectedPath = ref("");

const updateSelectedPath = () => {
  const drivePrefix = Directories.base;
  if (route.fullPath.startsWith(drivePrefix)) {
    selectedPath.value = decodeURIComponent(
      route.fullPath.substring(drivePrefix.length)
    );
  } else {
    selectedPath.value = decodeURIComponent(route.fullPath);
  }

  if (selectedPath.value.endsWith("/")) {
    selectedPath.value = selectedPath.value.slice(0, -1);
  }
};

const fetchTree = async function () {
  try {
    directoryData.value = (await fetchDirectoryData()) as unknown as Directory;
  } catch (error) {
    toast.error(error);
  }
};

onMounted(() => {
  fetchTree();
  updateSelectedPath();
});
watch(isFiles, newValue => {
  newValue && fetchUsage();
});
watch(isListing, newValue => {
  newValue && fetchTree();
});
watch(
  () => route.fullPath,
  () => {
    updateSelectedPath();
  }
);
const handleDirectorySelected = (directory: Directory) => {
  const path = directory.path;
  router.push({ path: Directories.base + path });
  selectedPath.value = path;
};
</script>
<style lang="scss" scoped>
.sidebar-wrap {
  padding: 30px 0;

  .mk-btn {
    padding: 0 35px;
  }

  .sidebar-content {
    height: calc(var(--file-content-height) - 40px);
    overflow-y: auto;
    padding: 0 35px;
  }

  .menu-label {
    @apply whitespace-nowrap line-clamp-1 overflow-ellipsis
    inline-flex items-center w-full space-x-2;
    &.plain {
      @apply text-black dark:text-gray-200;
    }
    svg {
      @apply min-w-6;
    }
  }

  .quota {
    //font-size: 0.8em;
    width: 90%;
    padding: 0 35px;
    font-size: 12px;
    margin: 3em 1em;
    color: #777;
    > span {
      display: block;
      margin: 0.1em 0;
    }
    a,
    a:hover {
      color: inherit;
      cursor: pointer;
    }
  }
}
</style>
