<template>
  <div class="file-select-nav py-4">
    <div class="flex items-center">
      <Breadcrumbs :base="Directories.base" />
      <action
        icon="info"
        :label="$t('prompts.fileOptions')"
        show="file-options"
      >
        <template #icon>
          <PhGearSix :size="20" weight="thin" />
        </template>
      </action>
    </div>
    <div class="hidden md:block">
      <FixedActionBar @action="onActionBar" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import Action from "@/components/files/Action.vue";
import Breadcrumbs from "@/views/files/Breadcrumbs.vue";
import FixedActionBar from "@/views/files/menu/FixedActionBar.vue";
import { Directories } from "@/utils/constants";
import { PhGearSix } from "@phosphor-icons/vue";

const onActionBar = funcName => {
  console.log("funcName", funcName);
};
</script>
