<script setup lang="ts">
import { fetchMetadata, updateMetadata } from "@/api/metadata";
import { removePrefixUrl } from "@/api/utils";
import { ViewModeType } from "@/class/interface/drive/file";
import { LevelPerm, Metadata } from "@/class/interface/drive/metadata";
import AiaModalContainer from "@/components/dialog/AiaModalContainer.vue";
import LevelNumberInput from "@/components/files/LevelNumberInput.vue";
import AiaButton from "@/components/form/AiaButton.vue";
import { useAuthStore } from "@/stores/auth";
import { useFileStore } from "@/stores/file";
import { useLayoutStore } from "@/stores/layout";
import FileLevelInfo from "@/views/files/FileLevelInfo.vue";
import { PhInfo } from "@phosphor-icons/vue";
import { computed, nextTick, onMounted, reactive, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import { toast } from "vue3-toastify";

const { t } = useI18n();
const layoutStore = useLayoutStore();
const authStore = useAuthStore();
const fileStore = useFileStore();
const route = useRoute();

const metadata = ref<Metadata>(null);
const levelPerm = ref<LevelPerm>({
  list: 0, // 폴더에서 파일 목록
  create: 0, // 파일/폴더 생성하기
  delete: 0, // 파일/폴더 삭제하기
  download: 0, // 다운로드
  modify: 0, // 파일 편집
  view: 0, // 파일 내용 보기
  rename: 0, // 파일 이름 변경 또는 폴더 이동
  share: 0, // 파일 공유하기
  execute: 0, // 명령 실행
});

const levelBulkApply = ref<number | string>("-");
function updateLevelPerm(value: number | string) {
  if (typeof value === "number") {
    levelPerm.value = {
      list: value,
      create: value,
      delete: value,
      download: value,
      modify: value,
      view: value,
      rename: value,
      share: value,
      execute: value,
    };
  }
}
const areAllLevelEqual = (obj: any = null): boolean => {
  if (!obj) obj = levelPerm.value;
  const values = Object.values(obj);
  return values.every(value => value === values[0]);
};

const onChangedLevel = (value: any) => {
  // console.log("onChangedLevel", !areAllLevelEqual(), value);
  nextTick(() => {
    if (!areAllLevelEqual()) {
      levelBulkApply.value = "-";
    }
  });
};

const closeHovers = () => {
  layoutStore.closeHovers();
};
// 보기 옵션
const viewOptionValue = ref<ViewModeType>("list"); // 기본값을 'list'로 설정

// const globalHideDotfiles = ref<boolean>(false);
// const globalDateFormat = ref<boolean>(false);
const hideDotfiles = ref<boolean>(false);
const dateFormat = ref<boolean>(false);
const sorting = reactive<Sorting>({
  by: "name",
  asc: true,
});
const path = computed<string>(() => {
  const folder = removePrefixUrl(route.path);
  // let folder = route.path.replace(Directories.base, '')
  // if (!folder.endsWith('/')) folder += '/'
  return folder;
});

onMounted(() => {
  // globalHideDotfiles.value = authStore.user.hideDotfiles;
  // globalDateFormat.value = authStore.user.dateFormat;

  initMetadata();
  if (!areAllLevelEqual()) {
    levelBulkApply.value = "-";
  }
  return true;
});

const isCreator = computed(() => {
  if (metadata.value) {
    if (metadata.value.uid != authStore.user.id) return false;
  }
  //metadata가 없으면 생성할 수 있게
  return true;
});

const nav = computed(() => decodeURIComponent(path.value));

const initMetadata = async () => {
  const data = await fetchMetadata(path.value);
  if (data && data.isDir) {
    metadata.value = data;
    levelPerm.value = data.levelPerm;
    viewOptionValue.value = data.viewMode;
    hideDotfiles.value = data.hideDotfiles;
    dateFormat.value = data.dateFormat;
    if (data.sorting) {
      sorting.by = data.sorting.by;
      sorting.asc = data.sorting.asc;
    }
  }
};

const updateSettings = async (event: Event) => {
  event.preventDefault();
  layoutStore.loading = true;
  try {
    if (authStore.user === null) throw new Error("User is not set!");

    const data: Metadata = {
      levelPerm: levelPerm.value,
      viewMode: viewOptionValue.value,
      hideDotfiles: hideDotfiles.value,
      dateFormat: dateFormat.value,
      sorting: sorting,
      rules: [],
    };

    // /api/metadata 엔드포인트로 업데이트 요청 전송
    const res = await updateMetadata(path.value, data);
    if (res) {
      // 업데이트가 성공하면 유저 정보 및 인터페이스 업데이트
      authStore.updateUser({ ...authStore.user, ...data });
      toast.success(t("settings.settingsUpdated"));
      fileStore.reload = true;
    }

    closeHovers();
  } catch (err) {
    if (err instanceof Error) {
      toast.error(String(err));
    } else {
      toast.error(t("message.updateOnlyCreatorMsg"));
    }
  } finally {
    layoutStore.loading = false;
  }
};

const currentMenu = ref("1");
const handleSelectMenu = idx => {
  currentMenu.value = idx;
};
</script>
<template>
  <AiaModalContainer @close="layoutStore.closeHovers">
    <template #title
      >{{ t("settings.currentFolder") }}
      <span class="text-xs font-normal ml-2">{{ nav }}</span>
    </template>
    <template #body>
      <div class="flex w-[400px] md:w-[500px] md:min-h-[300px]">
        <!-- 왼쪽 메뉴 -->
        <aside class="w-4/12 border-r">
          <ul class="">
            <li
              :class="{ selected: currentMenu === '1' }"
              @click="handleSelectMenu('1')"
            >
              {{ t("accessLevel.setting") }}
            </li>
            <li
              :class="{ selected: currentMenu === '2' }"
              @click="handleSelectMenu('2')"
            >
              {{ t("settings.defaultSwitchView") }}
            </li>

            <!--            <li @click="handleSelectMenu('3')" :class="{selected:currentMenu==='3'}">-->
            <!--              Folder Automation-->
            <!--            </li>-->

            <!--            <li  @click="handleSelectMenu('3')" :class="{selected:currentMenu==='3'}">-->
            <!--                {{ t('settings.globalSettings') }}-->
            <!--            </li>-->
          </ul>
        </aside>

        <!-- 오른쪽 설정 내용 -->
        <div class="pl-6 w-8/12">
          <!-- 레벨 -->
          <div v-if="currentMenu === '1'" class="w-full">
            <h3
              class="mb-2 text-sm font-medium text-gray-900 dark:text-white flex justify-between"
            >
              <span
                >{{ t("accessLevel.myLevel") }} :
                {{ authStore.user?.level }}</span
              >
              <span class="small text-gray-500 dark:text-gray-300 p-1"
                >* {{ t("accessLevel.settingHelp") }}</span
              >
            </h3>

            <table class="level-setting">
              <thead></thead>
              <tbody>
                <tr>
                  <th class="bg">{{ t("level.bulkApply") }}</th>
                  <td>
                    <LevelNumberInput
                      type="input"
                      v-model:model-value="levelBulkApply"
                      use-event
                      :min="0"
                      @change="updateLevelPerm"
                      :disabled="authStore.user.level < 1"
                      :max="authStore.user.level"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            <FileLevelInfo
              v-model:model-value="levelPerm"
              :path="nav"
              @change="onChangedLevel"
            />
          </div>

          <div v-else-if="currentMenu === '2'">
            <div class="break-words space-y-2">
              <h3
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                {{ t("buttons.switchView.title") }}
              </h3>
              <p>
                <select
                  v-model="viewOptionValue"
                  class="px-3 py-1 w-full border border-gray-300 ring-0 rounded text-xs"
                  name="hideDotfiles"
                >
                  <option value="">
                    {{ t("buttons.switchView.title") }}
                    {{ t("buttons.select") }}
                  </option>
                  <option value="list">
                    {{ t("buttons.switchView.list") }}
                  </option>
                  <option value="mosaic">
                    {{ t("buttons.switchView.tiles") }}
                  </option>
                  <option value="mosaic gallery">
                    {{ t("buttons.switchView.gallery") }}
                  </option>
                </select>
              </p>
              <p>
                <input
                  v-model="hideDotfiles"
                  type="checkbox"
                  name="hideDotfiles"
                  class="mr-1 border border-gray-300 ring-0 rounded text-xs"
                />
                <span @click="hideDotfiles = !hideDotfiles">{{
                  t("settings.hideDotfiles")
                }}</span>
              </p>
              <p>
                <input
                  v-model="dateFormat"
                  type="checkbox"
                  name="dateFormat"
                  class="mr-1 border border-gray-300 ring-0 rounded text-xs"
                />
                <span @click="dateFormat = !dateFormat">{{
                  t("settings.setDateFormat")
                }}</span>
              </p>
              <p class="py-1"></p>
              <h3
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                {{ t("settings.sort") }}
              </h3>
              <p>
                <select
                  v-model="sorting.by"
                  class="px-3 py-1 w-full border border-gray-300 ring-0 rounded text-xs"
                  name="hideDotfiles"
                >
                  <option value="name">{{ t("files.sortByName") }}</option>
                  <option value="modified">
                    {{ t("files.sortByLastModified") }}
                  </option>
                  <option value="created">
                    {{ t("files.sortByLastCreated") }}
                  </option>
                  <option value="size">{{ t("files.sortBySize") }}</option>
                </select>
              </p>

              <div class="flex items-center space-x-4">
                <label class="flex items-center">
                  <input
                    v-model="sorting.asc"
                    type="radio"
                    :value="true"
                    name="sortingOrder"
                    class="mr-2 border-gray-300 focus:ring-blue-500 text-blue-600 rounded-full"
                  />
                  {{ t("files.sortAsc") }}
                </label>

                <label class="flex items-center">
                  <input
                    v-model="sorting.asc"
                    type="radio"
                    :value="false"
                    name="sortingOrder"
                    class="mr-2 border-gray-300 focus:ring-blue-500 text-blue-600 rounded-full"
                  />
                  {{ t("files.sortDesc") }}
                </label>
              </div>
            </div>
          </div>

          <div v-else-if="currentMenu === '3'">
            <h3
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            ></h3>
            <!--              <div class="break-words space-y-1">-->
            <!--                <p>-->
            <!--                  <input-->
            <!--                    v-model="hideDotfiles"-->
            <!--                    type="checkbox"-->
            <!--                    name="hideDotfiles"-->
            <!--                    class="mr-1 border border-gray-300 ring-0 rounded text-xs"-->
            <!--                  />-->
            <!--                  <span @click="hideDotfiles = !hideDotfiles">{{-->
            <!--                      t('settings.hideDotfiles')-->
            <!--                    }}</span>-->
            <!--                </p>-->
            <!--                <p>-->
            <!--                  <input-->
            <!--                    v-model="dateFormat"-->
            <!--                    type="checkbox"-->
            <!--                    name="dateFormat"-->
            <!--                    class="mr-1 border border-gray-300 ring-0 rounded text-xs"-->
            <!--                  />-->
            <!--                  <span @click="dateFormat = !dateFormat">{{-->
            <!--                      t('settings.setDateFormat')-->
            <!--                    }}</span>-->
            <!--                </p>-->
            <!--              </div>-->
          </div>
        </div>
      </div>

      <div v-if="!isCreator" class="text-right my-3">
        <div
          class="inline-flex items-center py-1 px-2 text-xs text-yellow-800 border border-yellow-300 rounded-md bg-yellow-50 dark:bg-opacity-5 dark:text-yellow-300 dark:border-yellow-500"
          role="alert"
        >
          <template v-if="!authStore.user.perm.admin">
            <PhInfo :size="17" weight="thin" class="mr-2" />
            <div>
              {{ t("message.updateOnlyCreatorMsg") }}
            </div>
          </template>
          <template v-else-if="authStore.user.perm.admin">
            <PhInfo :size="17" weight="thin" class="mr-2" />
            <div>
              {{ t("message.updateWarnAdminMsg") }}
            </div>
          </template>
        </div>
      </div>
      <div class="flex items-center justify-between bottom-0">
        <div class="space-x-1 flex items-center">
          <AiaButton
            type="info"
            :aria-label="$t('buttons.close')"
            :title="$t('buttons.close')"
            tabindex="1"
            @click="layoutStore.closeHovers"
          >
            {{ t("buttons.close") }}
          </AiaButton>
        </div>

        <AiaButton
          class="d-gray"
          :aria-label="
            !isCreator ? t('message.updateOnlyCreatorMsg') : t('buttons.update')
          "
          :disabled="!isCreator && !authStore.user.perm.admin"
          :title="
            !isCreator ? t('message.updateOnlyCreatorMsg') : t('buttons.update')
          "
          @click="updateSettings"
        >
          {{ t("buttons.update") }}
        </AiaButton>
      </div>
    </template>
  </AiaModalContainer>
</template>
<style lang="scss" scoped>
aside {
  border-color: var(--divider);
  ul {
    li {
      @apply cursor-pointer hover:bg-blue-50/50 rounded-md mr-5 mb-1 px-2 py-1 dark:hover:bg-opacity-5;
      &.selected {
        @apply bg-blue-400 text-white hover:bg-blue-400;
      }
    }
  }
}
</style>
