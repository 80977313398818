import i18n from '@/i18n';
import { toast } from 'vue3-toastify';

export function getFileTaskSuccessMsg(
  cnt: number,
  action: string,
  isDir: boolean
): string {
  let successMsg = action + '';
  if (cnt > 1) {
    successMsg = i18n.global.t('message.multiTask', {
      cnt: cnt,
      action: action,
    });
  } else if (cnt === 1) {
    if (isDir === true) {
      successMsg = i18n.global.t('message.folderTask', { action: action });
    } else {
      successMsg = i18n.global.t('message.fileTask', { action: action });
    }
  }

  return successMsg;
}

export function showSuccess(msg: string) {
  toast.success(msg);
}
