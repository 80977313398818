<template>
  <div>
    <div>
      <form @submit="updateSettings">
        <h3
          class="mt-2 block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          {{ t('settings.nick') }}
        </h3>
        <div>
          <AiaInput
            v-model:model-value="nick"
            :rules="rules.nickName"
            class-name="text-sm px-3 w-full rounded-md border-0 ring-1 ring-inset ring-gray-300 dark:bg-gray-800"
            type="text"
            :placeholder="t('settings.nick')"
            name="nickName"
          />
        </div>
        <h3
          class="mt-2 block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          {{ t('settings.language') }}
        </h3>
        <div>
          <languages v-model:locale="locale" class="input"></languages>
        </div>
        <div class="absolute bottom-5 right-5">
          <AiaButton
            type="primary"
            size="md"
            :disabled='isDisabled'
            name="submitProfile"
            :value="t('buttons.update')"
          >
            {{ t('buttons.update') }}
          </AiaButton>
        </div>
      </form>
    </div>
  </div>
</template>

<script setup lang="ts">
import { settings, users as api } from '@/api';
import AiaButton from '@/components/form/AiaButton.vue';
import AiaInput from '@/components/form/AiaInput.vue';
import Languages from '@/components/settings/Languages.vue';
import i18n from '@/i18n';
import { useAuthStore } from '@/stores/auth';
import { useLayoutStore } from '@/stores/layout';
import { useSettingsStore } from '@/stores/setting';
import { computed, nextTick, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { toast } from 'vue3-toastify';

const layoutStore = useLayoutStore();
const authStore = useAuthStore();
const settings = useSettingsStore();
const { t } = useI18n();
const nick = ref<string>('');
const locale = ref<string>('');

onMounted(() => {
  layoutStore.loading = true;
  if (authStore.user === null) return false;
  locale.value = authStore.user.locale;
  nick.value = authStore.user.nick;
  layoutStore.loading = false;
  return true;
});

const isDisabled = computed(() => {
  return !nick.value || nick.value.length < 2
})

const rules = {
  nickName: [
    {
      required: true,
      message: t('auth.invalid-msg.nickname'),
      trigger: 'blur',
    },
    {
      minlength: 2,
      message: t('auth.invalid-msg.min', { min: 2 }),
      trigger: 'blur',
    },
    {
      maxlength: 20,
      message: t('auth.invalid-msg.max', { max: 20 }),
      trigger: 'blur',
    },
  ],
};

const updateSettings = async (event: Event) => {
  event.preventDefault();

  try {
    if (authStore.user === null) throw new Error('User is not set!');

    const data = {
      ...authStore.user,
      id: authStore.user.id,
      nick: nick.value,
      locale: locale.value,
      // singleClick: singleClick.value,
    };

    const isChLocal = !!(locale.value != authStore.user.locale)

    await api.update(data, ['locale', 'nick']);
    authStore.updateUser(data);
    toast.success(t('settings.settingsUpdated'));

    if (isChLocal) {
      await nextTick(()=>{
        settings.applyDefaultLanguageCode(locale.value);
      })
    }

  } catch (err) {
    if (err instanceof Error) {
      toast.error(err);
    }
  }
};
</script>
