<script setup lang="ts">
import AiaDropdown from '@/components/dropdown/AiaDropdown.vue';
import { useFilesLayoutStore } from '@/stores/filesLayout';
import { PhGridFour, PhList, PhSquaresFour } from '@phosphor-icons/vue';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
const { t } = useI18n();
const isDropdown = ref(false);
const emit = defineEmits(['action']);
const filesLayoutStore = useFilesLayoutStore();
const selectedView = ref('list');
const _switchView = async (mode: ViewModeType) => {
  const modes = {
    list: 'mosaic',
    mosaic: 'mosaic gallery',
    'mosaic gallery': 'list',
  };

  if (!mode) {
    mode = 'list';
  }

  selectedView.value = mode;
  filesLayoutStore.updateFolderViewMode(mode);

  isDropdown.value = false;
  emit('action', 'switchView', mode);
};

const tilesView = () => {
  _switchView('mosaic');
};
const listsView = () => {
  _switchView('list');
};
const galleryView = () => {
  _switchView('mosaic gallery');
};

const getButtonIcon = () => {
  switch (selectedView.value) {
    case 'list':
      return { com: PhList, weight: 'light' };
    case 'mosaic':
      return { com: PhSquaresFour, weight: 'thin' };
    case 'mosaic gallery':
      return { com: PhGridFour, weight: 'thin' };
    default:
      return { com: PhSquaresFour, weight: 'thin' };
  }
};

const currentIcon = computed(() => {
  return getButtonIcon();
});
</script>

<template>
  <AiaDropdown
    v-model:show-menu="isDropdown"
    class="inline-block"
    class-name="min-w-24"
    :title="$t('buttons.switchView.title')"
  >
    <slot>
      <component
        :is="currentIcon.com"
        :size="19"
        :weight="currentIcon.weight"
        class="inline-block"
      />
    </slot>
    <template #items>
      <ul class="drop-menu-items whitespace-nowrap">
        <li @click="listsView">
          <ph-list :size="19" weight="thin" />
          <span>{{ t('buttons.switchView.list') }}</span>
        </li>
        <li @click="tilesView">
          <ph-squares-four :size="19" weight="thin" />
          <span>{{ t('buttons.switchView.tiles') }}</span>
        </li>
        <li @click="galleryView">
          <ph-grid-four :size="19" weight="thin" />
          <span>{{ t('buttons.switchView.gallery') }}</span>
        </li>
      </ul>
    </template>
  </AiaDropdown>
</template>
