<template>
  <AiaModalContainer
    hide-close
    title-class="py-4"
    style="z-index: 1000"
    @close="layoutStore.closeHovers"
  >
    <template #title>
      <span>
        {{ t('prompts.discardEditorChanges') }}
      </span>
    </template>
    <template #body>
      <div class="flex justify-end mb-2 space-x-2">
        <AiaButton
          type="info"
          :aria-label="t('buttons.cancel')"
          :title="t('buttons.cancel')"
          tabindex="1"
          @click="layoutStore.closeHovers"
        >
          {{ t('buttons.cancel') }}
        </AiaButton>

        <AiaButton
          type="danger"
          :loading="isLoading"
          :aria-label="t('buttons.discardChanges')"
          :title="t('buttons.discardChanges')"
          tabindex="2"
          @click="submit"
        >
          {{ t('buttons.discardChanges') }}
        </AiaButton>
      </div>
    </template>
  </AiaModalContainer>
</template>
<script setup lang="ts">
import AiaModalContainer from '@/components/dialog/AiaModalContainer.vue';
import AiaButton from '@/components/form/AiaButton.vue';
import { useFileStore } from '@/stores/file';
import { useLayoutStore } from '@/stores/layout';
import url from '@/utils/url';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';

const { t } = useI18n();

// 스토어 사용
const layoutStore = useLayoutStore();
const fileStore = useFileStore();

// Vue Router 사용
const router = useRouter();
const route = useRoute();
const isLoading = ref(false);

const submit = async () => {
  isLoading.value = true;
  fileStore.updateRequest(null);

  const uri = url.removeLastDir(route.path) + '/';
  await router.push({ path: uri });
  isLoading.value = false;
};
</script>
