<script setup lang="ts">
import { MenuOptions } from '@/class/ViewUtils';
import AiaModalContainer from "@/components/dialog/AiaModalContainer.vue";
import { useAuthStore } from "@/stores/auth";
import { useLayoutStore } from "@/stores/layout";
import ChangePassword from "@/views/mypage/ChangePassword.vue";
import PermissionInfo from "@/views/mypage/PermissionInfo.vue";
import ProfileDefault from "@/views/mypage/ProfileDefault.vue";
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";

// Props
const props = defineProps<{ menuKey?: string }>();
// const authStore = useAuthStore();
// const user = computed(()=> authStore.user)
const MenuDef = {
  basic: "1",
  chPwd: "2",
  permInfo: "3",
};


const menuDefinitions: MenuOptions[] = [
  { key: "1", label: "settings.user" },
  { key: "2", label: "settings.changePassword" },
  { key: "3", label: "settings.permissions" },
];
const { t } = useI18n();
const layoutStore = useLayoutStore();
const currentMenu = ref<string>(props.menuKey || MenuDef.basic);
const handleSelectMenu = (idx: string) => {
  currentMenu.value = idx;
};
</script>
<template>
  <AiaModalContainer @close="layoutStore.closeHovers">
    <template #title>
      {{ t("settings.profileSettings") }}
    </template>
    <template #body>
      <div class="flex w-[500px] min-h-[300px]">
        <!-- 왼쪽 메뉴 -->
        <aside class="w-4/12 border-r">
          <ul>
            <li
              v-for="menu in menuDefinitions"
              :key="menu.key"
              :class="{ selected: currentMenu === menu.key }"
              @click="handleSelectMenu(menu.key)"
            >
              {{ t(menu.label) }}
            </li>
          </ul>
        </aside>

        <!-- 오른쪽 설정 내용 -->
        <div class="pl-6 w-8/12">
          <!-- 레벨 -->
          <div v-if="currentMenu === MenuDef.basic" class="w-full">
            <ProfileDefault />
          </div>
          <div v-else-if="currentMenu === MenuDef.chPwd">
            <ChangePassword />
          </div>
          <div v-else-if="currentMenu === MenuDef.permInfo">
            <PermissionInfo />
          </div>
        </div>
      </div>
    </template>
  </AiaModalContainer>
</template>
<style lang="scss" scoped>
aside {
  border-color: var(--divider);
  ul {
    li {
      @apply cursor-pointer hover:bg-blue-50/50 rounded-md mr-5 mb-1 px-2 py-1 dark:hover:bg-opacity-5;
      &.selected {
        @apply bg-blue-400 text-white hover:bg-blue-400;
      }
    }
  }
}
</style>
