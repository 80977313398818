import { defineStore } from "pinia";

import { computed, onMounted, onUnmounted, ref } from "vue";

export const useDeviceStore = defineStore("device", () => {
  const width = ref<number>(window.innerWidth);

  // 화면 너비를 업데이트하는 함수
  const updateWidth = () => {
    width.value = window.innerWidth;
  };

  // 컴포넌트 마운트 시점에서 이벤트 리스너 추가
  onMounted(() => {
    window.addEventListener("resize", updateWidth);
  });

  // 컴포넌트가 해제될 때 이벤트 리스너 제거
  onUnmounted(() => {
    window.removeEventListener("resize", updateWidth);
  });

  // Tailwind 반응형 기준에 맞는 상태 계산
  const isSm = computed(() => width.value >= 640 && width.value < 768);
  const isMd = computed(() => width.value >= 768 && width.value < 1024);
  const isLg = computed(() => width.value >= 1024 && width.value < 1280);
  const isXl = computed(() => width.value >= 1280);
  const isMobile = computed(() => width.value < 768);
  const isDesktop = computed(() => width.value >= 1024);

  return {
    width,
    isSm,
    isMd,
    isLg,
    isXl,
    isMobile,
    isDesktop,
  };
});
