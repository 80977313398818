<template>
  <ol :class="['breadcrumbs whitespace-nowrap', className]">
    <li class="inline-flex items-center">
      <component
        :is="element"
        :to="base || ''"
        :aria-label="t('files.home')"
        :title="t('files.home')"
      >
        <a>
          <!--          <svg-->
          <!--            class="w-4 h-4 me-2.5"-->
          <!--            aria-hidden="true"-->
          <!--            xmlns="http://www.w3.org/2000/svg"-->
          <!--            fill="currentColor"-->
          <!--            viewBox="0 0 20 20"-->
          <!--          >-->
          <!--            <path-->
          <!--              d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z"-->
          <!--            />-->
          <!--          </svg>-->
          <span>{{ t('sidebar.teamFolder') }}</span>
        </a>
      </component>
    </li>
    <li v-for="(link, index) in items" :key="index">
      <div class="flex items-center">
        <svg
          class="w-4 h-4 text-gray-500"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
        >
          <path
            fill="currentColor"
            d="m13.292 12l-4.6-4.6l.708-.708L14.708 12L9.4 17.308l-.708-.708z"
          />
        </svg>
        <a
          ><component :is="element" :to="link.url">{{
            link.name
          }}</component></a
        >
      </div>
    </li>
  </ol>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';

const { t } = useI18n();

const route = useRoute();

const props = withDefaults(
  defineProps<{
    className?: string;
    base: string;
    displayLength?: number;
    noLink?: boolean;
  }>(),
  {
    className: 'font-medium text-xl', // 기본값 설정
    displayLength: 3,
    noLink: false, // optional: 다른 속성에도 기본값을 설정할 수 있습니다
  }
);

const items = computed(() => {
  const relativePath = route.path.replace(props.base, '');
  const parts = relativePath.split('/');

  if (parts[0] === '') {
    parts.shift();
  }

  if (parts[parts.length - 1] === '') {
    parts.pop();
  }

  const breadcrumbs: BreadCrumb[] = [];

  for (let i = 0; i < parts.length; i++) {
    if (i === 0) {
      breadcrumbs.push({
        name: decodeURIComponent(parts[i]),
        url: props.base + '/' + parts[i] + '/',
      });
    } else {
      breadcrumbs.push({
        name: decodeURIComponent(parts[i]),
        url: breadcrumbs[i - 1].url + parts[i] + '/',
      });
    }
  }

  if (breadcrumbs.length > props.displayLength) {
    while (breadcrumbs.length !== props.displayLength + 1) {
      breadcrumbs.shift();
    }

    breadcrumbs[0].name = '...';
  }

  return breadcrumbs;
});

const element = computed(() => {
  if (props.noLink) {
    return 'span';
  }

  return 'router-link';
});
</script>

<style lang="scss" scoped>
.breadcrumbs {
  @apply inline-flex items-center space-x-1 md:space-x-1 rtl:space-x-reverse;

  li a {
    @apply inline-flex rounded items-center px-1
    text-gray-700 dark:text-gray-200
    hover:bg-gray-200
    dark:hover:bg-gray-700
    dark:hover:text-white;
  }
}
</style>
