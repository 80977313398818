import moment from "moment/min/moment-with-locales";

export const formatDate = (value, locales = ["ko-KR", "en-US"]) => {
  const date = new Date(value);
  return date.toLocaleString(locales, {
    month: "short",
    day: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });
};

export const humanTime = (time: string | number) => {
  const modifiedDate = moment(time);
  // if (authStore.user?.dateFormat) {
  //   return modifiedDate.format('L LT');
  // }
  return modifiedDate.fromNow();
};

export const localDetailTime = (time: string | number) => {
  const modifiedDate = moment(time);
  return modifiedDate.format("L LT");
};

export const getNowDate = () => {
  return moment(new Date()).format("YYYY-MM-DD");
};

export const camelCase = (str: string): string => {
  return str.replace(/_([a-z])/g, g => g[1].toUpperCase());
};

export const date = (date?) => {
  // if (format) {
  //     if (format == 'now') return moment().format('YYYY-MM-DD hh:mm:ss');
  // }
  return moment(date);
};

export function formatChatDate(date) {
  const now = moment();
  const messageDate = moment(date);
  const oneMonthAgo = moment().subtract(1, "months");

  if (messageDate.isSame(now, "day")) {
    // 오늘 날짜일 경우 시간만 표시
    return messageDate.format("HH:mm");
  } else if (messageDate.isAfter(oneMonthAgo)) {
    // 최근 1달 이내일 경우 ago 포맷
    return messageDate.fromNow();
  } else {
    // 그 이외의 경우 YYYY-MM-DD 포맷
    return messageDate.format("YYYY-MM-DD");
  }
}
