<template>
  <MainHeader v-if="!deviceStore.isMobile" />
  <MainMobileHeader v-else />
  <!--  <AsideLeftMenu />-->
  <div class="app-container">
    <FilesPanel />
  </div>
</template>

<script setup lang="ts">
import { useDeviceStore } from "@/stores/deviceStore";
import FilesPanel from "@/views/files/FilesPanel.vue";
import MainHeader from "@/views/layout/MainHeader.vue";
import MainMobileHeader from "@/views/layout/MainMobileHeader.vue";

const deviceStore = useDeviceStore();
</script>
<style lang="scss" scoped>
.app-container {
  //@apply rounded-md dark:rounded-md shadow-sm border bg-white dark:border-neutral-600 dark:bg-gray-800;
  height: var(--height-main-content);
  overflow: hidden;
  //border-top-left-radius: 15px;

  border-top: 1px solid var(--divider);
  border-left: 1px solid var(--divider);
}
</style>
