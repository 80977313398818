import { Metadata } from "@/class/interface/drive/metadata";

import {
  cleanSuffixUrl,
  extractInfoFromPath,
  fetchJSON,
  fetchURL,
  removePrefixUrl,
  StatusError,
} from "./utils";

export async function fetchMetadata(path: string): Promise<Metadata | null> {
  path = cleanSuffixUrl(path);
  if (!path) path = "/";
  let res;
  try {
    res = await fetchJSON(`/api/metadata${path}`, {});
  } catch (e) {}
  return res;
}

export async function fetchMetaHistory(
  path: string,
  limit?: number,
  offset?: number
) {
  path = cleanSuffixUrl(path);
  let addUrl = "";
  if (limit && offset !== null) {
    addUrl = `?limit=${limit}&offset=${offset}`;
  }
  if (!path) path = "/";
  let res;
  try {
    res = await fetchJSON(`/api/metahis${path}${addUrl}`, {});
  } catch (e) {}
  return res;
}
/**
 * 폴더 옵션 업데이트
 * @param path
 * @param payload
 */
export async function updateMetadata(path: string, payload: Metadata) {
  if (!path) {
    throw new Error("Path is required");
  }

  path = cleanSuffixUrl(path);
  let res;
  try {
    res = await fetchURL(`/api/metadata${path}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });
    // 성공 시 Location 헤더 반환
    if (typeof res !== "number" && (res.status === 200 || res.status === 201)) {
      return true;
    }
  } catch (e) {
    // 에러 발생 시 에러 응답 텍스트와 상태 코드 반환
    throw e;
  }
  return false;
}

// function encodePath(path:string) {
//   // '/'를 제외하고 인코딩
//   const encodedPath = path.split('/').map(encodeURIComponent).join('/');
//   return `${encodedPath}`
// }
