<script setup lang="ts">
import AiaPagnation from "@/components/basic/AiaPagnation.vue";
import { ref } from "vue";

// props로 전달된 pageInfo
const props = defineProps({
  pageInfo: {
    type: Object,
    required: true,
    default: () => ({ currentPage: 1, limit: 100, total: 0 }),
  },
  pagerCount: { type: Number, default: 5 },
  size: { type: String, default: "normal" },
});

const emit = defineEmits(["onPage"]);
const totalItems = ref<number>(props.pageInfo.total || 0);
const limit = ref<number>(props.pageInfo.limit || 0);
const currentPage = ref<number>(props.pageInfo.currentPage || 1);
// const offset = computed(() => (currentPage.value - 1) * limit.value);

const goPage = async (page: number) => {
  currentPage.value = isNaN(page) || page <= 0 ? 1 : page;
  const limit = props.pageInfo.limit;
  const offset = (currentPage.value - 1) * limit;
  emit("onPage", page, limit, offset);
};
</script>

<template>
  <!-- 페이징 -->
  <div v-if="totalItems > limit" class="flex justify-between py-2">
    <div></div>
    <AiaPagnation
      :total="totalItems"
      :current-page="currentPage"
      :page-size="limit"
      :pager-count="pagerCount"
      :size="size"
      @current-change="goPage"
    />
    <div></div>
  </div>
</template>
