<script setup lang="ts">
import { ValidateChecker } from '@/class/ValidateChecker';
import { baseURL } from '@/utils/constants';
import { HeadersInit } from 'node-fetch';
import { Auth } from '@/class/auth/Auth';
import { reactive } from 'vue';
// const apiBaseUrl = import.meta.env.VITE_API_BASE_URL;
const apiBaseUrl = baseURL + '/';

const props = defineProps({
  apiName: {
    type: String,
    required: true,
  },
  model: {
    type: Object,
    default: null,
  },
  method: {
    default() {
      return 'POST';
    },
  },
  // submit 전, 유효성 검증이 끝난 후
  // 데이터 재 가공(비밀번호 암호화 등)을 위함
  preSubmitModel: {
    type: Function,
    default() {
      return undefined;
    },
  },
  rules: {
    type: Object,
    default() {
      return {};
    },
  },
  enctype: {
    type: String,
    default: 'application/json;charset=utf-8',
    // default: 'application/x-www-form-urlencoded'
  },
});
// 통신응담, 유효성 체크 에러
const emits = defineEmits(['onResponse', 'onInvalid']);
// slot 에 바인딩을 위한 구조
const items = reactive({ lists: [], dto: null, error: null });
// 유효성 체크
const validation = new ValidateChecker();
let validateError = reactive({});

// 로딩 상태
// const loading = ref(false);

// 데이터 전송 요청시
const onSubmit = async event => {
  // item input invalid 를 활성화
  const childEle = event.target.elements;
  for (const ele of childEle) {
    ele.focus();
  }
  const ck = checkValidation();
  emits('onInvalid', ck);
  if (!ck || Object.keys(ck).length === 0) {
    let modelData = props.model;
    if (typeof props.preSubmitModel === 'function') {
      modelData = props.preSubmitModel() || props.model;
    }
    await fetchData(props.method, modelData);
  } else {
    // console.log('invalid!!', ck) // 디버깅
  }
};

// 유효성 체크
const checkValidation = () => {
  validateError = {};
  if (!props.rules) return validateError;
  Object.keys(props.rules).forEach(modelKey => {
    const error = [];
    for (let i = 0; i < props.rules[modelKey].length; i++) {
      const options = props.rules[modelKey][i];
      for (const [key, key_value] of Object.entries(options)) {
        if (key === 'type' || key === 'message' || key === 'trigger') {
          // skip
        } else {
          validation.setValue(props.model[modelKey]);
          const ck = validation.isValidParamRules(key, key_value);
          if (!ck) {
            error.push(options.message);
          }
        }
      }
    }
    if (error.length > 0) Object.assign(validateError, { [modelKey]: error });
  });
  return validateError;
};

const auth = new Auth();

// 데이터 전송
const fetchData = async (method: 'POST' | 'PUT', modelData) => {
  let data;
  let error;

  const token = auth.getToken();
  const headers: HeadersInit = {};
  if (token) Object.assign(headers, { Authorization: `Bearer ` + token });

  let bodyData;
  if (props.enctype?.includes('multipart/form-data')) {
    // fetch bugfix --> binery 값이 전달이 안됨. 반드시 제거 --> Object.assign(headers, {'Content-Type': props.enctype})
    bodyData = new FormData();
    // bodyData.append('file', modelData.clubIcon)
    Object.keys(modelData).forEach(key => {
      if (modelData[key]) bodyData.append(key + '', modelData[key]);
    });
  } else {
    Object.assign(headers, { 'Content-Type': props.enctype });
    bodyData = JSON.stringify(modelData);
  }

  try {
    const res = await fetch(apiBaseUrl + props.apiName, {
      method: method,
      headers: headers,
      body: bodyData,
    });
    if (res.status === 200 || res.status === 201) {
      data = await res.json();
      Object.assign(items, data);
    } else {
      let body;
      try {
        body = await res.json();
      } catch (e) {
        body = res;
      }
      return emits('onResponse', false, {
        error: res.statusText,
        status: res.status,
        errorBody: body,
      });
    }
  } catch (e) {
    console.log('error', e);
    // Object.assign(items, error)
    return emits('onResponse', false, e);
  }

  function sendError(msg?: string) {
    let errorMsg = msg || '데이터를 가져오는데 실패하였습니다.';
    if (error && error['message']) errorMsg = error['message'];
    Object.assign(items, { error: { message: errorMsg } });
    emits('onResponse', false, data);
  }

  if (data) {
    let codeKey = data['statusCode'];
    if (!codeKey && data['status']) codeKey = data['status'];
    switch (codeKey) {
      case 200:
      case 201:
        emits('onResponse', true, data);
        break;
      case 400:
        if (data && data['invalid']) {
          error ? Object.assign(error['invalid'], data['invalid']) : null;
          emits('onInvalid', data['invalid']); // invalid event
        }
        sendError('입력된 데이터에 오류가 없는지 확인해 주세요.');
        break;
      case 401:
        sendError('접근 권한이 없습니다.');
        break;
      case 500:
        sendError('데이터를 전송하는데 서버 오류가 발생하였습니다.');
        break;
      default:
        sendError('statusCode');
        break;
    }
  }
};
</script>
<template>
  <form :enctype="enctype" autocomplete="off" @submit.prevent="onSubmit">
    <slot />
    <slot name="response" v-bind="items" />
  </form>
</template>
