export class CheckValidation {
  public isValidFolderName(nm: string) {
    //const invalidCharacters = /[\\/:*?"<>|]/;
    const invalidCharacters = /[\\:*?"<>|]/;
    if (!nm || invalidCharacters.test(nm)) {
      return false;
    }
    return true;
  }
}
