<script setup lang="ts">
import { useLayoutStore } from "@/stores/layout";
import { PhFilePlus } from "@phosphor-icons/vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const layoutStore = useLayoutStore();
const createFile = () => {
  layoutStore.showHover("newFile");
};
</script>

<template>
  <div>
    <div
      class="btn-create flex items-center px-3 whitespace-nowrap"
      @click="createFile"
    >
      <PhFilePlus size="19" weight="thin" />
      <span>{{ t("prompts.newFile") }}</span>
      <span class="text-gray-500 text-xs hidden md:block">(N)</span>
    </div>
  </div>
</template>
