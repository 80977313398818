<template>
  <div
    class="item"
    tabindex="0"
    :class="{ 'drag-handle': isDragStart }"
    :draggable="isDraggable"
    :data-dir="item.isDir"
    :data-type="item.type"
    :data-level="isLevelData"
    :aria-label="item.name"
    :aria-selected="isSelected"
    :data-ext="getExtension(item.name).toLowerCase()"
    @dragstart="dragStart"
    @dragover="dragOver"
    @dragend="resetTarget"
    @dragleave="resetTarget"
    @drop="drop"
    @click="handleItemClick"
    @dblclick="moveFolder"
    @contextmenu="handleSelect"
  >
    <section v-if="fileStore.multiple">
      <template v-if="!isSelected">
        <svg
          class="check-box"
          xmlns="http://www.w3.org/2000/svg"
          width="20px"
          height="20px"
          viewBox="0 0 24 24"
        >
          <path
            class="over"
            fill="currentColor"
            d="M12 1.999c5.524 0 10.002 4.478 10.002 10.002c0 5.523-4.478 10.001-10.002 10.001S1.998 17.524 1.998 12.001C1.998 6.477 6.476 1.999 12 1.999m0 1.5a8.502 8.502 0 1 0 0 17.003A8.502 8.502 0 0 0 12 3.5M11.996 6a5.998 5.998 0 1 1 0 11.996a5.998 5.998 0 0 1 0-11.996"
          />
          <path
            class="default"
            fill="currentColor"
            d="M12 22.002c5.524 0 10.002-4.478 10.002-10.001c0-5.524-4.478-10.002-10.002-10.002S1.998 6.477 1.998 12.001c0 5.523 4.478 10.001 10.002 10.001m0-1.5A8.502 8.502 0 1 1 12 3.5a8.502 8.502 0 0 1 0 17.003"
          />
        </svg>
      </template>
      <svg
        v-else
        class="check-box"
        width="19px"
        height="19px"
        viewBox="0 0 48 48"
      >
        <g
          fill="none"
          stroke="currentColor"
          stroke-linejoin="round"
          stroke-width="4"
        >
          <path
            d="M24 44a19.937 19.937 0 0 0 14.142-5.858A19.937 19.937 0 0 0 44 24a19.938 19.938 0 0 0-5.858-14.142A19.937 19.937 0 0 0 24 4A19.938 19.938 0 0 0 9.858 9.858A19.938 19.938 0 0 0 4 24a19.937 19.937 0 0 0 5.858 14.142A19.938 19.938 0 0 0 24 44Z"
          />
          <path stroke-linecap="round" d="m16 24l6 6l12-12" />
        </g>
      </svg>
    </section>
    <div>
      <img
        v-if="!readOnly && item.type === 'image' && isThumbsEnabled"
        v-lazy="thumbnailUrl"
        alt=""
      />
      <i
        v-else
        :class="{ 'material-icons': true, 'folder filled': item.isDir }"
      ></i>
    </div>

    <div>
      <p class="filename">
        <a role="button" @click="moveFolder">{{ item.name }} </a>
        <slot />
        <PhDotOutline
          v-if="isNew"
          :size="16"
          weight="bold"
          class="fill-red-600"
        />
        <button class="context-menu-btn" @click="handleItemMenuClick">
          <PhDotsThree :size="20" weight="bold" />
        </button>
      </p>

      <p v-if="item.isDir" class="size" :data-order="countDirEntries()">
        {{ countDirEntries() }}
      </p>
      <p v-else class="size" :data-order="humanSize()">{{ humanSize() }}</p>

      <p class="creator">
        <div v-if="item.nick" class='flex items-center space-x-2'>
          <div class="max-w-4 h-4 rounded-full relative bg-blue-50 dark:bg-blue-400 whitespace-nowrap ">
            <svg viewBox="0 0 24 24" class="absolute w-4 h-4 fill-blue-300 dark:fill-blue-50">
              <path
                d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z"
              ></path>
            </svg>
          </div>
          <span>{{ item.nick }}</span>
        </div>
      </p>
      <p class="modified">
        <time :datetime="item.modified">{{ humanTimeByDay() }}</time>
      </p>
    </div>
  </div>
</template>

<script setup lang="ts">
import { files as api } from "@/api";
import { ResourceItem } from "@/class/interface/drive/file";
import { Metadata } from "@/class/interface/drive/metadata";
import { useAuthStore } from "@/stores/auth";
import { useFileStore } from "@/stores/file";
import { useLayoutStore } from "@/stores/layout";
import { filesize } from "@/utils";

import { enableThumbs } from "@/utils/constants";
import * as upload from "@/utils/upload";
import {
  PhDotOutline, PhDotsThree, PhDotsThreeVertical,
  PhStar,
  PhUser,
  PhUserCircle,
  PhUserRectangle,
} from "@phosphor-icons/vue";
import moment from "moment/min/moment-with-locales";
import { computed, nextTick, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { toast } from "vue3-toastify";

const { t } = useI18n();
const touches = ref<number>(0);
const isDragStart = ref<boolean>(false);
const router = useRouter();
const props = defineProps<{
  // displayIndex: number;
  item: ResourceItem;
  metaInfo?: Metadata;
  readOnly?: boolean;
  url: string;
}>();

const authStore = useAuthStore();
const fileStore = useFileStore();
const layoutStore = useLayoutStore();
const isSingleClick = false;
const singleClick = ref(false);
// const singleClick = computed(() => !props.item.readOnly && isSingleClick);
const isSelected = computed(
  () => fileStore.selected.indexOf(props.item.index) !== -1
);
const isLevelData = computed(() => props.item.isLevelFolder == true);
const isDraggable = computed(
  () => !props.readOnly && authStore.user?.perm.rename
);

const canDrop = computed(() => {
  if (!props.item.isDir || props.readOnly) return false;

  for (const i of fileStore.selected) {
    if (fileStore.req?.items[i].url === props.item.url) {
      return false;
    }
  }

  return true;
});

const thumbnailUrl = computed(() => {
  const file = {
    path: props.item.path,
    modified: props.item.modified,
  };

  return api.getPreviewURL(file as ResourceItem, "thumb");
});

const isThumbsEnabled = computed(() => {
  return enableThumbs;
});

const isNew = computed(() => {
  const modifiedDate = moment(props.item.modified);
  const now = moment();
  const hoursDiff = now.diff(modifiedDate, "second");

  // 파일이 최근 4초 내에 생성되었다면 isNew로 설정
  return hoursDiff < 4;
});

const humanSize = () => {
  return props.item.type == "invalid_link"
    ? "invalid link"
    : filesize(props.item.size);
};

const countDirEntries = () => {
  return t("files.countDirEntries", { cnt: props.item.size });
};

// 10일이 지났으면 날짜로 표시
const humanTimeByDay = (day: number = 10) => {
  const modifiedDate = moment(props.item.modified);
  const now = moment();
  const daysDiff = now.diff(modifiedDate, "days");
  if (daysDiff >= day || (!props.readOnly && props.metaInfo?.dateFormat)) {
    // return modifiedDate.format('L');
    return modifiedDate.format("L LT");
  }
  return modifiedDate.fromNow();
};

let preDragTarget = null;
const resetTarget = () => {
  if (preDragTarget !== null) {
    preDragTarget.style.opacity = "";
    preDragTarget.style.border = "";
  }
  preDragTarget = null;
  isDragStart.value = false;
};

const dragStart = () => {
  isDragStart.value = true;
  if (fileStore.selectedCount === 0) {
    fileStore.selected.push(props.item.index);
    return;
  }

  if (!isSelected.value) {
    fileStore.selected = [];
    fileStore.selected.push(props.item.index);
  }
};

const dragOver = (event: DragEvent) => {
  resetTarget();
  if (!canDrop.value) return;

  event.preventDefault();
  const el = _getTargetElement(event.target as HTMLElement | null);
  if (el !== null) {
    if (preDragTarget !== el) {
      el.style.opacity = "1";
      el.style.border = "2px dashed var(--dark-blue)";
      preDragTarget = el;
    }
  }
};

const _getTargetElement = (eventTarget: HTMLElement | null) => {
  let el = eventTarget;
  if (el !== null) {
    for (let i = 0; i < 5; i++) {
      if (!el?.classList.contains("item")) {
        el = el?.parentElement ?? null;
      } else {
        break;
      }
    }

    if (el !== null) {
      return el;
    }
  }
  return null;
};

const drop = async (event: Event) => {
  if (!canDrop.value) return;
  const targetEl = _getTargetElement(event.target as HTMLElement | null);
  if (targetEl !== null) {
    targetEl.removeAttribute("style");
  }

  event.preventDefault();

  if (fileStore.selectedCount === 0) return;

  let el = event.target as HTMLElement | null;
  for (let i = 0; i < 5; i++) {
    if (el !== null && !el.classList.contains("item")) {
      el = el.parentElement;
    }
  }

  const items = [];

  for (const i of fileStore.selected) {
    if (fileStore.req) {
      items.push({
        from: fileStore.req?.items[i].url,
        to: props.item.url + encodeURIComponent(fileStore.req?.items[i].name),
        name: fileStore.req?.items[i].name,
      });
    }
  }

  // Get url from ListingItem instance
  if (el === null) {
    return;
  }
  const path = el.__vue__.url;
  // console.log('path',path, 'el.__vue__',el.__vue__,'el',el)
  const baseItems = (await api.fetch(path)).items;

  const action = (overwrite: boolean, rename: boolean) => {
    api
      .move(items, overwrite, rename)
      .then(() => {
        fileStore.reload = true;
      })
      .catch(toast.error);
  };

  const conflict = upload.checkConflict(items, baseItems);

  let overwrite = false;
  let rename = false;

  if (conflict) {
    layoutStore.showHover({
      prompt: "replace-rename",
      confirm: (event: Event, option) => {
        overwrite = option == "overwrite";
        rename = option == "rename";

        event.preventDefault();
        layoutStore.closeHovers();
        action(overwrite, rename);
      },
    });

    return;
  }

  action(overwrite, rename);
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
// const handleSelect = (event: Event | KeyboardEvent) => {
const handleSelect = () => {
  // 이 아이템의 컨텍스트 메뉴가 아닐 경우
  if (fileStore.selected.indexOf(props.item.index) === -1) {
    fileStore.selected = [];
    fileStore.selected.push(props.item.index);
  }
};

const handleItemClick = (event: Event | KeyboardEvent) => {
  if (
    singleClick.value &&
    !(event as KeyboardEvent).ctrlKey &&
    !(event as KeyboardEvent).metaKey &&
    !(event as KeyboardEvent).shiftKey &&
    !fileStore.multiple
  )
    moveFolder();
  else click(event);
};
const emit = defineEmits(['selectMenu']);
const handleItemMenuClick = (event: Event | KeyboardEvent) => {
  event.preventDefault(); // 기본 동작 방지
  event.stopPropagation(); // 이벤트 전파 중지

  handleSelect()
  nextTick(()=>{
    emit('selectMenu', event )
  })

}

const click = (event: Event | KeyboardEvent) => {
  if (!singleClick.value && fileStore.selectedCount !== 0)
    event.preventDefault();

  setTimeout(() => {
    touches.value = 0;
  }, 300);

  touches.value++;
  if (touches.value > 1) {
    moveFolder();
  }

  if (fileStore.selected.indexOf(props.item.index) !== -1) {
    fileStore.removeSelected(props.item.index);
    return;
  }

  if ((event as KeyboardEvent).shiftKey && fileStore.selected.length > 0) {
    let fi = 0;
    let la = 0;

    if (props.item.index > fileStore.selected[0]) {
      fi = fileStore.selected[0] + 1;
      la = props.item.index;
    } else {
      fi = props.item.index;
      la = fileStore.selected[0] - 1;
    }

    for (; fi <= la; fi++) {
      if (fileStore.selected.indexOf(fi) == -1) {
        fileStore.selected.push(fi);
      }
    }

    return;
  }

  if (
    !singleClick.value &&
    !(event as KeyboardEvent).ctrlKey &&
    !(event as KeyboardEvent).metaKey &&
    !fileStore.multiple
  ) {
    fileStore.selected = [];
  }
  fileStore.selected.push(props.item.index);
};

const moveFolder = (event?) => {
  if (event) {
    event.stopPropagation();
    router.push({ path: props.item.url });
  }
};

const getExtension = (fileName: string): string => {
  const lastDotIndex = fileName.lastIndexOf(".");
  if (lastDotIndex === -1) {
    return fileName;
  }
  return fileName.substring(lastDotIndex);
};
</script>
<style lang="scss" scoped>
.item {
  .check-box {
    margin-right: 5px;
    .over {
      display: none;
    }
  }
  //&:hover {
  //  .over {
  //    display: block;
  //    //color: var(--blue);
  //  }
  //  .default {
  //  }
  //}
}
.drag-target {
  border: 2px dashed var(--dark-blue);
  opacity: 1;
}
.drag-handle {
  border: 1px dashed var(--dark-blue);
  background-color: yellow;
  cursor: move;
  opacity: 1;
}
</style>
