<script setup lang="ts">
import { files as api } from '@/api';
import { StatusError } from '@/api/utils';
import { CheckValidation } from '@/class/files/CheckValidation';
import AiaModalContainer from '@/components/dialog/AiaModalContainer.vue';
import AiaButton from '@/components/form/AiaButton.vue';
import AiaInput from '@/components/form/AiaInput.vue';
import { useFileStore } from '@/stores/file';
import { useLayoutStore } from '@/stores/layout';
import url from '@/utils/url';
import { storeToRefs } from 'pinia';
import { computed, onMounted, PropType, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';
import { toast } from 'vue3-toastify';

const props = defineProps({
  redirect: {
    type: Boolean,
    default: true,
  },
  filename: String,
  filetype: {
    type: String as PropType<ResourceType>,
    default: null,
  },
  fileBlob: {
    type: Blob,
    default: null,
  },
});

const fileStore = useFileStore();
const layoutStore = useLayoutStore();
const { currentPrompt } = storeToRefs(layoutStore);

const route = useRoute();
const router = useRouter();
const { t } = useI18n();

const checkValidation = new CheckValidation();

const name = ref<string>('');
const isInputMode = ref<boolean>(false); //X버튼 클릭시 유효성 검사 없애기
const extension = ref('.txt');
onMounted(() => {
  if (props.filename) {
    name.value = props.filename;
  }
  if (props.filetype === 'aia-json') {
    extension.value = '.tpl.json';
  }
});

// const addInvalidMsg = ref<string>(''); // 유효성 검사 메시지
// isDisabled 변수로 버튼 활성화 상태를 컴팩트하게 처리
const isDisabled = computed(() => {
  return (
    !name.value ||
    !checkValidation.isValidFolderName(name.value) ||
    checkFileConflict()
  );
});

const isConfirmVisible = ref(false); // 확인 버튼 표시 여부

const submit = async (event: Event, prompt) => {
  event.preventDefault();
  if (name.value === '') return;

  if (checkFileConflict()) {
    return;
  }

  // 확장자가 없을 때 .txt 추가
  if (extension.value !== '.none' && !name.value.includes('.')) {
    // name.value += '.txt';

    name.value += extension.value;
    // 확인 버튼 표시
    isConfirmVisible.value = true;
    return;
  }
  // Build the path of the new directory.
  let uri = fileStore.isFiles ? route.path + '/' : '/';

  if (!fileStore.isListing) {
    uri = url.removeLastDir(uri) + '/';
  }

  uri += encodeURIComponent(name.value);
  uri = uri.replace('//', '/');

  let content: any;
  if (props.filetype === 'aia-json') {
    const json = {
      'content-type': 'application/aia-schema',
      data: [],
    };
    content = JSON.stringify(json, null, 2);
  } else {
    if (props.fileBlob) content = props.fileBlob;
  }

  try {
    await api.post(uri, content);
    if (props.redirect) {
      await router.push({ path: uri });
    }

    if (prompt && typeof prompt === 'function') {
      prompt(uri);
    }
  } catch (e) {
    if (e instanceof StatusError) {
      if (e.status === 403) {
        toast.error(
          t('message.forbiddenMsg', { action: t('message.actionNewFolder') })
        );
      } else if (e.status === 409) {
        toast.error(t('message.conflictNameMsg'));
      } else {
        toast.error(String(e));
      }
    } else {
      toast.error(String(e));
    }
  }

  layoutStore.closeHovers();
};

const checkFileConflict = () => {
  // return fileStore.req.items.some(
  //   item => item.isDir && item.name === name.value
  // );
  // if (!fileStore.req || fileStore.req?.items) return true;
  return fileStore.req?.items.some(item => item.name === name.value);
};

const isValidNotConflictRule = (nm: string) => {
  isInputMode.value = true;
  return !fileStore.req?.items.some(item => item.name === nm);
};

const extensionOptions = [
  { label: '확장자 없이', value: '.none' },
  { label: 'Text File (.txt)', value: '.txt' },
  { label: 'Markdown File (.md)', value: '.md' },
  { label: 'JSON File (.json)', value: '.json' },
];
const changeExtension = (event: Event) => {
  const selectedExtension = (event.target as HTMLSelectElement).value;
  extension.value = selectedExtension;

  if (extension.value === '.none') {
    // 확장자가 없는 옵션을 선택한 경우 기존 확장자 제거
    name.value = name.value.replace(/\.\w+$/, '');
  } else {
    // 선택된 확장자로 파일 이름을 갱신
    if (!name.value.includes('.')) {
      name.value += extension.value;
    } else {
      name.value = name.value.replace(/\.\w+$/, extension.value); // 기존 확장자 변경
    }
  }
};

const handleClose = () => {
  isInputMode.value = false;
  layoutStore.closeHovers();
};
</script>
<template>
  <AiaModalContainer @close="handleClose">
    <template #title>
      <span v-if="filetype === 'aia-json'">{{
        t('prompts.newSchemaFile')
      }}</span>
      <span v-else>{{ t('prompts.newFile') }}</span>
    </template>
    <template #body>
      <AiaInput
        v-model:model-value="name"
        :rules="[
          {
            required: isInputMode,
            message: t('prompts.newFileMessage'),
            trigger: 'blur',
          },
          {
            message: t('fileMessages.conflict'),
            function: isValidNotConflictRule,
            trigger: 'live',
          },
          {
            message: t('fileMessages.invalidName'),
            function: checkValidation.isValidFolderName,
            trigger: 'live',
          },
        ]"
        focus
        class="input-blue"
        :select-text="!!filename"
        class-name="text-sm px-3 w-full rounded-md border-0 ring-1 ring-inset ring-gray-300 dark:bg-gray-800"
        valid-class="ring-gray-300"
        invalid-class="ring-red-500"
        type="text"
        :placeholder="t('prompts.newFileMessage')"
        @keyup.enter="(event: Event) => submit(event, currentPrompt.action)"
      />

      <div class="flex items-center justify-between my-4">
        <div>
          <!-- 확장자 선택 드롭다운 -->
          <select
            v-if="isConfirmVisible"
            :value="extension"
            class="text-sm px-3 rounded-md border-0 ring-1 ring-inset ring-gray-300 dark:bg-gray-800"
            @change="changeExtension"
          >
            <option
              v-for="option in extensionOptions"
              :key="option.value"
              :value="option.value"
            >
              {{ option.label }}
            </option>
          </select>
        </div>
        <AiaButton
          class="d-gray"
          :disabled="isDisabled"
          :aria-label="t('buttons.create')"
          :title="t('buttons.create')"
          tabindex="2"
          @click="(event: Event) => submit(event, currentPrompt.action)"
        >
          {{ t('buttons.create') }}
        </AiaButton>
      </div>
    </template>
  </AiaModalContainer>
</template>
