<template>
  <FilesViewErrorGroup
    v-if="statusError || scriptError"
    :status-error="statusError"
    :script-error="scriptError"
  />
  <div v-else-if="!layoutStore.loading">
    <div class="file-table-resources padding">
      <div class="title-wrap">
        <h2>
          {{ t("sidebar.latestFolder") }}
        </h2>
      </div>

      <div v-if="fileItems.length > 0">
        <table class="file-table-lists">
          <thead>
            <tr>
              <th></th>
              <th>{{ t("files.name") }}</th>
              <th class="w-3/12">{{ t("settings.path") }}</th>
              <th class="w-2/12">{{ t("files.lastModified") }}</th>
            </tr>
          </thead>

          <tbody class="file-display-section last">
            <tr v-for="item in fileItems" :key="item.path">
              <td
                class="icon"
                :data-ext="item.extension"
                :data-dir="item.isDir"
                :data-type="item.type"
              >
                <i
                  :class="{ 'material-icons': true, 'folder filled': true }"
                ></i>
              </td>
              <td class="filename">
                <router-link :to="item.path">{{ item.name }}</router-link>
              </td>
              <td>
                <LocalBreadcrumbs base="" :path="item.path"></LocalBreadcrumbs>
              </td>
              <td>
                {{ humanTime(item.modified) }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <h2 v-else class="message">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="64"
          height="64"
          viewBox="0 0 2048 2048"
        >
          <path
            fill="currentColor"
            d="M1664 896q79 0 149 30t122 82t83 123t30 149q0 80-30 149t-82 122t-123 83t-149 30q-60 0-117-18t-105-53l-437 436q-19 19-45 19t-45-19t-19-45t19-45l436-437q-35-48-53-105t-18-117q0-79 30-149t82-122t122-83t150-30m0 640q53 0 99-20t82-55t55-81t20-100q0-53-20-99t-55-82t-81-55t-100-20q-53 0-99 20t-82 55t-55 81t-20 100q0 53 20 99t55 82t81 55t100 20m-1280 64q0 15 8 27t19 23q29 29 73 51t95 37t100 26t89 16v128h-8q-6 0-8-1q-39-5-90-15t-107-27t-108-40t-95-56t-68-74t-26-95V448q0-48 22-86t58-70t83-54t96-41t95-29t84-18q66-11 132-16t132-6q67 0 133 5t132 18q36 6 83 17t95 29t96 40t83 55t59 69t23 87v320h-128V637q-58 37-130 62t-148 40t-153 22t-145 7t-144-6t-153-22t-149-41t-130-62zM960 256q-57 0-130 6t-148 20t-143 40t-115 63q-14 11-27 27t-13 36q0 19 13 35t27 28q46 38 114 63t143 39t148 21t131 6q57 0 130-6t148-20t143-40t114-63q14-11 27-27t14-36q0-19-13-35t-28-28q-46-38-114-63t-142-39t-148-21t-131-6"
          />
        </svg>
        <span>{{ t("files.lonely") }}</span>
      </h2>
    </div>
    <div class="h-20"></div>
  </div>
</template>

<script setup lang="ts">
import { files as api } from "@/api";
import { StatusError } from "@/api/utils";
import { useAuthStore } from "@/stores/auth";
import moment from "moment/min/moment-with-locales";
import { useLayoutStore } from "@/stores/layout";
import LocalBreadcrumbs from "@/views/drive/latest/LocalBreadcrumbs.vue";
import FilesViewErrorGroup from "@/views/error/FilesViewErrorGroup.vue";
import { humanTime } from "@/class/FormatData";
import {
  componentHandleError,
  scriptError,
  statusError,
} from "@/utils/errorHandler";
import { onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
const authStore = useAuthStore();

const { t } = useI18n();

const layoutStore = useLayoutStore();
const error = ref<StatusError | null>(null);
const fileItems = ref<ResourceBase[]>([]);

onMounted(async () => {
  layoutStore.loading = true;

  try {
    const newRecent = await api.fetchRecentFiles(20, 0);

    const files = [];
    for (const item of newRecent) {
      // const { ext, name } = extractInfoFromPath(item.Path);
      const fileData: ResourceBase = {
        path: item.path,
        // username: item.username ?? undefined,
        isDir: item.isDir,
        type: item.type,
        name: item.name,
        modified: item.modified,
        extension: item.extension,
      };
      files.push(fileData);
    }

    fileItems.value = files;
  } catch (err) {
    componentHandleError(err);
  } finally {
    layoutStore.loading = false;
  }
});
</script>
