<template>
  <ul
    v-if="!hideOnSinglePage || totalPages > 1"
    v-bind="attrs"
    :class="[
      'flex items-center -space-x-px',
      paginationHeight,
      paginationTextSize,
    ]"
  >
    <li>
      <a
        href="#"
        :class="[
          'flex items-center justify-center',
          paginationPadding,
          paginationHeight,
          'ms-0 leading-tight',
          'border',
          'rounded-s-lg',
          prevButtonClass,
          {
            'cursor-not-allowed opacity-50': isFirstPage,
            'hover:bg-gray-100 hover:text-gray-700 dark:hover:bg-gray-700 dark:hover:text-white':
              !isFirstPage,
          },
        ]"
        :aria-disabled="isFirstPage"
        @click.prevent="goToPreviousPage"
      >
        <span class="sr-only">{{ prevText }}</span>
        <svg
          :class="['rtl:rotate-180', paginationIconSize]"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 6 10"
        >
          <path
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M5 1 1 5l4 4"
          />
        </svg>
      </a>
    </li>

    <li v-for="page in visiblePages" :key="page">
      <a
        href="#"
        :class="[
          'flex items-center justify-center',
          paginationPadding,
          paginationHeight,
          'leading-tight',
          'border',
          pageButtonClass(page),
          {
            'z-10 text-blue-600 border-blue-300 bg-blue-50 dark:border-gray-700 dark:bg-gray-700 dark:text-white':
              page === currentPage,
          },
        ]"
        @click.prevent="goToPage(page)"
      >
        {{ page }}
      </a>
    </li>

    <li>
      <a
        href="#"
        :class="[
          'flex items-center justify-center',
          paginationPadding,
          paginationHeight,
          'leading-tight',
          'border',
          'rounded-e-lg',
          nextButtonClass,
          {
            'cursor-not-allowed opacity-50': isLastPage,
            'hover:bg-gray-100 hover:text-gray-700 dark:hover:bg-gray-700 dark:hover:text-white':
              !isLastPage,
          },
        ]"
        :aria-disabled="isLastPage"
        @click.prevent="goToNextPage"
      >
        <span class="sr-only">{{ nextText }}</span>
        <svg
          :class="['rtl:rotate-180', paginationIconSize]"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 6 10"
        >
          <path
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="m1 9 4-4-4-4"
          />
        </svg>
      </a>
    </li>
  </ul>
</template>

<script setup lang="ts">
import { computed, ref, useAttrs, watch } from "vue";

// Props 정의
const props = defineProps({
  pageSize: { type: Number, default: 10 },
  total: { type: Number, required: true },
  currentPage: { type: Number, default: 1 },
  pagerCount: { type: Number, default: 5 },
  prevText: { type: String, default: "Previous" },
  nextText: { type: String, default: "Next" },
  disabled: { type: Boolean, default: false },
  hideOnSinglePage: { type: Boolean, default: false },
  size: { type: String, default: "normal" },
  // size: { type: String, default: 'normal', validator: value => ['small', 'normal'].includes(value) },
});
const attrs = useAttrs();

// Emits 정의
const emit = defineEmits([
  "update:currentPage",
  "size-change",
  "current-change",
  "prev-click",
  "next-click",
]);

// 현재 페이지 상태
const currentPage = ref(props.currentPage);

// 총 페이지 수 계산
const totalPages = computed(() => Math.ceil(props.total / props.pageSize));

// 첫 페이지 여부 체크
const isFirstPage = computed(() => currentPage.value === 1);

// 마지막 페이지 여부 체크
const isLastPage = computed(() => currentPage.value === totalPages.value);

// 표시할 페이지 목록 계산
const visiblePages = computed(() => {
  const halfPagerCount = Math.floor(props.pagerCount / 2);
  let startPage = Math.max(currentPage.value - halfPagerCount, 1);
  let endPage = Math.min(currentPage.value + halfPagerCount, totalPages.value);

  // 페이지가 부족할 때는 양쪽으로 채워줌
  if (endPage - startPage + 1 < props.pagerCount) {
    if (startPage === 1) {
      endPage = Math.min(startPage + props.pagerCount - 1, totalPages.value);
    } else if (endPage === totalPages.value) {
      startPage = Math.max(endPage - props.pagerCount + 1, 1);
    }
  }

  const pages = [];
  for (let i = startPage; i <= endPage; i++) {
    pages.push(i);
  }
  return pages;
});

// limit와 offset으로 페이지 계산
// const limit = ref(props.pageSize);
// const offset = computed(() => (currentPage.value - 1) * limit.value);

// watch(currentPage, (newPage) => {
//   // 페이지가 변경될 때 offset을 계산하여 업데이트
//   emit('current-change', newPage);
// });

// 페이지 이동 함수들
const goToPage = page => {
  if (props.disabled || page === currentPage.value) return;
  currentPage.value = page;
  emit("update:currentPage", currentPage.value);
  emit("current-change", currentPage.value);
};

const goToPreviousPage = () => {
  if (props.disabled || isFirstPage.value) return;
  goToPage(currentPage.value - 1);
  emit("prev-click", currentPage.value);
};

const goToNextPage = () => {
  if (props.disabled || isLastPage.value) return;
  goToPage(currentPage.value + 1);
  emit("next-click", currentPage.value);
};

// 버튼 클래스 계산 함수들
const prevButtonClass = computed(
  () =>
    "text-gray-500 bg-white border-gray-300 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400"
);
const nextButtonClass = computed(
  () =>
    "text-gray-500 bg-white border-gray-300 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400"
);
const pageButtonClass = page =>
  page === currentPage.value
    ? ""
    : "text-gray-500 bg-white border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white";

// 크기에 따른 클래스 계산
const paginationHeight = computed(() =>
  props.size === "small" ? "h-8" : "h-10"
);
const paginationTextSize = computed(() =>
  props.size === "small" ? "text-sm" : "text-base"
);
const paginationPadding = computed(() =>
  props.size === "small" ? "px-3" : "px-4"
);
const paginationIconSize = computed(() =>
  props.size === "small" ? "w-2.5 h-2.5" : "w-3 h-3"
);
</script>

<style scoped>
/* 필요하다면 추가적인 스타일 정의 */
</style>
