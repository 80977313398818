<template>
  <AiaModalContainer hide-close @close="closeHovers">
    <template #title>
      {{ t('prompts.deleteTitle') }}
    </template>
    <template #body>
      <div class="pb-5">
        <span v-if="!isListing || selectedCount === 1">
          {{ t('prompts.deleteMessageSingle') }}
        </span>
        <span v-else>
          {{ t('prompts.deleteMessageMultiple', { count: selectedCount }) }}
        </span>
      </div>

      <div class="flex justify-end mb-2 space-x-2">
        <AiaButton
          ref="deleteButton"
          type="danger"
          :loading="isLoading"
          :aria-label="t('buttons.delete')"
          :title="t('buttons.delete')"
          tabindex="1"
          @click="submit"
        >
          {{ t('buttons.delete') }} (Enter)
        </AiaButton>

        <AiaButton
          ref="cancelButton"
          type="info"
          :aria-label="t('buttons.cancel')"
          :title="t('buttons.cancel')"
          tabindex="2"
          @click="closeHovers"
        >
          {{ t('buttons.cancel') }}
        </AiaButton>
      </div>
    </template>
  </AiaModalContainer>
</template>

<script setup lang="ts">
import { files as api } from '@/api';
import { StatusError } from '@/api/utils';
import { getFileTaskSuccessMsg } from '@/class/FileTaskToastMessage';
import AiaModalContainer from '@/components/dialog/AiaModalContainer.vue';
import AiaButton from '@/components/form/AiaButton.vue';
import { useFileStore } from '@/stores/file';
import { useLayoutStore } from '@/stores/layout';
import { storeToRefs } from 'pinia';
import { onBeforeUnmount, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import { toast } from 'vue3-toastify';

const { t } = useI18n();
// 스토어 및 라우터 사용
const fileStore = useFileStore();
const layoutStore = useLayoutStore();
const route = useRoute();

const deleteButton = ref(null);
const cancelButton = ref(null);

// 상태 및 액션 가져오기
const { isListing, selectedCount, req, selected, reload } =
  storeToRefs(fileStore);
const { closeHovers } = layoutStore;
const isLoading = ref(false);

onMounted(() => {
  window.addEventListener('keydown', keyEvent);
});

onBeforeUnmount(() => {
  window.removeEventListener('keydown', keyEvent);
});

const keyEvent = (event: KeyboardEvent) => {
  if (event.key === 'Enter') {
    submit();
  }
};
// submit 함수 정의
const submit = async () => {
  isLoading.value = true;

  window.sessionStorage.setItem('modified', 'true');
  try {
    if (!isListing.value) {
      await api.remove(route.path);
      isLoading.value = false;

      closeHovers();
      toast.success(
        t('fileMessages.deleteSuccess', { cnt: 1 }) + isListing.value
      );
      return;
    }

    closeHovers();

    if (selectedCount.value === 0) {
      return;
    }

    const promises = [];
    const taskItems = [];
    for (const index of selected.value) {
      promises.push(api.remove(req.value.items[index].url));
      taskItems.push(req.value.items[index]);
    }

    await Promise.all(promises);
    isLoading.value = false;
    reload.value = true;

    const successMsg = getFileTaskSuccessMsg(
      taskItems.length,
      t('buttons.delete'),
      taskItems[0].isDir
    );
    toast.success(successMsg);
  } catch (e) {
    isLoading.value = false;

    if (e instanceof StatusError) {
      if (e.status === 403) {
        toast.error(
          t('message.forbiddenMsg', { action: t('message.actionDelete') })
        );
      } else {
        toast.error(String(e));
      }
    } else {
      toast.error(String(e));
    }
    if (isListing.value) reload.value = true;
  }
};
</script>
