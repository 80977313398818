<script setup lang="ts">
import { Directory } from "@/class/interface/drive/file";
import { useFilesLayoutStore } from "@/stores/filesLayout";
import { computed, onMounted, reactive, ref, watch } from "vue";
import { ThemeModeValue, useSettingsStore } from "@/stores/setting";
import { PhFolder } from "@phosphor-icons/vue";

const settings = useSettingsStore();
const filesLayoutStore = useFilesLayoutStore();
defineOptions({
  name: "DirectoryTreeItem",
});

const props = withDefaults(
  defineProps<{
    data: Directory | null;
    select?: string | null;
    maxDepth?: number;
    canSelect?: boolean;
    isRoot?: boolean; // 루트 여부를 판단하기 위한 prop 추가
  }>(),
  {
    select: null,
    maxDepth: 1,
    canSelect: false,
    isRoot: false, // 기본값은 false로 설정
  }
);

const treeKey = ref("treeKey" + props.maxDepth);
const openMaxDeps = ref(props.maxDepth);

onMounted(() => {
  if (props.isRoot && filesLayoutStore.rootTreeOpened) {
    state.open = true; // 루트가 열린 상태로 설정
  }
});

watch(
  () => props.maxDepth,
  newData => {
    treeKey.value = "treeKey" + newData;
    openMaxDeps.value = newData;
  }
);

const emit = defineEmits<{
  (e: "selected", value: Directory): void;
}>();

const state = reactive({
  open:
    props.data &&
    props.data.subDirCount > 0 &&
    props.data.subDirectories &&
    props.data.subDirectories.length > 0 &&
    props.data.subDirectories[0].subDirCount > 0 &&
    props.data.subDirectories[0].subDirectories?.length &&
    props.data.subDirectories[0].subDirectories.length > 0
      ? props.data.subDirectories[0].subDirectories[0].subDirCount <
        openMaxDeps.value
      : props.data?.subDirCount < openMaxDeps.value,
});

const toggleOpen = (): void => {
  state.open = !state.open;
};

// 루트 폴더일 경우 계속 열려있게
const openOnce = (): void => {
  if (!state.open) {
    state.open = true;
    filesLayoutStore.updateRootTreeOpen(true);
  }
};

const onSelected = (data: Directory, func?): void => {
  emit("selected", data);
  if (func && typeof func === "function") func();
};

const classes = computed(() => ({
  "chevron-arrow": true,
  opened: state.open,
}));
</script>
<template>
  <div
    v-if="data"
    :key="treeKey"
    :class="{
      'aia-directory-tree-item': true,
      dark: settings.getAppliedTheme === ThemeModeValue.Dark,
      'root-item': props.isRoot, // 루트 항목인 경우 클래스를 추가
    }"
  >
    <div>
      <div class="flex items-center">
        <button
          :class="{ 'btn-toggle': true, 'is-not-child': data.subDirCount == 0 }"
          :aria-expanded="state.open ? 'true' : 'false'"
          @click.stop="toggleOpen"
        >
          <div :class="classes" />
        </button>
        <div
          :class="[
            {
              'subject-wrap': true,
              'selected-key': !props.isRoot && select === data.path,
            },
          ]"
          @click="props.isRoot ? onSelected(data, openOnce) : onSelected(data)"
        >
          <template v-if="props.isRoot">
            <!-- 루트 항목인 경우 -->
            <slot>{{ data.name }}</slot>
          </template>
          <template v-else>
            <!-- 루트가 아닌 경우 기존 로직 유지 -->
            <PhFolder size="20" weight="thin" />
            <span class="subject">{{ data.name }}</span>
            <span v-if="data.subDirCount > 0" class="subdir-count">
              ({{ data.subDirCount }})
            </span>
          </template>
        </div>
      </div>
      <!--      <div v-if="props.isRoot" class="h-1">&lt;!&ndash;space&ndash;&gt;</div>-->

      <div
        v-if="
          state.open && data.subDirectories && data.subDirectories.length > 0
        "
      >
        <DirectoryTreeItem
          v-for="child in data.subDirectories"
          :key="child.path"
          :data="child"
          :select="select"
          :max-depth="openMaxDeps"
          :can-select="canSelect"
          @selected="onSelected"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.aia-directory-tree-item {
  //border: 1px solid #ccc;
  --arrow-size: 6px;
  --arrow-color: #888;
  --hover-color: rgba(0, 0, 0, 0.1);
  --select-color: rgba(118, 169, 250, 0.9);
  margin-left: 0;
  height: auto;
  overflow: hidden;

  @apply text-black dark:text-gray-200;
  //margin-right: 3px;
  &.dark {
    --arrow-color: #ccc;
    --hover-color: rgba(255, 255, 255, 0.1);
  }
}

.aia-directory-tree-item:not(.root-item) {
  margin-left: 15px;
}

.subject-wrap {
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: start;
  justify-content: space-between;
  padding: 4px 5px 4px 5px;
  margin: 0 0 4px 0;
  cursor: pointer;
  border-radius: 5px;
  white-space: nowrap;

  &:hover {
    background-color: var(--hover-color);
  }

  &.selected-key {
    background-color: var(--select-color);
    & > * {
      color: #fff;
    }
    &:hover {
      opacity: 0.9;
    }
  }

  svg {
    width: 20px;
    margin-right: 5px;
    flex-shrink: 0;
  }
}

.subject {
  flex-grow: 1;
  flex-shrink: 1;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
}

.subdir-count {
  flex-shrink: 0;
  margin-left: 10px;
  font-size: 10px;
  color: #999;
}

.btn-toggle {
  color: var(--arrow-color);
  height: 30px;
  width: 15px;
  border-radius: 20px;

  &.is-not-child {
    opacity: 0.1;
  }

  &:hover {
    .chevron-arrow {
      border-right: 1px solid var(--select-color);
      border-bottom: 1px solid var(--select-color);
    }
  }
}

.chevron-arrow {
  transition:
    transform 0.1s ease,
    border-color 0.1s ease;
  border-right: 1px solid var(--arrow-color);
  border-bottom: 1px solid var(--arrow-color);
  width: var(--arrow-size);
  height: var(--arrow-size);
  transform: rotate(-45deg);

  &.opened {
    transform: rotate(45deg);
  }
}
</style>
