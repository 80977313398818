<script setup lang="ts">
import { useLayoutStore } from "@/stores/layout";
import { PhFolderPlus } from "@phosphor-icons/vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const layoutStore = useLayoutStore();
const createFolder = () => {
  layoutStore.showHover("newDir");
};
</script>

<template>
  <div>
    <div
      class="btn-create flex items-center px-3 whitespace-nowrap"
      @click="createFolder"
    >
      <PhFolderPlus size="20" weight="thin" />
      <span>{{ t("prompts.newDir") }}</span>
    </div>
  </div>
</template>
