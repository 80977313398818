import FilesPage from "@/pages/FilesPage.vue";
import LoginPage from "@/pages/LoginPage.vue";
import ManagePage from "@/pages/ManagePage.vue";
import { useAuthStore } from "@/stores/auth";
import { validateLogin } from "@/utils/auth";
import { Directories, loginPage } from "@/utils/constants";
import FavoriteListsView from "@/views/drive/latest/FavoriteListsView.vue";
import RecentListsView from "@/views/drive/latest/RecentListsView.vue";
import Files from "@/views/files/Files.vue";
import ShareListsView from "@/views/shares/ShareListsView.vue";
import { createRouter, createWebHistory } from "vue-router";
import i18n from "./i18n";

const titles = {
  Login: "sidebar.login",
  Share: "buttons.share",
  Files: "files.files",
  Settings: "sidebar.settings",
  ProfileSettings: "settings.profileSettings",
  Shares: "settings.shareManagement",
  ConfigSettings: "adminMenu.configSettings",
  Users: "adminMenu.users",
  User: "settings.user",
  Forbidden: "errors.forbidden",
  NotFound: "errors.notFound",
  InternalServerError: "errors.internal",
};

/** @type {import('vue-router').RouterOptions['routes']} */
const routes = [
  {
    path: "/login",
    name: "LoginPage",
    component: LoginPage,
    children: null,
  },
  {
    path: "/signup",
    name: "SignUpPage",
    component: () => import("@/pages/SighUpPage.vue"),
    children: null,
  },
  { path: "/", redirect: "/teamspace" },
  {
    path: "/profile",
    name: "ProfileSettings",
    component: () => import("@/pages/MyPage.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  // {
  //   path: "/workspace",
  //   meta: { title: "Label Note" },
  //   component: WorkspacePage,
  //   // example of route level basic-splitting
  //   // this generates a separate chunk (About.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   // component: () => import('./views/LabelNote.vue')
  // },
  {
    path: "/teamspace",
    name: "TramSpace",
    component: FilesPage,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: ":path*",
        name: "TramFiles",
        component: Files,
      },
    ],
  },
  {
    path: "/page",
    name: "HomePageLists",
    component: FilesPage,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "favorite",
        name: "Favorite",
        component: FavoriteListsView, // import("@/views/drive/latest/FavoriteListsView.vue"),
      },
      {
        path: "latest",
        name: "Latest",
        component: RecentListsView, //import("@/views/drive/latest/RecentListsView.vue"),
      },
      {
        path: "share-list",
        name: "Lists",
        component: ShareListsView, //import("@/views/shares/ShareListsView.vue"),
      },
    ],
  },
  {
    path: "/share",
    name: "SharePage",
    component: FilesPage,
    children: [
      // {
      //   path: 'lists',
      //   name: 'Lists',
      //   component: () => import('@/views/shares/ShareListsView.vue'),
      // },
      {
        path: ":path*",
        name: "Share",
        component: () => import("@/views/shares/FileShare.vue"),
      },
    ],
  },
  {
    path: "/manages",
    name: "SettingPage",
    component: ManagePage,
    meta: {
      requiresAuth: true,
    },
    redirect: "/manages/alogs",
    children: [
      // {
      //   path: 'shares',
      //   name: 'Shares',
      //   component: () => import('@/views/shares/ShareListsView.vue'),
      // },
      {
        path: "config",
        name: "ConfigSettings",
        component: () => import("@/views/manage/ConfigView.vue"),
        meta: {
          requiresAdmin: true,
        },
      },
      {
        path: "alogs",
        name: "AuthLogsView",
        component: () => import("@/views/manage/AuthLogsView.vue"),
        meta: {
          requiresAdmin: true,
        },
      },
      {
        path: "users",
        name: "Users",
        component: () => import("@/views/manage/UsersView.vue"),
        meta: {
          requiresAdmin: true,
        },
      },
      {
        path: "users/:id",
        name: "User",
        component: () => import("@/views/manage/UserView.vue"),
        meta: {
          requiresAdmin: true,
        },
      },
    ],
  },
  {
    path: "/403",
    name: "Forbidden",
    component: () => import("@/views/error/FileComponentErrors.vue"),
    children: null,
    props: {
      errorCode: 403,
      title: "Forbidden",
      showHeader: true,
    },
  },
  {
    path: "/404",
    name: "NotFound",
    component: () => import("@/views/error/FileComponentErrors.vue"),
    children: null,
    props: {
      errorCode: 404,
      title: "Not Found",
      showHeader: true,
    },
  },
  {
    path: "/500",
    name: "InternalServerError",
    component: () => import("@/views/error/FileComponentErrors.vue"),
    children: null,
    props: {
      errorCode: 500,
      title: "Server Error",
      showHeader: true,
    },
  },
  {
    path: "/:catchAll(.*)*",
    children: null,
    redirect: to => `${Directories.base}/${[...to.params.catchAll].join("/")}`,
  },
];

async function initAuth() {
  if (loginPage) {
    await validateLogin();
  } else {
    // await login('', '', '');
  }
  // if (recaptcha) {
  //   await new Promise<void>((resolve) => {
  //     const check = () => {
  //       if (typeof window.grecaptcha === "undefined") {
  //         setTimeout(check, 100);
  //       } else {
  //         resolve();
  //       }
  //     };
  //
  //     check();
  //   });
  // }
}
export const router = createRouter({
  history: createWebHistory(),
  routes,
});

/**
 * 로그인 체크 및 제목
 */
router.beforeResolve(async (to, from, next) => {
  document.title = titles[to.name] ? i18n.global.t(titles[to.name]) : "setFn";

  const authStore = useAuthStore();

  // this will only be null on first route
  if (from.name == null) {
    try {
      await initAuth();
    } catch (error) {
      // console.error(error);
    }
  }

  if (to.path.endsWith("/login") && authStore.isLoggedIn) {
    next({ path: Directories.base });
    return;
  }

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!authStore.isLoggedIn) {
      next({
        path: "/login",
        query: { redirect: to.fullPath },
      });

      return;
    }

    if (to.matched.some(record => record.meta.requiresAdmin)) {
      if (authStore.user === null || !authStore.user.perm.admin) {
        next({ path: "/403" });
        return;
      }
    }
  }

  next();
});
