export class ValidateChecker {
  private strValue = '';

  public setValue(v) {
    this.strValue = v;
  }

  /*
   * @brief 영문 _
   */
  public isAlphabetUnderbar(): boolean {
    if (/[^0-9a-z_]+/i.exec(this.strValue)) return false;
    else return true;
  }

  /*
   * @brief 앞은 영문 , 주로 아이디검사
   */
  public isAlphabetNumberUnderbar(): boolean {
    if (!/^([a-z]+)([0-9a-z_]+)$/i.exec(this.strValue)) return false;
    else return true;
  }
  /*
   * @brief 주로 닉네임 검사에 쓰임
   */
  public isAlphabetNumberUnderbarHangul(): boolean {
    if (!/^[a-zA-Z0-9\_가-힣\x20]+/i.exec(this.strValue)) return false;
    else return true;
  }

  /*
   * @brief 주로 닉네임 검사에 쓰임
   */
  public isKorean(): boolean {
    if (!/^[가-힣\x20]+$/i.exec(this.strValue)) return false;
    else return true;
  }
  /*
   * @brief 이메일 주소 검사
   */
  public isEmail(): boolean {
    if (
      !/^([0-9a-zA-Z\_\-\.]+)@([0-9a-zA-Z\_\-]+)\.([0-9a-zA-Z\_\-]{1,6})$/i.exec(
        this.strValue
      )
    )
      return false;
    else return true;
  }
  /*
   * @brief 이메일 주소 검사
   */
  public isNumber(): boolean {
    if (!/^[0-9]+$/i.exec(this.strValue)) return false;
    else return true;
  }
  /*
   * @brief 영문만
   */
  public isAlphabet(): boolean {
    if (!/^[a-zA-Z]+$/i.exec(this.strValue)) return false;
    else return true;
  }

  /*
   * @brief 영문,숫자
   */
  public isAlphabetNumber(): boolean {
    if (!/^[a-zA-Z0-9]+$/i.exec(this.strValue)) return false;
    else return true;
  }

  /*
   * @brief 영문,숫자
   */
  public isUrl(): boolean {
    if (
      /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/.exec(
        this.strValue
      )
    )
      return false;
    else return true;
    // return false;
  }

  public isValidParamRules(rule_key: string, rule_val): boolean {
    const value = this.strValue ? this.strValue.toString().trim() : null;

    if (value && value.length > 0) {
      switch (rule_key) {
        //최소값
        case 'minlength':
          if (value.length < rule_val) return false;
          else return true;
        //최대값
        case 'maxlength':
          if (value.length > rule_val) return false;
          else return true;
        case 'korean':
          return this.isKorean();
        //영문
        case 'alpha':
          return this.isAlphabet();
        //영문 + _
        case 'alphaunderbar':
          return this.isAlphabetUnderbar();
        case 'alphanumber':
          return this.isAlphabetNumber();
        case 'alphanumberunderbar':
          return this.isAlphabetNumberUnderbar();
        //숫자
        case 'number':
          return this.isNumber();
        //이메일
        case 'email':
          return this.isEmail();
        case 'url':
          return this.isUrl();
        //사용자 정규식
        case 'regex':
          return !!new RegExp(rule_val).exec(value);
        case 'function':
          if (rule_val && typeof rule_val === 'function') {
            return !!rule_val(value);
          }
        default:
          return true;
      }
      return true;
    } else {
      //필수입력 체크
      if (rule_key === 'required') {
        if (rule_val === true && (!value || value.length == 0)) return false;
      }
    }

    return true;
  }
}
