<script setup lang="ts">
import { computed, useAttrs, watchEffect } from 'vue';
const attrs = useAttrs();
const props = defineProps({
  size: {
    type: String,
    default: 'base',
  },
  type: {
    type: String,
    default: 'primary',
  },
  plain: {
    type: Boolean,
    default: false,
  },
  round: {
    type: Boolean,
    default: false,
  },
  className: {
    type: String,
    default: '',
  },
  loading: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

const classStyleFunc = computed(() => {
  const classStyle = ['aia-button'];

  if (props.plain) {
    classStyle.push('plain');
  } else {
    classStyle.push(props.type);
  }
  if (props.round) {
    classStyle.push('round');
  }

  //disabled
  // if (props.disabled) {
  //   classStyle += " disabled";
  // }

  // size
  if (props.size) classStyle.push(props.size);

  // custom css
  if (props.className && props.className !== '') {
    classStyle.push(props.className);
  }

  return classStyle;
});
watchEffect(() => {
  classStyleFunc.value;
});
</script>

<template>
  <button
    v-bind="attrs"
    :class="classStyleFunc"
    :disabled="disabled || loading"
  >
    <template v-if="loading">
      <svg
        aria-hidden="true"
        role="status"
        class="inline w-4 h-4 me-3 animate-spin"
        viewBox="0 0 100 101"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
          fill="#E5E7EB"
        />
        <path
          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
          fill="currentColor"
        />
      </svg>
      <span v-if="type !== 'plain'">Loading...</span>
    </template>
    <div v-else class="flex items-center justify-center space-x-1">
      <slot />
    </div>
  </button>
</template>
<style lang="scss">
.aia-button {
  @apply rounded px-4 py-1.5;
  font-family: 'Inter var', ui-sans-serif;
  &.base {
    @apply py-2 px-5;
  }
  &.xs {
    @apply px-2.5 py-1 text-xs;
  }
  &.none {
    @apply text-xs;
  }
  &.sm {
    @apply px-3 py-1.5 text-xs;
  }
  &.md {
    @apply px-4 py-1.5 text-sm;
  }
  &.lg {
    @apply px-5 py-2 text-base font-medium;
  }
  &.xl {
    @apply px-6 py-2.5 text-base font-medium;
  }

  &.round {
    @apply rounded-full;
  }
  &:disabled {
    @apply opacity-40 cursor-not-allowed;
  }
  &.d-gray {
    &:disabled {
      @apply opacity-70 text-gray-400 bg-gray-200 dark:bg-gray-500/30 cursor-not-allowed;
    }
  }
  &.primary {
    @apply bg-blue-400 text-white;
    &.plain {
      @apply text-blue-500 hover:text-blue-600;
    }
    //hover
    &:not(:disabled) {
      &:not(.plain) {
        @apply hover:bg-blue-500 dark:hover:bg-blue-300 dark:hover:text-blue-700;
      }
    }
  }

  &.success {
    @apply bg-green-400 text-white;
    &.plain {
      @apply text-green-500 hover:text-green-600;
    }
    &:not(:disabled) {
      &:not(.plain) {
        @apply hover:bg-green-500 dark:hover:bg-blue-300 dark:hover:text-blue-700;
      }
    }
  }

  &.info {
    @apply border border-neutral-200 dark:border-gray-500;
    &.plain {
      @apply text-blue-500 hover:border-neutral-600;
    }
    &:not(:disabled) {
      &:not(.plain) {
        @apply hover:bg-neutral-100 dark:hover:bg-opacity-10;
      }
    }
  }

  &.warning {
    @apply bg-yellow-400 text-white;
    &.plain {
      @apply text-yellow-500 hover:text-yellow-600;
    }
    &:not(:disabled) {
      &:not(.plain) {
        @apply hover:bg-yellow-500 dark:hover:bg-blue-300 dark:hover:text-blue-700;
      }
    }
  }

  &.danger {
    @apply bg-red-500 text-white;
    &.plain {
      @apply text-red-500 hover:text-red-600;
    }
    &:not(:disabled) {
      &:not(.plain) {
        @apply hover:bg-red-600 dark:hover:bg-opacity-90;
      }
    }
  }
}
</style>
