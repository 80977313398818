<template>
  <AiaModalContainer @close="layoutStore.closeHovers">
    <template #title>
      <span v-if="dir">{{ t("prompts.folderInfo") }}</span>
      <span v-else>{{ t("prompts.fileInfo") }}</span>
    </template>
    <template #body>
      <div class="break-words space-y-1">
        <p v-if="selected.length > 1">
          {{ t("prompts.filesSelected", { count: selected.length }) }}
        </p>

        <div v-if="selected.length < 2" class="flex items-center">
          <!--          <strong>{{ t('prompts.displayName') }}</strong>-->
          <PhFolder v-if="dir" size="20" weight="thin" class="inline-block" />
          <PhFile v-else size="20" weight="thin" class="inline-block" />
          <span class="break-word ml-1 text-sm font-medium">{{ name }}</span>
        </div>

        <p v-if="!dir && selected.length > 1">
          <span>{{ t("prompts.size") }}:</span>
          <span id="content_length"></span> {{ humanSize }}
        </p>

        <div v-if="resolution">
          <span>{{ t("prompts.resolution") }}:</span>
          {{ resolution.width }} x {{ resolution.height }}
        </div>

        <p v-if="selected.length < 2" :title="modTime">
          <span>{{ t("prompts.lastModified") }}:</span> {{ defaultTime }}
        </p>

        <template v-if="dir && selected.length === 0">
          <p>
            <span>{{ t("prompts.numberFiles") }}:</span>
            {{ req.listInfo.totalFiles }}
          </p>
          <p>
            <span>{{ t("prompts.numberDirs") }}:</span>
            {{ req.listInfo.totalDirs }}
          </p>
        </template>

        <div v-if="folderInfo">
          <span>{{ t("files.creator") }}:</span> {{ folderInfo.creator }}
        </div>

        <div class="py-1"></div>
        <div v-if="folderInfo" class="space-y-2">
          <div v-if="folderInfo.levelPerm">
            <h3
              class="block mb-1 text-sm font-medium text-gray-900 dark:text-white"
            >
              {{ t("settings.level") }}
            </h3>
            <div class="space-x-2">
              <span>{{ t("level.auth_list") }} : </span>
              <span
                >{{ folderInfo.levelPerm.list }} {{ t("level.above") }}</span
              >
            </div>
            <div class="space-x-2">
              <span>{{ t("level.auth_view") }} : </span>
              <span
                >{{ folderInfo.levelPerm.view }} {{ t("level.above") }}</span
              >
            </div>
            <div class="space-x-2">
              <span>{{ t("level.auth_write") }} : </span>
              <span
                >{{ folderInfo.levelPerm.write }} {{ t("level.above") }}</span
              >
            </div>
          </div>

          <div v-if="folderInfo.explain">
            <h3
              class="block mb-1 text-sm font-medium text-gray-900 dark:text-white"
            >
              {{ t("files.explain") }}
            </h3>
            <div>{{ folderInfo.explain }}</div>
          </div>
        </div>

        <!--        <template v-if="!dir">-->
        <!--          <p>-->
        <!--            <span>MD5: </span>-->
        <!--            <code>-->
        <!--              <a-->
        <!--                tabindex="2"-->
        <!--                @click="checksum($event, 'md5')"-->
        <!--                @keypress.enter="checksum($event, 'md5')"-->
        <!--                >{{ t('prompts.show') }}</a-->
        <!--              >-->
        <!--            </code>-->
        <!--          </p>-->
        <!--          <p>-->
        <!--            <span>SHA1: </span>-->
        <!--            <code>-->
        <!--              <a-->
        <!--                tabindex="3"-->
        <!--                @click="checksum($event, 'sha1')"-->
        <!--                @keypress.enter="checksum($event, 'sha1')"-->
        <!--                >{{ t('prompts.show') }}</a-->
        <!--              >-->
        <!--            </code>-->
        <!--          </p>-->
        <!--          <p>-->
        <!--            <span>SHA256: </span>-->
        <!--            <code>-->
        <!--              <a-->
        <!--                tabindex="4"-->
        <!--                @click="checksum($event, 'sha256')"-->
        <!--                @keypress.enter="checksum($event, 'sha256')"-->
        <!--                >{{ t('prompts.show') }}</a-->
        <!--              >-->
        <!--            </code>-->
        <!--          </p>-->
        <!--          <p>-->
        <!--            <span>SHA512: </span>-->
        <!--            <code>-->
        <!--              <a-->
        <!--                tabindex="5"-->
        <!--                @click="checksum($event, 'sha512')"-->
        <!--                @keypress.enter="checksum($event, 'sha512')"-->
        <!--                >{{ t('prompts.show') }}</a-->
        <!--              >-->
        <!--            </code>-->
        <!--          </p>-->
        <!--        </template>-->
      </div>

      <div class="flex items-center justify-between my-4">
        <div></div>
        <AiaButton
          type="info"
          class="d-gray"
          :aria-label="t('buttons.ok')"
          :title="t('buttons.ok')"
          @click="closeHovers"
        >
          {{ t("buttons.close") }}
        </AiaButton>
      </div>
    </template>
  </AiaModalContainer>
</template>

<script setup lang="ts">
import { fetchMetadata } from "@/api/metadata";
import AiaModalContainer from "@/components/dialog/AiaModalContainer.vue";
import AiaButton from "@/components/form/AiaButton.vue";
import { useFileStore } from "@/stores/file";
import { useLayoutStore } from "@/stores/layout";
import { filesize } from "@/utils";
import { Directories } from "@/utils/constants";
import { PhFile, PhFolder } from "@phosphor-icons/vue";
import { computed, nextTick, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";

const route = useRoute();

const { t } = useI18n();
const fileStore = useFileStore();
const layoutStore = useLayoutStore();

const req = computed(() => fileStore.req);
const selected = computed(() => fileStore.selected);
const selectedCount = computed(() => fileStore.selectedCount);
const isListing = computed(() => fileStore.isListing);

const humanSize = computed(() => {
  if (selectedCount.value === 0 || !isListing.value) {
    return filesize(req.value.size);
  }

  let sum = 0;

  for (const selectedItem of selected.value) {
    sum += req.value.items[selectedItem].size;
  }

  return filesize(sum);
});

const defaultTime = computed(() => {
  if (selectedCount.value === 0) {
    return new Date(Date.parse(req.value.modified)).toLocaleString();
  }

  return new Date(
    Date.parse(req.value.items[selected.value[0]].modified)
  ).toLocaleString();
});

const modTime = computed(() => {
  if (selectedCount.value === 0) {
    return new Date(Date.parse(req.value.modified)).toLocaleString();
  }

  return new Date(
    Date.parse(req.value.items[selected.value[0]].modified)
  ).toLocaleString();
});

const name = computed(() => {
  if (selectedCount.value === 0 && "/" + req.value.name === Directories.base)
    return "/";
  return selectedCount.value === 0
    ? req.value.name
    : req.value.items[selected.value[0]].name;
});

const dir = computed(() => {
  if (selectedCount.value === 0) {
    // 선택된 항목이 없을 때 기본값을 반환
    return req.value.isDir;
  } else if (selectedCount.value > 1) {
    // 선택된 항목이 1개 이상일 때 모든 항목의 isDir을 검사
    return selected.value.every(index => req.value.items[index].isDir);
  } else {
    // 선택된 항목이 1개일 때 해당 항목의 isDir을 반환
    return req.value.items[selected.value[0]].isDir;
  }
});

const resolution = computed(() => {
  if (selectedCount.value === 1) {
    const selectedItem = req.value.items[selected.value[0]];
    if (selectedItem && selectedItem.type === "image") {
      return selectedItem["resolution"];
    }
  } else if (req.value && req.value.type === "image") {
    return req.value["resolution"];
  }
  return null;
});

const closeHovers = () => {
  layoutStore.closeHovers();
};

const folderInfo = ref<Metadata>(null);
onMounted(() => {
  nextTick(async () => {
    if (dir.value) {
      let folderPath = null;
      if (selected.value.length === 1) {
        const selectedItem = req.value.items[selected.value[0]];
        folderPath = selectedItem.path;
        // if (!folderPath.endsWith('/')) folderPath += '/'
      } else if (selected.value.length === 0) {
        folderPath = req.value.path;
      }
      if (folderPath && folderPath !== "/")
        folderInfo.value = await fetchMetadata(folderPath);
    }
  });
});
// const checksum = async (event: Event, algo) => {
//   event.preventDefault();
//
//   let link;
//
//   if (selectedCount.value) {
//     link = req.value.items[selected.value[0]].url;
//   } else {
//     link = route.path as string;
//   }
//
//   try {
//     const hash = await api.checksum(link, algo);
//     (event.target as HTMLElement).textContent = hash;
//   } catch (e) {
//     toast.error(e as string);
//   }
// };
</script>
<style scoped></style>
