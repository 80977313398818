<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps({
  type: {
    type: String,
    default: 'primary',
    validator: (value: string) =>
      ['primary', 'warning', 'error'].includes(value),
  },
  title: {
    type: String,
    default: '',
  },
  closable: {
    type: Boolean,
    default: false,
  },
});

const alertClasses = computed(() => {
  return {
    'bg-blue-100 border border-blue-400 text-blue-700 dark:bg-opacity-10 dark:text-blue-300 dark:border-blue-300/50':
      props.type === 'primary',
    'bg-yellow-100 border border-yellow-400 text-yellow-700 dark:bg-opacity-10 dark:text-yellow-300 dark:border-yellow-300/50':
      props.type === 'warning',
    'bg-red-100 border border-red-400 text-red-700 dark:bg-opacity-10 dark:text-red-300 dark:border-red-300/50':
      props.type === 'error',
    'px-4 py-3 rounded relative': true,
  };
});

const emit = defineEmits(['close']);

const closeAlert = () => {
  emit('close');
};
</script>

<template>
  <div
    class="font-normal text-sm bg-opacity-50 border-opacity-50"
    :class="alertClasses"
    role="alert"
  >
    <strong>{{ title }}</strong>
    <span><slot /></span>
    <span
      v-if="closable"
      class="cursor-pointer absolute top-0 bottom-0 right-0 px-4 py-3"
      @click="closeAlert"
    >
      <svg
        class="fill-current h-6 w-6"
        role="button"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
      >
        <title>Close</title>
        <path
          d="M14.348 5.652a.5.5 0 1 0-.707-.707L10 9.293 6.359 5.652a.5.5 0 0 0-.707.707L9.293 10l-3.64 3.641a.5.5 0 0 0 .707.707L10 10.707l3.641 3.64a.5.5 0 0 0 .707-.707L10.707 10l3.641-3.641z"
        />
      </svg>
    </span>
  </div>
</template>
