<template>
  <AiaModalContainer hide-close>
    <template #title>{{ t('buttons.share') }}</template>
    <template #body>
      <div class="pb-5">
        <p>
          {{
            t('prompts.deleteMessageShare', { path: currentPrompt.props?.path })
          }}
        </p>
      </div>
      <div class="flex justify-end mb-2 space-x-2">
        <AiaButton
          ref="deleteButton"
          type="danger"
          :aria-label="t('buttons.delete')"
          :title="t('buttons.delete')"
          tabindex="1"
          @click="currentPrompt.confirm"
        >
          {{ t('buttons.delete') }}
        </AiaButton>

        <AiaButton
          ref="cancelButton"
          type="info"
          :aria-label="t('buttons.cancel')"
          :title="t('buttons.cancel')"
          tabindex="2"
          @click="layoutStore.closeHovers"
        >
          {{ t('buttons.cancel') }}
        </AiaButton>
      </div>
    </template>
  </AiaModalContainer>
</template>

<script setup lang="ts">
import AiaModalContainer from '@/components/dialog/AiaModalContainer.vue';
import AiaButton from '@/components/form/AiaButton.vue';
import { useLayoutStore } from '@/stores/layout.js';
import { storeToRefs } from 'pinia';
import { useI18n } from 'vue-i18n';
const layoutStore = useLayoutStore();
const { currentPrompt } = storeToRefs(layoutStore);
const { t } = useI18n();
</script>
