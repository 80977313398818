<script setup lang="ts">
import { ref, watch } from 'vue';

const props = defineProps({
  invalidMsg: {
    type: [String, Array],
    default: null,
  },
});
const errorMessage = ref('');
watch(
  () => props.invalidMsg,
  () => {
    if (props.invalidMsg) {
      if (typeof props.invalidMsg === 'string') {
        errorMessage.value = props.invalidMsg;
      } else if (Array.isArray(props.invalidMsg)) {
        errorMessage.value = props.invalidMsg.join('<br />');
      }
    } else {
      errorMessage.value = '';
    }
  }
);
</script>

<template>
  <div
    v-if="errorMessage"
    class="invalid-feedback d-block"
    v-html="errorMessage"
  />
</template>
<style lang="scss" scoped>
.invalid-feedback {
  @apply text-red-500 dark:text-red-200 text-xs m-1;
}
</style>
