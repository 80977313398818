<script setup lang="ts"></script>

<template>
  <section>
    <div class="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
      <div class="mx-auto max-w-screen-sm text-center">
        <h1
          class="mb-4 text-4xl tracking-tight font-bold text-gray-900 md:text-5xl dark:text-white"
        >
          <slot name="title"> Not found </slot>
        </h1>
        <p class="mb-1 text-lg font-light text-gray-500 dark:text-gray-400">
          <slot name="explain"> Sorry, we can't find that content </slot>
        </p>
        <slot name="body">
          <a
            href="/"
            class="inline-flex text-white bg-blue-400 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:focus:ring-primary-900 my-4"
            >Back to Home</a
          >
        </slot>
      </div>
    </div>
  </section>
</template>

<style scoped lang="scss"></style>
