<template>
  <div class="list-empty-upload">
    <div class="inner-wrap relative">
      <ph-upload-simple :size="60" class="m-auto mb-5" />
      <h5 class="text-xl text-gray-600 dark:text-gray-400">
        <span>{{ t("files.listEmpty") }}</span>
      </h5>
    </div>
  </div>
</template>
<script setup lang="ts">
import { PhUploadSimple } from "@phosphor-icons/vue";
import { useI18n } from "vue-i18n";
const { t } = useI18n();
</script>
<style lang="scss" scoped>
.list-empty-upload {
  @apply min-w-40 overflow-hidden rounded-xl block border-2 border-dashed text-center p-1 mr-5 mb-10
        dark:bg-dark-surface-sec/50;
  .inner-wrap {
    @apply overflow-hidden rounded-xl shadow block border border-dashed dark:border-gray-600 pb-32 text-center
    dark:border-white/10
    px-2 py-10 md:px-28 md:pt-20;
  }
}
</style>
