import { debounce } from "lodash-es";

export const ViewerConst = {
  From: {
    Files: "files",
    None: "none",
  },
};

export const EditorAction = {
  Layout: "layout",
  GenerateSQL: "generateSQL",
  EditorMenu: "editorMenu",
};

export const EditorMenuMode = {
  Find: "find",
  SelectedFolder: "sf",
};

export const LayoutViewMode = {
  Maximize: "maximize",
  Restore: "restore ",
  Minimize: "minimize",
};

export const TabName = {
  Preview: "view",
  TextEditor: "editor",
  Dataset: "data",
  Generator: "gen",
  Parser: "parser",
};

let isPreloaded = false;
const loadedComponents = {};

export const preloadComponents = async statusCallback => {
  if (isPreloaded) return;
  isPreloaded = true;

  const components = [
    {
      name: "MediaPreview",
      loader: () => import("@/views/viewer/media/MediaPreview.vue"),
    },
    {
      name: "BasicEditor",
      loader: () => import("@/views/viewer/basic/BasicEditorViewerMain.vue"),
    },
    {
      name: "MarkdownEditor",
      loader: () => import("@/views/viewer/markdown/MarkdownEditorMain.vue"),
    },
    {
      name: "JsonEditor",
      loader: () => import("@/views/viewer/json/JsonEditorViewerMain.vue"),
    },
    {
      name: "SchemaEditViewer",
      loader: () => import("@/views/viewer/schema/SchemaEditViewerMain.vue"),
    },
  ];

  // 컴포넌트를 순차적으로 로드하고 상태 메시지 콜백 호출
  for (const component of components) {
    if (loadedComponents[component.name]) {
      // 이미 로드된 경우 상태 콜백 호출만 수행
      if (statusCallback && typeof statusCallback === "function") {
        statusCallback(`Loaded from cache: ${component.name}`, "cache", true);
      }
      continue; // 이미 로드된 경우 로딩을 건너뜁니다.
    }

    await new Promise(resolve => {
      if ("requestIdleCallback" in window) {
        window.requestIdleCallback(async () => {
          await component.loader();
          loadedComponents[component.name] = true; // 로드 완료 후 캐시에 저장
          if (statusCallback && typeof statusCallback === "function") {
            statusCallback(`Plugin Loaded: ${component.name}`, "loaded");
          }
          resolve(true);
        });
      } else {
        // 폴리필: setTimeout을 통해 대체
        setTimeout(async () => {
          await component.loader();
          loadedComponents[component.name] = true; // 로드 완료 후 캐시에 저장
          if (statusCallback && typeof statusCallback === "function") {
            statusCallback(`Plugin Loaded: ${component.name}`, "loaded");
          }
          resolve(true);
        }, 0);
      }
    });
  }

  // 모든 컴포넌트가 로드된 후에만 "finished" 메시지를 콜백으로 전달
  if (statusCallback && typeof statusCallback === "function") {
    statusCallback("Plugin All Loaded", "", true);
  }
};
