const name = "setFn";
const baseURL = "";
// const staticURL: string = '';
const recaptcha = "";
// const recaptchaKey: string = '';
const signup = false;
const version = "0.0.1";
const noAuth = false;
// const authMethod = 'json';
const loginPage = true;
const theme: UserTheme = "";
const enableThumbs = true;
const resizePreview = true;
const enableExec = true;
const tusSettings = { chunkSize: 20485760, retryCount: 5 };
// const originWebURL = window.location.origin;
const tusEndpoint = `/api/tus`;
const Directories = {
  base: "/teamspace",
};
const fileListLimit = 2;

export {
  name,
  baseURL,
  recaptcha,
  signup,
  version,
  noAuth,
  loginPage,
  theme,
  enableThumbs,
  resizePreview,
  enableExec,
  tusSettings,
  tusEndpoint,
  Directories,
  fileListLimit,
};
