<script setup lang="ts">
import { files as api } from '@/api';
import { cleanSuffixUrl, removePrefixUrl } from '@/api/utils';
import AiaPagnation from '@/components/basic/AiaPagnation.vue';
import { router } from '@/routes';
import { useFileStore } from '@/stores/file';
import { changeResource } from '@/views/files/listing/fetchResource';
import { onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import { toast } from 'vue3-toastify';

const fileStore = useFileStore();
const route = useRoute();

const totalItems = ref<number>(0);
const limit = ref<number>(1000);
const offset = ref<number>(0);
const currentPage = ref<number>(1);
const listInfo = fileStore.req.listInfo;

onMounted(() => {
  totalItems.value = listInfo.totalDirs + listInfo.totalFiles;
  limit.value =
    isNaN(listInfo.limit) || listInfo.limit <= 0 ? 2 : listInfo.limit;
  offset.value =
    isNaN(listInfo.offset) || listInfo.offset < 0 ? 0 : listInfo.offset;
});

const goPage = (page: number) => {
  // 페이지 번호에 따른 offset 계산
  currentPage.value = isNaN(page) || page <= 0 ? 1 : page;
  offset.value = (currentPage.value - 1) * limit.value;

  // URL 상단에 필요한 파라미터 추가하기
  let pageUrl = `?limit=${limit.value}&offset=${offset.value}`;
  if (fileStore.req.listInfo?.sorting) {
    const sortAsc = fileStore.req.listInfo.sorting.asc ? '1' : '0';
    pageUrl += `&sort=${fileStore.req.listInfo.sorting.by}&asc=${sortAsc}`;
  }

  changeResource(route, fileStore, pageUrl);

  // fileStore.reload = true
};

// const changeResource = async (pageUrl: string) => {
//   fileStore.reload = false;
//   fileStore.selected = [];
//   fileStore.multiple = false;
//
//   // Set loading to true and reset the error.
//   let url = route.path;
//   url = removePrefixUrl(url);
//   try {
//     const res = await api.fetch(url, pageUrl);
//     if (cleanSuffixUrl(res.path) !== cleanSuffixUrl(`/${[...route.params.path].join('/')}`)) {
//       throw new Error('Data Mismatch!');
//     }
//     fileStore.updateRequest(res);
//   } catch (err) {
//     if (err instanceof Error) {
//       toast.error(String(err));
//     }
//   } finally {
//   }
// };
</script>
<template>
  <!-- 페이징 -->
  <div v-if="totalItems > limit" class="flex justify-between py-2">
    <div></div>
    <AiaPagnation
      :total="totalItems"
      :current-page="currentPage"
      :page-size="limit"
      @current-change="goPage"
    />
    <div></div>
  </div>
</template>
