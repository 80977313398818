<script setup lang="ts">
import { files as api } from '@/api';
import { StatusError } from '@/api/utils';
import { CheckValidation } from '@/class/files/CheckValidation';
import AiaModalContainer from '@/components/dialog/AiaModalContainer.vue';
import AiaButton from '@/components/form/AiaButton.vue';
import AiaInput from '@/components/form/AiaInput.vue';
import { useFileStore } from '@/stores/file';
import { useLayoutStore } from '@/stores/layout';
import url from '@/utils/url';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';
import { toast } from 'vue3-toastify';

const props = defineProps({
  base: String,
  redirect: {
    type: Boolean,
    default: false,
  },
});

const fileStore = useFileStore();
const layoutStore = useLayoutStore();

const route = useRoute();
const router = useRouter();
const { t } = useI18n();

const checkValidation = new CheckValidation();

const name = ref<string>('');
const isInputMode = ref<boolean>(false); //X버튼 클릭시 유효성 검사 없애기

const addInvalidMsg = ref<string>(''); // 유효성 검사 메시지
// isDisabled 변수로 버튼 활성화 상태를 컴팩트하게 처리
const isDisabled = computed(() => {
  return (
    !name.value ||
    !checkValidation.isValidFolderName(name.value) ||
    checkFolderConflict()
  );
});

const submit = async (event: Event) => {
  event.preventDefault();
  if (name.value === '') return;

  // 충동 여부 확인
  // const isConflict = checkFolderConflict();
  // if (isConflict) {
  //   toast.error(t('fileMessages.conflict'));
  //   layoutStore.closeHovers();
  //   return;
  // }
  if (checkFolderConflict()) {
    return;
  }

  // Build the path of the new directory.
  let uri: string;
  if (props.base) uri = props.base;
  else if (fileStore.isFiles) uri = route.path + '/';
  else uri = '/';

  if (!fileStore.isListing) {
    uri = url.removeLastDir(uri) + '/';
  }

  uri += encodeURIComponent(name.value) + '/';
  uri = uri.replace('//', '/');
  // console.log('uri', uri);

  try {
    await api.post(uri);
    if (props.redirect) {
      await router.push({ path: uri });
    } else if (!props.base) {
      const res = await api.fetch(url.removeLastDir(uri) + '/');
      fileStore.updateRequest(res);
    }
    toast.success(t('message.folderTask', { action: t('buttons.create') }));
    fileStore.reload = true;
  } catch (e) {
    if (e instanceof StatusError) {
      if (e.status === 403) {
        toast.error(
          t('message.forbiddenMsg', { action: t('message.actionNewFolder') })
        );
      } else if (e.status === 409) {
        toast.error(t('message.conflictNameMsg'));
      } else {
        toast.error(String(e));
      }
    } else {
      toast.error(String(e));
    }
  }
  layoutStore.closeHovers();
};
// 파일 스토어 내에 동일한 폴더명이 존재하는지 확인
const checkFolderConflict = () => {
  // return fileStore.req.items.some(
  //   item => item.isDir && item.name === name.value
  // );
  return fileStore.req?.items.some(item => item.name === name.value);
};

const isValidNotConflictRule = nm => {
  isInputMode.value = true;
  return !fileStore.req?.items.some(item => item.name === nm);
};

const handleClose = () => {
  isInputMode.value = false;
  layoutStore.closeHovers();
};
</script>
<template>
  <AiaModalContainer @close="handleClose">
    <template #title>{{ t('prompts.newDir') }}</template>
    <template #body>
      <AiaInput
        v-model:model-value="name"
        :rules="[
          {
            required: isInputMode,
            message: t('prompts.newDirMessage'),
            trigger: 'blur',
          },
          {
            message: t('fileMessages.conflict'),
            function: isValidNotConflictRule,
            trigger: 'live',
          },
          {
            message: t('fileMessages.invalidName'),
            function: checkValidation.isValidFolderName,
            trigger: 'live',
          },
        ]"
        focus
        class="input-blue"
        class-name="text-sm px-3 w-full rounded-md border-0 ring-1 ring-inset ring-gray-300 dark:bg-gray-800"
        valid-class="ring-gray-300"
        invalid-class="ring-red-500"
        type="text"
        :placeholder="t('prompts.newDirMessage')"
        :add-invalid-msg="addInvalidMsg"
        @keyup.enter="submit"
      />

      <div class="flex items-center justify-between my-4">
        <div></div>
        <AiaButton
          class="d-gray"
          :disabled="isDisabled"
          :aria-label="t('buttons.create')"
          :title="t('buttons.create')"
          tabindex="2"
          @click="submit"
        >
          {{ t('buttons.create') }}
        </AiaButton>
      </div>
    </template>
  </AiaModalContainer>
</template>
