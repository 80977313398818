import { defineStore } from 'pinia';
// const folderViewModeSaveId = 'folderViewMode';
export const useFilesLayoutStore = defineStore('fileLayout', {
  // convert to a function
  state: (): {
    viewMode: string;
    rOpened: boolean;
  } => ({
    viewMode: '', //localStorage.getItem(folderViewModeSaveId) || '',
    rOpened: false,
  }),
  getters: {
    folderViewMode(state): string {
      return state.viewMode;
    },
    rootTreeOpened(state): boolean {
      return state.rOpened;
    },
  },
  actions: {
    updateFolderViewMode(mode: string | null) {
      this.viewMode = mode;
    },
    updateRootTreeOpen(mode: boolean) {
      this.rOpened = mode;
    },
  },
});
