<template>
  <div>
    <template v-if="jsonResError">
      <LevelAccessErrors
        v-if="jsonResError['id'] && jsonResError['requiredLevel']"
        :json-code="jsonResError"
      />
      <FileComponentErrors v-else :error-code="jsonResError['statusCode']" />
    </template>
    <template v-else-if="statusError || scriptError">
      <FilesViewErrorGroup
        :status-error="statusError"
        :script-error="scriptError"
      />
    </template>
    <template v-else>
      <div v-if="currentView">
        <component :is="currentView" />
      </div>
      <div v-else-if="currentView !== null">
        <NoContents />
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import { files as api } from "@/api/index.js";
import { cleanSuffixUrl, removePrefixUrl, StatusError } from "@/api/utils.js";
import { useFileStore } from "@/stores/file.js";
import { useLayoutStore } from "@/stores/layout.js";
import { useUploadStore } from "@/stores/upload.js";
import FileComponentErrors from "@/views/error/FileComponentErrors.vue";
import FilesViewErrorGroup from "@/views/error/FilesViewErrorGroup.vue";
import {
  componentHandleError,
  componentResetErrors,
  scriptError,
  statusError,
} from "@/utils/errorHandler";
import LevelAccessErrors from "@/views/error/LevelAccessErrors.vue";
import NoContents from "@/views/error/NoContents.vue";
import { getQueryParams } from "@/views/files/listing/fetchResource";
import FileListing from "@/views/files/listing/FileListing.vue";
import { preloadComponents } from "@/views/viewer/ViewerConstance";
// const ViewerSplashIndex = defineAsyncComponent(
//   () => import('@/views/viewer/ViewerSplashIndex.vue')
// );
import ViewerSplashIndex from "@/views/viewer/ViewerSplashIndex.vue";
import { storeToRefs } from "pinia";
import {
  computed,
  onBeforeUnmount,
  onMounted,
  onUnmounted,
  ref,
  watch,
} from "vue";
import { useRoute } from "vue-router";
import { toast } from "vue3-toastify";

const layoutStore = useLayoutStore();
const fileStore = useFileStore();
const uploadStore = useUploadStore();

const { reload } = storeToRefs(fileStore);
const { error: uploadError } = storeToRefs(uploadStore);

const route = useRoute();
onUnmounted(() => {
  layoutStore.hideFileInfo();
});
const oldSelectedItem = ref<ResourceItem | null>(null);
const error = ref<StatusError | null>(null);
const jsonResError = ref<any>(null);

const currentView = computed(() => {
  if (oldSelectedItem.value) {
    if (oldSelectedItem.value?.isDir) {
      return FileListing;
    } else {
      return ViewerSplashIndex;
    }
  } else {
    if (fileStore.req?.type === undefined) {
      return null;
    }
    if (fileStore.req.isDir) {
      return FileListing;
    } else {
      return ViewerSplashIndex;
    }
  }
});

// Define hooks
onMounted(() => {
  resourceFetchData();
  fileStore.isFiles = true;
  window.addEventListener("keydown", keyEvent);
  // View컴포넌트 초기화
  setTimeout(() => {
    // layoutStore.addInitMessage("Starting Embedded Plugin...");
    preloadComponents((msg: string, type: string, isFinished: boolean) => {
      if (type !== "cache") {
        if (isFinished) {
          layoutStore.clearInitMessage();
        } else {
          layoutStore.addInitMessage(msg);
        }
      } else {
        layoutStore.clearInitMessage();
      }
    });
  }, 500);
});

onBeforeUnmount(() => {
  window.removeEventListener("keydown", keyEvent);
});

onUnmounted(() => {
  fileStore.isFiles = false;
  // if (layoutStore.showShell) {
  //   layoutStore.toggleShell();
  // }
  fileStore.updateRequest(null);
});

watch(route, (to, from) => {
  if (from.path.endsWith("/")) {
    window.sessionStorage.setItem(
      "listFrozen",
      (!to.path.endsWith("/")).toString()
    );
  } else if (to.path.endsWith("/")) {
    fileStore.updateRequest(null);
  }

  // 현재 경로를 가져오고, prefix 제거
  let url = route.path;
  url = decodeURIComponent(removePrefixUrl(url));
  oldSelectedItem.value = null;

  // console.log(url,fileStore.req?.items)
  // fileStore.oldReq.items에서 url과 path가 같은 항목을 oldSelectedItem에 할당
  if (fileStore.req?.items) {
    oldSelectedItem.value =
      fileStore.req.items.find(item => item.path === url) || null;
  }

  // view 파일은 해당 viewer에서 해결
  if (!oldSelectedItem.value || oldSelectedItem.value?.isDir === true) {
    resourceFetchData();
  }
});
// watch(route, (to, from) => {
//   if (from.path.endsWith('/')) {
//     window.sessionStorage.setItem(
//       'listFrozen',
//       (!to.path.endsWith('/')).toString()
//     );
//   } else if (to.path.endsWith('/')) {
//     fileStore.updateRequest(null);
//   }
//   resourceFfetchData();
// });

// 리로딩 명령시
watch(reload, newValue => {
  if (newValue) {
    resourceFetchData(getQueryParams(route));
  }
});
// 에러 발생시
watch(uploadError, newValue => {
  newValue && layoutStore.showError();
});

// Define functions
const resourceFetchData = async (pageUrl = "") => {
  // Reset view information.
  fileStore.reload = false;
  fileStore.selected = [];
  fileStore.multiple = false;
  layoutStore.closeHovers();

  // Set loading to true and reset the error.
  if (
    window.sessionStorage.getItem("listFrozen") !== "true" &&
    window.sessionStorage.getItem("modified") !== "true"
  ) {
    layoutStore.loading = true;
  }
  componentResetErrors();
  jsonResError.value = null;

  let url = route.path;
  url = removePrefixUrl(url);

  try {
    const res = await api.fetch(url, pageUrl);

    if (
      cleanSuffixUrl(res.path) !==
      cleanSuffixUrl(`/${[...route.params.path].join("/")}`)
    ) {
      throw new Error("Data Mismatch!");
    }

    fileStore.updateRequest(res);
    if (res.path !== "/") document.title = `${res.name} - ${document.title}`; // `${res.name}`;
  } catch (err) {
    console.log(err);
    jsonResError.value = err;
    if (err instanceof StatusError) {
      if (err.jsonError) {
        jsonResError.value = err.jsonError;
      } else {
        componentHandleError(err);
      }
    } else {
      toast.error(String(err));
    }
  } finally {
    layoutStore.loading = false;
  }
};
const keyEvent = (event: KeyboardEvent) => {
  if (event.key === "F1") {
    event.preventDefault();
    layoutStore.showHover("help");
  }
};
</script>
<style lang="scss">
@use "@/assets/styles/file-listing.scss";
@use "@/assets/styles/file-table-listing.scss";
@use "@/assets/styles/file-listing-icons.scss";
</style>
