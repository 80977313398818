<template>
  <select
    name="selectLanguage"
    :value="locale"
    class="text-sm px-3 w-full rounded-md border-0 ring-1 ring-inset ring-gray-300 dark:bg-gray-800"
    placeholder="Language"
    @change="change"
  >
    <option v-for="(language, value) in locales" :key="value" :value="value">
      {{ language }}
    </option>
  </select>
</template>

<script>
import { markRaw } from "vue";

export default {
  name: "Languages",
  props: ["locale"],
  data() {
    const dataObj = {};
    const locales = {
      en: "English",
      ko: "한국어",
      ja: "日本語",
      // he: 'עברית',
      // hu: 'Magyar',
      // ar: 'العربية',
      // de: 'Deutsch',
      // el: 'Ελληνικά',
      // es: 'Español',
      // fr: 'Français',
      // is: 'Icelandic',
      // it: 'Italiano',
      // 'nl-be': 'Dutch (Belgium)',
      // pl: 'Polski',
      // 'pt-br': 'Português',
      // pt: 'Português (Brasil)',
      // ro: 'Romanian',
      // ru: 'Русский',
      // sk: 'Slovenčina',
      // 'sv-se': 'Swedish (Sweden)',
      // tr: 'Türkçe',
      // uk: 'Українська',
      // 'zh-cn': '中文 (简体)',
      // 'zh-tw': '中文 (繁體)',
    };

    // Vue3 reactivity breaks with this configuration
    // so we need to use markRaw as a workaround
    // https://github.com/vuejs/core/issues/3024
    Object.defineProperty(dataObj, "locales", {
      value: markRaw(locales),
      configurable: false,
      writable: false,
    });

    return dataObj;
  },
  methods: {
    change(event) {
      this.$emit("update:locale", event.target.value);
    },
  },
};
</script>
