<script setup lang="ts">
import AiaDropdown from '@/components/dropdown/AiaDropdown.vue';
import { useFileStore } from '@/stores/file';
import { useLayoutStore } from '@/stores/layout';
import { Directories } from '@/utils/constants';
import * as upload from '@/utils/upload';
import ScreenMediaRecorder from '@/views/command/ScreenMediaRecorder.vue';
import {
  PhFileCode,
  PhFilePlus,
  PhFolderPlus,
  PhRecord,
  PhXCircle,
} from '@phosphor-icons/vue';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';

const { t } = useI18n();
const route = useRoute();
const router = useRouter();
const fileStore = useFileStore();
const layoutStore = useLayoutStore();

const showMenu = ref(false);

const openUpload = (isFolder: boolean) => {
  hideMenu();
  const input = document.createElement('input');
  input.type = 'file';
  input.multiple = true;
  input.webkitdirectory = isFolder;
  // TODO: call the function in FileListing.vue instead
  input.onchange = uploadInput;
  input.click();
};

const createFile = async () => {
  if (!route.path.startsWith(Directories.base)) {
    await router.push(Directories.base);
  }
  layoutStore.showHover('newFile');
  hideMenu();
};

const createSchemaFile = async () => {
  if (!route.path.startsWith(Directories.base)) {
    await router.push(Directories.base);
  }
  layoutStore.showHover({
    prompt: 'newFile',
    props: {
      filename: 'NewSchema.tpl-json',
      filetype: 'aia-json',
    },
  });
  hideMenu();
};

const createFolder = async () => {
  // if (!route.path.startsWith(Directories.base)) {
  // }

  await router.push(Directories.base);
  layoutStore.showHover({
    prompt: 'newDir',
    props: {
      redirect: true,
    },
  });
  hideMenu();
};

const showScreenRecorder = ref(false);
const screenRecorder = () => {
  showScreenRecorder.value = !showScreenRecorder.value;
  hideMenu();
};

const uploadFile = () => {
  openUpload(false);
};
const uploadFolder = () => {
  openUpload(true);
};

const uploadInput = (event: Event) => {
  layoutStore.closeHovers();

  const files = (event.currentTarget as HTMLInputElement)?.files;
  if (files === null) return;

  const folder_upload = !!files[0].webkitRelativePath;

  const uploadFiles: UploadList = [];
  for (let i = 0; i < files.length; i++) {
    const file = files[i];
    const fullPath = folder_upload ? file.webkitRelativePath : undefined;
    uploadFiles.push({
      file,
      name: file.name,
      size: file.size,
      isDir: false,
      fullPath,
    });
  }

  const path = route.path.endsWith('/') ? route.path : route.path + '/';
  const conflict = upload.checkConflict(uploadFiles, fileStore.req!.items);

  // TODO 수정 요망
  if (conflict) {
    layoutStore.showHover({
      prompt: 'replace',
      action: (event: Event) => {
        event.preventDefault();
        layoutStore.closeHovers();
        upload.handleFiles(uploadFiles, path, false);
      },
      confirm: (event: Event) => {
        event.preventDefault();
        layoutStore.closeHovers();
        upload.handleFiles(uploadFiles, path, true);
      },
    });

    return;
  }

  upload.handleFiles(uploadFiles, path);
};
const hideMenu = () => {
  setTimeout(() => {
    showMenu.value = false;
  }, 300);
};

const onScreenRecorded = (filename: string) => {
  console.log(filename, 'saved');
  showScreenRecorder.value = false;
};
</script>

<template>
  <div v-if="showScreenRecorder" class="flex items-center mb-7">
    <ScreenMediaRecorder @saved="onScreenRecorded" />
    <PhXCircle
      :size="24"
      weight="thin"
      class="ml-2 hover:fill-blue-500 cursor-pointer"
      @click="showScreenRecorder = false"
    />
  </div>
  <AiaDropdown
    v-else
    v-model:show-menu="showMenu"
    class="mb-5"
    class-name="min-w-40"
    @select="hideMenu"
  >
    <button
      type="button"
      class="shadow text-white bg-blue-400 hover:bg-blue-500
      focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm
      px-3 py-2.5 text-center inline-flex items-center dark:focus:ring-[#3b5998]/55 me-2 mb-2"
    >
      <svg
        fill="currentColor"
        width="20"
        height="20"
        viewBox="0 0 24 24"
        focusable="false"
      >
        <path d="M20 13h-7v7h-2v-7H4v-2h7V4h2v7h7v2z"></path>
      </svg>
      <span class="mx-1 px-1">{{ t('sidebar.make') }}</span>
      <!--      <span>기능 추가</span>-->
    </button>
    <template #items>
      <ul class="drop-menu-items">
        <li class="line" @click="createFolder">
          <PhFolderPlus size="20" weight="thin" />
          <span>{{ t('prompts.newDir') }}</span>
        </li>
        <li @click="createFile">
          <PhFilePlus size="19" weight="thin" />
          <span>{{ t('prompts.newFile') }}</span>
        </li>
        <li class="line" @click="createSchemaFile()">
          <PhFileCode size="19" weight="thin" />
          <span>{{ t('prompts.newSchemaFile') }}</span>
        </li>
        <li @click="screenRecorder">
          <PhRecord :size="19" weight="thin" />
          <span>{{ t('prompts.screenRecorder') }}</span>
        </li>
      </ul>
    </template>
  </AiaDropdown>
</template>

<style scoped lang="scss"></style>
