<script setup lang="ts">
import LevelNumberInput from "@/components/files/LevelNumberInput.vue";
import { useAuthStore } from "@/stores/auth";
import { computed, watch } from "vue";
import { useI18n } from "vue-i18n";

const props = defineProps({
  modelValue: Object,
  path: String,
  viewMode: Boolean,
});

const emit = defineEmits(["update:modelValue", "change"]);
const authStore = useAuthStore();
const { t } = useI18n();

// 권한 목록 정의
const perm = {
  list: { label: t("settings.perm.list"), disabled: props.path == "/" },
  view: { label: t("settings.perm.view"), disabled: false },
  modify: { label: t("settings.perm.modify"), disabled: false },
  create: { label: t("settings.perm.create"), disabled: false },
  delete: { label: t("settings.perm.delete"), disabled: false },
  rename: { label: t("settings.perm.rename"), disabled: false },
  download: { label: t("settings.perm.download"), disabled: false },
};

// perm 객체를 배열 형태로 변환하고 disabled 상태 계산
const permArray = computed(() => {
  return Object.entries(perm).map(([key, { label, disabled }]) => ({
    key,
    label,
    disabled: disabled || !authStore.user?.level || authStore.user?.level === 0,
  }));
});

// levelPerm이 변경될 때마다 부모와 동기화
const levelPerm = computed({
  get: () => props.modelValue,
  set: value => emit("update:modelValue", value),
});

const onChange = (value: any) => {
  emit("change", value);
};
</script>

<template>
  <table class="level-setting">
    <thead>
      <tr>
        <th>{{ t("settings.permissions") }}</th>
        <th class="border-t border-b">{{ t("level.above") }}</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="permission in permArray" :key="permission.key">
        <th>{{ permission.label }}</th>
        <td v-if="!viewMode">
          <!-- 입력 모드 (viewMode가 false일 때) -->
          <LevelNumberInput
            v-model:model-value="levelPerm[permission.key]"
            :disabled="permission.disabled"
            :min="0"
            :max="authStore.user.level"
            @change="
              onChange(v => {
                return permission.key, v;
              })
            "
          />
        </td>
        <td v-else>
          <!-- 읽기 모드 (viewMode가 true일 때) -->
          <span
            class="text-md"
            :class="{
              'text-blue-500 font-bold dark:text-blue-200':
                levelPerm[permission.key] > 0,
            }"
            >{{ levelPerm[permission.key] }}</span
          >
        </td>
      </tr>
    </tbody>
  </table>
</template>

<style lang="scss">
table.level-setting {
  @apply text-gray-500 text-xs mb-5 border dark:border-gray-600;

  thead {
    th {
      @apply bg-gray-50 dark:bg-gray-700 border-b;
    }
  }

  tr th {
    @apply w-7/12 text-center py-2 border-r dark:border-gray-600;
    &.bg {
      @apply bg-gray-50 dark:bg-gray-700 border-b;
    }
  }

  tbody {
    @apply border-t dark:border-gray-600;
    tr {
      th {
        @apply border-b border-r text-center dark:border-gray-600 py-2 px-1.5;
      }
      td {
        @apply border-b text-center dark:border-gray-600;
      }
    }
  }
}
</style>
