import "core-js/stable/promise";
import { ThemeModeValue, useSettingsStore } from "@/stores/setting";
import { createApp } from "vue";
import { createPinia } from "pinia";
import { useI18n } from "vue-i18n";
import i18n from "./i18n";
import "./assets/styles/main.scss";
import "./assets/styles/fonts-ico.scss";
import "./assets/styles/fonts-2.scss";
import { router } from "./routes.js";
import App from "./App.vue";
import LazyDirective from "vue3-lazyload";
import { updateGlobalOptions } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

const app = createApp(App);

app.use(createPinia());
app.use(router);
// app.config.globalProperties.$josaPicker = josaPicker;
app.use(LazyDirective);

app.mixin({
  mounted() {
    // expose vue instance to components
    this.$el.__vue__ = this;
  },
});

// provide v-focus for components
app.directive("focus", {
  mounted: async el => {
    // initiate focus for the element
    el.focus();
  },
});

const settings = useSettingsStore();
settings.loadSettings();
const theme = settings.theme;
const msgTheme = theme === "dark" ? "light" : "dark";
updateGlobalOptions({
  theme: msgTheme,
  type: "success",
  position: "bottom-right",
  hideProgressBar: true,
  dangerouslyHTMLString: true,
});

// Initialize theme on load
settings.applyTheme(theme);
settings.applyDefaultLanguageCode();
// console.log('language',settings.language)
// initDefault Language
app.use(i18n);

// const vfm = createVfm();
// app.use(vfm);

router.isReady().then(() => app.mount("#app"));
const loadingElement = document.getElementById("loading");
if (loadingElement) {
  loadingElement.parentNode.removeChild(loadingElement);
}
// app.mount("#app");
