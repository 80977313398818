<template>
  <AiaModalContainer @close="layoutStore.closeHovers">
    <template #title>{{ t('prompts.rename') }}</template>
    <template #body>
      <div>
        <p class="mb-3">{{ t('prompts.renameMessage') }}</p>
        <AiaInput
          ref="inputField"
          v-model:model-value="name"
          :rules="[
            {
              required: true,
              message: t('invalid.required'),
              trigger: 'blur',
            },
          ]"
          class="input-blue"
          class-name="text-sm px-3 w-full rounded-md border-0 ring-1 ring-inset ring-gray-300 dark:bg-gray-800"
          valid-class="ring-gray-300"
          invalid-class="ring-red-500"
          type="text"
          :select-text="true"
          :placeholder="oldName"
          @keyup.enter="submit"
        />

        <div class="flex items-center justify-between my-4">
          <div></div>
          <button
            class="aia-btn-primary d-gray"
            :disabled="!name || oldName === name"
            :aria-label="t('buttons.rename')"
            :title="t('buttons.rename') + ' (Enter)'"
            tabindex="2"
            @click="submit"
          >
            {{ t('buttons.rename') }}
          </button>
        </div>
      </div>
    </template>
  </AiaModalContainer>
</template>

<script setup lang="ts">
import { files as api } from '@/api';
import { StatusError } from '@/api/utils';
import AiaModalContainer from '@/components/dialog/AiaModalContainer.vue';
import AiaInput from '@/components/form/AiaInput.vue';
import { useFileStore } from '@/stores/file';
import { useLayoutStore } from '@/stores/layout';
import url from '@/utils/url';
import { storeToRefs } from 'pinia';
import { computed, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { toast } from 'vue3-toastify';

const { t } = useI18n();

// 상태 및 액션 가져오기
const fileStore = useFileStore();
const layoutStore = useLayoutStore();
const router = useRouter();
const { req, selected, selectedCount, isListing, reload } =
  storeToRefs(fileStore);

const name = ref<string>('');
const oldName = computed(() => {
  if (!isListing.value) {
    return req.value?.name;
  }

  if (selectedCount.value === 0 || selectedCount.value > 1) {
    // This shouldn't happen.
    return '';
  }

  return req.value?.items[selected.value[0]].name;
});

const inputField = ref(null);

onMounted(() => {
  name.value = oldName.value;
});

const submit = async () => {
  let oldLink = '';
  let newLink = '';

  if (!isListing.value) {
    oldLink = req.value?.url;
  } else {
    oldLink = req.value?.items[selected.value[0]].url;
  }

  newLink = url.removeLastDir(oldLink) + '/' + encodeURIComponent(name.value);
  window.sessionStorage.setItem('modified', 'true');
  try {
    await api.move([{ from: oldLink, to: newLink }]);
    if (!isListing.value) {
      await router.push({ path: newLink });
      return;
    }
    reload.value = true;
  } catch (e) {
    if (e instanceof StatusError) {
      if (e.status === 403) {
        toast.error(t('message.forbiddenMsg', { action: t('prompts.rename') }));
      } else if (e.status === 409) {
        toast.error(t('message.conflictNameMsg'));
      } else {
        toast.error(String(e));
      }
    } else {
      toast.error(String(e));
    }
  }

  layoutStore.closeHovers();
};
</script>
