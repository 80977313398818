<template>
  <ManageHeader />
  <div class="app-container">
    <aside>
      <ul>
        <li
          v-for="menu in menuDefinitions"
          :key="menu.key"
          :class="{ selected: currentMenu === menu.key }"
          @click="handleSelectMenu(menu.key, menu.path)"
        >
          <span> <!--space--></span>
          <component
            :is="menu.icon"
            :size="19"
            weight="thin"
            class="inline-block mr-2"
          />
          <span>{{ t(menu.label) }}</span>
        </li>
      </ul>
    </aside>
    <main class="scroll-s-project">
      <div v-if="pageLoading" class="loading-overlay">
        <ManagePageLoading />
      </div>
      <router-view v-else />
    </main>
  </div>
</template>

<script setup lang="ts">
import { MenuOptions } from "@/class/ViewUtils";
import ManageHeader from "@/views/layout/ManageHeader.vue";
import ManagePageLoading from "@/views/manage/ManagePageLoading.vue";
import { PhHouse, PhSignIn, PhUsers } from "@phosphor-icons/vue";
import { computed, nextTick, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { onBeforeRouteUpdate, useRouter } from "vue-router";

const { t } = useI18n();
const router = useRouter();

const props = defineProps<{ menuKey?: string }>();

const pageLoading = ref(false);

router.beforeEach((to, from, next) => {
  pageLoading.value = true; // 로딩 시작
  next();
});

router.afterEach(() => {
  pageLoading.value = false;
});

// const authStore = useAuthStore();
// const user = computed(()=> authStore.user)
const MenuDef = {
  home: "1",
  alogs: "20",
  users: "30",
  config: "90",
};
const menuDefinitions: MenuOptions[] = [
  {
    key: MenuDef.home,
    label: "adminMenu.home",
    icon: PhHouse,
    path: "/teamspace",
  },
  {
    key: MenuDef.alogs,
    label: "adminMenu.authLogs",
    icon: PhSignIn,
    path: "/manages/alogs",
  },
  {
    key: MenuDef.users,
    label: "adminMenu.users",
    icon: PhUsers,
    path: "/manages/users",
  },
  {
    key: MenuDef.config,
    label: "adminMenu.configSettings",
    icon: PhUsers,
    path: "/manages/config",
  },
];

const currentMenu = computed(() => {
  const routePath = router.currentRoute.value.path;
  const matchedMenu = menuDefinitions.find(menu => menu.path === routePath);
  return matchedMenu ? matchedMenu.key : props.menuKey || MenuDef.alogs;
});

const handleSelectMenu = (idx: string, path: string) => {
  router.push({ path: path });
};
</script>
<style lang="scss">
@use "@/assets/styles/manage";
</style>
<style lang="scss" scoped>
.app-container {
  //@apply rounded-md dark:rounded-md shadow-sm border bg-white dark:border-neutral-600 dark:bg-gray-800;

  height: var(--height-main-content);
  //margin-left: 0;
  //border-top-left-radius: 15px;
  border-top: 1px solid var(--divider);
  //border-left: 1px solid var(--divider);
  @apply flex justify-between;
  main {
    @apply overflow-x-auto p-5 flex-auto;
  }
}

aside {
  //border-color: var(--divider);
  @apply min-w-56 py-7 px-5;
  ul {
    li {
      @apply cursor-pointer my-2 py-2 hover:bg-gray-400/10 rounded-md dark:hover:bg-opacity-5 flex items-center;
      & span:first-child {
        @apply mr-3 h-5;
      }
      &.selected {
        @apply bg-white shadow text-black font-bold;
        svg {
          @apply fill-blue-600;
        }
        & span:first-child {
          @apply border-l border-blue-400 mr-3 h-4;
        }
      }
    }
  }
}
</style>
